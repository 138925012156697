import { useState } from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { DeleteButton, DetailButton, textCutStyle } from '../../Components/common/CommonElement';

import { api__delFanOnly, api__getFanOnlyList } from '../../services/ArtistPageService';
import { FanOnlyInfo, FanOnlyListInfo } from '../../Utils/types';
import { addCommas } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';

import CommonTable from '../../Components/CommonTable';

export default function FanOnly() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<FanOnlyListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<FanOnlyInfo | null>(null);

  return (
      <CommonTable
        dataListInfo={dataListInfo}
        setDataListInfo={setDataListInfo}
        hasArtistList

        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteCol="fanOnlyId"
        deleteDesc="content"

        mainTitle='아티스트 페이지 관리 / 팬끼리'
        pageTitle={`팬끼리 / ${addCommas(dataListInfo?.totalCount || 0)}개`}
        route={ROUTES.FANONLY_MAIN}

        getApiFn={api__getFanOnlyList}
        delApiFn={api__delFanOnly}

        ths={[
          { name: "#", width: 40 },
          { name: "아티스트명", width: 150 },
          { name: "계정 닉네임", width: 150 },
          { name: "내용", width: 200 },
          { name: "업로드 일시", width: 120 },
          { name: "삭제여부", width: 80 },
          { name: "관리", width: 120 },
        ]}
      >
        <TableBody>
          {dataListInfo && dataListInfo.data.map((item, uidx) => (
            <TableRow key={`user-${uidx}`}>
              <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
              <TableCell>{item.artistNameKo}</TableCell>
              <TableCell>{item.uid === null ? "someone" : item.uploaderNickname}</TableCell>
              <TableCell>
                <Typography variant='body2' sx={{ ...textCutStyle }}>
                  {item.content}
                </Typography>
              </TableCell>
              <TableCell>{item.rgstDt}</TableCell>
              <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
              <TableCell>
                <DetailButton onClick={() => { navigate(ROUTES.FANONLY_MAIN + "/" + item.fanOnlyId) }}>상세</DetailButton>
                {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </CommonTable>
  )
}