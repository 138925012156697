import { useEffect, useState, useRef } from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { DeleteButton, DetailButton, textCutStyle } from '../../Components/common/CommonElement';

import { api__delGalleries, api__getGalleriesList } from '../../services/ArtistPageService';
import { GalleriesInfo, GalleriesListInfo, } from '../../Utils/types';
import { addCommas, detailTimeFormat, getCodes } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { COLORS } from '../../Constant/COLORS';
import CommonTable from '../../Components/CommonTable';

export default function Galleries() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<GalleriesListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<GalleriesInfo | null>(null);


  const [groupType, setGroupType] = useState({});
  const codeProcess = useRef(false);

  const getMyCodes = () => {
    getCodes(codeProcess, "GALLERY_TAG", setGroupType);
  }

  useEffect(() => {
    getMyCodes();
  }, [])

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="galleryId"
      deleteDesc="content"

      mainTitle='아티스트 페이지 관리 / 갤러리'
      pageTitle={`갤러리 / ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.GALLERY_MAIN}

      getApiFn={api__getGalleriesList}
      delApiFn={api__delGalleries}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 150 },
        { name: "계정 닉네임", width: 150 },
        { name: "내용", width: 200 },
        { name: "분류", width: 80 },
        { name: "업로드 일시", width: 120 },
        { name: "삭제여부", width: 80 },
        { name: "관리", width: 120 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.uid === null ? "someone" : item.uploaderNickname}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{ ...textCutStyle }}>
                {item.content}
              </Typography>
            </TableCell>
            <TableCell>{groupType[item.tag] || "미분류"}</TableCell>
            <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.GALLERY_MAIN + "/" + item.galleryId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}