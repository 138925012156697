import React from "react";
import { OptionButton } from "./CommonElement";
import MySelectBox from "./MySelectBox";
import { MEMBERSHIP_FILTER_TYPE } from "../../Constant/MEMBERSHIP";

interface OpenRangeTypeSelectBoxProps {
  isTypeDropped: boolean;
  setIsTypeDropped: React.Dispatch<React.SetStateAction<boolean>>;
  selectedType: string;
  changeTypeSelect: Function;
}

const OpenRangeTypeSelectBox = ({
  isTypeDropped,
  setIsTypeDropped,
  selectedType,
  changeTypeSelect,
}: OpenRangeTypeSelectBoxProps) => {

  return (
    <MySelectBox
      value={selectedType}
      width={224}
      isDefault={true}
      isDropped={isTypeDropped}
      setIsDropped={setIsTypeDropped}
    >
      {Object.entries(MEMBERSHIP_FILTER_TYPE).map(([key, { type, code }], idx) => (
        <OptionButton
          key={`al_${idx}`}
          onClick={() => {
            changeTypeSelect(code, type); // `code`와 `type` 모두 전달
          }}
          className={selectedType === type ? "selected" : ""}
        >
          {type} {/* 화면에 표시할 텍스트 */}
        </OptionButton>
      ))}
    </MySelectBox>
  );
};

export default OpenRangeTypeSelectBox;

