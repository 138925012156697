import { useState, useEffect } from 'react';
import { Box, Typography, styled } from "@mui/material"
import { ICONIFY_ICONS } from "../../Constant/IMAGES";
import { Icon } from "@iconify/react";
import { SBCBox } from './CommonElement';
import { findElementWithClassAtClickPath } from '../../Utils/utils';

const ThisSelectBox = styled(Box)({
	padding: '7px 16px',
	borderRadius: '8px',
	border: '1px solid #ccc',
	cursor: 'pointer',
	position: 'relative'
})
const OptionBox = styled(Box)({
	width: '100%',
	padding: '16px 12px',
	backgroundColor: 'white',
	borderRadius: '8px',
	border: '1px solid #ccc',
	position: 'absolute',
	left: 0,
	top: '40px',
	zIndex: 11,
	height: '240px',
	overflow: 'auto',
	'&::-webkit-scrollbar-button': {
		// display : 'none'
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#E0E2E7',
		borderRadius: '5px'
	},
})

type IProps = {
	width?: number;
	value: string;
	children: React.ReactNode;
	isDefault: boolean;
	isDropped: boolean;
	setIsDropped: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MySelectBox({ width, value, children, isDefault, isDropped, setIsDropped }: IProps) {
	const [modalId] = useState(String(new Date().getTime()));

	useEffect(() => {
		if (isDropped) {
			const check = (e) => {
				e.stopPropagation();
				const elementWithClass = findElementWithClassAtClickPath(e, modalId);
				if (!elementWithClass) {
					setIsDropped(!isDropped);
				}
			}
			window.addEventListener("click", check);

			return () => {
				window.removeEventListener("click", check);
			}
		}
		// eslint-disable-next-line
	}, [isDropped])
	return (
		<>
			<ThisSelectBox style={{ width: `${width ? width : 200}px` }} className={modalId}>
				<SBCBox onClick={() => { setIsDropped(!isDropped) }}>
					<Typography variant="body2" color={isDefault ? "#ccc" : "#222"}>{value}</Typography>
					<Icon icon={isDropped ? ICONIFY_ICONS.ARROW_UP : ICONIFY_ICONS.ARROW_DOWN} width={18} height={18} />
				</SBCBox>
				{isDropped && (<OptionBox>{children}</OptionBox>)}
			</ThisSelectBox>

		</>
	)
}