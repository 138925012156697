import { CCBOX } from "../Components/common/CommonElement";
import MainLayout from "../layout/MainLayout";

export default function Home() {
  return (
    <MainLayout title="홈">
      {process.env.REACT_APP_ENV === "DEV" && (
        <CCBOX flexDirection='column' sx={{ height: 'calc(100vh - 70px)' }}>
          <img src="/img/home/hiimdev.gif" alt="데브" style={{ width: '60vw' }} />
          <h4 style={{ fontSize: '36px' }}>안녕! 나는 데브야!</h4>
        </CCBOX>
      )}
    </MainLayout>
  );
}
