import { useEffect, useState } from 'react';
import { DCBox, DeleteButton, ImageBox, ImageDeleteBtn, ImageModalBox } from "./CommonElement";
import SimpleModal from "./SimpleModal";
import { images } from '../../Utils/types';
import { Icon } from '@iconify/react';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { COLORS } from '../../Constant/COLORS';
import { Box } from '@mui/material';

type IProps = {
    imgModal: string;
    setImgModal: React.Dispatch<React.SetStateAction<string>>;
    imgList?: images[];
}

export default function ImagePopup({ imgModal, setImgModal, imgList }: IProps) {
    const [leftImg, setLeftImg] = useState('');
    const [rightImg, setRightImg] = useState('');
    useEffect(() => {
        const escFn = (e) => {
            if (e.keyCode === 27) {
                setImgModal("");
            } else if (e.keyCode === 37) {
                if (leftImg !== "") setImgModal(leftImg);
            } else if (e.keyCode === 39) {
                if (rightImg !== "") setImgModal(rightImg);
            }
        }
        window.addEventListener("keydown", escFn);

        return () => {
            window.removeEventListener("keydown", escFn);
        }
        // eslint-disable-next-line
    }, [leftImg, rightImg]);

    useEffect(() => {
        if (imgList === undefined) return;
        const idx = imgList?.findIndex((item) => item.imgUrl === imgModal);
        if (idx === 0) {
            setLeftImg('');
        } else setLeftImg(imgList[idx - 1].imgUrl);
        if (idx === (imgList?.length - 1)) {
            setRightImg('');
        } else setRightImg(imgList[idx + 1].imgUrl);

    }, [imgModal, imgList]);
    return (
        <SimpleModal>
            <ImageModalBox position='relative'>
                <ImageDeleteBtn
                    style={{
                        right: '12px',
                        top: '12px',
                        minWidth: '20px',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: '#ff5656',
                        border: 'none'
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setImgModal("");
                    }}>
                </ImageDeleteBtn>
                <DCBox mt="12px" ml="12px">
                    <DeleteButton
                        style={{ border: 'none', color: leftImg === "" ? COLORS.SIDEBAR_GRAY : COLORS.MAIN }}
                        onClick={() => { if (leftImg !== "") setImgModal(leftImg); }}
                    >
                        <Icon icon={ICONIFY_ICONS.ARROW_LEFT} width={24} height={24} />
                    </DeleteButton>
                    <DeleteButton
                        style={{ border: 'none', color: rightImg === "" ? COLORS.SIDEBAR_GRAY : COLORS.MAIN }}
                        onClick={() => { if (rightImg !== "") setImgModal(rightImg); }}
                    >
                        <Icon icon={ICONIFY_ICONS.ARROW_RIGHT} width={24} height={24} />
                    </DeleteButton>
                </DCBox>
                <Box width={'720px'} height={'540px'} m="12px auto">
                    <ImageBox>
                        <img src={imgModal} alt="popup_image" />
                    </ImageBox>
                </Box>
            </ImageModalBox>
        </SimpleModal>
    )
}