import { useRef, useState } from "react";
import { BackBtn, DCBox, DeleteButton, InfoTextBox, InfoTitleBox, MainButton, PageTitle, SBCBox, StretchBox, TextInputInBBox } from "../../Components/common/CommonElement";
import MainLayout from "../../layout/MainLayout"
import { CreateArtistMemberAccountInfo } from "../../Utils/types";
import { api__dupleMemberEmail, api__dupleMemberName, api__postArtistMemberInfo, api__postManagerInfo } from "../../services/ManageUserService";
import { logoutWithOutNavigate, validateEmail } from "../../Utils/utils";
import { useLocation, useNavigate, useParams } from "react-router";
import ImageUploader2 from "../../Components/common/ImageUploader2";

const ArtistMemberNew = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [memberInfo, setMemberInfo] = useState<CreateArtistMemberAccountInfo>({
    email: '',
    emailCheck: false,
    fullName: '',
    nickName: '',
    profileImgUrl: '',
    nameCheck: false,
  });
  const isPostProgress = useRef(false);
  const changeState = (value: string, target: string) => {
    setMemberInfo((prev) => {
      const clone = { ...prev };
      clone[target] = value;
      if (target === "nickName") clone.nameCheck = false;
      else if (target === "email") clone.emailCheck = false;
      return clone;
    })
  }
  const dupleCheckEmail = () => {
    if (!memberInfo.email.trim()) return window.alert("이메일을 입력해주세요.");
    if (validateEmail(memberInfo.email) === false) return window.alert("이메일 형식이 올바르지 않습니다.");
    api__dupleMemberEmail(memberInfo.email).then((res) => {
      if (!res) return;
      const { code, data } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        if (data.isDuplicated) window.alert(`중복된 이메일입니다.\nemail : ${memberInfo.email}`);
        else window.alert(`사용이 가능합니다.\nemail : ${memberInfo.email}`);
        setMemberInfo({ ...memberInfo, emailCheck: !data.isDuplicated });
      }
    }).catch(console.error)
  }
  const dupleCheckName = () => {
    if (!memberInfo.nickName.trim()) return window.alert("닉네임을 입력해주세요.");
    api__dupleMemberName(params.artistId || '', memberInfo.nickName).then((res) => {
      if (!res) return;
      const { code, data } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        if (data.isDuplicated) window.alert(`중복된 닉네임입니다.\nnickName : ${memberInfo.nickName}`);
        else window.alert(`사용이 가능합니다.\nnickName : ${memberInfo.nickName}`);
        setMemberInfo({ ...memberInfo, nameCheck: !data.isDuplicated });
      }
    }).catch(console.error)
  }
  const createArtist = () => {
    if (!memberInfo.emailCheck) return window.alert("이메일 중복체크를 해주세요.");
    if (!memberInfo.nameCheck) return window.alert("닉네임 중복체크를 해주세요.");
    if (memberInfo.fullName!.trim() === '') return window.alert("활동명(본명)을 입력해주세요.");
    if (memberInfo.profileImgUrl === '') return window.alert("계정 이미지를 입력해주세요.");
    if (isPostProgress.current) return;
    isPostProgress.current = true;
    const apiFn = location.state.isManager ? api__postManagerInfo : api__postArtistMemberInfo;
    apiFn(memberInfo, params.artistId || '').then((res) => {
      if (!res) return;
      const { code } = res;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "L006") {
        window.alert("솔로 아티스트는 계정을 하나만 가질 수 있습니다.")
        return;
      }
      if (code === "S200") {
        window.alert("계정이 생성되었습니다.");
        navigate(-1);
      }
    }).catch(console.error).finally(() => { isPostProgress.current = false; })
  }

  return (
    <MainLayout title="아티스트 / 계정 발급">
      <SBCBox mt="38px" mb="29px">
        <DCBox>
          <BackBtn />
          <PageTitle>계정 발급</PageTitle>
        </DCBox>
        <MainButton onClick={createArtist}>계정 발급</MainButton>
      </SBCBox>
      <CheckInfos
        title="계정 이메일ID"
        changeState={(val) => { changeState(val, "email") }}
        target={memberInfo.email}
        checkFn={dupleCheckEmail}
        checking={memberInfo.emailCheck}
      />
      <CheckInfos
        title="계정 닉네임"
        changeState={(val) => { changeState(val, "nickName") }}
        target={memberInfo.nickName}
        checkFn={dupleCheckName}
        checking={memberInfo.nameCheck}
      />
      <StretchBox>
        <InfoTitleBox>{location.state.isManager ? "관리자명" : "활동명(본명)"}</InfoTitleBox>
        <InfoTextBox>
          <TextInputInBBox style={{ width: '400px' }} value={memberInfo.fullName || ''}
            onChange={(e) => { changeState(e.target.value, "fullName") }} />
        </InfoTextBox>
      </StretchBox>
      <StretchBox>
        <InfoTitleBox>계정 이미지</InfoTitleBox>
        <InfoTextBox>
          <ImageUploader2
            title=''
            width={62}
            height={62}
            imageUrl={memberInfo.profileImgUrl}
            size='750x750'
            onChange={(url: string) => { changeState(url, "profileImgUrl") }}
            folderName='artistProfiles'
          />
        </InfoTextBox>
      </StretchBox>
    </MainLayout>
  )
}

export default ArtistMemberNew;

const CheckInfos = ({ title, changeState, target, checking, checkFn }) => (
  <StretchBox>
    <InfoTitleBox>{title}</InfoTitleBox>
    <InfoTextBox sx={{ pr: "12px" }}>
      <TextInputInBBox style={{ width: '400px' }} value={target}
        onChange={(e) => { changeState(e.target.value) }} />
      <DeleteButton
        style={{ marginLeft: 'auto' }}
        onClick={() => { if (!checking) { checkFn() } }}>
        {checking ? "체크완료" : "중복체크"}
      </DeleteButton>
    </InfoTextBox>
  </StretchBox>
)