import { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router";
import { Box, Typography } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { AMPMButton, BackBtn, CheckLabelStyle, DCBox, DayPickerBox, DeleteButton, DetailButton, DisableBox, FlexBox, ImageBox, ImageDeleteBtn, ImageSizeBox, InfoComp, InfoTextBox, InfoTitleBox, InfoTxtComp, OptionButton, PageTitle, ResetButton, SBCBox, SimepleTextArea, StretchBox, TextInputInBBox, TimeInput } from '../../Components/common/CommonElement';
import { api__getCalendarDetail, api__putCalendar } from '../../services/CalendarService';
import { CalendarDetailInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat, findElementWithClassAtClickPath, getCodes, getInfo, logoutWithOutNavigate } from '../../Utils/utils';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import MySelectBox from '../../Components/common/MySelectBox';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { ReactSVG } from 'react-svg';
import { ko } from 'date-fns/locale';
import ImageUploadModal from '../../Components/common/ImageUploadModal';
import ImagePopup from '../../Components/common/ImagePopup';


export default function CalendarDetail() {
	const [detailInfo, setDetailInfo] = useState<CalendarDetailInfo>({
		calendarId: '',
		content: '',
		imgList: [],
		linkUrl: '',
		scheduleDt: '',
		scheduleTime: '',
		tag: '',
		title: '',
		viewCnt: 0,
		youtubeUrl: '',
		artistNameKo: '',
		openYn: "Y",
		rgstDt: '',
		uploaderNickname: '',
		rgstId: '',
		artistUseYn: "Y"
	});

	const [dpOn, setDpOn] = useState(false);
	const [isAM, setIsAM] = useState(true);
	const [hour, setHour] = useState("00");
	const [minute, setMinute] = useState("00");
	const [timeOn, setTimeOn] = useState(false);

	const [selected, setSelected] = useState<Date>();
	const [tagIsDropped, setTagIsDropped] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [imgModal, setImgModal] = useState("");
	const [imgUploadModal, setImgUploadModal] = useState(false);

	const { calendarFeedId } = useParams();

	const [groupType, setGroupType] = useState<any>({});
	const codeProcess = useRef(false);

	const getMyInfo = () => {
		getInfo(api__getCalendarDetail, calendarFeedId, setDetailInfo);
	}

	const getMyCodes = () => {
		getCodes(codeProcess, "CALENDAR_TAG", setGroupType);
	}

	const changeEditState = (value: string, target: string) => {
		if (!isEdit) setIsEdit(true);
		setDetailInfo({ ...detailInfo, [target]: value });
	}
	useEffect(() => {
		getMyInfo();
		// eslint-disable-next-line
	}, [calendarFeedId]);

	useEffect(() => {
		if (detailInfo.rgstId !== "") {
			setDetailInfo(prev => {
				const clone = { ...prev };
				for (let i of clone.imgList) i["flag"] = "default";
				return clone;
			})
		}
	}, [detailInfo.rgstId]);

	const changeTagSelect = (tVal: string) => {
		if (!isEdit) setIsEdit(true);
		setDetailInfo({ ...detailInfo, tag: tVal });
		setTagIsDropped(false);
	}
	const hourChange = (e) => {
		if (e.target.value === "") {
			setHour(e.target.value);
		} else {
			const regex = /^[0-9\s]+$/;
			if (!regex.test(e.target.value)) return;
			const num = Number(e.target.value);
			if (num > 12) return;
			setHour(e.target.value);
		}
	}
	const minuteChange = (e) => {
		if (e.target.value === "") {
			setMinute(e.target.value);
		} else {
			const regex = /^[0-9\s]+$/;
			if (!regex.test(e.target.value)) return;
			const num = Number(e.target.value);
			if (num > 59) return;
			setMinute(e.target.value);
		}
	}

	const checkDateValidation = () => {
		if (!selected) {
			window.alert("날짜를 선택해주세요.");
			return;
		}
		if (!isEdit) setIsEdit(true);
		if (timeOn) {
			const checkH = hour === "" ? "00" : hour;
			const checkM = minute === "" ? "00" : minute;
			const h = isAM ? checkH : `${Number(checkH) + 12}`;
			setDetailInfo({ ...detailInfo, scheduleDt: moment(selected).format("YYYYMMDD"), scheduleTime: h + checkM + "00" });
		} else {
			setDetailInfo({ ...detailInfo, scheduleDt: moment(selected).format("YYYYMMDD"), scheduleTime: "" });
		}
		setDpOn(false);
	}

	useEffect(() => {
		if (dpOn) {
			const check = (e) => {
				const elementWithClass = findElementWithClassAtClickPath(e, 'date-modal');
				if (!elementWithClass) {
					setDpOn(!dpOn);
				}
			}
			window.addEventListener("click", check);

			return () => {
				window.removeEventListener("click", check);
			}
		}
		// eslint-disable-next-line
	}, [dpOn]);

	const removeImageOnList = (url: string) => {
		if (!isEdit) setIsEdit(true);
		setDetailInfo(prev => {
			const clone = { ...prev };
			const { imgList } = clone;
			const idx = imgList.findIndex((item) => item.imgUrl === url);
			if (imgList[idx].flag === "default") imgList[idx].flag = "delete";
			else if (imgList[idx].flag === 'new') imgList.splice(idx, 1);
			return clone;
		})
	}

	const updateFn = async () => {
		try {
			const { tag, title, content, scheduleDt, scheduleTime, youtubeUrl, linkUrl, imgList } = detailInfo;
			if (title.trim() === "") throw new Error("fill_title");

			const params = { tag, title, content, scheduleDt, scheduleTime, youtubeUrl, linkUrl };
			if (scheduleTime !== "") params["scheduleTime"] = scheduleTime;
			if (youtubeUrl !== "") params["youtubeUrl"] = youtubeUrl;
			if (linkUrl !== "") params["linkUrl"] = linkUrl;
			const imgCol: any[] = [];
			let j = 0;
			for (let i of imgList) {
				if (i.flag === "default") {
					j++;
					continue;
				} else if (i.flag === "new") {
					j++;
					i.orderSeq = j;
				}
				imgCol.push(i);
			}
			if (imgCol.length !== 0) params["imgList"] = imgCol;

			const result = await api__putCalendar(params, calendarFeedId || "");


			if (!result) return;
			const { code, msg } = result.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert("수정이 완료 되었습니다.");
			window.location.reload();
		} catch (e: any) {
			console.error(e);
		}
	}

	const editActive = () => {
		if (editMode) {
			if (isEdit) updateFn();
			setIsEdit(false);
		}
		setEditMode(!editMode);
	}

	const newImages = (datas: string[]) => {
		if (!isEdit) setIsEdit(true);
		setDetailInfo(prev => {
			const clone = { ...prev };
			const updateImg = clone.imgList.concat(
				datas.map((item) => ({
					flag: "new",
					seq: -1,
					orderSeq: -1,
					imgUrl: item
				}))
			)
			clone.imgList = updateImg;
			return clone;
		})
	}
	useEffect(() => {
		getMyCodes();
	}, [])

	return (
		<>
			{imgModal && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} imgList={detailInfo.imgList} />}
			{imgUploadModal && <ImageUploadModal
				setImgModal={setImgModal}
				setImgUploadModal={setImgUploadModal}
				getMyInfo={() => { }}
				api_Fn={newImages}
				no_api
			/>}

			<MainLayout title="캘린더 관리 / 일정 게시물">
				<Box p="38px 34px">
					<SBCBox mb="29px">
						<DCBox>
							<BackBtn />
							<PageTitle>캘린더 일정 상세</PageTitle>
						</DCBox>
						<FlexBox>
							<DeleteButton sx={{ marginRight: '15px' }}>삭제</DeleteButton>
							<DetailButton onClick={editActive}>{editMode ? "수정 적용" : "수정"}</DetailButton>
						</FlexBox>
					</SBCBox>
					<Box>
						<StretchBox>
							<InfoTitleBox>아티스트</InfoTitleBox>
							<InfoTextBox>
								<TextInputInBBox style={{ width: '332px' }} value={detailInfo.artistNameKo} readOnly />
								<Typography ml="9px" variant='body2'>ㆍ변경 불가</Typography>
							</InfoTextBox>
						</StretchBox>
						<InfoComp title='계정 닉네임' value={detailInfo.rgstId.includes("admin") ? detailInfo.rgstId : detailInfo.uploaderNickname} />
						<InfoTxtComp title='제목' value={detailInfo.title} readonly={!editMode} maxLength={200}
							onChange={(e) => { changeEditState(e.target.value, "title") }}
						/>
						<StretchBox>
							<InfoTitleBox>분류</InfoTitleBox>
							<InfoTextBox>
								<MySelectBox value={groupType[detailInfo.tag]} width={332} isDefault={detailInfo.tag === ""} isDropped={tagIsDropped} setIsDropped={editMode ? setTagIsDropped : () => { }}>
									{
										Object.entries(groupType).map(([key, value]) => (
											<OptionButton
												onClick={() => { changeTagSelect(key) }} key={`tag_${key}`}
												className={detailInfo.tag === key ? "selected" : ""}
											>
												{String(value)}
											</OptionButton>
										))
									}
								</MySelectBox>
								<Typography ml="9px" variant='body2'>ㆍ1개 선택 가능</Typography>
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>일정</InfoTitleBox>
							<InfoTextBox position='relative'>
								<SBCBox style={{ width: '332px' }} border={'1px solid #ADB5BD'} borderRadius={'6px'} pl="12px">
									<Typography variant='body2' color={detailInfo.scheduleDt === "" ? "#ADB5BD" : "#222"}>
										{detailInfo.scheduleDt === ""
											? "YYYY.MM.DD 오전/오후 hh:mm"
											: detailInfo.scheduleTime === ""
												? moment(detailInfo.scheduleDt).format("YYYY. MM. DD")
												: detailTimeFormat(`${detailInfo.scheduleDt} ${detailInfo.scheduleTime}`)
										}
									</Typography>
									<ResetButton sx={{ minWidth: '40px' }} onClick={(e) => {
										if (editMode) {
											e.stopPropagation();
											setDpOn(prev => !prev)
										}
									}}>
										<ReactSVG style={{ transform: 'translateY(3px)' }} src={SVG_IMAGES.DATE_PICKER} />
									</ResetButton>
								</SBCBox>
								{
									dpOn &&
									<DayPickerBox className='date-modal'>
										<CheckLabelStyle sx={{ ml: '12px', mt: '12px' }}>
											<input type="checkbox" checked={timeOn} onChange={(e) => { setTimeOn(e.target.checked) }} />
											<div className={timeOn ? "custom active" : "custom"}>
												<ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
											</div>
											<Typography ml="8px" width='120px' variant="h5">시간 지정</Typography>
										</CheckLabelStyle>
										<SBCBox p="10px">
											<DisableBox className={timeOn ? "" : "disabled"}>
												<TimeInput placeholder='00' type='text' value={hour} onChange={hourChange} readOnly={!timeOn} />
												<Typography variant='body2' mx="4px">:</Typography>
												<TimeInput placeholder='00' type='text' value={minute} onChange={minuteChange} readOnly={!timeOn} />
												<AMPMButton
													sx={{ ml: '8px', borderRadius: '8px 0 0 8px' }}
													className={isAM ? "selected" : ""}
													onClick={() => { if (timeOn) setIsAM(true) }}
												>
													오전
												</AMPMButton>
												<AMPMButton
													sx={{ borderRadius: '0 8px 8px 0' }}
													className={!isAM ? "selected" : ""}
													onClick={() => { if (timeOn) setIsAM(false) }}
												>
													오후
												</AMPMButton>
											</DisableBox>
											<DeleteButton onClick={checkDateValidation}>적용</DeleteButton>
										</SBCBox>
										<DayPicker
											locale={ko}
											mode="single"
											selected={selected}
											onSelect={setSelected}
											modifiersClassNames={{
												selected: 'my-selected',
												today: 'my-today'
											}}
											modifiersStyles={{
												disabled: { fontSize: '75%' }
											}}
										/>
									</DayPickerBox>
								}
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>이미지</InfoTitleBox>
							<InfoTextBox style={{ paddingRight: '12px', overflow: 'auto', whiteSpace: 'nowrap' }}>
								<DCBox
									minWidth={120}
									width={'120px'}
									height={'120px'}
									bgcolor={'#DEDEDE'}
									mr="12px"
									justifyContent={'center'}
									onClick={() => {
										if (editMode) setImgUploadModal(true);
									}}
								>
									<ReactSVG src={SVG_IMAGES.UPLOAD_IMAGE} />
								</DCBox>
								{detailInfo.imgList.map((item, idx) => (
									item.flag === "delete" ? <></> :
										<ImageSizeBox onClick={() => { setImgModal(item.imgUrl) }} key={`image_preview_${idx}`} mr="12px">
											{editMode && <ImageDeleteBtn onClick={(e) => {
												e.stopPropagation();
												if (!editMode) return;
												removeImageOnList(item.imgUrl)
											}}>
												X
											</ImageDeleteBtn>}
											<ImageBox>
												<img src={item.imgUrl} alt={`image_preview_${idx}`} />
											</ImageBox>
										</ImageSizeBox>
								))}
							</InfoTextBox>
						</StretchBox>
						{detailInfo.youtubeUrl !== null && (
							<InfoTxtComp title='유튜브 URL' value={detailInfo.youtubeUrl} readonly={!editMode}
								onChange={(e) => { changeEditState(e.target.value, "youtubeUrl") }} isUrl
							/>
						)}
						{detailInfo.linkUrl !== null && (
							<InfoTxtComp title='링크버튼 URL' value={detailInfo.linkUrl} readonly={!editMode}
								onChange={(e) => { changeEditState(e.target.value, "linkUrl") }} isUrl
							/>
						)}
						<StretchBox>
							<InfoTitleBox>내용</InfoTitleBox>
							<InfoTextBox>
								<SimepleTextArea sx={{ minHeight: '316px' }} readOnly={!editMode} value={detailInfo.content} onChange={(e) => { changeEditState(e.target.value, "content") }} />
							</InfoTextBox>
						</StretchBox>
						<InfoComp title='조회수' value={addCommas(detailInfo.viewCnt)} />
						<InfoComp title='삭제여부' value={detailInfo.openYn === "Y" ? "N" : "Y"} />
					</Box>
				</Box>
			</MainLayout>
		</>
	);
}
