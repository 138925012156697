import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import MainLayout from '../../layout/MainLayout';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router';

import {
  CheckLabelStyle,
  DCBox,
  DetailHeader,
  DetailLayoutBox,
  FEBox,
  InfoTextBox,
  InfoTitleBox,
  MainButton,
  PageTitle,
  SBCBox,
  StretchBox,
  TextInputInBBox,
} from '../../Components/common/CommonElement';
import ArtistDetailBasicInfo from '../../Components/artist/ArtistDetailBasicInfo';

import { COLORS } from '../../Constant/COLORS';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { handleError } from '../../Utils/utils';

import { CreateArtistBasicInfo, CreateMusicPlatformInfo, CreateYoutubeInfo } from '../../Utils/types';
import { api__createArtist } from '../../services/ManageUserService';
import { logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';

export default function ArtistNew() {
  const navigate = useNavigate();
  const [artistBasicInfo, setArtistBasicInfo] = useState<CreateArtistBasicInfo>({
    about: '',
    artistColor: '#fff',
    artistMainImgUrl: '',
    artistNameEn: '',
    artistNameKo: '',
    artistRecommendProfileImgUrl: '',
    genre: '',
    groupType: '001',
  });
  const [musicPlatforms, setMusicPlatforms] = useState<CreateMusicPlatformInfo[]>([
    { siteType: '001', siteName: '멜론', siteUrl: '', checked: false },
    { siteType: '002', siteName: '유튜브뮤직', siteUrl: '', checked: false },
    { siteType: '003', siteName: '스포티파이', siteUrl: '', checked: false },
    { siteType: '004', siteName: '지니', siteUrl: '', checked: false },
    { siteType: '005', siteName: '벅스', siteUrl: '', checked: false },
    { siteType: '006', siteName: '바이브', siteUrl: '', checked: false },
  ]);
  const [youtubeInfos, setYoutubeInfos] = useState<CreateYoutubeInfo[]>([
    { mainYn: 'Y', youtubeTitle: '', youtubeUrl: '' },
    { mainYn: 'N', youtubeTitle: '', youtubeUrl: '' },
    { mainYn: 'N', youtubeTitle: '', youtubeUrl: '' },
    { mainYn: 'N', youtubeTitle: '', youtubeUrl: '' },
  ]);

  // 아티스트 기본 정보 함수
  const changeBasicInfo = (value: string, column: string) => {
    setArtistBasicInfo({ ...artistBasicInfo, [column]: value });
  };
  // 뮤직 플랫폼 함수
  const changeMusicPlatformInfo = (value: string | boolean, column: string, idx: number) => {
    setMusicPlatforms(prev => {
      const clone = [...prev];
      clone[idx][column] = value;
      return clone;
    });
  };
  // 유튜브 함수
  const changeYoutubeInfo = (value: string | boolean, column: string, idx: number) => {
    setYoutubeInfos(prev => {
      const clone = [...prev];
      clone[idx][column] = value;
      return clone;
    });
  };
  // 유튜브 url 추가
  const addUrl = () => {
    setYoutubeInfos(prev => {
      const clone = [...prev];
      clone.push({
        mainYn: 'N',
        youtubeTitle: '',
        youtubeUrl: '',
      });
      return clone;
    });
  };
  // 유튜브 url 삭제
  const delUrl = (idx: number) => {
    setYoutubeInfos(prev => {
      const clone = [...prev];
      clone.splice(idx, 1);
      return clone;
    });
  };

  const completFn = async () => {
    try {
      // 아티스트 베이직 인포 유효성 체크
      const { about, artistMainImgUrl, artistNameEn, artistNameKo, artistRecommendProfileImgUrl, genre } =
        artistBasicInfo;
      if (artistNameKo.trim() === '') throw new Error('create_artist_name_ko');
      if (artistNameEn.trim() === '') throw new Error('create_artist_name_en');
      if (genre.trim() === '') throw new Error('create_artist_genre');
      if (about.trim() === '') throw new Error('create_artist_about');
      if (artistMainImgUrl === '') throw new Error('create_artist_main_img');
      if (artistRecommendProfileImgUrl === '') throw new Error('create_artist_profile_img');
      // 뮤직 플랫폼 유효성 체크
      const mp: any[] = [];
      for (let platform of musicPlatforms) {
        const { checked, siteName, siteType, siteUrl } = platform;
        if (!checked) continue;
        if (siteUrl.trim() === '') throw new Error(`${siteName}의 URL을 채워주세요.`);
        mp.push({ siteType, siteUrl });
      }
      // 유튜브 링크 유효성 체크
      for (let i = 0; i < youtubeInfos.length; i++) {
        const { mainYn, youtubeTitle, youtubeUrl } = youtubeInfos[i];
        const now = mainYn === 'Y' ? '유튜브 메인 ' : `리스트_${i} `;
        if (youtubeTitle.trim() === '') throw new Error(`${now}제목을 채워주세요.`);
        if (youtubeUrl.trim() === '') throw new Error(`${now}URL을 채워주세요.`);
      }

      const params = {
        artistBasicInfo,
        musicPlatformList: mp,
        youtubeList: youtubeInfos,
      };
      const result = await api__createArtist(params);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.alert('아티스트 생성이 완료되었습니다.');
      navigate(ROUTES.SIGNUPARTIST + '/1/10');
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <MainLayout title="캘린더 관리 / 일정 게시물">
      <DetailLayoutBox>
        <DetailHeader title="아티스트 생성" />
        <Box mb="37px">
          <ArtistDetailBasicInfo basicInfo={artistBasicInfo} basicStateChange={changeBasicInfo} editMode={true} />
          {/* MUSIC_PLATFORM_INFO_AREA_START */}
          <StretchBox>
            <InfoTitleBox>음원 사이트 URL</InfoTitleBox>
            <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start' }}>
              {musicPlatforms.map((item, idx) => (
                <DCBox width="100%" mb="8px" key={`mp_${idx}`}>
                  <CheckLabelStyle>
                    <input
                      type="checkbox"
                      checked={item.checked}
                      onChange={e => {
                        changeMusicPlatformInfo(e.target.checked, 'checked', idx);
                      }}
                    />
                    <div className={item.checked ? 'custom active' : 'custom'}>
                      <ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
                    </div>
                    <Typography ml="8px" width="120px" variant="h5">
                      {item.siteName}
                    </Typography>
                  </CheckLabelStyle>
                  <TextInputInBBox
                    value={item.siteUrl}
                    readOnly={!item.checked}
                    className={!item.checked ? 'disabled' : ''}
                    onChange={e => {
                      changeMusicPlatformInfo(e.target.value, 'siteUrl', idx);
                    }}
                  />
                </DCBox>
              ))}
            </InfoTextBox>
          </StretchBox>
          {/* MUSIC_PLATFORM_INFO_AREA_END */}
          {/* YOUTUBE_AREA_START */}
          <StretchBox>
            <InfoTitleBox>유튜브 URL</InfoTitleBox>
            <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start', paddingRight: '12px' }}>
              <DCBox mb="18px">
                <MainButton sx={{ padding: '4px 12px' }} onClick={addUrl}>
                  URL 리스트 추가
                </MainButton>
                <Typography variant="h5" ml="8px">
                  * 메인, 리스트_1, 2, 3은 아티스트 프로필 페이지에 노출됨
                </Typography>
              </DCBox>
              {youtubeInfos.map((item, idx) => (
                <Box
                  p="14px 16px"
                  border="1px solid #969696"
                  borderRadius="4px"
                  width="100%"
                  mb="18px"
                  key={`youtube_${idx}`}
                >
                  {item.mainYn === 'Y' ? (
                    <DCBox mb="16px">
                      <PageTitle style={{ fontSize: '20px' }}>메인</PageTitle>
                      <Typography variant="body2" ml="16px">
                        * 아티스트 프로필 페이지에 메인으로 적용되는 유튜브 임베드 URL 입니다.
                      </Typography>
                    </DCBox>
                  ) : (
                    <SBCBox mb="16px">
                      <PageTitle style={{ fontSize: '20px' }}>리스트_{idx}</PageTitle>
                      <MainButton
                        sx={{ bgcolor: COLORS.BUTTON_BLACK, padding: '4px' }}
                        onClick={() => {
                          delUrl(idx);
                        }}
                      >
                        삭제
                      </MainButton>
                    </SBCBox>
                  )}
                  <DCBox width={'80%'} mb="8px">
                    <Typography width={'55px'} variant="h5">
                      제목
                    </Typography>
                    <TextInputInBBox
                      value={item.youtubeTitle}
                      onChange={e => {
                        changeYoutubeInfo(e.target.value, 'youtubeTitle', idx);
                      }}
                      maxLength={200}
                    />
                  </DCBox>
                  <DCBox width={'80%'}>
                    <Typography width={'55px'} variant="h5">
                      URL
                    </Typography>
                    <TextInputInBBox
                      value={item.youtubeUrl}
                      onChange={e => {
                        changeYoutubeInfo(e.target.value, 'youtubeUrl', idx);
                      }}
                    />
                  </DCBox>
                </Box>
              ))}
            </InfoTextBox>
          </StretchBox>
          {/* YOUTUBE_AREA_END */}
        </Box>
      </DetailLayoutBox>
      <FEBox>
        <MainButton style={{ padding: '12px 28px', marginTop: '24px' }} onClick={completFn}>
          아티스트 생성
        </MainButton>
      </FEBox>
    </MainLayout>
  );
}
