import { useMemo } from "react";
import { Button, styled } from "@mui/material";
import { CDBox } from "./CommonElement";
import { COLORS } from "../../Constant/COLORS";
import { ListInfo } from "../../Utils/types";

const Page = styled(Button)({
  padding: '6px 12px',
  minWidth: '40px',
  fontSize: '16px',
  color: COLORS.MAIN,
  fontFamily: 'Pretendard',
  border: `1px solid ${COLORS.BORDER_GRAY}`,
  borderRadius: 0,
  marginLeft: '-1px',
  textTransform: 'capitalize',
  "&.active": {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: COLORS.MAIN,
    borderColor: COLORS.MAIN
  },
  "&.disabled": {
    color: COLORS.BORDER_GRAY,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  "&.first": {
    borderRadius: "8px 0 0 8px",
  },
  "&.last": {
    borderRadius: "0 8px 8px 0",
  },
})

type IProps = {
  pageInfo: ListInfo;
  getList: (page: number) => void;
};

export default function Pages({ pageInfo, getList }: IProps) {
  const { currentPage, totalPage } = pageInfo;
  const paginationInfo = useMemo(() => {
    const isLast = Math.floor((currentPage - 1) / 10) === Math.floor((totalPage - 1) / 10);
    const startPage = Math.floor((currentPage - 1) / 10) * 10;
    const pages = isLast ? totalPage - startPage : 10;
    return { isLast, startPage, pages };
  }, [currentPage, totalPage]);
  const { isLast, startPage, pages } = paginationInfo;
  if (totalPage === 0) return <></>;

  const handlePrevClick = () => {
    if (currentPage > 10) {
      getList(startPage);
    }
  };
  const handlePageClick = (calPage: number) => {
    if (currentPage !== calPage) {
      getList(calPage);
    }
  };
  const handleNextClick = () => {
    if (!isLast) {
      getList(startPage + 11);
    }
  };

  return (
    <CDBox style={{ width: "100%", marginTop: "24px" }}>
      <Page onClick={handlePrevClick} className={currentPage > 10 ? "first" : "first disabled"}>
        Prev
      </Page>
      {new Array(pages).fill(0).map((_, idx) => {
        const calPage = startPage + idx + 1;
        return (
          <Page key={`page-${idx}`} className={currentPage === calPage ? "active" : ""}
            onClick={() => handlePageClick(calPage)}
          >
            {calPage}
          </Page>
        );
      })}
      <Page onClick={handleNextClick} className={!isLast ? "last" : "last disabled"}>
        Next
      </Page>
    </CDBox>
  );
}
