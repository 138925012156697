import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  DCBox,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  MainButton,
  ModalContentTypo,
  OptionButton,
  PageTitle,
  SBCBox,
  StretchBox,
  TextInputInBBox,
} from '../../Components/common/CommonElement';
import ImageUploader from '../../Components/common/ImageUploader';
import { useParams } from 'react-router';
import { ArtistId, MembershipNewType } from '../../Utils/types';
import { api__postMembership } from '../../services/ManageUserService';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { addCommasFixed, getArtistList } from '../../Utils/utils';
import MySelectBox from '../../Components/common/MySelectBox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import { handleError } from '../../Utils/utils';

export default function ArtistMembershipNew() {
  const { artistId } = useParams();
  const [dataInfo, setDataInfo] = useState<MembershipNewType>({
    amount: 0,
    amountd: 0,
    imgUrl: '',
    name: '',
    period: '',
    benefits: '',
    detail: '',
  });
  const [noticeModal, setNoticeModal] = useState(false);
  const [pName, setPName] = useState('개월');
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const changeEditState = (value: string | number, target: string) => {
    setDataInfo(prev => {
      const clone = { ...prev };
      clone[target] = value;
      return clone;
    });
  };

  const checkValidation = () => {
    try {
      const { imgUrl, name, period, benefits, detail, amount, amountd } = dataInfo;
      if (imgUrl === '') throw new Error('membership_upload_badge');
      if (name.trim() === '') throw new Error('membership_fill_name');
      if (period === '') throw new Error('pick_period');
      if (benefits.trim() === '') throw new Error('membership_benefits');
      if (detail.trim() === '') throw new Error('membership_detail');
      if (amount <= 0 || amountd <= 0) throw new Error('membership_amount');

      setNoticeModal(true);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const createBadge = async () => {
    try {
      // amountd를 100배로 변환한 새로운 데이터 객체 생성
      const processedData = {
        ...dataInfo,
        amountd: dataInfo.amountd * 100  // USD 금액을 100배로 변환
      };
  
      const result = await api__postMembership(artistId || '', processedData);
      if (!result) return;
      
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      
      window.alert('생성이 완료되었습니다.');
      window.history.back();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const changePeriod = (value: string, name: string) => {
    setPName(name);
    setDataInfo(prev => {
      const clone = { ...prev };
      clone.period = value;
      return clone;
    });
    setIsDropped(false);
  };

  useEffect(() => {
    getArtistList(setArtistList);
  }, []);
  function detailUploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'membership-detail');
    };
  }

  return (
    <>
      {noticeModal && (
        <ModalMsgBox
          title={'멤버십을 생성하시겠어요?'}
          setModal={bool => {
            setNoticeModal(false);
            if (!bool) createBadge();
          }}
          extraBtn={'적용'}
        >
          <ModalContentTypo>
            <Typography variant="h5">
              ㆍ아티스트명 : {artistList.find(item => item.artistId === artistId)?.artistNameKo || ''}
            </Typography>
            <Typography variant="h5">ㆍ멤버십명 : {dataInfo.name}</Typography>
            <Typography variant="h5">
              ㆍ멤버십 기간 : {Number(dataInfo.period) / 30}개월({dataInfo.period}일)
            </Typography>
            <Typography variant="h5">
              ㆍ가격 : {addCommasFixed(dataInfo.amount)}원
            </Typography>
            <Typography variant="h5" mb="24px">
              ㆍ가격 : {addCommasFixed(dataInfo.amountd)}달러
            </Typography>
            <Typography variant="h5">ㆍ"적용"을 클릭하면 즉시 멤버십이 활성화되어요.</Typography>
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout
        title={`유저 관리 / 아티스트 / ${artistList.find(item => item.artistId === artistId)?.artistNameKo || ''}`}
      >
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>
                멤버십 생성 - {artistList.find(item => item.artistId === artistId)?.artistNameKo || ''}
              </PageTitle>
            </DCBox>
            <MainButton onClick={checkValidation}>멤버십 생성</MainButton>
          </SBCBox>
          <Box mb="40px">
            <InfoTxtComp
              title="멤버십명"
              value={dataInfo.name}
              onChange={e => {
                changeEditState(e.target.value, 'name');
              }}
            />
            <StretchBox>
              <InfoTitleBox>멤버십 가격(원)</InfoTitleBox>
              <InfoTextBox>
                <TextInputInBBox
                  type="number"
                  value={dataInfo.amount}
                  onChange={e => {
                    changeEditState(e.target.value, 'amount');
                  }}
                />
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>멤버십 가격(USD)</InfoTitleBox>
              <InfoTextBox>
                <TextInputInBBox
                  type="number"
                  value={dataInfo.amountd}
                  onChange={e => {
                    changeEditState(e.target.value, 'amountd');
                  }}
                />
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>멤버십 기간</InfoTitleBox>
              <InfoTextBox>
                <MySelectBox
                  value={pName}
                  width={332}
                  isDefault={dataInfo.period === ''}
                  isDropped={isDropped}
                  setIsDropped={setIsDropped}
                >
                  {new Array(12).fill(1).map((item, idx) => {
                    const nowNum = idx + 1;
                    const displayValue = `${nowNum}개월(${nowNum * 30}일)`;
                    return (
                      <OptionButton
                        key={`period_${idx}`}
                        onClick={() => {
                          changePeriod(String(nowNum * 30), displayValue);
                        }}
                        className={dataInfo.period === String(nowNum) ? 'selected' : ''}
                      >
                        {displayValue}
                      </OptionButton>
                    );
                  })}
                </MySelectBox>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>배지 이미지</InfoTitleBox>
              <InfoImageBox>
                <Box>
                  <ImageUploader
                    title=""
                    width={120}
                    height={120}
                    imageUrl={dataInfo.imgUrl}
                    size="750x750"
                    onChange={(url: string) => {
                      changeEditState(url, 'imgUrl');
                    }}
                    folderName="membership"
                  />
                </Box>
              </InfoImageBox>
            </StretchBox>
          </Box>
          <SBCBox sx={{ alignItems: 'flex-start' }}>
            <Box width={445} mr="24px">
              <InfoTitleBox sx={{ width: '100%' }}>멤버십 혜택</InfoTitleBox>
              <CKEditor
                editor={ClassicEditor}
                data={dataInfo.benefits}
                onChange={(event, editor) => {
                  changeEditState(editor.getData(), 'benefits');
                }}
              />
            </Box>
            <Box width={445}>
              <InfoTitleBox sx={{ width: '100%' }}>상품상세</InfoTitleBox>
              <CKEditor
                editor={ClassicEditor}
                data={dataInfo.detail}
                config={{ extraPlugins: [detailUploadPlugin] }}
                onChange={(event, editor) => {
                  changeEditState(editor.getData(), 'detail');
                }}
              />
            </Box>
          </SBCBox>
        </Box>
      </MainLayout>
    </>
  );
}
