import { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  CheckLabelStyle,
  DCBox,
  DeleteButton,
  DetailButton,
  FlexBox,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  ModalContentTypo,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import { ArtistId, CreateGoodduckMagazineType, magazineSelectInfo } from '../../Utils/types';

import ImageUploader from '../../Components/common/ImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ArtistSelectBox from '../../Components/common/ArtistSelectBox';
import { getArtistList } from '../../Utils/utils';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { ReactSVG } from 'react-svg';
import { api__createGoodduckMagazine } from '../../services/HomePostService';
import { ROUTES } from '../../Utils/routes';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MoreMagazineModal from '../../Components/goodduck-magazine/MoreMagazineModal';
import { handleError } from '../../Utils/utils';
import CalendarPicker from '../../Components/common/CalendarPicker';
import dayjs from 'dayjs';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';

export default function GoodduckMagazineCreate() {
  const [detailInfo, setDetailInfo] = useState<CreateGoodduckMagazineType>({
    artistId: '',
    content: '',
    linkUrl: '',
    mainImgUrl: '',
    moreMagazineList: [],
    subTitle: '',
    temporaryYn: 'Y',
    thumbImgUrl: '',
    title: '',
    youtubeUrl: '',
    bookedDt: null,
    bookedYn: 'N',
  });
  const [tempModal, setTempModal] = useState<string | null>(null);
  const isProgress = useRef(false); // ?

  const [aName, setAName] = useState('아티스트 선택');
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const [magModal, setMagModal] = useState(false);

  const [isPicked, setIsPicked] = useState(false);
  const [bookedTime, setBookedTime] = useState<Date | null>(null)

  const changeSelect = (aVal: string, nameVal: string) => {
    setDetailInfo({ ...detailInfo, artistId: aVal });
    setAName(nameVal);
    setIsDropped(false);
  };

  const changeMoreList = (item: magazineSelectInfo) => {
    setDetailInfo(prev => {
      const clone = { ...prev };
      const { moreMagazineList } = clone;

      const sIdx = moreMagazineList.findIndex(mag => mag.magazineId === item.magazineId);
      if (sIdx === -1) {
        if (moreMagazineList.length === 5) {
          // 이미 5개가 선택된 경우에는 alert창 이우 return;
          window.alert('5개까지 선택이 가능합니다.');
          return prev;
        } else {
          moreMagazineList.push(item);
          return clone;
        }
      } else {
        moreMagazineList.splice(sIdx, 1);
        return clone;
      }
    });
  };

  const changeEditState = (value: string, target: string) => {
    setDetailInfo({ ...detailInfo, [target]: value });
  };

  const createPost = async (temporaryYn: string) => {
    if (isProgress.current) return;
    isProgress.current = true;
    try {
      const { mainImgUrl, content, linkUrl, thumbImgUrl, title, youtubeUrl, subTitle, artistId, moreMagazineList } =
        detailInfo;
      if (temporaryYn === 'N') {
        if (content.trim() === '') throw new Error('fill_content');
        if (title.trim() === '') throw new Error('fill_title');
        if (subTitle.trim() === '') throw new Error('fill_subTitle');
        if (thumbImgUrl === '') throw new Error('upload_thumbImg');
        if (mainImgUrl === '') throw new Error('upload_mainImg');
      }
      // 예약 일시 및 상태 처리
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;
      const result = await api__createGoodduckMagazine({
        artistId: artistId === '' ? null : artistId,
        youtubeUrl: youtubeUrl === '' ? null : youtubeUrl,
        linkUrl: linkUrl === '' ? null : linkUrl,
        title,
        subTitle,
        content,
        thumbImgUrl,
        mainImgUrl,
        temporaryYn,
        moreMagazineIdList: moreMagazineList.map(item => item.magazineId),
        bookedDt,
      });
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('생성이 완료되었습니다.\n해당 매거진으로 이동합니다.');
      window.location.replace(ROUTES.GOODDUCKMAGAZINE_MAIN + '/' + result.data.data.magazineId);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isProgress.current = false;
    }
  };

  useEffect(() => {
    getArtistList(setArtistList);
  }, []);

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'magazine');
    };
  }

  return (
    <>
      {magModal && (
        <MoreMagazineModal
          setMagModal={setMagModal}
          changeMoreList={changeMoreList}
          selectedMagazineList={detailInfo.moreMagazineList}
        />
      )}
      {tempModal === 'create' && (
        <ModalMsgBox
          title={'게시물을 생성하시겠어요?'}
          setModal={bool => {
            setTempModal(null);
            if (!bool) createPost('N');
          }}
          extraBtn={'생성'}
        >{isPicked && !bookedTime ? (
          <>
            ㆍ예약공개 일시를 입력하셔야 등록 가능합니다.
          </>
        ) : (
          <>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
        )}</ModalMsgBox>
      )}
      {tempModal === 'temp' && (
        <ModalMsgBox
          title={'게시물을 임시저장 하시겠어요?'}
          setModal={bool => {
            setTempModal(null);
            if (!bool) createPost('Y');
          }}
          extraBtn={'임시저장'}
        >
          <ModalContentTypo>
            ㆍ임시저장된 게시물은 앱에 등록되지 않으며, 언제든 수정하여 앱에 등록 가능해요
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="홈게시물 관리 / 굿덕 매거진">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="29px">
            <DCBox>
              <BackBtn />
              <PageTitle>굿덕 매거진 생성</PageTitle>
            </DCBox>
            <FlexBox>
              <DeleteButton
                onClick={() => {
                  setTempModal('temp');
                }}
                style={{ marginRight: '20px' }}
              >
                임시저장
              </DeleteButton>
              <DetailButton
                onClick={() => {
                  setTempModal('create');
                }}
              >
                생성
              </DetailButton>
            </FlexBox>
          </SBCBox>
          <Box mb="12px">
            <InfoTxtComp
              title="제목"
              value={detailInfo.title}
              onChange={e => {
                changeEditState(e.target.value, 'title');
              }}
            />
            <InfoTxtComp
              title="부제목"
              value={detailInfo.subTitle}
              onChange={e => {
                changeEditState(e.target.value, 'subTitle');
              }}
            />
            <InfoTxtComp
              title="링크버튼 URL"
              value={detailInfo.linkUrl || ''}
              onChange={e => {
                changeEditState(e.target.value, 'linkUrl');
              }}
              isUrl
            />
            <InfoTxtComp
              title="유튜브 URL"
              value={detailInfo.youtubeUrl || ''}
              onChange={e => {
                changeEditState(e.target.value, 'youtubeUrl');
              }}
              isUrl
            />
            <StretchBox>
              <InfoTitleBox>썸네일 이미지</InfoTitleBox>
              <InfoImageBox>
                <Box mr="90px">
                  <ImageUploader
                    title="썸네일 이미지(외부)"
                    width={131}
                    height={91}
                    imageUrl={detailInfo.thumbImgUrl || ''}
                    size="1014x705"
                    onChange={(url: string) => {
                      changeEditState(url, 'thumbImgUrl');
                    }}
                    folderName="magazine"
                  />
                </Box>
                <Box>
                  <ImageUploader
                    title="메인 이미지(내부)"
                    width={91}
                    height={91}
                    imageUrl={detailInfo.mainImgUrl || ''}
                    size="750x750"
                    onChange={(url: string) => {
                      changeEditState(url, 'mainImgUrl');
                    }}
                    folderName="magazine"
                  />
                </Box>
              </InfoImageBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>아티스트 정보란 추가</InfoTitleBox>
              <InfoTextBox>
                <ArtistSelectBox
                  aName={aName}
                  artistId={detailInfo.artistId}
                  artistList={artistList}
                  changeSelect={changeSelect}
                  isDropped={isDropped}
                  setIsDropped={setIsDropped}
                  notAll
                  isMagazine
                />
                <Typography variant="body1">ㆍ1팀 선택 가능</Typography>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>굿덕매거진 더보기 선택</InfoTitleBox>
              <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start' }}>
                <DCBox>
                  <DetailButton
                    onClick={() => {
                      setMagModal(true);
                    }}
                  >
                    굿덕 매거진 더보기 선택
                  </DetailButton>
                  <Typography variant="body1">ㆍ5개까지 선택 가능</Typography>
                </DCBox>
                <Box>
                  {detailInfo.moreMagazineList.map((item, idx) => {
                    return (
                      <CheckLabelStyle key={`seletedMagazine_${idx}`} sx={{ marginY: '5px' }}>
                        <input
                          type="checkbox"
                          checked={true}
                          onChange={() => {
                            changeMoreList(item);
                          }}
                        />
                        <div className={true ? 'custom active' : 'custom'}>
                          <ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
                        </div>
                        <Typography ml="8px" variant="h5">
                          {item.title}
                        </Typography>
                      </CheckLabelStyle>
                    );
                  })}
                </Box>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>예약 공개</InfoTitleBox>
              <InfoTextBox>
                <CalendarPicker
                  isBooked={false}
                  isPicked={isPicked}
                  setIsPicked={setIsPicked}
                  bookedTime={bookedTime}
                  setBookedTime={setBookedTime}
                  editMode={true}
                />
              </InfoTextBox>
            </StretchBox>
          </Box>
          <Box width={445} margin={'0 auto'}>
            <CKEditor
              editor={ClassicEditor}
              data={detailInfo.content}
              config={{ extraPlugins: [uploadPlugin] }}
              onChange={(event, editor) => {
                changeEditState(editor.getData(), 'content');
              }}
            />
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
