import { api__fileUpload } from '../../services/CommonService';

interface ILoader {
    file: Promise<File>;
    // ... 여기에 필요한 다른 필드를 정의하세요.
}

class MyUploadAdapter {
    private loader: ILoader;
    private target: string;

    constructor(loader: ILoader, target : string) {
        this.loader = loader;
        this.target = target;
    }

    public async upload(): Promise<any> {
        const data = new FormData();
        const file = await this.loader.file;
        if(file.size >= 10000000){
            window.alert("이미지 사이즈가 너무 큽니다.");
            return null;
        }
        data.append("file", file);
        data.append("folder", this.target);

        const result = await api__fileUpload(data);
        return {
            default: result?.data.data
        };
    }

    public abort(): void {
        // TODO: 여기에 업로드를 중단하는 로직을 구현하세요.
    }
}

export default MyUploadAdapter;