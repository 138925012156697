import { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router";
import { Box } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { api__delFanOnly, api__getFanOnlyDetail } from '../../services/ArtistPageService';
import { FanOnlyDetailInfo, ReportType } from '../../Utils/types';
import { delData, detailTimeFormat, getInfo, updateReportStatus } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { BackBtn, DCBox, InfoComp, InfoContextComp, InfoImageComp, InfoURLComp, PageTitle, ReportBtnBoxes } from '../../Components/common/CommonElement';
import ImagePopup from '../../Components/common/ImagePopup';


export default function FanOnlyDetail() {
	const { state } = useLocation();
	const [detailInfo, setDetailInfo] = useState<FanOnlyDetailInfo>({
		fanOnlyId: '',
		artistNameKo: '',
		content: '',
		imgList: [],
		linkUrl: '',
		openYn: 'Y',
		rgstDt: '',
		uploaderNickname: '',
		youtubeUrl: '',
		artistUseYn: "Y",
		uid: ""
	});
	const [reportInfo, setReportInfo] = useState<ReportType | undefined>(state?.reportInfo);

	const { fanOnlyId } = useParams();
	const [deleteModal, setDeleteModal] = useState<FanOnlyDetailInfo | null>(null);
	const [imgModal, setImgModal] = useState("");

	const getMyInfo = () => {
		getInfo(api__getFanOnlyDetail, fanOnlyId, setDetailInfo);
	}

	const delMyData = (id: string) => {
		delData(id, api__delFanOnly, getMyInfo);
	}

	useEffect(() => {
		getMyInfo();
		// eslint-disable-next-line
	}, [fanOnlyId]);

	const checkDelete = (result: string | null, openYn: string) => {
		if (result !== "처리완료_삭제" && openYn === "N") {
			updateReportStatus({ result: "처리완료_삭제", seq: reportInfo?.seq }, () => { });
		}
	}

	useEffect(() => {
		if (reportInfo !== undefined && detailInfo.fanOnlyId !== '') {
			checkDelete(reportInfo.reportResult, detailInfo.openYn);
		}
		// eslint-disable-next-line
	}, [reportInfo, detailInfo.fanOnlyId])

	const commentReportHandler = async (type: string) => {
		if (reportInfo === undefined) return;
		const { seq } = reportInfo;
		if (type === "keep") {
			updateReportStatus({ result: "처리완료_유지", seq }, () => { });
		} else {
			delMyData(detailInfo.fanOnlyId);
			updateReportStatus({ result: "처리완료_삭제", seq }, () => { });
		}
		setReportInfo(prev => {
			if (prev === undefined) return prev;
			const clone = { ...prev };
			clone.reportResult = type === "keep" ? "처리완료_유지" : "처리완료_삭제";
			return clone;
		})
	}

	return (
		<>
			{imgModal && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} imgList={detailInfo.imgList}/>}
			{deleteModal && (
				<ModalMsgBox title="게시물을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(null);
					if (!bool) commentReportHandler("delete");
				}} extraBtn={'삭제'}>
				</ModalMsgBox>
			)}
			<MainLayout title="아티스트 페이지 관리 / 팬끼리">
				<Box p="38px 34px" pb="120px">
					<DCBox mb='34px'>
						<BackBtn />
						<PageTitle>팬끼리 상세</PageTitle>
					</DCBox>
					<Box mb='40px'>
						<InfoComp title='아티스트명' value={detailInfo.artistNameKo} />
						<InfoComp title='업로드 일시' value={detailTimeFormat(detailInfo.rgstDt)} />
						<InfoComp title='계정 닉네임' value={detailInfo.uid === "" ? "someone" :  detailInfo.uploaderNickname} />
						<InfoContextComp title='내용' value={detailInfo.content} />
						<InfoImageComp title='이미지' imageList={detailInfo.imgList} setImgModal={setImgModal} />
						<InfoURLComp title='링크버튼 URL' url={detailInfo.linkUrl || ""} readOnly />
						<InfoURLComp title='유튜브 URL' url={detailInfo.youtubeUrl || ""} readOnly />
						<InfoComp title='삭제여부' value={detailInfo.openYn === "Y" ? "N" : "Y"} />
					</Box>
					<ReportBtnBoxes 
						reportInfo={reportInfo}
						detailInfo={detailInfo}
						setDeleteModal={setDeleteModal}
						commentReportHandler={commentReportHandler}
					/>
				</Box>
			</MainLayout>
		</>
	);
}
