import { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  api__delMailBoxDefaultImages,
  api__getMailBoxDefaultImages,
  api__postMailBoxDefaultImages,
  api__putMailBoxDefaultImages,
} from '../../services/ArtistPageService';
import { images } from '../../Utils/types';
import { delData, logoutWithOutNavigate } from '../../Utils/utils';
import {
  BackBtn,
  DeleteButton,
  DetailButton,
  FlexBox,
  ImageBox,
  InfoTextBox,
  InfoTitleBox,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { ModalContentTypo } from '../../Components/common/CommonElement';
import { DCBox } from '../../Components/common/CommonElement';
import { ReactSVG } from 'react-svg';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { handleError } from '../../Utils/utils';
import ImagePopup from '../../Components/common/ImagePopup';
import ImageUploadModal from '../../Components/common/ImageUploadModal';

export default function MailBoxDefaultImage() {
  const [dataInfo, setDataInfo] = useState<images[]>([]);
  const [deleteModal, setDeleteModal] = useState<number | null>(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [imgModal, setImgModal] = useState('');

  const [sortDragIdx, setSortDragIdx] = useState(-1);
  const [startSortIdx, setStartSortIdx] = useState(-1);

  const isGetProgress = useRef(false);

  const getMyInfo = async () => {
    if (isGetProgress.current) return;
    isGetProgress.current = true;
    try {
      const result = await api__getMailBoxDefaultImages();
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        window.alert('토큰이 만료되었습니다.');
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const { data } = result.data;
      for (let [key, value] of Object.entries(data)) {
        if (value === null) data[key] = '';
      }
      setDataInfo(data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetProgress.current = false;
    }
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);

  const delMyData = (seq: number) => {
    delData(seq, api__delMailBoxDefaultImages, getMyInfo);
  };

  const putImgList = async (arr: any[]) => {
    try {
      const result = await api__putMailBoxDefaultImages(arr);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        window.alert('토큰이 만료되었습니다.');
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetProgress.current = false;
    }
  };

  // 드래그, 드롭에 따른 이벤트
  const sortDragEnter = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setSortDragIdx(idx);
  };
  const sortDrop = e => {
    e.preventDefault();
    if (e.type === 'drop') {
      if (startSortIdx !== sortDragIdx) {
        const clone = [...dataInfo];
        const saving = clone.splice(startSortIdx, 1)[0];
        clone.splice(sortDragIdx, 0, saving);
        setDataInfo(clone);
        const putList = clone.map(item => ({ imgSeq: item.seq, orderSeq: item.orderSeq }));
        putImgList(putList);
      }
      setSortDragIdx(-1);
      setStartSortIdx(-1);
    }
  };

  return (
    <>
      {imgModal !== '' && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} />}
      {uploadModal && (
        <ImageUploadModal
          setImgModal={setImgModal}
          setImgUploadModal={setUploadModal}
          getMyInfo={getMyInfo}
          api_Fn={api__postMailBoxDefaultImages}
          folderName="mail-box"
        />
      )}
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(dataInfo[deleteModal - 1].seq);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ{deleteModal}번 이미지</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="아티스트 페이지 관리 / 굿덕사서함">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px" style={{ alignItems: 'flex-start' }}>
            <DCBox mt="12px">
              <BackBtn />
              <PageTitle>굿덕사서함 디폴트 이미지 관리</PageTitle>
            </DCBox>
            <FlexBox flexDirection={'column'} alignItems={'flex-end'}>
              <FlexBox>
                {editOrder ? (
                  <DetailButton
                    onClick={() => {
                      setEditOrder(false);
                    }}
                  >
                    이미지 순서 변경 적용
                  </DetailButton>
                ) : (
                  <DeleteButton onClick={() => [setEditOrder(true)]}>이미지 순서 변경</DeleteButton>
                )}
                <DeleteButton
                  sx={{ marginLeft: '11px' }}
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  이미지 업로드
                </DeleteButton>
              </FlexBox>
              <Box mt="8px">
                <Typography variant="body2">ㆍ지원하는 파일형식 : jpg, png</Typography>
                <Typography variant="body2">ㆍ권장 규격 : 260x260(px)</Typography>
              </Box>
            </FlexBox>
          </SBCBox>
          <Box mb="40px">
            {dataInfo.map((item, idx) => (
              <StretchBox
                key={`default_image_${idx}`}
                className={idx === sortDragIdx ? 'here' : ''}
                onDragEnter={e => {
                  sortDragEnter(e, idx);
                }}
                onDragOver={sortDrop}
                onDrop={sortDrop}
              >
                <InfoTitleBox>{idx + 1}</InfoTitleBox>
                <InfoTextBox style={{ paddingRight: '46px' }}>
                  <SBCBox>
                    <Box
                      minWidth={120}
                      width={'120px'}
                      height={'120px'}
                      mr="12px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setImgModal(item.imgUrl);
                      }}
                    >
                      <ImageBox>
                        <img src={item.imgUrl} alt={`image_${item.seq}`} />
                      </ImageBox>
                    </Box>
                    <DCBox>
                      <DeleteButton
                        onClick={() => {
                          setDeleteModal(idx + 1);
                        }}
                      >
                        삭제
                      </DeleteButton>
                      {editOrder && (
                        <Box
                          ml="40px"
                          sx={{ cursor: 'grab' }}
                          draggable
                          onDragStart={() => {
                            setStartSortIdx(idx);
                          }}
                        >
                          <ReactSVG src={SVG_IMAGES.BIG_HAMBURGER} style={{ transform: 'translateY(3px)' }} />
                        </Box>
                      )}
                    </DCBox>
                  </SBCBox>
                </InfoTextBox>
              </StretchBox>
            ))}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
