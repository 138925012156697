import { TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import CommonTable from '../../Components/CommonTable';
import { COLORS } from '../../Constant/COLORS';
import { api__delCalendar, api__getCalendarList } from '../../services/CalendarService';
import { ROUTES } from '../../Utils/routes';
import { CalendarInfo, CalendarListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat, getCodes } from '../../Utils/utils';


export default function Calendar() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<CalendarListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<CalendarInfo | null>(null);

  const [groupType, setGroupType] = useState({});
  const codeProcess = useRef(false);

  const getMyCodes = () => {
    getCodes(codeProcess, "CALENDAR_TAG", setGroupType);
  }

  useEffect(() => {
    getMyCodes();
  }, [])

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="calendarId"
      deleteDesc="title"

      mainTitle='캘린더 관리 / 일정 게시물'
      pageTitle={`캘린더 일정 게시물 / ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.CALENDAR_MAIN}
      createTxt='캘린더 일정 업로드'

      getApiFn={api__getCalendarList}
      delApiFn={api__delCalendar}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 120 },
        { name: "계정 닉네임", width: 100 },
        { name: "일정", width: 100 },
        { name: "제목", width: 120 },
        { name: "분류", width: 50 },
        { name: "조회수", width: 50 },
        { name: "업로드 일시", width: 50 },
        { name: "삭제여부", width: 50 },
        { name: "관리", width: 50 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell sx={{ textAlign: 'center' }}>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.rgstId.includes("admin") ? item.rgstId : item.uploaderNickname}</TableCell>
            <TableCell>{detailTimeFormat(`${item.scheduleDt}`)}
            {item.scheduleTime ? ` ${item.scheduleTime.slice(0, 2)}:${item.scheduleTime.slice(2, 4)}` : ''}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{groupType[item.tag] || "미분류"}</TableCell>
            <TableCell>{item.viewCnt}</TableCell>
            <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.CALENDAR_MAIN + "/" + item.calendarId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}