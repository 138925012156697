import Cookies from "js-cookie";

export const getCookie = (name : string) => {
  return JSON.parse(Cookies.get(name) || "{}");
};
// '{"token":""}'

export const setCookie = (name : string, data: Object) => {
  Cookies.set(name, JSON.stringify(data));
};

export const removeCookie = (name : string) => {
  Cookies.remove(name);
};
