import { ROUTES } from "../Utils/routes";
import OrderTerms from "../pages/terms/OrderTerms";
import PaymentTerms from "../pages/terms/PaymentTerms";
import PersonalTerms from "../pages/terms/PersonalTerms";
import PersonalTermsCreate from "../pages/terms/PersonalTermsCreate";
import PersonalTermsDetail from "../pages/terms/PersonalTermsDetail";
import RecommendURL from "../pages/terms/RecommendURL";
import ServiceTerms from "../pages/terms/ServiceTerms";
import ServiceTermsCreate from "../pages/terms/ServiceTermsCreate";
import ServiceTermsDetail from "../pages/terms/ServiceTermsDetail";
import SignUpTerms from "../pages/terms/SignUpTerms";
import TelAuthTerms from "../pages/terms/TelAuthTerms";

export const temrsMenuList = [
  { path: ROUTES.SERVICE_TERMS + "/:page/:limit", component: ServiceTerms },
  { path: ROUTES.SERVICE_TERMS + "/new", component: ServiceTermsCreate },
  { path: ROUTES.SERVICE_TERMS + "/:seq", component: ServiceTermsDetail },

  { path: ROUTES.PERSONAL_TERMS + "/:page/:limit", component: PersonalTerms },
  { path: ROUTES.PERSONAL_TERMS + "/new", component: PersonalTermsCreate },
  { path: ROUTES.PERSONAL_TERMS + "/:seq", component: PersonalTermsDetail },

  { path: ROUTES.SIGNUP_TERMS, component: SignUpTerms },
  { path: ROUTES.TELAUTH_TERMS, component: TelAuthTerms },
  { path: ROUTES.PAYMENT_TERMS, component: PaymentTerms },
  { path: ROUTES.ORDER_TERMS, component: OrderTerms },

  { path: ROUTES.RECOMMEND_TERMS, component: RecommendURL },
]