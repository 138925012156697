import { ROUTES } from "../Utils/routes";
import PartnersAccount from "../pages/partners/PartnersAccount";
import PartnersAccountCreate from "../pages/partners/PartnersAccountCreate";
import PartnersAccountDetail from "../pages/partners/PartnersAccountDetail";
import PartnersAccountMemberDetail from "../pages/partners/PartnersAccountMemberDetail";
import PartnersAccountMemberNew from "../pages/partners/PartnersAccountMemberNew";
import PartnersNotice from "../pages/partners/PartnersNotice";
import PartnersNoticeDetail from "../pages/partners/PartnersNoticeDetail";
import PartnersNoticeNew from "../pages/partners/PartnersNoticeNew";
import PartnersPrivacy from "../pages/partners/PartnersPrivacy";
import PartnersPrivacyCreate from "../pages/partners/PartnersPrivacyCreate";
import PartnersPrivacyDetail from "../pages/partners/PartnersPrivacyDetail";
import PartnersTerms from "../pages/partners/PartnersTerms";
import PartnersTermsCreate from "../pages/partners/PartnersTermsCreate";
import PartnersTermsDetail from "../pages/partners/PartnersTermsDetail";

export const partnersMenuList = [
  { path: ROUTES.PARTNERS_ACCOUNT, component: PartnersAccount },
  { path: ROUTES.PARTNERS_ACCOUNT + "/new", component: PartnersAccountCreate },
  { path: ROUTES.PARTNERS_ACCOUNT + "/:agencyId", component: PartnersAccountDetail },
  { path: ROUTES.PARTNERS_ACCOUNT + "/:agencyId/new", component: PartnersAccountMemberNew },
  { path: ROUTES.PARTNERS_ACCOUNT + "/:agencyId/detail/:uid", component: PartnersAccountMemberDetail },

  { path: ROUTES.PARTNERS_NOTICE + "/:page/:limit", component: PartnersNotice },
  { path: ROUTES.PARTNERS_NOTICE + "/new", component: PartnersNoticeNew },
  { path: ROUTES.PARTNERS_NOTICE + "/detail/:noticeId", component: PartnersNoticeDetail },

  { path: ROUTES.PARTNERS_TERMS + "/:page/:limit", component: PartnersTerms },
  { path: ROUTES.PARTNERS_TERMS + "/new", component: PartnersTermsCreate },
  { path: ROUTES.PARTNERS_TERMS + "/:seq", component: PartnersTermsDetail },

  { path: ROUTES.PARTNERS_PERSONAL + "/:page/:limit", component: PartnersPrivacy },
  { path: ROUTES.PARTNERS_PERSONAL + "/new", component: PartnersPrivacyCreate },
  { path: ROUTES.PARTNERS_PERSONAL + "/:seq", component: PartnersPrivacyDetail },
];
