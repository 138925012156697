import { Box, Checkbox, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { BackBtn, DCBox, FlexBox, ImageBox, ImageDeleteBtn, ImageSizeBox, InfoTextBox, InfoTitleBox, InfoTxtComp, MainButton, ModalContentTypo, OptionButton, PageTitle, SBCBox, SimepleTextArea, StretchBox } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MainLayout from "../../layout/MainLayout";

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import dayjs from 'dayjs';
import _, { debounce } from 'lodash';
import { useLocation } from 'react-router';
import { ReactSVG } from 'react-svg';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';
import CalendarPicker from '../../Components/common/CalendarPicker';
import ImagePopup from '../../Components/common/ImagePopup';
import ImageUploadModal from '../../Components/common/ImageUploadModal';
import MySelectBox from '../../Components/common/MySelectBox';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { api__getNoticeArtistList, api__getNoticeData, api__postNotices, api__putNoticeData } from '../../services/AgencieServices';
import { AgencyNoticeArtistType } from '../../Utils/types';
import { logoutWithOutNavigate } from '../../Utils/utils';
import NoticePreview from './NoticePreview';


export default function PartnersNoticeNew() {
	const location = useLocation();
	const [title, setTitle] = useState("");
	const [fixed, setFixed] = useState(false);
	const [content, setContent] = useState('');
	const [youtubeUrl, setYoutubeUrl] = useState('');
	const [linkUrl, setLinkUrl] = useState('');

	const [aName, setAName] = useState("아티스트 선택");
	const [isDropped, setIsDropped] = useState(false);
	const [artistList, setArtistList] = useState<AgencyNoticeArtistType[]>([]);
	const [artistId, setArtistId] = useState("");
	const [prevent, setPrevent] = useState(false);

	const [imgList, setImgList] = useState<string[]>([]);
	const [imgUploadModal, setImgUploadModal] = useState(false);
	const [imgModal, setImgModal] = useState("");
	const [openYn, setOpenYn] = useState('Y');
	const [imageEdited, setImageEdited] = useState(false);

	const [previewOn, setPreviewOn] = useState(false);
	const [createModal, setCreateModal] = useState(false);
	const [tempModal, setTempModal] = useState(false);
	const [alertModal, setAlertModal] = useState(false);

	const [isUploaded, setIsUploaded] = useState<string>('');

	const [isPicked, setIsPicked] = useState(false);
	const [bookedTime, setBookedTime] = useState<Date | null>(null)

	// 예약 일시 및 상태 처리
	// 예약시간이 현재시각보다 이전이면 null로 처리
	const bookedDt = bookedTime && bookedTime > new Date() ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;

	// 공지글 데이터 가져오기
	const getNoticeData = () => {
		api__getNoticeData(location.state?.noticeId).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			const { title, content, fixedYn, imgUrlList, linkUrl, youtubeUrl, artistId, artistName, openYn, bookedDt, bookedYn } = res.data.data;
			setTitle(title);
			setContent(content);
			setFixed(fixedYn === 'Y');
			setImgList(imgUrlList.map((item) => item.imgUrl));
			setLinkUrl(linkUrl);
			setYoutubeUrl(youtubeUrl);
			setArtistId(artistId);
			setAName(artistName || '아티스트 선택');
			setOpenYn(openYn);
			if(bookedYn === 'Y') {
				setBookedTime(bookedDt);
				setIsPicked(true);
			} else if(bookedYn === 'N') {
				setBookedTime(null);
				setIsPicked(false);
			}
		}).catch(console.error);
	}

	const getArtistList = debounce(() => {
		if (location.state?.noticeId) {
			getNoticeData();
			setIsUploaded(location.state?.noticeId);
			return;
		}
		api__getNoticeArtistList().then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setArtistList(res.data.data);
			if (location.state?.noticeId) {
				getNoticeData();
			}
		}).catch(console.error);

	}, 300);

	const createFn = (tempYn: 'Y' | 'N') => {
		setPrevent(true);
		api__postNotices({
			artistId, content, fixedYn: fixed ? 'Y' : 'N', imgUrlList: imgList, linkUrl, title, youtubeUrl, temporaryYn: tempYn, bookedDt,
		}).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert(tempYn === 'Y' ? '임시저장 되었습니다.' : '생성되었습니다.');
			if (tempYn === 'N') {
				window.history.back();
			} else {
				setTempModal(false);
				setIsUploaded(res.data.data);
			}
		}).catch(console.error).finally(() => { setPrevent(false) });
	}
	const updateFn = (tempYn: 'Y' | 'N') => {
		setPrevent(true);
		// 현재 시각과 비교하기 위해 현재 시간을 가져옴
		const currentTime = new Date();
		const isBookedDtValid = bookedDt && new Date(bookedDt) > currentTime; // bookedDt가 현재시각보다 큰지 확인	   
		const params = {
			content, fixedYn: fixed ? 'Y' : 'N', linkUrl, openYn, title, youtubeUrl, temporaryYn: tempYn, bookedDt: isBookedDtValid ? bookedDt : null,
		};
		if (imageEdited) params['imgUrlList'] = imgList;
		if (tempYn === 'N') params['openYn'] = 'Y';
		api__putNoticeData(isUploaded, params).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert(tempYn === 'Y' ? '임시저장 되었습니다.' : '생성되었습니다.');
			if (tempYn === 'N') {
				window.history.back();
			} else {
				setTempModal(false);
			}
		}).catch(console.error).finally(() => { setPrevent(false) });
	}
	const changeSelect = (aVal: string, nameVal: string) => {
		if (aVal === 'ALL') {
			setFixed(true);
		}
		setArtistId(aVal);
		setAName(nameVal);
		setIsDropped(false);
	}
	useEffect(() => {
		getArtistList();
		// eslint-disable-next-line
	}, []);
	const changeImages = (datas) => {
		setImgList(imgList.concat(datas));
		if (location.state?.noticeId) setImageEdited(true);
		return null;
	}

	const removeImageOnList = (idx: number) => {
		setImgList(prev => {
			const clone = [...prev];
			clone.splice(idx, 1);
			return clone;
		})
		if (location.state?.noticeId) setImageEdited(true);
	}
	const handleDragChange = (result: any) => {
		if (!result.destination) return;
		const items = _.cloneDeep(imgList);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem)
		setImgList(items);
	};
	return (
		<>
			{previewOn && <NoticePreview title={title} content={content} setPreviewOn={setPreviewOn} imgList={imgList} youtubeUrl={youtubeUrl} linkUrl={linkUrl} />}
			{imgModal !== "" && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} />}
			{imgUploadModal && <ImageUploadModal
				setImgModal={setImgModal}
				setImgUploadModal={setImgUploadModal}
				getMyInfo={() => { }}
				api_Fn={changeImages}
				no_api
				folderName='notice'
			/>}
			{tempModal && (
				<ModalMsgBox title="공지글을 임시저장 하시겠어요? " setModal={(bool) => {
					setTempModal(false);
					if (!bool) {						
						if (isUploaded !== '') {
							updateFn('Y')
						} else {
							createFn('Y')
						}
					};
				}} extraBtn={'임시저장'}>
				</ModalMsgBox>
			)}
			{createModal && (
				<ModalMsgBox title="공지글을 생성하시겠어요?" setModal={(bool) => {
					setCreateModal(false);
					if (!bool) {
						if (isUploaded !== '') {
							updateFn('N')
						} else {
							createFn('N')
						}
					};
				}} extraBtn={'생성'}>
					<ModalContentTypo>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</ModalContentTypo>
				</ModalMsgBox>
			)}
			{alertModal && 
			<ModalMsgBox title="예약공개 일시를 입력하지 않았습니다." setModal={(bool) => {
				setAlertModal(false);
			}}>ㆍ예약공개 일시를 입력하셔야 등록 가능합니다.</ModalMsgBox>}

			<MainLayout title="소속사(관리자) 파트너센터 / 공지 게시물 작성" cn={prevent ? "prevent" : ''}>
				<Box p="38px 34px" pb="120px">
					<SBCBox mb="34px">
						<DCBox>
							<BackBtn />
							<PageTitle>공지 게시물 작성</PageTitle>
						</DCBox>
						<DCBox>
							<MainButton onClick={() => {
								if (artistId === '') {
									window.alert('아티스트를 선택해주세요.');
									return;
								}
								setTempModal(true)
							}}>임시저장</MainButton>
							<MainButton sx={{ marginX: '8px' }} onClick={() => { setPreviewOn(true) }}>미리보기</MainButton>
							<MainButton onClick={() => {
								if (artistId === '') {
									window.alert('아티스트를 선택해주세요.');
									return;
								}
								if (title.trim() === '') {
									window.alert('제목을 입력해주세요.');
									return;
								}
								if (content.trim() === '') {
									window.alert('내용을 입력해주세요.');
									return;
								}
								if(isPicked && !bookedTime) {
									setAlertModal(true);
									return;
								}
								setCreateModal(true)
							}}
							>생성</MainButton>
						</DCBox>
					</SBCBox>
					<MyBox>
						<StretchBox>
							<InfoTitleBox >아티스트 선택</InfoTitleBox>
							<InfoTextBox>
								{isUploaded ? aName : <MySelectBox value={aName} width={332} isDefault={artistId === ""} isDropped={isDropped} setIsDropped={setIsDropped}>
									{artistList.map((item, idx) => (
										<OptionButton
											key={`al_${idx}`}
											onClick={() => { changeSelect(item.artistId, item.nameKo) }}
											className={artistId === item.artistId ? "selected" : ""}
										>{item.nameKo}
										</OptionButton>
									))}
								</MySelectBox>}
							</InfoTextBox>
						</StretchBox>
						<InfoTxtComp title='제목' value={title} onChange={(e) => { setTitle(e.target.value) }} maxLength={50} />
						<StretchBox>
							<InfoTitleBox>고정글로 등록</InfoTitleBox>
							<InfoTextBox>
								<Checkbox
									checked={fixed}
									onChange={(e) => {
										if (artistId === 'ALL') return;
										setFixed(e.target.checked);
									}}
									color='info'
									size='medium'
									sx={{ my: 1 }}
									disabled={artistId === 'ALL'} // artistId가 'ALL'이면 비활성화
									/>
								<Box>
									<Typography variant='h6'>*고정글로 등록하면 공지 리스트 상단에 해당 게시물이 표기됩니다.</Typography>
									<Typography variant='h6'>*전체(공동) 공지 게시물은 고정글로 등록됩니다.</Typography>
								</Box>
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>이미지</InfoTitleBox>
							<InfoTextBox style={{ paddingRight: '12px', overflow: 'auto', whiteSpace: 'nowrap' }}>
								<DCBox minWidth={120} width={'120px'} height={'120px'} bgcolor={'#DEDEDE'} mr="12px" justifyContent={'center'} onClick={() => { setImgUploadModal(true) }}>
									<ReactSVG src={SVG_IMAGES.UPLOAD_IMAGE} />
								</DCBox>
								{<DragDropContext onDragEnd={handleDragChange}>
									<Droppable droppableId="dataList" direction='horizontal'>
										{(provided) => (
											<FlexBox
												className="dataList"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{imgList.map((item, idx) => (
													<Draggable
														key={`detail_image_${idx}`}
														draggableId={String(idx)}
														index={idx}
													>
														{(provided) => (
															<div
																ref={provided.innerRef}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																<ImageSizeBox onClick={() => { setImgModal(item) }} key={`image_preview_${idx}`} mr="12px">
																	<ImageDeleteBtn onClick={(e) => { e.stopPropagation(); removeImageOnList(idx) }}>
																		X
																	</ImageDeleteBtn>
																	<ImageBox>
																		<img src={item} alt={`image_preview_${idx}`} />
																	</ImageBox>
																</ImageSizeBox>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</FlexBox>
										)}
									</Droppable>
								</DragDropContext>}
							</InfoTextBox>
						</StretchBox>
						<InfoTxtComp title='유튜브 URL' value={youtubeUrl} onChange={(e) => { setYoutubeUrl(e.target.value) }} />
						<InfoTxtComp title='링크버튼 URL' value={linkUrl} onChange={(e) => { setLinkUrl(e.target.value) }} />
						<StretchBox>
							<InfoTitleBox>예약 공개</InfoTitleBox>
							<InfoTextBox>
								<CalendarPicker
									isBooked={false}
									isPicked={isPicked}
									setIsPicked={setIsPicked}
									bookedTime={bookedTime}
									setBookedTime={setBookedTime}
									editMode={true}
								/>
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>내용</InfoTitleBox>
							<InfoTextBox>
								<SimepleTextArea sx={{ minHeight: '316px' }} value={content} onChange={(e) => { setContent(e.target.value) }} />
							</InfoTextBox>
						</StretchBox>
					</MyBox>
				</Box>
			</MainLayout>
		</>
	);
}
const MyBox = styled(Box)({
	matginBottom: '24px',
	'& label': {
		width: '40px',
		height: '40px',
		cursor: 'pointer',
		marginRight: '8px',
		transform: 'translateY(2px)',
		'& input[type="checkbox"]': {
			display: 'none'
		},
		'& img': {
			width: '100%'
		}
	},
	'& h6': {
		fontSize: '13px',
		fontWeight: 500,
		lineHeigth: '24px',
		color: '#6D6D6D'
	}
})