import { useEffect, useRef, useState } from "react";
import { BackBtn, DCBox, DeleteButton, ImageBox, InfoTextBox, InfoTitleBox, MainButton, ModalContentTypo, PageTitle, SBCBox, StretchBox, TextInputInBBox } from "../../Components/common/CommonElement";
import MainLayout from "../../layout/MainLayout"
import { ArtistMemberAccountInfo } from "../../Utils/types";
import { api__activeMember, api__deleteMemberData, api__dupleMemberEmail, api__dupleMemberName, api__getMemberData, api__putMemberData } from "../../services/ManageUserService";
import { logoutWithOutNavigate, validateEmail } from "../../Utils/utils";
import { useLocation, useParams } from "react-router";
import ImageUploader2 from "../../Components/common/ImageUploader2";
import { debounce } from "lodash";
import { Box } from "@mui/material";
import ModalMsgBox from "../../Components/common/ModalMsgBox";

const ArtistMemberDetail = () => {
  const params = useParams();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [memberInfo, setMemberInfo] = useState<ArtistMemberAccountInfo>({
    email: '',
    emailCheck: false,
    fullName: '',
    nickName: '',
    profileImgUrl: '',
    nameCheck: false,
    profileId: '',
    uid: '',
    useYn: 'Y'
  });
  const [saving, setSaving] = useState<any>(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const isPutProgress = useRef(false);
  const changeState = (value: string, target: string) => {
    setMemberInfo((prev) => {
      const clone = { ...prev };
      clone[target] = value;
      if (target === "nickName") clone.nameCheck = false;
      else if (target === "email") clone.emailCheck = false;
      return clone;
    })
  }
  const dupleCheckEmail = () => {
    if (!memberInfo.email.trim()) return window.alert("이메일을 입력해주세요.");
    if (validateEmail(memberInfo.email) === false) return window.alert("이메일 형식이 올바르지 않습니다.");
    api__dupleMemberEmail(memberInfo.email).then((res) => {
      if (!res) return;
      const { code, data } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        if (data.isDuplicated) window.alert(`중복된 이메일입니다.\nemail : ${memberInfo.email}`);
        else window.alert(`사용이 가능합니다.\nemail : ${memberInfo.email}`);
        setMemberInfo({ ...memberInfo, emailCheck: !data.isDuplicated });
      }
    }).catch(console.error)
  }
  const dupleCheckName = () => {
    if (!memberInfo.nickName.trim()) return window.alert("닉네임을 입력해주세요.");
    api__dupleMemberName(params.artistId || '', memberInfo.nickName).then((res) => {
      if (!res) return;
      const { code, data } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        if (data.isDuplicated) window.alert(`중복된 닉네임입니다.\nnickName : ${memberInfo.nickName}`);
        else window.alert(`사용이 가능합니다.\nnickName : ${memberInfo.nickName}`);
        setMemberInfo({ ...memberInfo, nameCheck: !data.isDuplicated });
      }
    }).catch(console.error)
  }
  const activeFn = () => {
    api__activeMember(params.artistId!, {
      profileId: memberInfo.profileId,
      uid: memberInfo.uid,
      useYn: memberInfo.useYn === 'Y' ? 'N' : 'Y'
    }).then((res) => {
      if (!res) return;
      const { code } = res;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        window.alert("변경되었습니다.");
        setMemberInfo({ ...memberInfo, useYn: memberInfo.useYn === 'Y' ? 'N' : 'Y' });
      }
    }).catch(console.error)
  }
  const deleteFn = () => {
    api__deleteMemberData(memberInfo.uid!).then((res) => {
      if (!res) return;
      const { code } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        window.alert("삭제되었습니다.");
        window.history.back();
      }
    })
  }

  const editFn = () => {
    if (!memberInfo.emailCheck) return window.alert("이메일 중복체크를 해주세요.");
    if (!memberInfo.nameCheck) return window.alert("닉네임 중복체크를 해주세요.");
    if (memberInfo.fullName!.trim() === '') return window.alert("활동명(본명)을 입력해주세요.");
    if (memberInfo.profileImgUrl === '') return window.alert("계정 이미지를 입력해주세요.");
    if (isPutProgress.current) return;
    isPutProgress.current = true;
    api__putMemberData(memberInfo).then((res) => {
      if (!res) return;
      const { code } = res;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        window.alert("수정되었습니다.");
        setEditMode(false);
        setSaving(null);
      }
    }).catch(console.error).finally(() => { isPutProgress.current = false; })

  }

  const getMembersData = debounce(() => {
    api__getMemberData(params.uid || '').then((res) => {
      if (!res) return;
      const { code, data } = res.data;
      if (code.includes("T")) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code === "S200") {
        setMemberInfo({ ...data, emailCheck: true, nameCheck: true })
      }
    }).catch(console.error)

  }, 300);

  useEffect(() => {
    getMembersData();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {activeModal !== '' && (
        <ModalMsgBox title={memberInfo.useYn === 'Y' ? "비활성화 하시겠습니까?" : "활성화 하시겠습니까?"} setModal={(bool) => {
          setActiveModal('');
          if (!bool) { activeFn(); }
        }} extraBtn={memberInfo.useYn === 'Y' ? '비활성화' : '활성화'}>
        </ModalMsgBox>
      )}
      {editModal && (
        <ModalMsgBox title="수정하시겠습니까?" setModal={(bool) => {
          setEditModal(false);
          if (!bool) { editFn(); }
        }} extraBtn={'확인'}>
        </ModalMsgBox>
      )}
      {deleteModal && (
        <ModalMsgBox title="해당 계정을 삭제하시겠어요?" setModal={(bool) => {
          setDeleteModal(false);
          if (!bool) { deleteFn(); }
        }} extraBtn={'삭제'}>
          <ModalContentTypo>
            ㆍ계정을 삭제하는 경우 해당 계정이 남긴 게시물과 댓글은 삭제 되지 않지만, 계정 닉네임은 더이상 사용할 수 없게 됩니다.
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="아티스트 / 계정 관리">
        <SBCBox mt="38px" mb="29px">
          <DCBox>
            <BackBtn />
            <PageTitle>계정 관리</PageTitle>
          </DCBox>
          <DCBox>
            <MainButton className={memberInfo.useYn === 'Y' ? "inactive" : ''} style={{ padding: '8px 16px' }} onClick={() => {
              setActiveModal(memberInfo.useYn);
            }}>{memberInfo.useYn === 'Y' ? '비활성화' : '활성화'}</MainButton>
            <MainButton className="inactive" style={{ padding: '8px 16px', margin: "0 12px" }} onClick={() => { setDeleteModal(true) }}>삭제</MainButton>
            {editMode && <MainButton sx={{ mr: '12px' }} onClick={() => {
              setMemberInfo({ ...saving });
              setEditMode(false);
            }}>취소</MainButton>}
            <MainButton onClick={() => {
              if (!editMode) {
                setEditMode(true);
                setSaving({ ...memberInfo });
              }
              else {
                setEditModal(true);
              }
            }}>{editMode ? '수정 완료' : '정보 수정'}</MainButton>
          </DCBox>
        </SBCBox>
        <CheckInfos
          title="계정 이메일ID"
          changeState={(val) => { changeState(val, "email") }}
          target={memberInfo.email}
          checkFn={dupleCheckEmail}
          checking={memberInfo.emailCheck}
          editMode={editMode}
        />
        <CheckInfos
          title="계정 닉네임"
          changeState={(val) => { changeState(val, "nickName") }}
          target={memberInfo.nickName}
          checkFn={dupleCheckName}
          checking={memberInfo.nameCheck}
          editMode={editMode}
        />
        <StretchBox>
          <InfoTitleBox>{location.state.isManager ? "관리자명" : "활동명(본명)"}</InfoTitleBox>
          <InfoTextBox>
            {editMode ? <TextInputInBBox style={{ width: '400px' }} value={memberInfo.fullName || ''}
              onChange={(e) => { changeState(e.target.value, "fullName") }} /> : <>{memberInfo.fullName}</>}
          </InfoTextBox>
        </StretchBox>
        <StretchBox>
          <InfoTitleBox>계정 이미지</InfoTitleBox>
          <InfoTextBox>
            {editMode ? (
              <ImageUploader2
                title=''
                width={62}
                height={62}
                imageUrl={memberInfo.profileImgUrl}
                size='750x750'
                onChange={(url: string) => { changeState(url, "profileImgUrl") }}
                folderName='artistProfiles'
              />
            ) : <Box width={62} height={62}>
              <ImageBox>
                <img src={memberInfo.profileImgUrl} alt="profile" />
              </ImageBox>
            </Box>}
          </InfoTextBox>
        </StretchBox>
      </MainLayout>
    </>
  )
}

export default ArtistMemberDetail;

const CheckInfos = ({ title, changeState, target, checking, checkFn, editMode }) => (
  <StretchBox>
    <InfoTitleBox>{title}</InfoTitleBox>
    <InfoTextBox sx={{ pr: "12px" }}>
      {editMode ? <TextInputInBBox style={{ width: '400px' }} value={target}
        onChange={(e) => { changeState(e.target.value) }} /> : <>{target}</>}
      {editMode && (
        <DeleteButton
          style={{ marginLeft: 'auto' }}
          onClick={() => { if (!checking) { checkFn() } }}>
          {checking ? "체크완료" : "중복체크"}
        </DeleteButton>
      )}
    </InfoTextBox>
  </StretchBox>
)