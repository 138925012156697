import { Box } from '@mui/material';
import { useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  BackBtn,
  DCBox,
  DeleteButton,
  DetailButton,
  FlexBox,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  ModalContentTypo,
  PageTitle,
  SBCBox,
  StretchBox
} from '../../Components/common/CommonElement';
import ImageUploader from '../../Components/common/ImageUploader';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import MainLayout from '../../layout/MainLayout';
import { api__createGoodNewsInfo } from '../../services/HomePostService';
import { ROUTES } from '../../Utils/routes';
import { CreateGoodNewsType } from '../../Utils/types';
import { handleError } from '../../Utils/utils';

import dayjs from 'dayjs';

import CalendarPicker from '../../Components/common/CalendarPicker';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';

export default function GoodNewsCreate() {
  const [detailInfo, setDetailInfo] = useState<CreateGoodNewsType>({
    content: '',
    linkUrl: '',
    thumbImgUrl: '',
    title: '',
    temporaryYn: 'Y',
    youtubeUrl: '',
    bookedDt: null,
  });
  const [tempModal, setTempModal] = useState<string | null>(null);
  const [alertModal, setAlertModal] = useState(false);
  const [isPicked, setIsPicked] = useState(false);
  const [bookedTime, setBookedTime] = useState<Date | null>(null)

  // 상세 정보 변경 함수
  const changeEditState = (value: string, target: string) => {
    setDetailInfo({ ...detailInfo, [target]: value });
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'goodnews');
    };
  }
  // 생성 버튼 클릭 시 굿뉴스 생성 함수
  const createPost = async (temporaryYn: string) => {
    try {
      const { content, linkUrl, thumbImgUrl, title, youtubeUrl } = detailInfo;
      if (temporaryYn === 'N') {
        if (content.trim() === '') throw new Error('goodnews_create_content');
        if (title.trim() === '') throw new Error('goodnews_create_title');
        if (thumbImgUrl === '') throw new Error('upload_thumbImg');
      }
      // 예약 일시 및 상태 처리
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;
      const result = await api__createGoodNewsInfo({
        content,
        linkUrl,
        thumbImgUrl,
        title,
        temporaryYn,
        youtubeUrl: youtubeUrl ? youtubeUrl : null,
        bookedDt,
      });      
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      if (temporaryYn === 'Y') {
        window.alert('임시저장이 완료되었습니다.\n해당 굿뉴스로 이동합니다.');
      } else {
        window.alert('생성이 완료되었습니다.\n해당 굿뉴스로 이동합니다.');
      }
      window.location.replace(ROUTES.GOODNEWS_MAIN + '/' + result.data.data.goodNewsSeq);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      {tempModal === 'create' && (
        <ModalMsgBox
          title={'게시물을 생성하시겠어요?'}
          setModal={bool => {
            setTempModal(null);
            if (!bool) createPost('N');
          }}
          extraBtn={'생성'}
        >
          ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}
        </ModalMsgBox>
      )}
      {tempModal === 'temp' && (
        <ModalMsgBox
          title={'게시물을 임시저장 하시겠어요?'}
          setModal={bool => {
            setTempModal(null);
            if (!bool) createPost('Y');
          }}
          extraBtn={'임시저장'}
        >
          <ModalContentTypo>
            ㆍ임시저장된 게시물은 앱에 등록되지 않으며, 언제든 수정하여 앱에 등록 가능해요
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      {alertModal && 
			<ModalMsgBox title="예약공개 일시를 입력하지 않았습니다." setModal={(bool) => {
				setAlertModal(false);
			}}>ㆍ예약공개 일시를 입력하셔야 등록 가능합니다.</ModalMsgBox>}
      <MainLayout title="홈게시물 관리 / 굿뉴스">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="29px">
            <DCBox>
              <BackBtn />
              <PageTitle>굿뉴스 생성</PageTitle>
            </DCBox>
            <FlexBox>
              <DeleteButton
                onClick={() => {
                  setTempModal('temp');
                }}
                style={{ marginRight: '20px' }}
              >
                임시저장
              </DeleteButton>
              <DetailButton
                onClick={() => {
                  setTempModal('create');
                }}
              >
                생성
              </DetailButton>
            </FlexBox>
          </SBCBox>
          <Box mb="12px">
            <InfoTxtComp
              title="제목"
              value={detailInfo.title}
              onChange={e => {
                changeEditState(e.target.value, 'title');
              }}
            />
            <InfoTxtComp
              title="링크버튼 URL"
              value={detailInfo.linkUrl || ''}
              onChange={e => {
                changeEditState(e.target.value, 'linkUrl');
              }}
              isUrl
            />
            <InfoTxtComp
              title="유튜브 URL"
              value={detailInfo.youtubeUrl || ''}
              onChange={e => {
                changeEditState(e.target.value, 'youtubeUrl');
              }}
              isUrl
            />
            <StretchBox>
              <InfoTitleBox>썸네일 이미지</InfoTitleBox>
              <InfoImageBox>
                <Box mr="90px">
                  <ImageUploader
                    title=""
                    width={332}
                    height={106}
                    imageUrl={detailInfo.thumbImgUrl || ''}
                    size="1014x705"
                    onChange={(url: string) => {
                      changeEditState(url, 'thumbImgUrl');
                    }}
                    folderName="goodNews"
                  />
                </Box>
              </InfoImageBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>예약 공개</InfoTitleBox>
              <InfoTextBox>
                <CalendarPicker
                  isBooked={false}
                  isPicked={isPicked}
                  setIsPicked={setIsPicked}
                  bookedTime={bookedTime}
                  setBookedTime={setBookedTime}
                  editMode={true}
                />
              </InfoTextBox>
            </StretchBox>
          </Box>
          <Box width={445} margin={'0 auto'}>
            <CKEditor
              editor={ClassicEditor}
              data={detailInfo.content}
              config={{ extraPlugins: [uploadPlugin] }}
              onChange={(event, editor) => {
                changeEditState(editor.getData(), 'content');
              }}
            />
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
