import axios from "axios";
import { defaultHeader, makeHeader } from "../Utils/utils";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 로그인
export const api__login = async (id: string, password: string) => {
  try {
    return await axios.post(`${rootUrl}/api/login`, { id, password }, { headers: defaultHeader });
  } catch (error) {
    console.log("api__login error ::: " + error);
    return null;
  }
};

// 비밀번호 찾기
export const api__resetPassword = async (id: string) => {
  try {
    return await axios.post(`${rootUrl}/api/reset-password`, { id }, { headers: defaultHeader });
  } catch (error) {
    console.log("api__resetPassword error ::: " + error);
    return null;
  }
};

// 이메일 인증
export const api__emailVerify = async (code: string) => {
  try {
    return await axios.get(`${rootUrl}/api/email/verify?code=${code}`, makeHeader());
  } catch (error) {
    console.log("api__emailVerify error ::: " + error);
    return null;
  }
};

export const api__getEmailById = async (adminId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/members/email?adminId=${adminId}`, makeHeader());
  } catch (error) {
    console.log("api__getEmailById error ::: " + error);
    return null; 
  }
};
