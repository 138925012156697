import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography, styled } from '@mui/material';
import { Icon } from '@iconify/react';

import MainLayout from '../../layout/MainLayout';
import {
  FButton,
  InfoTextBox,
  InfoTitleBox,
  MainButton,
  ModalContentTypo,
  SBCBox,
  StretchBox,
  TextInputInBBox,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { COLORS } from '../../Constant/COLORS';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';

import { api__changePassword, api__getMyAccountInfo } from '../../services/AdminMemberService';
import { AdminAccountInfo } from '../../Utils/types';
import { getCookie, setCookie } from '../../Utils/cookies';
import { enterFn, isEmptyCookie, logout, regexPW, toHash } from '../../Utils/utils';
import { handleError } from '../../Utils/utils';

export default function HeaderInfo() {
  const navigate = useNavigate();
  const [myInfo, setMyInfo] = useState<AdminAccountInfo | null>(null);

  const [curPW, setCurPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [checkNewPW, setCheckNewPW] = useState('');

  const [seeCurPW, setSeeCurPW] = useState(false);
  const [seeNewPW, setSeeNewPW] = useState(false);
  const [seeCheckNewPW, setSeeCheckNewPW] = useState(false);

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorMordal] = useState(false);

  const isProgress = useRef(false);

  const getInfo = async () => {
    if (isProgress.current) return;
    isProgress.current = true;
    try {
      const cookie = getCookie('myInfo');
      if (isEmptyCookie(cookie)) {
        const result = await api__getMyAccountInfo();
        if (!result) return;
        const { code, msg } = result.data;
        if (code !== 'S200') throw new Error(msg);
        setCookie('myInfo', result.data.data);
        setMyInfo(result.data.data);
      } else {
        setMyInfo(cookie);
      }
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isProgress.current = false;
    }
  };

  const changePw = async () => {
    // 이미 진행중일 경우 반환
    if (isProgress.current) return;
    isProgress.current = true;

    try {
      if (curPW.trim() === '') throw new Error('change_cur_password');
      if (newPW.trim() === '') throw new Error('change_new_password');
      if (checkNewPW.trim() === '') throw new Error('change_check_password');
      if (curPW === newPW) throw new Error('change_same');
      if (newPW !== checkNewPW) throw new Error('change_different');
      if (newPW.length < 8) throw new Error('change_short');
      if (!regexPW(newPW)) throw new Error('change_invalid');
      const result = await api__changePassword(toHash(curPW), toHash(newPW));
      if (!result) return;
      const { code, msg } = result.data;
      switch (code) {
        case 'S200': {
          setSuccessModal(true);
          break;
        }
        case 'L001': {
          setErrorMordal(true);
          break;
        }
        default:
          throw new Error(msg);
      }
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isProgress.current = false;
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      {errorModal && (
        <ModalMsgBox title="다시 시도하세요." setModal={setErrorMordal}>
          <ModalContentTypo>ㆍ현재 비밀번호가 맞는지 확인</ModalContentTypo>
          <ModalContentTypo>ㆍ새로운 비밀번호가 조건을 충족하는지 확인</ModalContentTypo>
          <ModalContentTypo>ㆍ새 비밀번호와 비밀번호 확인이 일치하는지 확인</ModalContentTypo>
        </ModalMsgBox>
      )}
      {successModal && (
        <ModalMsgBox
          title="비밀번호가 변경되었어요."
          setModal={() => {
            setSuccessModal(false);
            logout(navigate);
          }}
        >
          <ModalContentTypo>ㆍ새로운 비밀번호로 로그인 하세요.</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="">
        <Box display="flex" flexDirection="column" width="100%" padding={'44px 40px'}>
          <Typography variant="h2" color={COLORS.MAIN} mb="29px">
            정보
          </Typography>
          <Box boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)" mb="68px">
            <InfoBox title="담당자명" content={myInfo && myInfo.adminName} />
            <InfoBox title="아이디" content={myInfo && myInfo.adminId} />
            <InfoBox title="마지막 로그일시" content={myInfo && myInfo.lastLoginTime} />
          </Box>
          <Typography variant="h2" color={COLORS.MAIN} mb="29px">
            비밀번호 변경
          </Typography>
          <Box boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)" mb="9px">
            <StretchBox>
              <InfoTitleBox>현재 비밀번호</InfoTitleBox>
              <InfoTextBox style={{ paddingRight: '12px', justifyContent: 'space-between' }}>
                <TextInputInBBox
                  type={seeCurPW ? 'text' : 'password'}
                  value={curPW}
                  onChange={e => {
                    setCurPW(e.target.value);
                  }}
                  style={{ width: '70%' }}
                />
                <FButton
                  onClick={() => {
                    setSeeCurPW(prev => !prev);
                  }}
                  style={{ color: 'black' }}
                >
                  <Icon icon={seeCurPW ? ICONIFY_ICONS.EYE_OFF : ICONIFY_ICONS.EYE_EMPTY} width={24} height={24} />
                </FButton>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>새 비밀번호</InfoTitleBox>
              <InfoTextBox style={{ paddingRight: '12px', justifyContent: 'space-between' }}>
                <TextInputInBBox
                  type={seeNewPW ? 'text' : 'password'}
                  value={newPW}
                  onChange={e => {
                    setNewPW(e.target.value);
                  }}
                  style={{ width: '70%' }}
                />
                <FButton
                  onClick={() => {
                    setSeeNewPW(prev => !prev);
                  }}
                  style={{ color: 'black' }}
                >
                  <Icon icon={seeNewPW ? ICONIFY_ICONS.EYE_OFF : ICONIFY_ICONS.EYE_EMPTY} width={24} height={24} />
                </FButton>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>새 비밀번호 확인</InfoTitleBox>
              <InfoTextBox style={{ paddingRight: '12px', justifyContent: 'space-between' }}>
                <TextInputInBBox
                  style={{ width: '70%' }}
                  type={seeCheckNewPW ? 'text' : 'password'}
                  value={checkNewPW}
                  onChange={e => {
                    setCheckNewPW(e.target.value);
                  }}
                  onKeyDown={e => {
                    enterFn(e, changePw);
                  }}
                />
                <FButton
                  onClick={() => {
                    setSeeCheckNewPW(prev => !prev);
                  }}
                  style={{ color: 'black' }}
                >
                  <Icon icon={seeCheckNewPW ? ICONIFY_ICONS.EYE_OFF : ICONIFY_ICONS.EYE_EMPTY} width={24} height={24} />
                </FButton>
              </InfoTextBox>
            </StretchBox>
          </Box>
          <SBCBox>
            <Box>
              <Typography variant="body2">
                * 비밀번호 조합 : 영어, 숫자, 특수문자 조합 (특수문자는!@#$%^*만 허용)
              </Typography>
              <Typography variant="body2">* 비밀번호 길이 : 8자리 이상</Typography>
              <Typography variant="body2">* 계정 최초 발급시 임시 비밀번호 : goodduck123!</Typography>
            </Box>
            <MainButton onClick={changePw}>저장</MainButton>
          </SBCBox>
        </Box>
      </MainLayout>
    </>
  );
}

const BorderBox = styled(Box)({
  border: '1px solid #A4A4A4',
  fontSize: '16px',
  fontFamily: 'Pretendard',
  display: 'flex',
  alignItems: 'center',
  '&.left': {
    fontWeight: 700,
    backgroundColor: COLORS.SECOND,
    color: 'black',
    padding: '15px 0px',
    justifyContent: 'center',
    width: '200px',
  },
  '&.right': {
    fontWeight: 400,
    justifyContent: 'flex-start',
    padding: '15px 23px',
    width: 'calc(100% - 200px)',
  },
  '&.text': {
    justifyContent: 'space-between',
    padding: '10px',
    width: 'calc(100% - 200px)',
  },
});

const InfoBox = ({ title, content }) => (
  <SBCBox width="100%">
    <BorderBox className="left">{title}</BorderBox>
    <BorderBox className="right">{content}</BorderBox>
  </SBCBox>
);
