import { Box, styled } from "@mui/material";
import CommonHeader from "../Components/common/CommonHeader";
import CommonSideBar from "../Components/common/CommonSideBar";
import { FullSizeFixedBox } from "../Components/common/CommonElement";
import { useState } from "react";
import { COLORS } from "../Constant/COLORS";

type IProps = {
	title: string;
	children?: React.ReactNode;
	cn?: string;
	onClick?: any;
}

export default function MainLayout({ title, children = <></>, cn = '', onClick = () => { } }: IProps) {
	const [fold, setFold] = useState('');
	return (
		<>
			{cn.includes('prevent') && (
				<FullSizeFixedBox className="prevent">
					잠시만 기다려 주세요.<br />데이터 변환작업이 진행중입니다.
				</FullSizeFixedBox>
			)}
			<MainBox className={cn} onClick={onClick}>
				<CommonSideBar fold={fold} />
				<Box className={`content ${fold}`}>
					<CommonHeader title={title} setFold={setFold} />
					<Box className="child">
						{children}
					</Box>
				</Box>
			</MainBox>
		</>
	)
}

const MainBox = styled(Box)({
	height: '100vh',
	display: 'flex',
	width: '100vw',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	'& .content': {
		width: 'calc(100% - 281px)',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		transition: 'width 0.3s',
		'&.fold': {
			width: '100vw',
		}
	},
	'& .child': {
		minWidth: '960px',
		height: 'calc(100vh - 90px)',
		margin: '0 auto',
		overflow: 'auto',
	},
	"& .side-bar": {
		width: '281px',
		backgroundColor: COLORS.BLACK,
		overflow: 'auto',
		height: '100vh',
		padding: '12px 11px 41px 21px',
		transition: 'all 0.3s',
		'&.fold': {
			width: '0',
			padding: '0'
		}
	},
})