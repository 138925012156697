import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import MainLayout from '../../layout/MainLayout';

import {
  BackBtn,
  DCBox,
  DeleteButton,
  FEBox,
  InfoComp,
  ModalContentTypo,
  PageTitle,
} from '../../Components/common/CommonElement';
import EditComp from '../../Components/common/EditComp';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';

import { detailTimeFormat, getInfo, logoutWithOutNavigate } from '../../Utils/utils';
import { TermsDetailInfo } from '../../Utils/types';
import { api__getTermsDetail, api__putTermsDetail } from '../../services/TermsService';

export default function PartnersPrivacyDetail() {
  const [detailInfo, setDetailInfo] = useState<TermsDetailInfo>({
    content: '',
    rgstDt: '',
    seq: 0,
    updtDt: '',
    version: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [isEdited, setIsEdted] = useState(false);

  const [copyModal, setCopyModal] = useState(false);

  const { seq } = useParams();

  const getMyInfo = () => {
    getInfo(api__getTermsDetail, seq, setDetailInfo);
  };

  const changeDetailInfo = (value: string, column: string) => {
    if (!isEdited) setIsEdted(true);
    setDetailInfo({ ...detailInfo, [column]: value });
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [seq]);

  const editFn = async () => {
    try {
      const result = await api__putTermsDetail(detailInfo.seq, detailInfo.content);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      getMyInfo();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      {copyModal && (
        <ModalMsgBox
          title="URL이 복사되었어요."
          setModal={() => {
            setCopyModal(false);
          }}
        >
          <ModalContentTypo>ㆍURL :</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="소속사(관리자) 파트너센터 / 개인정보처리방침 상세">
        <Box p="38px 34px" pb="120px">
          <DCBox mb="34px">
            <BackBtn />
            <PageTitle>개인정보처리방침 상세</PageTitle>
          </DCBox>
          <Box mb="82px">
            <InfoComp title="버전" value={'V ' + detailInfo.version} />
            <InfoComp title="등록일시" value={detailTimeFormat(detailInfo.rgstDt)} />
            <InfoComp title="수정일시" value={detailTimeFormat(detailInfo.updtDt)} />
          </Box>
          {detailInfo.rgstDt !== '' && (
            <CKEditor
              editor={ClassicEditor}
              data={detailInfo.content}
              onChange={(event, editor) => {
                changeDetailInfo(editor.getData(), 'content');
              }}
              disabled={!editMode}
            />
          )}
          <FEBox mt="18px">
            <DeleteButton
              sx={{ mr: '12px' }}
              onClick={() => {
                setCopyModal(true);
              }}
            >
              URL 복사
            </DeleteButton>
            <EditComp editMode={editMode} setEditMode={setEditMode} setIsEdited={setIsEdted} editFn={editFn} />
          </FEBox>
        </Box>
      </MainLayout>
    </>
  );
}
