import { enterFn } from "../../Utils/utils";
import { SearchButton, SearchInput } from "./CommonElement"

type IProps = {
    text : string;
    setText : React.Dispatch<React.SetStateAction<string>>;
    applySearch : () => void;
}

export default function CommonSearch({text, setText, applySearch}: IProps) {
    return (
        <>
            <SearchInput
                value={text}
                onChange={(e) => { setText(e.target.value) }}
                onKeyDown={(e) => {enterFn(e, applySearch)}}
                type="text"
                placeholder="검색어를 입력해주세요."
            />
            <SearchButton onClick={applySearch}>검색</SearchButton>
        </>
    )
}