import { useState } from 'react';
import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import { api__delGoodduckMagazine, api__getGoodDuckMagazineList } from '../../services/HomePostService';
import { GoodduckMagazineInfo, GoodduckMagazineListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import CommonTable from '../../Components/CommonTable';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';


export default function GoodDuckMagazine() {
  const { limit } = useParams();
  const navigate = useNavigate();
  const [dataListInfo, setDataListInfo] = useState<GoodduckMagazineListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<GoodduckMagazineInfo | null>(null);

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="magazineId"
      deleteDesc="title"

      mainTitle='홈 게시물 관리 / 굿덕 매거진'
      pageTitle='굿덕매거진'
      route={ROUTES.GOODDUCKMAGAZINE_MAIN}
      createTxt='굿덕매거진 생성'

      getApiFn={api__getGoodDuckMagazineList}
      delApiFn={api__delGoodduckMagazine}

      ths={[
        { name: "No.", width: 40 },
        { name: "제목", width: 300 },
        { name: "조회수", width: 40 },
        { name: "업로드 일시", width: 200 },
        { name: "관리", width: 120 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{addCommas(item.viewCnt)}</TableCell>
            <TableCell>
              {item.temporaryYn === "Y" 
                ? "임시저장 상태" 
                : item.bookedYn === 'Y' 
                  ? (item.bookedDt && new Date(item.bookedDt) <= new Date() 
                      ? `${detailTimeFormat(item.bookedDt)}` 
                      : `${detailTimeFormat(item.bookedDt || '')} (예약)`)
                  : `${detailTimeFormat(item.rgstDt)}`}
            </TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.GOODDUCKMAGAZINE_MAIN + "/" + item.magazineId) }}>상세</DetailButton>
              <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}