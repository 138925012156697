import { useEffect, useState, useRef } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import { api__delInfoRooms, api__getInfoRoomsList } from '../../services/ArtistPageService';
import { InfoRoomsInfo, InfoRoomsListInfo } from '../../Utils/types';
import { addCommas, getCodes } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { COLORS } from '../../Constant/COLORS';
import CommonTable from '../../Components/CommonTable';

export default function InfoRoom() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<InfoRoomsListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<InfoRoomsInfo | null>(null);

  const [groupType, setGroupType] = useState<any>({});
  const codeProcess = useRef(false);

  const getMyCodes = () => {
    getCodes(codeProcess, "INFO_ROOM_TYPE", setGroupType);
  }
  useEffect(() => {
    getMyCodes();
  }, [])

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="infoRoomId"
      deleteDesc="title"

      mainTitle='아티스트 페이지 관리 / 자유게시판'
      pageTitle={`자유게시판 / ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.INFOROOM_MAIN}

      getApiFn={api__getInfoRoomsList}
      delApiFn={api__delInfoRooms}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 150 },
        { name: "계정 닉네임", width: 150 },
        { name: "제목", width: 200 },
        { name: "조회수", width: 120 },
        { name: "업로드 일시", width: 120 },
        { name: "삭제여부", width: 80 },
        { name: "관리", width: 120 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.uid === null ? "someone" : item.uploaderNickname}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.viewCnt}</TableCell>
            <TableCell>{item.rgstDt}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.INFOROOM_MAIN + "/" + item.infoRoomId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}