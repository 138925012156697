import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow, styled } from '@mui/material';
import { useNavigate } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import { ContainedLinkBtn, DetailButton, MyTable, PageTitle, SBCBox, THS } from '../../Components/common/CommonElement';

import { handleError } from '../../Utils/utils';

import { AgencyType } from '../../Utils/types';
import { logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { debounce } from 'lodash';
import { api__getAgencies } from '../../services/AgencieServices';

export default function PartnersAccount() {
  const navigate = useNavigate();
  const [dataListInfo, setDataListInfo] = useState<AgencyType[]>([]);

  const getDatas = debounce(async () => {
    try {
      const result = await api__getAgencies();
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setDataListInfo(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <MainLayout title="소속사(관리자) 파트너센터 / 소속사/계정 관리">
        <Box p="30px">
          <SBCBox mb="12px">
            <PageTitle>소속사/계정 관리</PageTitle>
            <ContainedLinkBtn
              style={{ padding: '16px 24px', fontSize: '16px' }}
              href={ROUTES.PARTNERS_ACCOUNT + '/new'}
              target="_self"
              rel="noopener noreferrer"
            >
              소속사 추가
            </ContainedLinkBtn>
          </SBCBox>
          <Box mt="18px">
            <MyTable>
              <THS
                ths={[
                  { name: '소속사명', width: 200 },
                  { name: '관리 아티스트', width: 300 },
                  { name: '관리', width: 200 },
                ]}
              />
              <TableBody>
                {dataListInfo.map((item, uidx) => (
                  <CenterRow key={`admin-${uidx}`}>
                    <TableCell>{item.agencyName} </TableCell>
                    <TableCell>
                      {(item.managedArtists || '-').split('\n').map((a, i) => (
                        <div key={`${uidx}_a_${i}`}>{a}</div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <DetailButton
                        onClick={() => {
                          navigate(ROUTES.PARTNERS_ACCOUNT + '/' + item.agencyId);
                        }}
                      >
                        상세
                      </DetailButton>
                    </TableCell>
                  </CenterRow>
                ))}
              </TableBody>
            </MyTable>
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}

const CenterRow = styled(TableRow)({
  '& td': {
    textAlign: 'center',
  },
});
