import { debounce } from 'lodash';
import { api__getSingleDoc, api__postSingleDoc } from '../services/TermsService';
import { codeHandler } from '../services/CommonService';
import { handleError } from './utils';

export const getDocsInfo = debounce((target: string, setDetailInfo: any) => {
  api__getSingleDoc(target)
    .then(res => {
      if (codeHandler(res)) {
        setDetailInfo(res.data.content);
      } else {
        throw new Error(res.msg);
      }
    })
    .catch(console.error);
}, 300);

export const editDocsInfo = debounce(
  (
    isEdited: boolean,
    setEditMode: (mode: boolean) => void,
    detailInfo: string | null,
    target: string | null,
    setDetailInfo: (info: string) => void,
    getMyInfo: () => void,
  ) => {
    if (!isEdited) {
      setEditMode(false);
      return;
    }
    if (!detailInfo || !target) return;
    api__postSingleDoc(detailInfo, target)
      .then(res => {
        if (codeHandler(res)) {
          setDetailInfo(res.data.content);
          getMyInfo();
        } else {
          throw new Error(res.msg);
        }
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  },
  300,
);
