import { COLORS } from "../../Constant/COLORS";
import { InfoTextBox, InfoTitleBox, StretchBox } from "./CommonElement";

type IProps = {
    artistUseYn : "Y" | "N";
    artistNameKo : string;
}

export default function DetailArtistName({ artistUseYn, artistNameKo } : IProps) {
    return (
        <StretchBox>
            <InfoTitleBox>아티스트명</InfoTitleBox>
            <InfoTextBox sx={{ color: artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>
                {artistNameKo}{artistUseYn === "N" ? "(비)" : ""}
            </InfoTextBox>
        </StretchBox>
    )
}