export const COLORS = {
  MAIN: "#7749F8",
  SECOND: "#EBE5FC",

  GRADIENT_PINK: "linear-gradient(339.07deg, #e99eac 27.07%,  #e4beab 71.53%)",
  BORDER_GRAY: "#DEE2E6",
  BLACK: "#343A40",
  BUTTON_BLACK: "#6C757D",
  SIDEBAR_GRAY: "#AAA7B4",
};
