import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { getAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 캘린더 관리 - 캘린더 리스트 불러오기
export const api__getCalendarList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/calendar?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getCalendarList error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 캘린더 삭제
export const api__delCalendar = async (calendarId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/calendar/${calendarId}`, makeHeader());
  } catch (error) {
    console.log("delCalendar error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 캘린더 생성
export const api__postCalendar = async (datas) => {
  try {
    return await axios.post(`${rootUrl}/api/calendar/`, datas, makeHeader());
  } catch (error) {
    console.log("postCalendar error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 캘린더 업데이트
export const api__putCalendar = async (datas, calendarId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/calendar/${calendarId}`, datas, makeHeader());
  } catch (error) {
    console.log("api__putCalendar error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 캘린더 상세보기
export const api__getCalendarDetail = async (calendarId: string) => {
  try {
    const url = `${rootUrl}/api/calendar/${calendarId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("api__getCalendarDetail error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 제보 관리 리스트 불러오기
export const api__getTipOffScheduleList = async (page: string, limit: string) => {
  try {
    const url = `${rootUrl}/api/tip-off-schedule?page=${page}&limit=${limit}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getTipOffScheduleList error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 제보 관리 상세 불러오기
export const api__getTipOffScheduleDetail = async (seq: number) => {
  try {
    const url = `${rootUrl}/api/tip-off-schedule/${seq}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getTipOffScheduleDetail error ::: " + error);
    return null;
  }
};

// 캘린더 관리 - 제보 관리 삭제
export const api__delTipOffSchedule = async (id: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/tip-off-schedule/${id}`, makeHeader());
  } catch (error) {
    console.log("delTipOffSchedule error ::: " + error);
    return null;
  }
};
