import { useEffect, useState } from "react";
import { editDocsInfo, getDocsInfo } from "../../Utils/apiUtils";
import MainLayout from "../../layout/MainLayout";
import { BottomRightBox, MainBox, PageTitle } from "../common/CommonElement";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditComp from "../common/EditComp";

export default function CommonTerms({ title, target }) {
  const [detailInfo, setDetailInfo] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [isEdited, setIsEdted] = useState(false);
  const getMyInfo = () => {
    getDocsInfo(target, setDetailInfo);
  }
  const changeDetailInfo = (value: string) => {
    if (!isEdited) setIsEdted(true);
    setDetailInfo(value);
  }
  const editFn = () => {
    editDocsInfo(isEdited, setEditMode, detailInfo, target, setDetailInfo, getMyInfo);
  }
  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);
  return (
    <MainLayout title={title}>
      <MainBox>
        <PageTitle mb="21px">{title}</PageTitle>
        <CKEditor
          editor={ClassicEditor}
          data={detailInfo || ''}
          onChange={(event, editor) => { changeDetailInfo(editor.getData()); }}
          disabled={!editMode}
        />
        <BottomRightBox>
          <EditComp editMode={editMode} setEditMode={setEditMode} setIsEdited={setIsEdted} editFn={editFn} />
        </BottomRightBox>
      </MainBox>
    </MainLayout>
  );

};