import { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  AMPMButton,
  BackBtn,
  CheckLabelStyle,
  DCBox,
  DayPickerBox,
  DeleteButton,
  DetailButton,
  DisableBox,
  ImageBox,
  ImageDeleteBtn,
  ImageSizeBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  OptionButton,
  PageTitle,
  ResetButton,
  SBCBox,
  SimepleTextArea,
  StretchBox,
  TimeInput,
} from '../../Components/common/CommonElement';
import { ArtistId, PostCalendarInfo } from '../../Utils/types';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import MySelectBox from '../../Components/common/MySelectBox';
import {
  detailTimeFormat,
  findElementWithClassAtClickPath,
  getArtistList,
  getCodes,
  logoutWithOutNavigate,
} from '../../Utils/utils';
import { ReactSVG } from 'react-svg';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import ImageUploadModal from '../../Components/common/ImageUploadModal';
import ImagePopup from '../../Components/common/ImagePopup';
import { api__postCalendar } from '../../services/CalendarService';
import { ROUTES } from '../../Utils/routes';
import { handleError } from '../../Utils/utils';

export default function CalendarCreate() {
  const [detailInfo, setDetailInfo] = useState<PostCalendarInfo>({
    artistId: '',
    content: '',
    imgUrlList: [],
    linkUrl: '',
    scheduleDt: '',
    scheduleTime: '',
    tag: '',
    title: '',
    youtubeUrl: '',
  });

  const [selected, setSelected] = useState<Date>();

  const [aName, setAName] = useState('아티스트 선택');
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const [tName, setTName] = useState('분류 선택');
  const [tagIsDropped, setTagIsDropped] = useState(false);

  const [dpOn, setDpOn] = useState(false);
  const [isAM, setIsAM] = useState(true);
  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [timeOn, setTimeOn] = useState(false);

  const [imgList, setImgList] = useState<string[]>([]);

  const [imgUploadModal, setImgUploadModal] = useState(false);
  const [imgModal, setImgModal] = useState('');

  const [groupType, setGroupType] = useState<any>({});
  const codeProcess = useRef(false);

  const changeEditState = (value: string, target: string) => {
    setDetailInfo({ ...detailInfo, [target]: value });
  };

  const changeSelect = (aVal: string, nameVal: string) => {
    setDetailInfo({ ...detailInfo, artistId: aVal });
    setAName(nameVal);
    setIsDropped(false);
  };

  const changeTagSelect = (tVal: string, nameVal: string) => {
    setDetailInfo({ ...detailInfo, tag: tVal });
    setTName(nameVal);
    setTagIsDropped(false);
  };

  const getMyCodes = () => {
    getCodes(codeProcess, 'CALENDAR_TAG', setGroupType);
  };

  useEffect(() => {
    getArtistList(setArtistList);
    getMyCodes();
  }, []);

  const checkDateValidation = () => {
    if (!selected) {
      window.alert('날짜를 선택해주세요.');
      return;
    }
    if (timeOn) {
      const checkH = hour === '' ? '00' : hour;
      const checkM = minute === '' ? '00' : minute;
      const h = isAM ? checkH : `${Number(checkH) + 12}`;
      setDetailInfo({
        ...detailInfo,
        scheduleDt: moment(selected).format('YYYYMMDD'),
        scheduleTime: h + checkM + '00',
      });
    } else {
      setDetailInfo({ ...detailInfo, scheduleDt: moment(selected).format('YYYYMMDD'), scheduleTime: '' });
    }
    setDpOn(false);
  };

  const hourChange = e => {
    if (e.target.value === '') {
      setHour(e.target.value);
    } else {
      const regex = /^[0-9\s]+$/;
      if (!regex.test(e.target.value)) return;
      const num = Number(e.target.value);
      if (num > 12) return;
      setHour(e.target.value);
    }
  };
  const minuteChange = e => {
    if (e.target.value === '') {
      setMinute(e.target.value);
    } else {
      const regex = /^[0-9\s]+$/;
      if (!regex.test(e.target.value)) return;
      const num = Number(e.target.value);
      if (num > 59) return;
      setMinute(e.target.value);
    }
  };
  useEffect(() => {
    if (dpOn) {
      const check = e => {
        const elementWithClass = findElementWithClassAtClickPath(e, 'date-modal');
        if (!elementWithClass) {
          setDpOn(!dpOn);
        }
      };
      window.addEventListener('click', check);

      return () => {
        window.removeEventListener('click', check);
      };
    }
    // eslint-disable-next-line
  }, [dpOn]);

  const changeImages = datas => {
    setImgList(imgList.concat(datas));
    return null;
  };

  const removeImageOnList = (idx: number) => {
    setImgList(prev => {
      const clone = [...prev];
      clone.splice(idx, 1);
      return clone;
    });
  };

  const completeFn = async () => {
    try {
      const { artistId, tag, title, content, scheduleDt, scheduleTime, youtubeUrl, linkUrl } = detailInfo;
      if (artistId === '') throw new Error('pick_artist');
      if (title.trim() === '') throw new Error('fill_title');
      if (tag === '') throw new Error('pick_tag');
      if (scheduleDt === '') throw new Error('pick_date');
      if (content.trim() === '') throw new Error('fill_content');

      const params = { artistId, tag, title, content, scheduleDt };
      if (scheduleTime !== '') params['scheduleTime'] = scheduleTime;
      if (youtubeUrl !== '') params['youtubeUrl'] = youtubeUrl;
      if (linkUrl !== '') params['linkUrl'] = linkUrl;
      if (imgList.length !== 0) params['imgUrlList'] = imgList;
      const result = await api__postCalendar(params);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.alert('생성이 완료 되었습니다.');
      window.location.replace(ROUTES.CALENDAR_MAIN + '/' + result.data.data.calendarId);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      {imgModal !== '' && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} />}
      {imgUploadModal && (
        <ImageUploadModal
          setImgModal={setImgModal}
          setImgUploadModal={setImgUploadModal}
          getMyInfo={() => {}}
          api_Fn={changeImages}
          no_api
        />
      )}
      <MainLayout title="캘린더 관리 / 일정 게시물">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>캘린더 일정 업로드</PageTitle>
            </DCBox>
            <DetailButton onClick={completeFn}>생성</DetailButton>
          </SBCBox>
          <Box>
            <StretchBox>
              <InfoTitleBox>아티스트</InfoTitleBox>
              <InfoTextBox>
                <MySelectBox
                  value={aName}
                  width={332}
                  isDefault={detailInfo.artistId === ''}
                  isDropped={isDropped}
                  setIsDropped={setIsDropped}
                >
                  {artistList.map((item, idx) => (
                    <OptionButton
                      key={`al_${idx}`}
                      onClick={() => {
                        changeSelect(item.artistId, item.artistNameKo);
                      }}
                      className={detailInfo.artistId === item.artistId ? 'selected' : ''}
                    >
                      {item.artistNameKo}
                      {item.artistUseYn === 'Y' ? '' : '(비활성화)'}
                    </OptionButton>
                  ))}
                </MySelectBox>
                <Typography ml="9px" variant="body2">
                  ㆍ1팀 선택 가능
                </Typography>
              </InfoTextBox>
            </StretchBox>
            <InfoTxtComp
              title="제목"
              value={detailInfo.title}
              maxLength={200}
              onChange={e => {
                changeEditState(e.target.value, 'title');
              }}
            />
            <StretchBox>
              <InfoTitleBox>분류</InfoTitleBox>
              <InfoTextBox>
                <MySelectBox
                  value={tName}
                  width={332}
                  isDefault={detailInfo.tag === ''}
                  isDropped={tagIsDropped}
                  setIsDropped={setTagIsDropped}
                >
                  {Object.entries(groupType).map(([key, value]) => (
                    <OptionButton
                      onClick={() => {
                        changeTagSelect(key, String(value));
                      }}
                      key={`tag_${key}`}
                      className={detailInfo.tag === key ? 'selected' : ''}
                    >
                      {String(value)}
                    </OptionButton>
                  ))}
                </MySelectBox>
                <Typography ml="9px" variant="body2">
                  ㆍ1개 선택 가능
                </Typography>
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>일정</InfoTitleBox>
              <InfoTextBox position="relative">
                <SBCBox style={{ width: '332px' }} border={'1px solid #ADB5BD'} borderRadius={'6px'} pl="12px">
                  <Typography variant="body2" color={detailInfo.scheduleDt === '' ? '#ADB5BD' : '#222'}>
                    {detailInfo.scheduleDt === ''
                      ? 'YYYY.MM.DD 오전/오후 hh:mm'
                      : detailInfo.scheduleTime === ''
                        ? moment(detailInfo.scheduleDt).format('YYYY. MM. DD')
                        : detailTimeFormat(`${detailInfo.scheduleDt} ${detailInfo.scheduleTime}`)}
                  </Typography>
                  <ResetButton
                    sx={{ minWidth: '40px' }}
                    onClick={e => {
                      e.stopPropagation();
                      setDpOn(prev => !prev);
                    }}
                  >
                    <ReactSVG style={{ transform: 'translateY(3px)' }} src={SVG_IMAGES.DATE_PICKER} />
                  </ResetButton>
                </SBCBox>
                {dpOn && (
                  <DayPickerBox className="date-modal">
                    <CheckLabelStyle sx={{ ml: '12px', mt: '12px' }}>
                      <input
                        type="checkbox"
                        checked={timeOn}
                        onChange={e => {
                          setTimeOn(e.target.checked);
                        }}
                      />
                      <div className={timeOn ? 'custom active' : 'custom'}>
                        <ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
                      </div>
                      <Typography ml="8px" width="120px" variant="h5">
                        시간 지정
                      </Typography>
                    </CheckLabelStyle>
                    <SBCBox p="10px">
                      <DisableBox className={timeOn ? '' : 'disabled'}>
                        <TimeInput placeholder="00" type="text" value={hour} onChange={hourChange} readOnly={!timeOn} />
                        <Typography variant="body2" mx="4px">
                          :
                        </Typography>
                        <TimeInput
                          placeholder="00"
                          type="text"
                          value={minute}
                          onChange={minuteChange}
                          readOnly={!timeOn}
                        />
                        <AMPMButton
                          sx={{ ml: '8px', borderRadius: '8px 0 0 8px' }}
                          className={isAM ? 'selected' : ''}
                          onClick={() => {
                            if (timeOn) setIsAM(true);
                          }}
                        >
                          오전
                        </AMPMButton>
                        <AMPMButton
                          sx={{ borderRadius: '0 8px 8px 0' }}
                          className={!isAM ? 'selected' : ''}
                          onClick={() => {
                            if (timeOn) setIsAM(false);
                          }}
                        >
                          오후
                        </AMPMButton>
                      </DisableBox>
                      <DeleteButton onClick={checkDateValidation}>적용</DeleteButton>
                    </SBCBox>
                    <DayPicker
                      locale={ko}
                      mode="single"
                      selected={selected}
                      onSelect={setSelected}
                      modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today',
                      }}
                      modifiersStyles={{
                        disabled: { fontSize: '75%' },
                      }}
                    />
                  </DayPickerBox>
                )}
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>이미지</InfoTitleBox>
              <InfoTextBox style={{ paddingRight: '12px', overflow: 'auto', whiteSpace: 'nowrap' }}>
                <DCBox
                  minWidth={120}
                  width={'120px'}
                  height={'120px'}
                  bgcolor={'#DEDEDE'}
                  mr="12px"
                  justifyContent={'center'}
                  onClick={() => {
                    setImgUploadModal(true);
                  }}
                >
                  <ReactSVG src={SVG_IMAGES.UPLOAD_IMAGE} />
                </DCBox>
                {imgList.map((item, idx) => (
                  <ImageSizeBox
                    onClick={() => {
                      setImgModal(item);
                    }}
                    key={`image_preview_${idx}`}
                    mr="12px"
                  >
                    <ImageDeleteBtn
                      onClick={e => {
                        e.stopPropagation();
                        removeImageOnList(idx);
                      }}
                    >
                      X
                    </ImageDeleteBtn>
                    <ImageBox>
                      <img src={item} alt={`image_preview_${idx}`} />
                    </ImageBox>
                  </ImageSizeBox>
                ))}
              </InfoTextBox>
            </StretchBox>
            <InfoTxtComp
              title="유튜브 URL"
              value={detailInfo.youtubeUrl}
              onChange={e => {
                changeEditState(e.target.value, 'youtubeUrl');
              }}
            />
            <InfoTxtComp
              title="링크버튼 URL"
              value={detailInfo.linkUrl}
              onChange={e => {
                changeEditState(e.target.value, 'linkUrl');
              }}
            />
            <StretchBox>
              <InfoTitleBox>내용</InfoTitleBox>
              <InfoTextBox>
                <SimepleTextArea
                  sx={{ minHeight: '316px' }}
                  value={detailInfo.content}
                  onChange={e => {
                    changeEditState(e.target.value, 'content');
                  }}
                />
              </InfoTextBox>
            </StretchBox>
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
