import { useEffect, useState, useRef } from 'react';
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import {
  DCBox,
  DetailButton,
  MainButton,
  ModalBtn,
  ModalContentTypo,
  MyTable,
  PageTitle,
  SecondaryButton,
  THS,
} from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';

import { api__getReportCommentList } from '../../services/RerportService';
import { api__delReply, api__getReplyDetail } from '../../services/ReplyService';
import { BoardReportListInfo, ReportPopupInfo, ReportType } from '../../Utils/types';
import {
  addCommas,
  detailTimeFormat,
  getCodes,
  getListPL,
  logoutWithOutNavigate,
  updateReportStatus,
} from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { ROUTES } from '../../Utils/routes';
import ModalBox from '../../Components/common/ModalBox';
import { COLORS } from '../../Constant/COLORS';
import { handleError } from '../../Utils/utils';

const thisContentsType = {
  '001': 'ARTIST_PROFILE', // 아티스트 프로필 댓글
  '002': 'MAGAZINE', // 굿덕 매거진 댓글
  '003': 'ARTIST_FEED', //아티스트 피드 댓글
  '004': 'MAIL_BOX', // 사서함 댓글
  '006': 'GALLERY', // 갤러리 댓글
  '009': 'FAN_ONLY', // 팬끼리 댓글
  '011': 'INFO_ROOM', // 자유게시판 댓글
  '012': 'GOOD_TALK', // 굿톡 댓글
  '013': 'CALENDAR', // 캘린더 댓글
};

export default function CommentReport() {
  const navigate = useNavigate();
  const { page, limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<BoardReportListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<ReportPopupInfo | null>(null);
  const [detailModal, setDetailModal] = useState<ReportPopupInfo | null>(null);

  const isGetListProgress = useRef(false);
  const codeProcess = useRef(false);

  const getMyList = async () => {
    getListPL(api__getReportCommentList, setDataListInfo, page || '1', limit || '10');
  };

  const [groupType, setGroupType] = useState<any>({});

  const getMyCodes = () => {
    getCodes(codeProcess, 'REPORT_TYPE', setGroupType);
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    getMyCodes();
  }, []);

  // 상세버튼 눌렀을 때 팝업에 필요한 정보를 불러오는 함수
  const replyPopup = async (contentsType: string, item: ReportType) => {
    if (isGetListProgress.current) return;
    isGetListProgress.current = true;
    try {
      const result = await api__getReplyDetail(contentsType, item.contentsId);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const { artistNameKo, artistUseYn, content, openYn } = result.data.data;
      if (item.reportResult !== '처리완료_삭제' && openYn === 'N') {
        updateReportStatus({ result: '처리완료_삭제', seq: item.seq }, getMyList);
      }
      setDetailModal({ ...item, artistNameKo, artistUseYn, content, openYn });
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetListProgress.current = false;
    }
  };

  const delReply = async (item: ReportType) => {
    try {
      const result = await api__delReply(thisContentsType[item.type], item.contentsId);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.alert('삭제되었습니다.');
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const commentReportHandler = async (type: string, reportInfo: ReportPopupInfo) => {
    const { seq } = reportInfo;
    if (type === 'keep') {
      updateReportStatus({ result: '처리완료_유지', seq }, getMyList);
    } else {
      delReply(reportInfo);
      updateReportStatus({ result: '처리완료_삭제', seq }, getMyList);
    }
    setDetailModal(null);
  };

  return (
    <>
      {detailModal && (
        <ModalBox title="댓글 상세">
          <Box borderBottom={`1px solid ${COLORS.BORDER_GRAY}`} p="16px">
            <ModalContentTypo>
              <Typography variant="body1">
                ㆍ댓글이 위치한 아티스트명 : {detailModal.artistNameKo}
                {detailModal.artistUseYn === 'N' ? '(비)' : ''}
              </Typography>
              <Typography variant="body1">ㆍ댓글 위치 : {groupType[detailModal.type]}</Typography>
              <Typography variant="body1">ㆍ신고유형 : {detailModal.reportContent}</Typography>
              <Typography variant="body1">
                ㆍ신고한 계정 닉네임 : {detailModal.uid === null ? 'someone' : detailModal.reportNickname}
              </Typography>
              <Typography variant="body1">ㆍ신고 일시 : {detailTimeFormat(detailModal.rgstDt)}</Typography>
              <Typography variant="body1">ㆍ댓글 내용</Typography>
              <Typography variant="body1" pl="14px">
                {detailModal.content}
              </Typography>
            </ModalContentTypo>
          </Box>
          <DCBox p="16px" justifyContent={'flex-end'}>
            <ModalBtn
              onClick={() => {
                setDetailModal(null);
              }}
              onKeyDown={() => {}}
              forwardRef={null}
              extra={true}
            />
            {detailModal.openYn === 'Y' ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    if (detailModal.reportResult !== '처리완료_유지') commentReportHandler('keep', detailModal);
                  }}
                  style={{
                    padding: '4px 12px',
                    marginLeft: '8px',
                    opacity: detailModal.reportResult === '처리완료_유지' ? 0.6 : 1,
                  }}
                >
                  {detailModal.reportResult === '처리완료_유지' ? '유지된 게시물' : '유지'}
                </SecondaryButton>
                <MainButton
                  onClick={() => {
                    setDeleteModal(detailModal);
                    setDetailModal(null);
                  }}
                  sx={{ p: '4px 12px', marginLeft: '8px' }}
                >
                  삭제
                </MainButton>
              </>
            ) : (
              <MainButton onClick={() => {}} sx={{ p: '4px 12px', marginLeft: '8px', opacity: 0.6 }}>
                삭제된 게시물
              </MainButton>
            )}
          </DCBox>
        </ModalBox>
      )}
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) commentReportHandler('delete', deleteModal);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo></ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="신고내역 관리 / 댓글 리스트">
        <Box p="34px">
          <PageTitle mb="12px">신고 내역 : 댓글 / {addCommas(dataListInfo?.totalCount || 0)}개</PageTitle>
          <Box mt="18px">
            <MyTable>
              <THS
                ths={[
                  { name: '#', width: 40 },
                  { name: '댓글 위치', width: 100 },
                  { name: '신고 유형', width: 120 },
                  { name: '신고한 계정 닉네임', width: 100 },
                  { name: '신고 일시', width: 120 },
                  { name: '관리', width: 80 },
                  { name: '처리여부', width: 60 },
                ]}
              />
              <TableBody>
                {dataListInfo &&
                  dataListInfo.data.map((item, uidx) => (
                    <TableRow key={`user-${uidx}`}>
                      <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                      <TableCell>{groupType[item.type]}</TableCell>
                      <TableCell>{item.reportContent}</TableCell>
                      <TableCell>{item.uid === null ? 'someone' : item.reportNickname}</TableCell>
                      <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
                      <TableCell>
                        <DetailButton
                          style={{ marginRight: '0' }}
                          onClick={() => {
                            replyPopup(thisContentsType[item.type], item);
                          }}
                        >
                          상세
                        </DetailButton>
                      </TableCell>
                      <TableCell>{item.reportResult || '미처리'}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </MyTable>
            {dataListInfo && (
              <Pages
                pageInfo={dataListInfo}
                getList={p => {
                  navigate(ROUTES.COMMENT_REPORT_MAIN + `/${String(p)}/${limit}`);
                }}
              />
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
