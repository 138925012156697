import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { getAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 신고내역 관리 - 댓글 리스트 불러오기
export const api__getReportCommentList = async (page: string, limit: string) => {
  try {
    const url = `${rootUrl}/api/report/review/list?page=${page}&limit=${limit}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("api__getReportCommentList error ::: " + error);
    return null;
  }
};

// 신고내역 관리 - 게시글 리스트 불러오기
export const api__getReportPostList = async (page: string, limit: string) => {
  try {
    const url = `${rootUrl}/api/report/post/list?page=${page}&limit=${limit}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("api__getReportPostList error ::: " + error);
    return null;
  }
};

// 신고내역 관리 - 게시글 결과 업데이트
export const api__putReportStatus = async (params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/report/result`, params, makeHeader());
  } catch (error) {
    console.log("api__putReportStatus error ::: " + error);
    return null;
  }
};
