import { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';


import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';


import { api__delGoodTalks, api__getGoodTalksList } from '../../services/ArtistPageService';
import { GoodTalkInfo, GoodTalkListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';

import { COLORS } from '../../Constant/COLORS';
import CommonTable from '../../Components/CommonTable';

export default function GoodTalks() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<GoodTalkListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<GoodTalkInfo | null>(null);

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="goodTalkId"
      deleteDesc="title"

      mainTitle='아티스트 페이지 관리 / 굿톡'
      pageTitle={`굿톡 / 게시물 수 : ${addCommas(dataListInfo?.totalCount || 0)}개 메모장 수 : ${addCommas(dataListInfo?.additionalData.totalGoodTalkMemoCnt || 0)}개`}
      route={ROUTES.GOODTALKS_MAIN}

      getApiFn={api__getGoodTalksList}
      delApiFn={api__delGoodTalks}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 150 },
        { name: "계정 닉네임", width: 150 },
        { name: "제목", width: 200 },
        { name: "조회수(회)", width: 80 },
        { name: "시작 일시", width: 120 },
        { name: "메모장 수", width: 80 },
        { name: "삭제여부", width: 80 },
        { name: "관리", width: 120 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.uploaderNickname}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{addCommas(item.playCnt)}</TableCell>
            <TableCell>{detailTimeFormat(item.startDt)}</TableCell>
            <TableCell>{addCommas(item.goodTalkMemoCnt)}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.GOODTALKS_MAIN + "/" + item.goodTalkId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}