import axios from "axios";
import { makeHeader } from "../Utils/utils";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 댓글 관리 - 댓글 리스트 불러오기
export const api__getReplyList = async (page: string, limit: string, contentsType: string, artistId: string) => {
  try {
    return await axios.get(
      `${rootUrl}/api/review/list?page=${page}&limit=${limit}&contentsType=${contentsType}${!artistId ? "" : `&artistId=${artistId}`}`,
      makeHeader()
    );
  } catch (error) {
    console.log("api__getReplyList error ::: " + error);
    return null;
  }
};

// 댓글 관리 - 댓글 상세 불러오기
export const api__getReplyDetail = async (contentsType: string, reviewId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/review/detail?contentsType=${contentsType}&reviewId=${reviewId}`, makeHeader());
  } catch (error) {
    console.log("api__getReplyDetail error ::: " + error);
    return null;
  }
};

export const api__delReply = async (contentsType: string, reviewId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/review?contentsType=${contentsType}&reviewId=${reviewId}`, makeHeader());
  } catch (error) {
    console.log("api__delReply error ::: " + error);
    return null;
  }
};