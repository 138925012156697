import { useNavigate } from "react-router";
import { DCBox, SelectBox } from "./CommonElement"

type IProps = {
    limit: string;
    page: string;
    route: string;
    subUrl?: string | undefined;
}

export default function LimitBox({ limit, page, route, subUrl }: IProps) {
    const navigate = useNavigate();

    const changeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const p = Number(page);
        const l = Number(limit);
        const nl = e.target.value;
        const nowPosition = (p - 1) * l + 1;
        const np = Math.ceil((nowPosition / Number(nl)));
        navigate(route + `/${np}/${nl}${subUrl !== undefined ? subUrl : ""}`);
    }

    return (
        <DCBox>
            <SelectBox value={limit} onChange={changeHandler}>
                <option value={10}>10개씩 보기</option>
                <option value={50}>50개씩 보기</option>
                <option value={100}>100개씩 보기</option>
            </SelectBox>
        </DCBox>
    )
}