import { useState, useEffect, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Button, Typography, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import { DCBox, FlexBox, HeaderLink, SBCBox } from './CommonElement';
import { getCookie, setCookie } from '../../Utils/cookies';
import { isEmptyCookie, logout } from '../../Utils/utils';
import { api__getMyAccountInfo } from '../../services/AdminMemberService';
import { AdminAccountInfo } from '../../Utils/types';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { COLORS } from '../../Constant/COLORS';
import ModalMsgBox from './ModalMsgBox';
import { debounce } from 'lodash';
import { handleError } from '../../Utils/utils';

type IProps = {
  title: string;
  setFold: React.Dispatch<React.SetStateAction<string>>;
};

export default memo(function CommonHeader({ title, setFold }: IProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [myInfo, setMyInfo] = useState<AdminAccountInfo | null>(null);
  const [thisCookie] = useState(getCookie('authCookie'));

  const [logoutModal, setLogoutModal] = useState(false);

  const checkMyInfo = debounce(async () => {
    try {
      const authCookie = getCookie('authCookie');
      if (isEmptyCookie(authCookie)) return;
      const cookie = getCookie('myInfo');
      if (isEmptyCookie(cookie)) {
        const result = await api__getMyAccountInfo();

        if (!result) return;
        const { code, msg } = result.data;
        if (code !== 'S200') throw new Error(msg);
        setCookie('myInfo', result.data.data);
        setMyInfo(result.data.data);
      } else {
        setMyInfo(cookie);
      }
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);

  useEffect(() => {
    checkMyInfo();
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      {logoutModal && (
        <ModalMsgBox
          title="로그아웃 하시겠어요?"
          setModal={bool => {
            setLogoutModal(false);
            if (!bool) logout(navigate);
          }}
          extraBtn={'로그아웃'}
        />
      )}
      <SBCBox p="12px 16px" bgcolor="#F8F9FA" boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)">
        <DCBox>
          <ReactSVG
            onClick={() => {
              setFold(prev => {
                if (prev === 'fold') return '';
                return 'fold';
              });
            }}
            src={SVG_IMAGES.HAMBURGER}
            style={{ transform: 'translateY(3px)', cursor: 'pointer' }}
          />
          <Typography ml="31px" variant="body1" fontSize="14px">
            {title}
          </Typography>
        </DCBox>
        <DCBox position="relative" className="click-area">
          <FlexBox>
            <Typography variant="body2" fontWeight={700}>
              {myInfo?.adminName}
            </Typography>
            <Typography variant="body2">님 안녕하세요 :)</Typography>
          </FlexBox>
          {/* {thisCookie.role === "MASTER" && <LocalButton className={pathname === "/master" ? "active" : ""} onClick={() => { navigate("/master") }}>멤버관리</LocalButton>} */}
          {thisCookie.role === 'MASTER' && (
            <HeaderLink
              className={pathname.includes('/master') ? 'active' : ''}
              href={'/master/1/10'}
              target="_self"
              rel="noopener"
            >
              멤버관리
            </HeaderLink>
          )}
          <HeaderLink className={pathname === '/info' ? 'active' : ''} href={'/info'} target="_self" rel="noopener">
            정보조회/변경
          </HeaderLink>
          <LocalButton
            onClick={() => {
              setLogoutModal(true);
            }}
          >
            로그아웃
          </LocalButton>
        </DCBox>
      </SBCBox>
    </>
  );
});

const LocalButton = styled(Button)({
  color: COLORS.BUTTON_BLACK,
  fontFamily: 'Pretendard',
  fontWeight: 400,
  fontSize: '14px',
  border: 'none',
  backgroundColor: 'transparent',
  marginLeft: '45px',
});
