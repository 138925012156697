import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';

import {
  CenterBox,
  DCBox,
  FEBox,
  InfoTextBox,
  InfoTitleBox,
  ModalContentTypo,
  PageTitle,
} from '../../Components/common/CommonElement';
import { TextInputInBBox } from '../../Components/common/CommonElement';
import { MainButton } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { SVG_IMAGES } from '../../Constant/IMAGES';

import { api__resetPassword, api__getEmailById } from '../../services/AuthService';
import { enterFn } from '../../Utils/utils';
import { debounce } from 'lodash';
import { handleError } from '../../Utils/utils';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [email, setEmail] = useState('');

  const idRef = useRef<HTMLInputElement>(null);
  const isProgress = useRef(false);

  const sendingPw = async () => {
    try {
      const result = await api__resetPassword(id);
      if (!result) return;
      const { code, msg } = result.data;
      if (code === 'S200') {
        setSuccessModal(true);
      } else if (code === 'L002') setFailModal(true);
      else throw new Error(msg);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isProgress.current = false;
    }
  };

  const findingEmail = debounce(async () => {
    // 이미 진행중일 경우 반환
    if (isProgress.current) return;
    isProgress.current = true;

    try {
      if (id.trim() === '') throw new Error('login_id');
      const result = await api__getEmailById(id);

      if (!result) return;
      const { code, msg } = result.data;
      if (code === 'S200') {
        setEmail(result.data.data.adminEmail);
        sendingPw();
      } else if (code === 'L002') setFailModal(true);
      else throw new Error(msg);
    } catch (error: unknown) {
      handleError(error);
      isProgress.current = false;
    }
  }, 300);

  // 페이지 진입시 아이디쪽으로 커서
  useEffect(() => {
    idRef.current?.focus();
  }, []);

  return (
    <>
      {failModal && (
        <ModalMsgBox
          title="일치하는 ID가 없어요."
          setModal={bool => {
            setFailModal(bool);
            idRef.current?.focus();
          }}
        />
      )}
      {successModal && (
        <ModalMsgBox
          title="임시 비밀번호가 이메일로 전송되었어요."
          setModal={bool => {
            setSuccessModal(bool);
            navigate('/auth');
          }}
        >
          <ModalContentTypo>ㆍ이메일 : {email}</ModalContentTypo>
          <ModalContentTypo>ㆍ이메일의 받은 편지함을 확인해주세요.</ModalContentTypo>
          <ModalContentTypo>
            ㆍ받은 편지함에 비밀번호 변경 이메일이 없다면, 스팸 메일함을 확인해주세요.
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <CenterBox flexDirection={'column'} style={{ justifyContent: 'flex-start' }}>
        <DCBox mt="125px" mb="150px">
          <ReactSVG src={SVG_IMAGES.GOODDUCK_HEAD} style={{ transform: 'translateY(2px)' }} />
          <Typography variant="h4" ml="8px" fontWeight={800}>
            Goodduck Admin 비밀번호 찾기
          </Typography>
        </DCBox>
        <Box minWidth="960px" mb="41px">
          <PageTitle mb="30px">비밀번호 찾기</PageTitle>
          <DCBox>
            <InfoTitleBox>아이디</InfoTitleBox>
            <InfoTextBox>
              <TextInputInBBox
                value={id}
                onChange={e => {
                  setId(e.target.value);
                }}
                onKeyDown={e => {
                  enterFn(e, findingEmail);
                }}
              />
            </InfoTextBox>
          </DCBox>
        </Box>
        <FEBox width="960px">
          <MainButton onClick={findingEmail}>다음</MainButton>
        </FEBox>
      </CenterBox>
    </>
  );
}
