import { useState } from 'react';
import { DarkContainedButton, DetailButton } from "./CommonElement"
import ModalMsgBox from './ModalMsgBox';

type IProps = {
    editMode: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
    editFn: Function;
}

export default function EditComp({ editMode, setEditMode, setIsEdited, editFn }: IProps) {
    const [editModal, setEditModal] = useState(false);
    const cancelEdit = () => {
        setEditMode(false);
        setIsEdited(false);
    }

    const editOn = () => { setEditMode(true) };
    const editModalOn = () => { setEditModal(true) };

    return (
        <>
            {editModal && (
                <ModalMsgBox title="수정사항을 적용하시겠어요?" setModal={(bool) => {
                    setEditModal(false);
                    if (!bool) {
                        editFn();
                        cancelEdit();
                    };
                }} extraBtn={'적용'}>
                </ModalMsgBox>
            )}
            {editMode && <DarkContainedButton sx={{ mr: '12px' }} onClick={cancelEdit}>수정 취소</DarkContainedButton>}
            <DetailButton onClick={editMode ? editModalOn : editOn}>{editMode ? "수정 적용" : "수정"}</DetailButton>
        </>
    )
}