import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';

import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import { useNavigate, useParams } from 'react-router';
import CommonTable from '../../Components/CommonTable';
import { COLORS } from '../../Constant/COLORS';
import { api__delArtistFeed, api__getArtistFeedList } from '../../services/ArtistPageService';
import { ROUTES } from '../../Utils/routes';
import { ArtistFeedInfo, ArtistFeedListInfo } from '../../Utils/types';
import { addCommas, checkMembershipType } from '../../Utils/utils';

const textCutStyle = {
  width: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  justifyContent: 'flex-start'
}

export default function ArtistFeed() {
  const navigate = useNavigate();
  const { limit } = useParams();

  const [dataListInfo, setDataListInfo] = useState<ArtistFeedListInfo | null>(null);;
  const [deleteModal, setDeleteModal] = useState<ArtistFeedInfo | null>(null)
  
  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList
      hasMembershipType

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="artistFeedId"
      deleteDesc="content"

      mainTitle='아티스트 페이지 관리 / 아티스트 피드'
      pageTitle={`아티스트 피드 / 게시물 수 : ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.ARTISTFEED_MAIN}

      getApiFn={api__getArtistFeedList}
      delApiFn={api__delArtistFeed}

      ths={[
        { name: "#", width: 40 },
        { name: "종류", width: 300 },
        { name: "아티스트명", width: 150 },
        { name: "계정 닉네임", width: 150 },
        { name: "내용", width: 300 },
        { name: "업로드 일시", width: 120 },
        { name: "삭제여부", width: 100 },
        { name: "관리", width: 120 },
      ]}
    >
<TableBody>
  {dataListInfo &&
    dataListInfo.data
      .map((item, uidx) => (
        <TableRow key={`user-${uidx}`}>
          <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
          <TableCell>{checkMembershipType(item)}</TableCell>
          <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>
            {item.artistNameKo}
            {item.artistUseYn === "N" ? "(비)" : ""}
          </TableCell>
          <TableCell>{item.uploaderNickname}</TableCell>
          <TableCell>
            <Typography variant="body2" sx={{ ...textCutStyle }}>
              {item.content}
            </Typography>
          </TableCell>
          <TableCell>{item.rgstDt}</TableCell>
          <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
          <TableCell>
            <DetailButton onClick={() => { navigate(ROUTES.ARTISTFEED_MAIN + "/" + item.artistFeedId); }}>상세</DetailButton>
            {item.openYn === "Y" && (
              <DeleteButton onClick={() => { setDeleteModal(item); }}>삭제</DeleteButton>
            )}
          </TableCell>
        </TableRow>
      ))}
</TableBody>
    </CommonTable>
  )
}