import { useContext, useRef } from 'react';
import { Box, Typography, styled } from "@mui/material"
import { DCBox, InfoTextBox, InfoTitleBox, MainButton, PageTitle, SBCBox, StretchBox, TextInputInBBox } from "../common/CommonElement"
import { COLORS } from "../../Constant/COLORS";
import { ArtistDetailContext } from '../../pages/manage/ArtistDetail';

export default function ArtistDetailYoutubeInfo() {
  const { youtubeEdit, setYoutubeEdit, youtubeInfo, setYoutubeInfo, editMode } = useContext(ArtistDetailContext);
  const numbering = useRef(1);
  // 유튜브 함수
  const changeYoutubeInfo = (value: string | boolean, column: string, idx: number) => {
    if (!editMode) return;
    if (!youtubeEdit) setYoutubeEdit(true);
    setYoutubeInfo(prev => {
      const clone = [...prev];
      clone[idx][column] = value;
      if (clone[idx].flag === "default") clone[idx].flag = "origin";
      return clone;
    });
  }
  // 유튜브 url 추가
  const addUrl = () => {
    if (!editMode) return;
    if (!youtubeEdit) setYoutubeEdit(true);
    setYoutubeInfo(prev => {
      const clone = [...prev];
      clone.push({
        flag: 'new',
        mainYn: clone.length === 0 ? "Y" : "N",
        youtubeTitle: '',
        youtubeUrl: '',
        orderSeq: -1,
        seq: -1
      });
      return clone;
    });
  }
  // 유튜브 url 삭제
  const delUrl = (idx: number) => {
    if (!editMode) return;
    if (!youtubeEdit) setYoutubeEdit(true);
    setYoutubeInfo(prev => {
      const clone = [...prev];
      if (clone[idx].flag === "new") {
        clone.splice(idx, 1);
      } else {
        clone[idx].flag = "delete";
      }
      return clone;
    });
  }
  return (
    <StretchBox>
      <InfoTitleBox>유튜브 URL</InfoTitleBox>
      <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start', paddingRight: '12px' }}>
        <DCBox mb="18px" height='40px'>
          {editMode &&
            <>
              <MainButton sx={{ padding: '4px 12px' }} onClick={addUrl}>URL 리스트 추가</MainButton>
              <Typography variant="h5" ml="8px">* 메인, 리스트_1, 2, 3은 아티스트 프로필 페이지에 노출됨</Typography>
            </>
          }
        </DCBox>
        {youtubeInfo.map((item, idx) => {
          if (idx === 0) numbering.current = 0;
          if (item.flag === 'delete') return <></>;
          const nowIdx = numbering.current.toString();
          numbering.current += 1;
          return <CardBox key={`youtube_${idx}`}>
            {item.mainYn === "Y" ? (
              <DCBox>
                <PageTitle style={{ fontSize: '20px' }}>메인</PageTitle>
                <Typography variant="body2" ml="16px">* 아티스트 프로필 페이지에 메인으로 적용되는 유튜브 임베드 URL 입니다.</Typography>
              </DCBox>
            ) : (
              <SBCBox>
                <PageTitle style={{ fontSize: '20px' }}>리스트_{nowIdx}</PageTitle>
                {
                  editMode &&
                  <MainButton
                    sx={{ bgcolor: COLORS.BUTTON_BLACK, padding: '4px' }}
                    onClick={() => { delUrl(idx) }}>
                    삭제
                  </MainButton>
                }
              </SBCBox>
            )}
            <Box mt="16px" />
            <ThisTypeArea
              title="제목" value={item.youtubeTitle} editMode={editMode}
              changeFn={(e) => { changeYoutubeInfo(e.target.value, "youtubeTitle", idx) }}
            />
            <ThisTypeArea
              title="URL" value={item.youtubeUrl} editMode={editMode}
              changeFn={(e) => { changeYoutubeInfo(e.target.value, "youtubeUrl", idx) }}
            />
          </CardBox>
        })}
      </InfoTextBox>
    </StretchBox>
  )
}
const CardBox = styled(Box)({
  padding: '14px 16px',
  border: '1px solid #969696',
  borderRadius: '4px',
  width: '100%',
  marginBottom: '18px'
})


const ThisTypeArea = ({ title, value, changeFn, editMode }) => (
  <DCBox width={'80%'} mb="8px">
    <Typography width={'55px'} variant="h5">{title}</Typography>
    <TextInputInBBox value={value} onChange={changeFn} readOnly={!editMode} maxLength={200} />
  </DCBox>
)