import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import { api__delGoodTalks, api__getGoodTalkInfo } from '../../services/ArtistPageService';
import { GoodTalkDetailInfo } from '../../Utils/types';
import { addCommas, delData, detailTimeFormat, logoutWithOutNavigate } from '../../Utils/utils';
import {
  BackBtn,
  DCBox,
  FlexBox,
  InfoComp,
  InfoImageComp,
  InfoTextBox,
  InfoTitleBox,
  MainButton,
  PageTitle,
  StretchBox,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import ImagePopup from '../../Components/common/ImagePopup';
import 'react-h5-audio-player/lib/styles.css';
import DetailArtistName from '../../Components/common/DetailArtistName';
import ReactHlsPlayer from 'react-hls-player';
import { handleError } from '../../Utils/utils';

export default function GoodTalkReview() {
  const [detailInfo, setDetailInfo] = useState<GoodTalkDetailInfo>({
    openYn: 'Y',
    rgstDt: '',
    title: '',
    artistNameKo: '',
    artistUseYn: 'Y',
    goodTalkId: '',
    playCnt: 0,
    startDt: '',
    endDt: '',
    goodTalkMemoCnt: 0,
    goodTalkVoteCnt: 0,
    hasQAndA: 'N',
    imgUrlList: [],
    replayAudioLength: '',
    replayAudioUrl: '',
    uploaderNickname: '',
  });
  const [deleteModal, setDeleteModal] = useState<GoodTalkDetailInfo | null>(null);
  const [imgModal, setImgModal] = useState('');

  const { goodTalkId } = useParams();

  const playerRef = useRef<any>(null);
  const isGetProgress = useRef(false);

  const getMyInfo = async () => {
    if (isGetProgress.current || goodTalkId === undefined) return;
    isGetProgress.current = true;
    try {
      const result = await api__getGoodTalkInfo(goodTalkId);

      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const { data } = result.data;
      for (let [key, value] of Object.entries(data)) {
        if (key === 'imgUrlList') {
          data[key] = (value as string[]).map((img: string, idx: number) => {
            return {
              imgUrl: img,
              orderSeq: idx,
              seq: idx,
            };
          });
        }
        if (value === null) data[key] = '';
      }
      setDetailInfo(data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetProgress.current = false;
    }
  };

  const delMyData = (id: string) => {
    delData(id, api__delGoodTalks, getMyInfo);
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {imgModal && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} imgList={detailInfo.imgUrlList} />}
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(deleteModal.goodTalkId);
          }}
          extraBtn={'삭제'}
        ></ModalMsgBox>
      )}
      <MainLayout title="아티스트 페이지 관리 / 굿톡">
        <Box p="38px 34px" pb="120px">
          <DCBox mb="34px">
            <BackBtn />
            <PageTitle>굿톡 다시듣기</PageTitle>
          </DCBox>
          <Box mb="40px">
            <DetailArtistName artistNameKo={detailInfo.artistNameKo} artistUseYn={detailInfo.artistUseYn} />
            <InfoComp title="업로드 일시" value={detailTimeFormat(detailInfo.endDt)} />
            <InfoComp title="계정 닉네임" value={detailInfo.uploaderNickname} />
            <InfoComp title="계정 닉네임" value={detailInfo.title} />
            <InfoComp title="조회수(회)" value={addCommas(detailInfo.playCnt)} />
            <InfoComp title="굿톡 메모장 수(개)" value={addCommas(detailInfo.goodTalkMemoCnt)} />
            <InfoImageComp title="이미지" imageList={detailInfo.imgUrlList} setImgModal={setImgModal} />
            <StretchBox>
              <InfoTitleBox>오디오 파일</InfoTitleBox>
              <InfoTextBox style={{ justifyContent: 'space-between', paddingRight: '12px' }}>
                <ReactHlsPlayer
                  src={detailInfo.replayAudioUrl}
                  playerRef={playerRef}
                  controls={true}
                  height={'auto'}
                  width={'640px'}
                />
              </InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>오디오 URL</InfoTitleBox>
              <InfoTextBox>{detailInfo.replayAudioUrl}</InfoTextBox>
            </StretchBox>
            <InfoComp title="투표 개수" value={addCommas(detailInfo.goodTalkVoteCnt)} />
            <InfoComp title="Q&A 진행 여부" value={detailInfo.hasQAndA} />
            <InfoComp title="삭제여부" value={detailInfo.openYn === 'Y' ? 'N' : 'Y'} />
          </Box>
          {detailInfo.openYn === 'Y' && (
            <FlexBox justifyContent={'flex-end'}>
              <MainButton
                onClick={() => {
                  setDeleteModal(detailInfo);
                }}
              >
                삭제
              </MainButton>
            </FlexBox>
          )}
        </Box>
      </MainLayout>
    </>
  );
}
