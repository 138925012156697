import { Box, Checkbox, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { BackBtn, DCBox, FlexBox, ImageBox, ImageDeleteBtn, ImageSizeBox, InfoComp, InfoTextBox, InfoTitleBox, InfoTxtComp, MainButton, PageTitle, SBCBox, SimepleTextArea, StretchBox } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MainLayout from "../../layout/MainLayout";

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import dayjs from 'dayjs';
import _, { debounce } from 'lodash';
import { useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';
import CalendarPicker from '../../Components/common/CalendarPicker';
import ImagePopup from '../../Components/common/ImagePopup';
import ImageUploadModal from '../../Components/common/ImageUploadModal';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { api__deleteNoticeData, api__getNoticeData, api__putNoticeData } from '../../services/AgencieServices';
import { AgencyNoticeDetailType } from '../../Utils/types';
import { checkBookingStatus, detailTimeFormat, logoutWithOutNavigate } from '../../Utils/utils';
import NoticePreview from './NoticePreview';

export default function PartnersNoticeDetail() {
	const { noticeId } = useParams();
	const [data, setData] = useState<AgencyNoticeDetailType | null>(null);
	const [saving, setSaving] = useState<AgencyNoticeDetailType | null>(null);
	const [prevent, setPrevent] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const [imgUploadModal, setImgUploadModal] = useState(false);
	const [imageEdited, setImageEdited] = useState(false);
	const [imgModal, setImgModal] = useState("");

	const [previewOn, setPreviewOn] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const [isPicked, setIsPicked] = useState(false);
	const [bookedTime, setBookedTime] = useState<Date | null>(null)
	const [isBooked, setIsBooked] = useState(false);
	
	// 수정 적용여부 확인 모달
	const [noticeModal, setNoticeModal] = useState<string | null>(null);
	// 게시글 공개 여부 상태
	const [isPublished, setIsPublished] = useState(false);
	// 수정모드에 들어갈 때의 bookedDt 값
	const [editBookedDt, setEditBookedDt] = useState<string | null>(null);
	
	const bookedDt = bookedTime && !isPublished && bookedTime > new Date() ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;

	const getNoticeData = debounce(() => {
		api__getNoticeData(noticeId!).then((res) => {
			if (!res) return;
			const { code, msg, data } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setData({ ...data, imgUrlList: data.imgUrlList.map((item) => item.imgUrl) });
		}).catch(console.error);
	}, 300)

	useEffect(() => {
		getNoticeData();
		// eslint-disable-next-line
	}, []);
	// 예약 관련데이터가 변경될 때마다 예약 여부 및 상태 업데이트 (예약대기 중이었던 게시물이 이미 발행된 경우 체크)
	useEffect(() => {
		if (data) {
			const { isBooked, bookedTime, isPublished } = checkBookingStatus(
				data.bookedYn ?? 'N',
				data.bookedDt ?? null,
				data.openYn ?? 'N'
			  );
	  	 // 예약 여부 및 상태 업데이트
		  setIsPicked(isBooked);
		  setIsBooked(isBooked);
	  	  setBookedTime(bookedTime) 
	    // 게시 상태 업데이트
		  setIsPublished(isPublished);
		}
	  }, [data?.bookedYn, data?.bookedDt, data?.openYn]);

	const changeImages = (datas) => {
		setData((prev: any) => ({ ...prev, imgUrlList: prev.imgUrlList.concat(datas) }));
		setImageEdited(true);
		return null;
	}

	const removeImageOnList = (idx: number) => {
		setData((prev: any) => {
			const clone = _.cloneWith(prev);
			clone.imgUrlList.splice(idx, 1);
			return clone;
		})
		setImageEdited(true);
	}
	const deleteFn = () => {
		api__deleteNoticeData(noticeId!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert('삭제되었습니다.');
			window.history.back();
		}).catch(console.error);
	}

	const editFn = () => {
		setPrevent(true);
		const { fixedYn, title, content, youtubeUrl, linkUrl, imgUrlList, openYn, temporaryYn } = data!;
		const params = {
			content, fixedYn, linkUrl, openYn, title, youtubeUrl, temporaryYn, bookedDt
		}
		if (imageEdited) params["imgUrlList"] = imgUrlList;
		api__putNoticeData(noticeId!, params).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert('수정되었습니다.');
			window.location.reload();
		}).catch(console.error).finally(() => { setPrevent(false) });
	}
	const handleDragChange = (result: any) => {
		if (!result.destination) return;
		const items = _.cloneDeep(data!.imgUrlList);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem)
		setData((prev: any) => ({ ...prev, imgUrlList: items }));
	};
	if (data === null) return <MainLayout title='소속사(관리자) 파트너센터 / 공지 게시물 상세'><></></MainLayout>
	  
	 // 필수 입력값 및 예약공개 일시 체크 후 저장하기
	const handleSave = () => {
	if (!validateAndCheckBooking()) return;
	
	// 예약 공개 일시가 과거인 경우 모달 열기
	if (bookedTime && bookedTime < new Date()) {
	  setNoticeModal('edit'); // 예약 공개 일시가 과거인 경우 "edit" 모달 열기
	} else {
	  handleModalAndSave(); // 정상적인 경우 저장 실행
	}
  };
  
  // 필수 입력값 및 예약공개 일시 체크 함수
  const validateAndCheckBooking = (): boolean => {
	const messages: string[] = [];
	
	if (data.title.trim() === '') messages.push('제목을 입력해주세요.');
	if (data.content.trim() === '') messages.push('내용을 입력해주세요.');
	
	// 예약공개 일시 관련 체크
	if (!isPublished && isPicked && !bookedTime) {
	  messages.push('예약공개 일시를 입력하셔야 등록 가능합니다.');
	}
  
	// 경고 메시지 출력
	if (messages.length > 0) {
	  window.alert(messages.join('\n'));
	  setNoticeModal(null);
	  return false;
	}
	
	return true;
  };
  
  // 모달 처리 및 저장 실행
  const handleModalAndSave = () => {
	if (isPicked && bookedTime && bookedTime > new Date()) {
	  setNoticeModal('edit'); // 예약 공개 일시가 유효할 때 모달 열기
	} else {
	  editFn(); // 바로 저장 실행
	}
  };
  
  // 수정 모드 토글
  const handleClick = () => {
	if (editMode) { // 수정 완료 버튼 클릭 시
	  const bookedDate = bookedTime ? new Date(bookedTime).getTime() : null;
	  const editedBookedDate = editBookedDt ? new Date(editBookedDt).getTime() : null;
	  
	  // 예약 시간이 현재보다 이전이고, 예약 날짜가 다르면 발행된 상태
	  const isAlreadyPublished = 
		bookedDate && 
		editedBookedDate && 
		bookedDate !== editedBookedDate && 
		editedBookedDate <= Date.now();
  
	  // 발행되었다면 alert 띄운 뒤 예약 공개 시간을 기존 값으로 초기화한 후 '게시됨'으로 변경하기
	  if (isAlreadyPublished) {
		setIsPublished(true);
		alert('이미 게시되어 예약 공개 시간을 수정할 수 없습니다.');
		setBookedTime(editBookedDt ? new Date(editBookedDt) : null);
	  } else {
		handleSave(); // 수정 완료 처리
	  }
	} else { // 수정 모드 진입
	  setEditMode(true);
	  setSaving(_.cloneWith(data)); // 수정 모드로 전환 시 데이터 저장
	  const clonedBookedDt = JSON.parse(JSON.stringify(data.bookedDt));	  
	  setEditBookedDt(clonedBookedDt || null); // bookedDt 값 수정모드 진입 시 고정
	}
  };

	return (
		<>
			{previewOn && <NoticePreview title={data.title} content={data.content} setPreviewOn={setPreviewOn} imgList={data.imgUrlList} youtubeUrl={data.youtubeUrl} linkUrl={data.linkUrl} />}
			{imgModal !== "" && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} />}
			{imgUploadModal && <ImageUploadModal
				setImgModal={setImgModal}
				setImgUploadModal={setImgUploadModal}
				getMyInfo={() => { }}
				api_Fn={changeImages}
				no_api
				folderName='notice'
			/>}
			{deleteModal && (
				<ModalMsgBox title="게시글을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(false);
					if (!bool) { deleteFn() };
				}} extraBtn={'삭제'}>
				</ModalMsgBox>
			)}
			{noticeModal === "edit" && (
				<ModalMsgBox
					title={'수정사항을 적용하시겠어요?'}
					setModal={(bool) => {
						setNoticeModal(null);
						if (!bool) editFn();
					}}
					extraBtn={'적용'}
				>
					<>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
				</ModalMsgBox>
				)}


			<MainLayout title="소속사(관리자) 파트너센터 / 공지 게시물 상세" cn={prevent ? "prevent" : ''}>
				<Box p="38px 34px" pb="120px">
					<SBCBox mb="34px">
						<DCBox>
							<BackBtn />
							<PageTitle>공지 게시물 상세</PageTitle>
						</DCBox>
						<DCBox>
							{(!editMode && !(data.openYn === 'N' && data.temporaryYn === 'N')) && <MainButton className='inactive' style={{ padding: '8px 24px' }} onClick={() => {
								setDeleteModal(true)
							}}>삭제</MainButton>}
							<MainButton sx={{ marginX: '8px' }} onClick={() => { setPreviewOn(true) }}>미리보기</MainButton>
							{editMode && <MainButton className='inactive' style={{ padding: '8px 24px', marginRight: '8px' }} onClick={() => {
								setData(_.cloneWith(saving));
								setSaving(null);
								setEditMode(false);
							}}>취소</MainButton>}
							<MainButton onClick={handleClick}>
								{editMode ? '완료' : '수정'}
							</MainButton>
						</DCBox>
					</SBCBox>
					<MyBox>
						<InfoComp title='아티스트 선택' value={data.artistName} />
						{editMode ? <InfoTxtComp title='제목' value={data.title} maxLength={50} onChange={(e) => {
							setData((prev: any) => {
								return { ...prev, title: e.target.value }
							})
						}} /> : <InfoComp title='제목' value={data.title} />}
						<StretchBox>
							<InfoTitleBox>고정글로 등록</InfoTitleBox>
							<InfoTextBox>
								<Checkbox
									checked={data.fixedYn === 'Y'} 
									onChange={(e) => {
										if (data.artistName === '전체(공통)' || !editMode) return;
										setData((prev: any) => {
											return { ...prev, fixedYn: e.target.checked ? 'Y' : 'N' }
									})}}
									disabled={data.artistName === '전체(공통)' || !editMode} // 비활성화 조건
									color='info'
									size='medium'
									sx={{ my: 1 }}
									/>
								<Box>
									<Typography variant='h6'>*고정글로 등록하면 공지 리스트 상단에 해당 게시물이 표기됩니다.</Typography>
									<Typography variant='h6'>*전체(공동) 공지 게시물은 고정글로 등록됩니다.</Typography>
								</Box>
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>이미지</InfoTitleBox>
							<InfoTextBox style={{ paddingRight: '12px', overflow: 'auto', whiteSpace: 'nowrap' }}>
								{editMode && <DCBox minWidth={120} width={'120px'} height={'120px'} bgcolor={'#DEDEDE'} mr="12px" justifyContent={'center'} onClick={() => { setImgUploadModal(true) }}>
									<ReactSVG src={SVG_IMAGES.UPLOAD_IMAGE} />
								</DCBox>}
								{editMode ? <DragDropContext onDragEnd={handleDragChange}>
									<Droppable droppableId="dataList" direction='horizontal'>
										{(provided) => (
											<FlexBox
												className="dataList"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{data.imgUrlList.map((item, idx) => (
													<Draggable
														key={`detail_image_${idx}`}
														draggableId={String(idx)}
														index={idx}
													>
														{(provided) => (
															<div
																ref={provided.innerRef}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																<ImageSizeBox onClick={() => { setImgModal(item) }} key={`image_preview_${idx}`} mr="12px">
																	{editMode && <ImageDeleteBtn onClick={(e) => { e.stopPropagation(); removeImageOnList(idx) }}>
																		X
																	</ImageDeleteBtn>}
																	<ImageBox>
																		<img src={item} alt={`image_preview_${idx}`} />
																	</ImageBox>
																</ImageSizeBox>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</FlexBox>
										)}
									</Droppable>
								</DragDropContext> :
									data.imgUrlList.map((item, idx) => <ImageSizeBox onClick={() => { setImgModal(item) }} key={`image_preview_${idx}`} mr="12px">
										<ImageBox>
											<img src={item} alt={`image_preview_${idx}`} />
										</ImageBox>
									</ImageSizeBox>)
								}
							</InfoTextBox>
						</StretchBox>
						{editMode ? <InfoTxtComp title='유튜브 URL' value={data.youtubeUrl} onChange={(e) => {
							setData((prev: any) => ({ ...prev, youtubeUrl: e.target.value }))
						}} /> : <InfoComp title='유튜브 URL' value={data.youtubeUrl} />}
						{editMode ? <InfoTxtComp title='링크버튼 URL' value={data.linkUrl} onChange={(e) => {
							setData((prev: any) => ({ ...prev, linkUrl: e.target.value }))
						}} /> : <InfoComp title='링크버튼 URL' value={data.linkUrl} />}
						<InfoComp title='작성자' value={`${data.rgstId} / ${data.rgstPosition} ${data.rgstDt !== data.updtDt ? `(최종 수정 : ${data.updtId} / ${data.updtPosition})` : ""}`} />
						<StretchBox>
							<InfoTitleBox>예약 공개</InfoTitleBox>
							<InfoTextBox>
								<CalendarPicker
									isPicked={isPicked}
									setIsPicked={setIsPicked}
									bookedTime={bookedTime}
									setBookedTime={setBookedTime}
									editMode={editMode}
									isBooked={isBooked}
									isPublished={isPublished}
								/>
							</InfoTextBox>
						</StretchBox>
						<InfoComp 
							title='업로드 일자' 
							value={
								isBooked && !isPublished 
								? '예약 중' 
								: (isBooked && isPublished && bookedTime) 
									? detailTimeFormat(data.bookedDt)
									: detailTimeFormat(data.rgstDt)
							} 
							/>
						<InfoComp title='최종 수정일' value={isBooked && !isPublished ? '예약 중' : data.updtDt === data.rgstDt ? "-" : detailTimeFormat(data.updtDt)} />
						<InfoComp title='조회수' value={String(data.viewCnt)} />
						<StretchBox>
							<InfoTitleBox>내용</InfoTitleBox>
							<InfoTextBox>
								{editMode ? <SimepleTextArea sx={{ minHeight: '316px' }} value={data.content} onChange={(e) => {
									setData((prev: any) => ({ ...prev, content: e.target.value }))
								}} /> : <div dangerouslySetInnerHTML={{ __html: data.content.replaceAll("\n", '<br />') }} />}
							</InfoTextBox>
						</StretchBox>
					</MyBox>
				</Box>
			</MainLayout>
		</>
	);
}
const MyBox = styled(Box)({
	matginBottom: '24px',
	'& label': {
		width: '40px',
		height: '40px',
		cursor: 'pointer',
		marginRight: '8px',
		transform: 'translateY(2px)',
		'& input[type="checkbox"]': {
			display: 'none'
		},
		'& img': {
			width: '100%'
		}
	},
	'& h6': {
		fontSize: '13px',
		fontWeight: 500,
		lineHeigth: '24px',
		color: '#6D6D6D'
	}
})