import { useEffect, useState, useRef } from 'react';
import { Box, Button, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  ContainedLinkBtn,
  DCBox,
  DarkContainedButton,
  FlexBox,
  ModalContentTypo,
  MyTable,
  PageTitle,
  SBCBox,
} from '../../Components/common/CommonElement';

import { api__getBadgeList, api__getBadgeStatic } from '../../services/BadgeService';
import { BadgeInfo, GalleriesInfo } from '../../Utils/types';
import { addCommas } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { ReactSVG } from 'react-svg';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import SimpleModal from '../../Components/common/SimpleModal';
import { logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { handleError } from '../../Utils/utils';

export default function Badge() {
  const [badgesInfo, setBadgesInfo] = useState<BadgeInfo[]>([]);
  const [staticInfo, setStaticInfo] = useState<any>([]);
  const [totalBadge, setTotalBadge] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<GalleriesInfo | null>(null);
  const [noticeModal, setNoticeModal] = useState(false);

  const isGetListProgress = useRef(false);
  const isGetStaticProgress = useRef(false);

  // 리스트를 받아오는 API 함수
  const getMyList = async () => {
    if (isGetListProgress.current) return;
    isGetListProgress.current = true;
    try {
      const result = await api__getBadgeList();
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setBadgesInfo(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetListProgress.current = false;
    }
  };

  const delMyData = async (id: string) => {
    // delData(id, api__delGalleries, getMyList);
  };

  const getBadgeStatic = async () => {
    if (isGetStaticProgress.current) return;
    isGetStaticProgress.current = true;
    try {
      const result = await api__getBadgeStatic();
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        window.alert('토큰이 만료되었습니다.');
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const sumObj = {};
      for (let i of badgesInfo) {
        sumObj[i.badgeId] = 0;
      }

      const newObj = {};
      for (let i of result.data.data) {
        if (!newObj[i.artistNameKo]) {
          newObj[i.artistNameKo] = {};
          for (let j of badgesInfo) {
            newObj[i.artistNameKo] = { ...newObj[i.artistNameKo], [j.badgeId]: 0 };
          }
        }
        sumObj[i.badgeId] += i.profileCnt;
        newObj[i.artistNameKo][i.badgeId] = i.profileCnt;
      }
      setTotalBadge(Object.entries(sumObj));
      setStaticInfo(Object.entries(newObj));

      // setBadgesInfo(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetStaticProgress.current = false;
    }
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (badgesInfo.length !== 0) getBadgeStatic();
    // eslint-disable-next-line
  }, [badgesInfo]);

  return (
    <>
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(deleteModal.galleryId);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ내용 : {deleteModal.content}</ModalContentTypo>
        </ModalMsgBox>
      )}
      {noticeModal && (
        <SimpleModal>
          <Box width={580} p="28px 13px 15px 13px" border={'1px solid #8F8F8F'} borderRadius="5px" bgcolor={'white'}>
            <DCBox mb="18px">
              <ReactSVG src={SVG_IMAGES.QUESTION_CIRCLE} style={{ transform: 'translateY(2px)' }} />
              <Typography ml="7px" variant="h4">
                배지 획득 조건
              </Typography>
            </DCBox>
            <Box border={'1px solid #8F8F8F'} borderRadius="5px" p="13px" mb="12px">
              <Typography variant="body2">
                ㆍ첫 게시글 작성 : 응원하기, 갤러리, 자유게시판, 팬끼리 중 하나의 게시물 등록
              </Typography>
              <Typography variant="body2">ㆍ질문왕 : 자유게시판에 질문하기 태그로 게시물 20개 이상 등록</Typography>
              <Typography variant="body2">ㆍ응원 단장 : 한 달 동안 응원글 10개 이상 등록</Typography>
              <Typography variant="body2">ㆍ헐리우드 리액션 : 댓글 30개 이상 등록</Typography>
              <Typography variant="body2">ㆍ쥬쥬 시크릿 : 팬끼리 게시물 10개 이상 등록</Typography>
              <Typography variant="body2">ㆍ홈 마스터 : 갤러리 게시물 100개 이상 등록</Typography>
              <Typography variant="body2">ㆍ정보요원 : 자유게시판 정보공유 게시물 100개 이상 등록</Typography>
              <Typography variant="body2">ㆍ개근맨 : 한 달 동안 매일 굿덕에 접속</Typography>
            </Box>
            <FlexBox justifyContent={'flex-end'}>
              <DarkContainedButton
                onClick={() => {
                  setNoticeModal(false);
                }}
              >
                확인
              </DarkContainedButton>
            </FlexBox>
          </Box>
        </SimpleModal>
      )}
      <MainLayout title="활동배지 관리 / 활동 배지">
        <Box p="34px">
          <SBCBox mb="32px">
            <PageTitle>활동 배지 관리</PageTitle>
            <Button
              sx={{ minWidth: '30px' }}
              onClick={() => {
                setNoticeModal(true);
              }}
            >
              <ReactSVG src={SVG_IMAGES.QUESTION_CIRCLE} />
            </Button>
          </SBCBox>
          <Box mt="18px">
            <MyTable>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: `150px` }}>아티스트명</TableCell>
                  {badgesInfo.map((item, idx) => (
                    <TableCell key={`th-${idx}`} sx={{ minWidth: '80px' }}>
                      <DCBox flexDirection={'column'}>
                        <ContainedLinkBtn
                          href={ROUTES.BADGE_MAIN + '/' + item.badgeId}
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          상세
                        </ContainedLinkBtn>
                        <Typography variant="h2" fontSize={'15px'} mt="12px">
                          {item.name}
                        </Typography>
                      </DCBox>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>(총합)</TableCell>
                  {totalBadge.map((item, tIdx) => (
                    <TableCell key={`tBadge-${tIdx}`}>{addCommas(item[1])}</TableCell>
                  ))}
                </TableRow>
                {staticInfo.map((item, sIdx) => (
                  <TableRow key={`static-${sIdx}`}>
                    <TableCell>{item[0]}</TableCell>
                    {Object.values(item[1]).map((i, bIdx) => (
                      <TableCell key={`${sIdx}_${bIdx}`}>{addCommas(Number(i))}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </MyTable>
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
