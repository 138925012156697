import { Box, Typography } from "@mui/material"
import { InfoImageBox, InfoTextBox, InfoTitleBox, InfoTxtComp, LabelRadio, PageTitle, StretchBox } from "../common/CommonElement"
import ImageUploader from "../common/ImageUploader"
import { SketchPicker } from "react-color"
import { COLORS } from "../../Constant/COLORS"
import { ArtistBasicInfo, CreateArtistBasicInfo } from "../../Utils/types"

type IProps = {
  basicInfo: CreateArtistBasicInfo | ArtistBasicInfo | null;
  basicStateChange: (value: string, target: string) => void;
  editMode: boolean;
}

export default function ArtistDetailBasicInfo({ basicInfo, basicStateChange, editMode }: IProps) {
  return (
    <>
      <PageTitle mb="24px" style={{ fontSize: '20px' }}>아티스트 기본 정보</PageTitle>
      <InfoTxtComp
        title="아티스트명(한글)"
        value={basicInfo?.artistNameKo || ""}
        onChange={(e) => { basicStateChange(e.target.value, "artistNameKo") }}
        readonly={!editMode}
        maxLength={50}
      />
      <InfoTxtComp
        title="아티스트명(영문)"
        value={basicInfo?.artistNameEn || ""}
        onChange={(e) => { basicStateChange(e.target.value, "artistNameEn") }}
        readonly={!editMode}
        maxLength={50}
      />
      <StretchBox>
        <InfoTitleBox>분류 선택</InfoTitleBox>
        <InfoTextBox>
          <LabelRadio
            name="groupType"
            checkedCondition={basicInfo?.groupType === "002"}
            onChange={(e) => { basicStateChange(e.target.value, 'groupType') }}
            value="002"
            desc="그룹"
          />
          <Box mr='12px' />
          <LabelRadio
            name="groupType"
            checkedCondition={basicInfo?.groupType === "001"}
            onChange={(e) => { basicStateChange(e.target.value, 'groupType') }}
            value="001"
            desc="솔로"
          />
        </InfoTextBox>
      </StretchBox>
      <InfoTxtComp
        title="분야 설명"
        value={basicInfo?.genre || ""}
        onChange={(e) => { basicStateChange(e.target.value, "genre") }}
        readonly={!editMode}
        maxLength={50}
      />
      <InfoTxtComp
        title="한 줄 소개"
        value={basicInfo?.about || ""}
        onChange={(e) => { basicStateChange(e.target.value, "about") }}
        readonly={!editMode}
        maxLength={200}
      />
      <StretchBox>
        <InfoTitleBox>이미지</InfoTitleBox>
        <InfoImageBox>
          <Box mr="90px">
            <ImageUploader
              title='메인 이미지'
              width={120}
              height={120}
              imageUrl={basicInfo?.artistMainImgUrl || ""}
              size='750x750'
              onChange={(url: string) => { basicStateChange(url, "artistMainImgUrl") }}
              disabled={!editMode}
              folderName="profile"
            />
          </Box>
          <Box>
            <ImageUploader
              title='랜덤 아티스트 추천 이미지(홈화면)'
              width={105}
              height={120}
              imageUrl={basicInfo?.artistRecommendProfileImgUrl || ""}
              size='1029x1170'
              onChange={(url: string) => { basicStateChange(url, "artistRecommendProfileImgUrl") }}
              disabled={!editMode}
              folderName="profile"
            />
          </Box>
        </InfoImageBox>
      </StretchBox>
      <StretchBox>
        <InfoTitleBox>캘린더 색상 선택</InfoTitleBox>
        <InfoTextBox>
          <SketchPicker
            color={basicInfo?.artistColor || "#fff"}
            onChange={(color) => { basicStateChange(color.hex, "artistColor") }}
          />
          <Box p="12px" border={`1px solid ${COLORS.BORDER_GRAY}`} borderRadius={'4px'} ml="24px">
            <Typography variant="h5">지금 선택한 색상</Typography>
            <Box borderRadius={'4px'} border={`1px solid ${COLORS.BORDER_GRAY}`} width={120} height={120} bgcolor={basicInfo?.artistColor || "#fff"} />
          </Box>
        </InfoTextBox>
      </StretchBox>

    </>
  )
}