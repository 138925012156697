import axios from 'axios';
import { makeHeader } from '../Utils/utils';
import { TermsDocType } from '../Utils/types';
import { getAxiosProcess, postAxiosProcess } from './CommonService';
import { DOCUMENT_CODE } from '../Constant/CODES';

const rootUrl = process.env.REACT_APP_ENV === 'LOCAL' ? '' : process.env.REACT_APP_API_URL;

// 약관 - 리스트 불러오기
export const api__getTerms = async (page: number, limit: number, docType: TermsDocType) => {
  try {
    return await axios.get(`${rootUrl}/api/documents?docType=${docType}&page=${page}&limit=${limit}`, makeHeader());
  } catch (error: unknown) {
    console.log('getTerms error ::: ' + error);
    return null;
  }
};

// 약관 - 버전 리스트 불러오기
export const api__getVersionsList = async (docType: TermsDocType) => {
  try {
    return await axios.get(`${rootUrl}/api/documents/version/list?docType=${docType}`, makeHeader());
  } catch (error) {
    console.log('api__getVersionsList error ::: ' + error);
    return null;
  }
};

// 약관 - 최신버전 가져오기
export const api__getLatestVersion = async (docType: TermsDocType) => {
  try {
    return await axios.get(`${rootUrl}/api/documents/version/latest?docType=${docType}`, makeHeader());
  } catch (error) {
    console.log('api__getLatestVersion error ::: ' + error);
    return null;
  }
};

// 약관 - 상세보기
export const api__postTerms = async (content: string, docType: TermsDocType, version: string) => {
  try {
    return await axios.post(
      `${rootUrl}/api/documents`,
      { content, docType: DOCUMENT_CODE[docType], version },
      makeHeader(),
    );
  } catch (error) {
    console.log('api__postTerms error ::: ' + error);
    return null;
  }
};

// 서비스 이용약관 - 상세보기
export const api__delTerms = async (seq: number) => {
  try {
    return await axios.delete(`${rootUrl}/api/documents/${seq}`, makeHeader());
  } catch (error) {
    console.log('api__delTerms error ::: ' + error);
    return null;
  }
};

// 서비스 이용약관 - 상세보기
export const api__getTermsDetail = async (seq: number) => {
  try {
    const url = `${rootUrl}/api/documents/${seq}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log('api__getTermsDetail error ::: ' + error);
    return null;
  }
};

// 서비스 이용약관 - 업데이트하기
export const api__putTermsDetail = async (seq: number, content: string) => {
  try {
    return await axios.put(`${rootUrl}/api/documents/${seq}`, { content }, makeHeader());
  } catch (error) {
    console.log('api__putTermsDetail error ::: ' + error);
    return null;
  }
};

// 서비스 이용약관 - 삭제
export const api__delPrivacyPolicy = async (seq: number) => {
  try {
    return await axios.delete(`${rootUrl}/api/common/privacy-policy/${seq}`, makeHeader());
  } catch (error) {
    console.log('delPrivacyPolicy error ::: ' + error);
    return null;
  }
};

export const api__getSingleDoc = async (docType: string) => {
  const url = `${rootUrl}/api/single-document/${docType}`;
  return await getAxiosProcess(url);
};
export const api__postSingleDoc = async (content: string, type: string) => {
  const url = `${rootUrl}/api/single-document/`;
  return await postAxiosProcess(url, { content, type });
};

// 회원가입 약관 - 조회
export const api__getSignupTerms = async () => {
  const url = `${rootUrl}/api/privacy-consent`;
  return await getAxiosProcess(url);
};
// 회원가입 약관 - 수정
export const api__postSignupTerms = async (content: string) => {
  const url = `${rootUrl}/api/privacy-consent`;
  return await postAxiosProcess(url, { content });
};

// 아티스트 추천버튼 URL - 조회
export const api__getRecommendURL = async () => {
  try {
    return await axios.get(`${rootUrl}/api/artist-recommend-url`, makeHeader());
  } catch (error) {
    console.log('api__getRecommendURL error ::: ' + error);
    return null;
  }
};

// 아티스트 추천버튼 URL - 수정
export const api__putRecommendURL = async (artistRecommendUrl: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artist-recommend-url`, { artistRecommendUrl }, makeHeader());
  } catch (error) {
    console.log('api__putRecommendURL error ::: ' + error);
    return null;
  }
};
