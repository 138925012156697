import { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  DCBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  MainButton,
  ModalContentTypo,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';
import { logoutWithOutNavigate } from '../../Utils/utils';
import _, { debounce } from 'lodash';
import { api__getAgencyArtistList, api__postAgency } from '../../services/AgencieServices';
import { Icon } from '@iconify/react';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { AgencyArtistType } from '../../Utils/types';

export default function PartnersAccountCreate() {
  const [agencyName, setAgencyName] = useState('');
  const [artistList, setArtistList] = useState<AgencyArtistType[]>([]);
  const [artistModal, setArtistModal] = useState<AgencyArtistType[]>([]);

  const [createModal, setCreateModal] = useState(false);

  const getMyInfo = debounce(async () => {
    try {
      const result = await api__getAgencyArtistList();
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setArtistList(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);

  const createFn = async () => {
    try {
      const result = await api__postAgency({
        agencyName,
        artistIdList: artistList.filter(item => item.checkYn === 'Y').map(item => item.artistId),
      });
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.history.back();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {createModal && (
        <ModalMsgBox
          title="소속사를 추가하시겠어요?"
          setModal={bool => {
            setCreateModal(false);
            if (!bool) {
              createFn();
            }
          }}
          extraBtn={'생성'}
        >
          <ModalContentTypo>ㆍ소속사명 : {agencyName}</ModalContentTypo>
          <ModalContentTypo>ㆍ관리 아티스트</ModalContentTypo>
          {artistList.map(
            (al, idx) =>
              al.checkYn === 'Y' && (
                <ModalContentTypo ml="12px" key={`artist_${idx}`}>
                  ㆍ{al.nameKo}
                </ModalContentTypo>
              ),
          )}
        </ModalMsgBox>
      )}
      <MainLayout title="소속사(관리자) 파트너센터 / 소속사 추가 ">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>소속사 추가</PageTitle>
            </DCBox>
            <MainButton
              onClick={() => {
                if (agencyName.trim() === '') {
                  window.alert('소속사명을 입력해주세요.');
                  return;
                }
                if (artistList.filter(item => item.checkYn === 'Y').length === 0) {
                  window.alert('관리 아티스트를 선택해주세요.');
                  return;
                }
                setCreateModal(true);
              }}
            >
              소속사 추가
            </MainButton>
          </SBCBox>
          <Box mb="82px">
            <InfoTxtComp
              title="소속사명"
              value={agencyName}
              onChange={e => {
                setAgencyName(e.target.value);
              }}
            />
            <StretchBox>
              <InfoTitleBox>관리 아티스트</InfoTitleBox>
              <InfoTextBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ThisSelectBox
                  style={{ width: '200px' }}
                  onClick={() => {
                    setArtistModal(_.cloneDeep(artistList));
                  }}
                >
                  <SBCBox>
                    <Typography variant="body2" color={'#222'}>
                      아티스트 선택
                    </Typography>
                    <Icon icon={ICONIFY_ICONS.ARROW_DOWN} width={18} height={18} />
                  </SBCBox>
                </ThisSelectBox>
                {artistList.filter(item => item.checkYn === 'Y').length !== 0 && <hr style={{ width: '100%' }} />}
                <Box>
                  {artistList.map(
                    (item, idx) => item.checkYn === 'Y' && <Box key={`artist_${idx}`}>ㆍ{item.nameKo}</Box>,
                  )}
                </Box>
              </InfoTextBox>
            </StretchBox>
          </Box>
        </Box>
      </MainLayout>
      {artistModal.length !== 0 && (
        <ModalMsgBox
          title="아티스트 선택(복수선택 가능)"
          setModal={bool => {
            if (!bool) {
              setArtistList(_.cloneDeep(artistModal));
              setArtistModal([]);
            } else setArtistModal([]);
          }}
          extraBtn={'저장'}
        >
          <ArtistListBox className="reset-scroll">
            {artistModal.map((item, idx) => (
              <label key={`artist_modal_${idx}`}>
                <input
                  type="checkbox"
                  checked={item.checkYn === 'Y'}
                  onChange={() => {
                    const temp = [...artistModal];
                    temp[idx].checkYn = temp[idx].checkYn === 'Y' ? 'N' : 'Y';
                    setArtistModal(temp);
                  }}
                />
                {item.nameKo}
              </label>
            ))}
          </ArtistListBox>
        </ModalMsgBox>
      )}
    </>
  );
}

const ThisSelectBox = styled(Box)({
  padding: '7px 16px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  cursor: 'pointer',
  position: 'relative',
});
const ArtistListBox = styled(Box)({
  padding: '16px',
  overflow: 'auto',
  height: '300px',
  '& label': {
    marginBottom: '12px',
    display: 'block',
  },
  '& input': {
    marginRight: '8px',
  },
});
