import { ROUTES } from "../Utils/routes";
import Calendar from "../pages/calendar";
import CalendarCreate from "../pages/calendar/CalendarCreate";
import CalendarDetail from "../pages/calendar/CalendarDetail";
import TipOffSchedule from "../pages/tip-off-schedule";
import TipOffScheduleDetail from "../pages/tip-off-schedule/TipOffScheduleDetail";

export const calendarMenuList = [
  { path: ROUTES.CALENDAR_MAIN + "/:page/:limit/:artistId?", component: Calendar },
  { path: ROUTES.CALENDAR_MAIN + "/new", component: CalendarCreate },
  { path: ROUTES.CALENDAR_MAIN + "/:calendarFeedId", component: CalendarDetail },

  { path: ROUTES.TIPOFFSCHEDULE_MAIN + "/:page/:limit/:artistId?", component: TipOffSchedule },
  { path: ROUTES.TIPOFFSCHEDULE_MAIN + "/:tipOffScheduleId", component: TipOffScheduleDetail },
]