import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { DeleteButton, ModalContentTypo, MyTable, PageTitle, SBCBox, THS } from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';

import { api__delCheerUp, api__getCheerUpList } from '../../services/ArtistPageService';
import { ArtistId, CheerUpInfo, CheerUpListInfo } from '../../Utils/types';
import { addCommas, delData, getArtistList, getListPL } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import ArtistSelectBox from '../../Components/common/ArtistSelectBox';
import { useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../Utils/routes';
import LimitBox from '../../Components/common/LimitBox';
import { COLORS } from '../../Constant/COLORS';

export default function CheerUp() {
  const navigate = useNavigate();
  const { page, limit, artistId } = useParams();
  const [dataListInfo, setDataListInfo] = useState<CheerUpListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<CheerUpInfo | null>(null);

  const [aName, setAName] = useState("아티스트 선택");
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);


  const getMyList = async () => {
    getListPL(api__getCheerUpList, setDataListInfo, page || "1", limit || "10", artistId);
  }

  const delMyData = async (id: string) => {
    delData(id, api__delCheerUp, getMyList);
  }

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit, artistId]);

  useEffect(() => {
    getArtistList(setArtistList);
  }, [])
  useEffect(() => {
    if (artistList.length !== 0 && artistId) {
      setAName(artistList.find((item) => item.artistId === artistId)?.artistNameKo || "아티스트 선택")
    }
    // eslint-disable-next-line
  }, [artistList])

  const changeSelect = (aVal: string, nameVal: string) => {
    navigate(
      ROUTES.CHEERUP_MAIN + `/1/${limit}${aVal !== "" && aVal !== "ALL" ? `/${aVal}` : ""}`
    )
    setAName(nameVal);
    setIsDropped(false);
  }

  return (
    <>
      {deleteModal && (
        <ModalMsgBox title="게시물을 삭제하시겠어요?" setModal={(bool) => {
          setDeleteModal(null);
          if (!bool) delMyData(deleteModal.cheerUpId);
        }} extraBtn={'삭제'}>
          <ModalContentTypo>
            ㆍ내용 : {deleteModal.content}
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="아티스트 페이지 관리 / 응원글">
        <Box p="34px">
          <SBCBox mb='12px'>
            <PageTitle>응원글 / {addCommas(dataListInfo?.totalCount || 0)}개</PageTitle>
            <ArtistSelectBox
              aName={aName} artistId={artistId || ""} isDropped={isDropped} artistList={artistList}
              changeSelect={changeSelect} setIsDropped={setIsDropped}
            />
          </SBCBox>
          <LimitBox limit={limit || "10"} page={page || "1"} route={ROUTES.CHEERUP_MAIN} subUrl={artistId ? `/${artistId}` : ""} />
          <Box mt="24px">
            <MyTable>
              <THS
                ths={[
                  { name: "#", width: 40 },
                  { name: "아티스트명", width: 150 },
                  { name: "계정 닉네임", width: 150 },
                  { name: "내용", width: 200 },
                  { name: "업로드 일시", width: 120 },
                  { name: "삭제여부", width: 80 },
                  { name: "관리", width: 60 },
                ]}
              />
              <TableBody>
                {dataListInfo && dataListInfo.data.map((item, uidx) => (
                  <TableRow key={`user-${uidx}`}>
                    <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                    <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
                    <TableCell>{item.uid === null ? "someone" : item.uploaderNickname}</TableCell>
                    <TableCell>{item.content}</TableCell>
                    <TableCell>{item.rgstDt}</TableCell>
                    <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
                    <TableCell>
                      {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MyTable>
            {dataListInfo && <Pages pageInfo={dataListInfo} getList={(p) => { navigate(ROUTES.CHEERUP_MAIN + `/${String(p)}/${limit}${artistId ? `/${artistId}` : ""}`) }} />}
          </Box>
        </Box>
      </MainLayout >
    </>
  )
}