import { Box, Typography } from "@mui/material";
import { CenterBox } from "./CommonElement";
import { COLORS } from "../../Constant/COLORS";

type IProps = {
	children: React.ReactNode;
	title?: string
}

export default function ModalBox({ children, title }: IProps) {
	return (
		<CenterBox position='fixed' left='0' top='0' zIndex={100}>
			<Box display='flex' flexDirection='column' width='480px' bgcolor={'white'} border={`1px solid ${COLORS.BORDER_GRAY}`} borderRadius='12px' overflow='hidden'>
				<Box borderBottom={`1px solid ${COLORS.BORDER_GRAY}`} p="16px">
					<Typography sx={{ textAlign: 'left' }} variant="body1" fontWeight={600}>{title}</Typography>
				</Box>
				{children}
			</Box>
		</CenterBox>
	)
}