import { useState } from 'react';
import { Box } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import MemberList from '../../Components/header/MemberList';
import MemberNew from '../../Components/header/MemberNew';




export default function HeaderMaster() {
  const [addMember, setAddMember] = useState(false);

  return (
    <MainLayout title="">
      <Box p="38px">
        {addMember ?
          <MemberNew setAddMember={setAddMember}/>
          : <MemberList setAddMember={setAddMember}/>
        }
      </Box>
    </MainLayout>
  );
}
