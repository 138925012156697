import { Box, Button, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { ROOTROUTES, ROUTES } from "../../Utils/routes";
import { COLORS } from "../../Constant/COLORS";
import { ReactSVG } from "react-svg";
import { SVG_IMAGES } from "../../Constant/IMAGES";
import { memo } from "react";

const NavButton = ({ children, path, parameters }: { children: string; path: string; parameters?: string; }) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	return (
		<SideNavBtn
			onClick={() => { navigate(path + (parameters || "")) }}
			className={pathname.includes(path) ? "active" : ""}>
			{children}
		</SideNavBtn>
	)
}

export default memo(function CommonSideBar({ fold }: { fold: string; }) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<Box className={`side-bar ${fold}`}>
			<SideBigNav className={pathname === "/" ? "active" : ""} onClick={() => { navigate("/") }}>
				<ReactSVG src={SVG_IMAGES.GOODDUCK_HEAD} />
				Goodduck
			</SideBigNav>
			<SideTitle className={pathname.includes(ROOTROUTES.PARTNERS) ? "active" : ""}>소속사(관리자) 파트너센터</SideTitle>
			<NavButton path={ROUTES.PARTNERS_ACCOUNT}>소속사/계정 관리</NavButton>
			<NavButton path={ROUTES.PARTNERS_NOTICE} parameters="/1/10">공지 게시물 관리</NavButton>
			<NavButton path={ROUTES.PARTNERS_TERMS} parameters="/1/10">[파트너센터] 이용약관</NavButton>
			<NavButton path={ROUTES.PARTNERS_PERSONAL} parameters="/1/10">[파트너센터] 개인정보처리방침</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.TERMS) ? "active" : ""}>약관 및 추천URL 관리</SideTitle>
			<NavButton path={ROUTES.SERVICE_TERMS} parameters="/1/10">서비스 이용약관</NavButton>
			<NavButton path={ROUTES.PERSONAL_TERMS} parameters="/1/10">개인정보처리방침</NavButton>

			<NavButton path={ROUTES.SIGNUP_TERMS}>[회원가입] 개인정보 수집 이용 동의</NavButton>
			<NavButton path={ROUTES.TELAUTH_TERMS}>[본인인증] 개인정보 수집 이용 동의</NavButton>
			<NavButton path={ROUTES.PAYMENT_TERMS}>[결제] 개인정보 수집 및 이용 동의</NavButton>
			<NavButton path={ROUTES.ORDER_TERMS}>[결제] 주문 정책 동의 관리</NavButton>

			<NavButton path={ROUTES.RECOMMEND_TERMS}>아티스트 추천버튼 URL</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.USERMANAGE) ? "active" : ""}>유저 관리</SideTitle>
			<NavButton path={ROUTES.SIGNUPUSERS} parameters="/1/10">가입유저</NavButton>
			<NavButton path={ROUTES.SIGNUPARTIST} parameters="/1/10">아티스트</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.HOMEFEED) ? "active" : ""}>홈 게시물 관리</SideTitle>
			<NavButton path={ROUTES.GOODNEWS_MAIN} parameters="/1/10">굿뉴스</NavButton>
			<NavButton path={ROUTES.GOODDUCKMAGAZINE_MAIN} parameters="/1/10">굿덕 매거진</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.ARTISTPAGE) ? "active" : ""}>아티스트 페이지 관리</SideTitle>
			<NavButton path={ROUTES.ARTISTFEED_MAIN} parameters="/1/10">아티스트 피드</NavButton>
			<NavButton path={ROUTES.MAILBOXES_MAIN} parameters="/1/10">굿덕 사서함</NavButton>
			<NavButton path={ROUTES.GOODTALKS_MAIN} parameters="/1/10">굿톡</NavButton>
			<NavButton path={ROUTES.GALLERY_MAIN} parameters="/1/10">갤러리</NavButton>
			<NavButton path={ROUTES.CHEERUP_MAIN} parameters="/1/10">응원글</NavButton>
			<NavButton path={ROUTES.FANONLY_MAIN} parameters="/1/10">팬끼리</NavButton>
			<NavButton path={ROUTES.INFOROOM_MAIN} parameters="/1/10">자유게시판</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.CALENDAR) ? "active" : ""}>캘린더 관리
			</SideTitle>
			<NavButton path={ROUTES.CALENDAR_MAIN} parameters="/1/10">일정 게시물</NavButton>
			<NavButton path={ROUTES.TIPOFFSCHEDULE_MAIN} parameters="/1/10">일정 제보</NavButton>

			<SideTitle className={pathname.includes(ROOTROUTES.REPLY) ? "active" : ""}>댓글 관리</SideTitle>
			<NavButton path={ROUTES.REPLY_MAIN} parameters="/1/10/MAGAZINE">댓글 리스트</NavButton>

			{/* <SideTitle className={pathname.includes(ROOTROUTES.BADGE) ? "active" : ""}>활동 배지 관리</SideTitle>
			<NavButton path={ROUTES.BADGE_MAIN}>활동 배지</NavButton> */}

			<SideTitle className={pathname.includes(ROOTROUTES.REPORT) ? "active" : ""}>신고 내역 관리</SideTitle>
			<NavButton path={ROUTES.COMMENT_REPORT_MAIN} parameters="/1/10">댓글 리스트</NavButton>
			<NavButton path={ROUTES.BOARD_REPORT_MAIN} parameters="/1/10">게시물 리스트</NavButton>
		</Box>
	)
})

const SideBigNav = styled(Button)({
	fontSize: '20px',
	fontWeight: 700,
	lineHeight: '16px',
	width: '100%',
	fontFamily: 'Pretendard',
	color: '#fff',
	'& svg': {
		marginRight: '9px',
		transform: 'translateY(1px)'
	}
})

const SideTitle = styled('h3')({
	fontSize: '18px',
	marginBottom: '8px',
	marginTop: '30px',
	fontWeight: 700,
	fontFamily: 'Pretendard',
	color: COLORS.SIDEBAR_GRAY,
	'&.active': {
		color: 'white'
	},
});


const SideNavBtn = styled(Button)({
	fontFamily: 'Pretendard',
	display: "block",
	height: '18px',
	textAlign: 'left',
	padding: 0,
	color: COLORS.SIDEBAR_GRAY,
	paddingLeft: '3px',
	fontSize: '15px',
	fontWeight: 600,
	marginBottom: '12px',
	textDecoration: 'none',
	'&.active': {
		color: 'white',
	}
})