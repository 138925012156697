import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import { api__getBadgeDetail, api__putBadgeDetail } from '../../services/BadgeService';
import { BadgeDetailInfo } from '../../Utils/types';
import { addCommas, getInfo, logoutWithOutNavigate } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import {
  BackBtn,
  DCBox,
  InfoComp,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  MainButton,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import ImageUploader from '../../Components/common/ImageUploader';
import { handleError } from '../../Utils/utils';

export default function BadgeManage() {
  const [detailInfo, setDetailInfo] = useState<BadgeDetailInfo>({
    badgeId: '',
    desc: '',
    name: '',
    imgUrl: '',
    profileCntList: [],
  });

  const { badgeId } = useParams();
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const getMyInfo = () => {
    getInfo(api__getBadgeDetail, badgeId, setDetailInfo);
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [badgeId]);

  const changeImage = (url: string) => {
    if (!editMode) return;
    if (!isEdit) setIsEdit(true);
    setDetailInfo({ ...detailInfo, imgUrl: url });
  };

  const putBadgeDetail = async () => {
    try {
      const { badgeId, imgUrl, name } = detailInfo;
      const result = await api__putBadgeDetail(badgeId, imgUrl, name);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        window.alert('토큰이 만료되었습니다.');
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);

      window.alert('수정이 완료되었습니다.');
      setIsEdit(false);
      setEditMode(false);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const editActive = () => {
    if (editMode) {
      setEditModal(true);
    }
    setEditMode(prev => !prev);
  };

  return (
    <>
      {editModal && (
        <ModalMsgBox
          title="수정사항을 적용하시겠어요?"
          setModal={bool => {
            setEditModal(false);
            if (!bool) putBadgeDetail();
          }}
          extraBtn={'적용'}
        ></ModalMsgBox>
      )}
      <MainLayout title="활동배지 관리 / 활동배지">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>활동 배지 관리 - 배지명</PageTitle>
            </DCBox>
            <MainButton onClick={editActive}>{editMode ? '수정 적용' : '수정'}</MainButton>
          </SBCBox>
          <Box mb="87px">
            <InfoTxtComp
              title="배지명"
              readonly={!editMode}
              value={detailInfo.name}
              onChange={e => {
                setDetailInfo({ ...detailInfo, name: e.target.value });
                setIsEdit(true);
              }}
            />
            <InfoComp title="배지 획득 조건" value={detailInfo.desc} />
            <StretchBox>
              <InfoTitleBox>배지 이미지</InfoTitleBox>
              <InfoImageBox>
                <ImageUploader
                  title=""
                  width={120}
                  height={120}
                  imageUrl={detailInfo.imgUrl}
                  size="750x750"
                  onChange={(url: string) => {
                    changeImage(url);
                  }}
                  disabled={!editMode}
                  folderName="badge"
                />
              </InfoImageBox>
            </StretchBox>
          </Box>
          <PageTitle sx={{ fontSize: '20px' }} mb="11px">
            배지 획득 유저수(프로필 수)
          </PageTitle>
          <Box>
            {detailInfo.profileCntList.map((item, idx) => (
              <StretchBox key={`px_${idx}`}>
                <InfoTitleBox>{item.artistNameKo}</InfoTitleBox>
                <InfoTextBox>
                  <Typography variant="body2" fontWeight={700}>
                    {addCommas(item.profileCnt)}
                  </Typography>
                </InfoTextBox>
              </StretchBox>
            ))}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
