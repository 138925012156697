import { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import { InfoComp, PageTitle } from "../common/CommonElement";
import { api__getPostStatic } from '../../services/ManageUserService';
import { addCommas, getOnlyDataByP1 } from '../../Utils/utils';
import { PostStaticInfo } from '../../Utils/types';
import { debounce } from 'lodash';

type IProps = {
  artistId: string | undefined
}

export default function ArtistDetailPostStaticInfo({ artistId }: IProps) {
  const [staticData, setStaticData] = useState<PostStaticInfo | null>(null);
  const getMyInfo = debounce(() => {
    getOnlyDataByP1(api__getPostStatic, artistId, setStaticData);
  }, 300)
  useEffect(() => {
    if (artistId === undefined) return;
    getMyInfo();
    // eslint-disable-next-line
  }, [artistId])
  if (staticData === null) return <></>
  return <>
    <PageTitle style={{ fontSize: '20px', marginTop: '120px', marginBottom: "24px" }}>아티스트 페이지 게시물 통계</PageTitle>
    {
      staticData && <Box>
        <InfoComp title='피드 게시물 수' value={addCommas(staticData.artistFeedCnt)} />
        <InfoComp title='굿덕사서함' value={addCommas(staticData.mailBoxCnt)} />
        <InfoComp title='캘린더 일정 게시물 수' value={addCommas(staticData.calendarCnt)} />
        <InfoComp title='굿톡 진행 횟수' value={addCommas(staticData.goodTalkCnt)} />
        <InfoComp title='갤러리 게시물 수' value={addCommas(staticData.galleryCnt)} />
        <InfoComp title='응원글 수' value={addCommas(staticData.cheerUpCnt)} />
        <InfoComp title='팬끼리 게시물 수' value={addCommas(staticData.fanOnlyCnt)} />
        <InfoComp title='자유게시판 게시물 수' value={addCommas(staticData.infoRoomCnt)} />
      </Box>

    }
  </>
}