import { useState, useEffect, memo, createContext } from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layout/MainLayout';
import { DetailLayoutBox } from '../../Components/common/CommonElement';
import { useParams } from 'react-router';
import { getInfo, logoutWithOutNavigate } from '../../Utils/utils';
import {
  api__getArtistInfo,
  api__updateArtistActivation,
  api__updateArtistBasicInfo,
  api__updateMusicPlatformInfo,
  api__updateYotubueInfo,
} from '../../services/ManageUserService';
import { ArtistBasicInfo, ArtistDetailInfo, MusicPlatformInfo, YoutubeInfo } from '../../Utils/types';
import { handleError } from '../../Utils/utils';
import ArtistDetailHeader from '../../Components/artist/ArtistDetailHeader';
import ArtistDetailBasicInfo from '../../Components/artist/ArtistDetailBasicInfo';
import ArtistDetailAccountsInfo from '../../Components/artist/ArtistDetailAccountsInfo';
import ArtistDetailPlatformInfo from '../../Components/artist/ArtistDetailPlatformInfo';
import ArtistDetailYoutubeInfo from '../../Components/artist/ArtistDetailYoutubeInfo';
import ArtistDetailPostStaticInfo from '../../Components/artist/ArtistDetailPostStaticInfo';
import ArtistDetailMembership from '../../Components/artist/ArtistDetailMembership';
import { debounce } from 'lodash';
import { validBasicInfo } from '../../Utils/manageArtistUtil';

export const ArtistDetailContext = createContext<{
  detailInfo: ArtistDetailInfo | null;
  setDetailInfo: React.Dispatch<React.SetStateAction<ArtistDetailInfo | null>>;
  basicInfo: ArtistBasicInfo | null;
  setBasicInfo: React.Dispatch<React.SetStateAction<ArtistBasicInfo | null>>;
  platformInfo: MusicPlatformInfo[];
  setPlatformInfo: React.Dispatch<React.SetStateAction<MusicPlatformInfo[]>>;
  youtubeInfo: YoutubeInfo[];
  setYoutubeInfo: React.Dispatch<React.SetStateAction<YoutubeInfo[]>>;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  basicIsEdit: boolean;
  setBasicIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  mpEdit: boolean;
  setMpEdit: React.Dispatch<React.SetStateAction<boolean>>;
  youtubeEdit: boolean;
  setYoutubeEdit: React.Dispatch<React.SetStateAction<boolean>>;
  artistId: string | undefined;
}>({
  detailInfo: null,
  setDetailInfo: () => {},
  basicInfo: null,
  setBasicInfo: () => {},
  platformInfo: [],
  setPlatformInfo: () => {},
  youtubeInfo: [],
  setYoutubeInfo: () => {},
  editMode: false,
  setEditMode: () => {},
  basicIsEdit: false,
  setBasicIsEdit: () => {},
  mpEdit: false,
  setMpEdit: () => {},
  youtubeEdit: false,
  setYoutubeEdit: () => {},
  artistId: '',
});

export default memo(function ArtistDetail() {
  const [detailInfo, setDetailInfo] = useState<ArtistDetailInfo | null>(null);
  const [basicInfo, setBasicInfo] = useState<ArtistBasicInfo | null>(null);
  const [platformInfo, setPlatformInfo] = useState<MusicPlatformInfo[]>([]);
  const [youtubeInfo, setYoutubeInfo] = useState<YoutubeInfo[]>([]);

  const [editMode, setEditMode] = useState(false);
  const [basicIsEdit, setBasicIsEdit] = useState(false);
  const [mpEdit, setMpEdit] = useState(false);
  const [youtubeEdit, setYoutubeEdit] = useState(false);
  const [prevent, setPrevent] = useState(false);

  const { artistId } = useParams();

  const getMyInfo = debounce(() => {
    getInfo(api__getArtistInfo, artistId, setDetailInfo);
  }, 300);
  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [artistId]);

  useEffect(() => {
    if (!detailInfo) return;
    setBasicInfo({ ...detailInfo.artistBasicInfo });
    setPlatformInfo(detailInfo.musicPlatformList);
    const ytClone = [...detailInfo.youtubeList];
    for (let i of ytClone) {
      i.flag = 'default';
    }
    setYoutubeInfo(ytClone);

    // 매니저에 플래그 추가
    // setManagerInfo(detailInfo.artistManagerAccount ? { ...detailInfo.artistManagerAccount } : null);
    // 멤버리스트에 플래그 추가
    // const memberClone = [...detailInfo.artistMemberAccountList];
    // for (let i = 0; i < memberClone.length; i++) {
    // 	memberClone[i].emailCheck = true;
    // 	memberClone[i].nameCheck = true;
    // }
    // setMembersInfo(memberClone);
    // 뮤직플랫폼 초기세팅
    // eslint-disable-next-line
  }, [detailInfo]);

  // 아티스트 기본 정보 변경
  const basicStateChange = (value: string, target: string) => {
    if (!basicInfo || !editMode) return;
    if (!basicIsEdit) setBasicIsEdit(true);
    setBasicInfo({ ...basicInfo, [target]: value });
  };

  const updateArtistUseYn = async (useYn: 'Y' | 'N') => {
    try {
      if (prevent) return;
      setPrevent(true);
      const result = await api__updateArtistActivation({ useYn }, artistId || '');
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('활성화 상태가 변경 되었습니다.');
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    } finally {
      setPrevent(false);
    }
  };
  // 기본정보 변경 API함수
  const basicInfoUpdateFn = async () => {
    try {
      if (basicInfo !== null) {
        const validCheck = validBasicInfo(basicInfo);
        if (validCheck !== null) throw new Error(validCheck);
        const result = await api__updateArtistBasicInfo(basicInfo, artistId || '');
        if (!result) return;
        const { code, msg } = result.data;
        if (code.includes('T')) {
          logoutWithOutNavigate(code);
          return false;
        }
        if (code !== 'S200') throw new Error(msg);
        setBasicIsEdit(false);
        return true;
      }
    } catch (error: unknown) {
      handleError(error);
      return false;
    }
  };
  // 뮤직플랫폼 정보 변경 API함수
  const platformInfoUpdateFn = async () => {
    try {
      const clone = [...platformInfo];
      let i = 1;
      for (let platform of clone) {
        const { siteName, siteUrl, checked } = platform;
        if (checked === 'Y') {
          if (siteUrl === null || siteUrl.trim() === '') throw new Error(`${siteName}의 URL을 채워주세요.`);
          platform.orderSeq = i++;
        }
      }
      const result = await api__updateMusicPlatformInfo(clone, artistId || '');
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return false;
      }
      if (code !== 'S200') throw new Error(msg);
      setMpEdit(false);
      return true;
    } catch (error: unknown) {
      handleError(error);
      return false;
    }
  };
  // 유튜브 정보 변경 API함수
  const youtubeInfoUpdateFn = async () => {
    try {
      const linksArr: any[] = [];
      let index = 0;
      for (let i = 0; i < youtubeInfo.length; i++) {
        const { mainYn, youtubeTitle, youtubeUrl, flag } = youtubeInfo[i];
        if (flag === 'default') continue;
        index += 1;
        const now = mainYn === 'Y' ? '유튜브 메인 ' : `리스트_${index} `;
        if (youtubeTitle.trim() === '') throw new Error(`${now}제목을 채워주세요.`);
        if (youtubeUrl.trim() === '') throw new Error(`${now}URL을 채워주세요.`);
        linksArr.push(youtubeInfo[i]);
      }
      const result = await api__updateYotubueInfo(linksArr, artistId || '');
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return false;
      }
      if (code !== 'S200') throw new Error(msg);
      setYoutubeEdit(false);
      return true;
    } catch (error: unknown) {
      handleError(error);
      return false;
    }
  };

  const editFn = async () => {
    try {
      if (basicIsEdit && basicInfo !== null) {
        const result = await basicInfoUpdateFn();
        if (!result) return;
      }
      if (mpEdit) {
        const result = await platformInfoUpdateFn();
        if (!result) return;
      }
      if (youtubeEdit) {
        const result = await youtubeInfoUpdateFn();
        if (!result) return;
      }
      window.alert('아티스트 수정이 완료되었습니다.');
      window.location.reload();
      setEditMode(false);
    } catch (error: unknown) {
      handleError(error);
    }
  };
  // 수정을 누름에 따른 이벤트
  const editActivate = () => {
    if (editMode) {
      editFn();
    } else {
      setEditMode(true);
    }
  };
  if (!detailInfo) return <></>;
  return (
    <ArtistDetailContext.Provider
      value={{
        detailInfo,
        setDetailInfo,
        basicInfo,
        setBasicInfo,
        platformInfo,
        setPlatformInfo,
        youtubeInfo,
        setYoutubeInfo,
        editMode,
        setEditMode,
        basicIsEdit,
        setBasicIsEdit,
        mpEdit,
        setMpEdit,
        youtubeEdit,
        setYoutubeEdit,
        artistId,
      }}
    >
      <MainLayout title="유저 관리 / 아티스트" cn={prevent ? 'prevent' : ''}>
        <ArtistDetailHeader updateActivation={updateArtistUseYn} editActivate={editActivate} />
        <DetailLayoutBox>
          <Box mb="37px">
            {/* 아티스트 기본 정보 */}
            <ArtistDetailBasicInfo basicInfo={basicInfo} basicStateChange={basicStateChange} editMode={editMode} />
            {/* 아티스트 뮤직 플랫폼 정보 */}
            <ArtistDetailPlatformInfo />
            {/* 아티스트 유튜브 정보 */}
            <ArtistDetailYoutubeInfo />
          </Box>
          <ArtistDetailAccountsInfo />
          <ArtistDetailPostStaticInfo artistId={artistId} />
          <ArtistDetailMembership />
        </DetailLayoutBox>
      </MainLayout>
    </ArtistDetailContext.Provider>
  );
});
