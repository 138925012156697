import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from "@mui/material";
import { Icon } from '@iconify/react';

import { DCBox, DeleteButton, FEBox, InfoTextBox, InfoTitleBox, MainButton, ModalContentTypo, StretchBox, TextInputInBBox } from "../common/CommonElement";
import ModalMsgBox from '../common/ModalMsgBox';
import { COLORS } from "../../Constant/COLORS";
import { ERRORS } from '../../Constant/ERRORS';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { api__createMember, api__isDuplicatedID } from '../../services/AdminMemberService';
import { enterFn } from '../../Utils/utils';
import { codeHandler } from '../../services/CommonService';

type IProps = {
	setAddMember: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MemberNew({ setAddMember }: IProps) {
	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [checkedDuple, setCheckedDuple] = useState(false);
	const [modal, setModal] = useState(false);
	const [completeModal, setCompleteModal] = useState(false);
	const [modalTitle, setModalTitle] = useState("");

	const idRef = useRef<HTMLInputElement>(null);
	const nameRef = useRef<HTMLInputElement>(null);

	const preventReq = useRef(false);

	const checkingApi = async () => {
		if (checkedDuple) {
			window.alert("이미 체크가 완료되었습니다.\n사용이 가능합니다.");
			nameRef.current?.focus();
			return;
		}
		api__isDuplicatedID(id).then((res) => {
			if (codeHandler(res)) {
				const { data } = res;
				setCheckedDuple(!data.isDuplicated);
				setModal(true);
				setModalTitle(data.isDuplicated ? "사용 불가능한 아이디에요." : "사용 가능한 아이디에요.");
			} else {
				throw new Error(res.msg);
			}
		}).catch((error) => {
			window.alert(ERRORS[error?.message] || error?.message || "오류가 발생하였습니다.\n다시 시도하여주세요.");
		})
	}

	const createApi = () => {
		if (preventReq.current) return;
		preventReq.current = true;
		api__createMember(id + '@pondmakers.com', id, name).then((res) => {
			if (codeHandler(res)) {
				setCompleteModal(true);
			} else {
				throw new Error(res.msg);
			}
		}).catch((error) => {
			window.alert(ERRORS[error?.message] || error?.message || "오류가 발생하였습니다.\n다시 시도하여주세요.");
			preventReq.current = false;
		})
	}

	useEffect(() => {
		if (!modal) {
			if (checkedDuple) {
				nameRef.current?.focus();
			} else {
				idRef.current?.focus();
			}
		}
		// eslint-disable-next-line
	}, [modal])
	return (
		<>
			{modal && <ModalMsgBox title={modalTitle} setModal={setModal} />}
			{completeModal && <ModalMsgBox title="새로운 멤버 계정이 추가되었어요." setModal={(bool) => { setCompleteModal(bool); setAddMember(false); }}>
				<ModalContentTypo>ㆍ아이디 : {id}</ModalContentTypo>
				<ModalContentTypo>ㆍ담당자명 : {name}</ModalContentTypo>
				<ModalContentTypo>ㆍ임시 비밀번호 : goodduck123!</ModalContentTypo>
			</ModalMsgBox>}
			<DCBox mb="206px">
				<DeleteButton style={{ marginRight: '12px', border: 'none' }} onClick={() => { setAddMember(false); }}>
					<Icon icon={ICONIFY_ICONS.ARROW_LEFT} width={36} height={36} />
				</DeleteButton>
				<Typography variant='h2' color={COLORS.MAIN}>멤버 추가</Typography>
			</DCBox>
			<Box boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)' mb="44px">
				<StretchBox>
					<InfoTitleBox>아이디</InfoTitleBox>
					<InfoTextBox style={{ paddingRight: '12px', justifyContent: 'space-between' }}>
						<TextInputInBBox value={id} onChange={(e) => { setId(e.target.value); setCheckedDuple(false) }} onKeyDown={(e) => { enterFn(e, checkingApi) }} ref={idRef} style={{ width: '70%' }} maxLength={50} />
						<DeleteButton onClick={checkingApi} variant='outlined' style={{ borderColor: COLORS.MAIN, marginLeft: '24px' }}>
							{checkedDuple ? "체크완료" : "중복확인"}
						</DeleteButton>
					</InfoTextBox>
				</StretchBox>
				<StretchBox>
					<InfoTitleBox>담당자명</InfoTitleBox>
					<InfoTextBox style={{ paddingRight: '12px' }}>
						<TextInputInBBox type="text" value={name} onChange={(e) => { setName(e.target.value) }} ref={nameRef} style={{ width: '70%' }} maxLength={100} />
					</InfoTextBox>
				</StretchBox>
				<StretchBox>
					<InfoTitleBox>이메일</InfoTitleBox>
					<InfoTextBox style={{ paddingRight: '12px' }}>
						<TextInputInBBox type="email" value={id + (id.trim() === '' ? '' : '@pondmakers.com')} onChange={() => { }} readOnly style={{ width: '70%' }} />
					</InfoTextBox>
				</StretchBox>
			</Box>
			<FEBox>
				<MainButton onClick={createApi}>
					멤버 계정 생성
				</MainButton>
			</FEBox>
		</>
	)
}