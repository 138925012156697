import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow, styled } from "@mui/material"
import { useNavigate, useParams } from 'react-router';

import MainLayout from "../../layout/MainLayout"
import { ContainedLinkBtn, DCBox, DetailButton, MainButton, MyTable, OptionButton, PageTitle, SBCBox, THS } from "../../Components/common/CommonElement"
import LimitBox from '../../Components/common/LimitBox';
import Pages from '../../Components/common/Pages';

import { AgencyNoticeArtistType, AgencyNoticeListInfo, AgencyNoticeTempType } from '../../Utils/types';
import { detailTimeFormat, logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { debounce } from 'lodash';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { api__getNoticeArtistList, api__getNoticeTemps, api__getNotices } from '../../services/AgencieServices';
import TemporaryList from './TemporaryList';
import MySelectBox from '../../Components/common/MySelectBox';

export default function PartnersNotice() {
	const navigate = useNavigate();
	const { page, limit } = useParams();
	const [dataListInfo, setDataListInfo] = useState<AgencyNoticeListInfo | null>(null);
	const [aName, setAName] = useState("게시물 전체보기");
	const [isDropped, setIsDropped] = useState(false);
	const [artistList, setArtistList] = useState<AgencyNoticeArtistType[]>([]);
	const [artistId, setArtistId] = useState("");
	const [tempList, setTempList] = useState<AgencyNoticeTempType[]>([]);

	const [tempModal, setTempModal] = useState(false);

	const getNotices = debounce(() => {
		api__getNotices(artistId, Number(limit), Number(page)).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setDataListInfo(res.data.data);
		}).catch(console.error)
	}, 300);
	const getTemps = debounce(() => {
		api__getNoticeTemps().then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setTempList(res.data.data);
		}).catch(console.error)
	}, 300)
	const changeSelect = (aVal: string, nameVal: string) => {
		setAName(nameVal);
		setArtistId(aVal);
		setIsDropped(false);
		navigate(ROUTES.PARTNERS_NOTICE + `/1/10`);
	}
	const getArtistList = debounce(() => {
		api__getNoticeArtistList().then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setArtistList(res.data.data);
		}).catch(console.error);
	}, 300);
	useEffect(() => {
		getArtistList();
		getTemps();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		getNotices();
		// eslint-disable-next-line
	}, [page, limit, artistId]);

	return (
		<>
			{tempModal && <TemporaryList tempList={tempList} setTempModal={setTempModal} setTempList={setTempList} />}
			<MainLayout title="소속사(관리자) 파트너센터 / 공지 게시물 관리">
				<Box p="30px">
					<SBCBox mb='24px'>
						<PageTitle>공지 게시물 관리 / {aName}</PageTitle>
						<DCBox>
							<MainButton onClick={() => { setTempModal(true) }} sx={{ marginRight: '8px' }}>임시 저장글 불러오기 | {tempList.length}</MainButton>
							<ContainedLinkBtn style={{ padding: '16px 24px', fontSize: '16px' }} href={ROUTES.PARTNERS_NOTICE + '/new'} target="_self" rel="noopener noreferrer">공지 게시물 작성</ContainedLinkBtn>
						</DCBox>
					</SBCBox>
					<SBCBox>
						<MySelectBox value={aName} width={332} isDefault={artistId === ""} isDropped={isDropped} setIsDropped={setIsDropped}>
							<OptionButton onClick={() => {
								changeSelect("", "게시물 전체보기");
							}}>
								게시물 전체보기
							</OptionButton>
							{artistList.map((item, idx) => (
								<OptionButton
									key={`al_${idx}`}
									onClick={() => { changeSelect(item.artistId, item.nameKo) }}
									className={artistId === item.artistId ? "selected" : ""}
								>{item.nameKo}
								</OptionButton>
							))}
						</MySelectBox>
						<LimitBox limit={limit || "10"} page={page || "1"} route={ROUTES.PARTNERS_NOTICE} />
					</SBCBox>
					<Box mt="24px">
						<MyTable>
							<THS
								ths={[
									{ name: "제목", width: 200 },
									{ name: "아티스트명", width: 160 },
									{ name: "작성자", width: 120 },
									{ name: "업로드 일자", width: 100 },
									{ name: "게시물 조회수", width: 80 },
									{ name: "삭제여부", width: 80 },
									{ name: "관리", width: 80 },
								]}
							/>
							<TableBody>
								{(dataListInfo?.data || []).map((item, idx) => (
									<CenterRow key={`public-notice-${idx}`} className={item.fixedYn === 'Y' ? 'fixed' : ''}>
										<TableCell>{item.fixedYn === 'Y' && <img src={SVG_IMAGES.FIXED} alt="고정" />} {item.title}</TableCell>
										<TableCell>{item.artistName}</TableCell>
										<TableCell>{item.rgstAuthorType}</TableCell>
										<TableCell>
											{item.bookedYn === 'Y' 
												? (new Date(item.bookedDt) <= new Date() 
													? `${detailTimeFormat(item.bookedDt)}` 
													: `${detailTimeFormat(item.bookedDt)} (예약)`)
												: `${detailTimeFormat(item.rgstDt)}`}
											</TableCell>
										<TableCell>{item.viewCnt}</TableCell>
										<TableCell>{item.openYn === 'Y' ? 'N' : 'Y'}</TableCell>
										<TableCell>
											<DetailButton onClick={() => { navigate(ROUTES.PARTNERS_NOTICE + `/detail/${item.noticeId}`) }}>상세</DetailButton>
										</TableCell>
									</CenterRow>
								))}
							</TableBody>
						</MyTable>
						{dataListInfo && <Pages pageInfo={dataListInfo} getList={(p) => { navigate(ROUTES.PARTNERS_NOTICE + `/${String(p)}/${limit}`) }} />}
					</Box>
				</Box>
			</MainLayout>
		</>
	)
}

const CenterRow = styled(TableRow)({
	'& td': {
		textAlign: 'center'
	},
	'& img': {
		width: '18px',
		height: '18px',
		transform: 'translateY(5px)',
		marginRight: '8px'
	},
	'&.fixed': {
		backgroundColor: '#DEE2E6',
	}
})