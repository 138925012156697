import { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import { FEBox, InfoTxtComp, MainButton, PageTitle } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';

import { logoutWithOutNavigate } from '../../Utils/utils';
import { api__getRecommendURL, api__putRecommendURL } from '../../services/TermsService';

export default function RecommendURL() {
  const [url, setUrl] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const isGetInfoProgress = useRef(false);

  const getMyInfo = async () => {
    if (isGetInfoProgress.current) return;
    isGetInfoProgress.current = true;
    try {
      const result = await api__getRecommendURL();
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setUrl(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetInfoProgress.current = false;
    }
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);

  const putUrl = async () => {
    try {
      const result = await api__putRecommendURL(url);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setEditMode(false);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const editActivate = () => {
    if (editMode && isEdit) {
      setEditModal(true);
    } else {
      setEditMode(true);
    }
  };

  return (
    <>
      {editModal && (
        <ModalMsgBox
          title="수정사항을 적용하시겠어요?"
          setModal={bool => {
            setEditModal(false);
            if (!bool) {
              putUrl();
              setIsEdit(false);
            }
          }}
          extraBtn={'적용'}
        ></ModalMsgBox>
      )}
      <MainLayout title="약관 및 추천URL 관리 / 아티스트 추천버튼 URL">
        <Box p="38px 34px" pb="120px">
          <PageTitle mb="34px">아티스트 추천버튼 URL</PageTitle>
          <Box>
            <InfoTxtComp
              title="URL"
              value={url}
              onChange={e => {
                setUrl(e.target.value);
                setIsEdit(true);
              }}
              readonly={!editMode}
            />
          </Box>
          <FEBox mt="18px">
            <MainButton onClick={editActivate}>{editMode ? '수정 적용' : '수정'}</MainButton>
          </FEBox>
        </Box>
      </MainLayout>
    </>
  );
}
