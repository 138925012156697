import { Box, Typography, styled } from "@mui/material";
import { CCBOX, DCBox, FullSizeFixedBox, SBCBox } from "../../Components/common/CommonElement";
import { AgencyNoticeTempType } from "../../Utils/types";
import { Icon } from "@iconify/react";
import { ICONIFY_ICONS, SVG_IMAGES } from "../../Constant/IMAGES";
import { detailTimeFormat } from "../../Utils/utils";
import { ROUTES } from "../../Utils/routes";
import { useNavigate } from "react-router";
import ModalMsgBox from "../../Components/common/ModalMsgBox";
import { useState } from "react";
import { api__deleteNoticeData } from "../../services/AgencieServices";

export default function TemporaryList({ tempList, setTempModal, setTempList }: { tempList: AgencyNoticeTempType[], setTempModal: any, setTempList: any }) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<AgencyNoticeTempType | null>(null);
  const deleteFn = () => {
    api__deleteNoticeData(deleteModal?.noticeId || '').then((res) => {
      if (!res) return;
      const { code, msg } = res.data;
      if (code.includes("T")) {
        return;
      }
      if (code !== "S200") throw new Error(msg);
      window.alert("삭제되었습니다.");
      setTempList(tempList.filter((item) => item.noticeId !== deleteModal?.noticeId));
    }).catch(console.error);
  }
  return <>
    <FullSizeFixedBox>
      {deleteModal && (
        <ModalMsgBox title="게시글을 삭제하시겠어요?" setModal={(bool) => {
          if (!bool) { deleteFn() };
          setDeleteModal(null);
        }} extraBtn={'삭제'}>
        </ModalMsgBox>
      )}
      <Box width="960px" height='720px' bgcolor={'white'} overflow='auto' position="relative" p="50px">
        <XButton onClick={() => { setTempModal(false) }}><Icon icon={ICONIFY_ICONS.DELETE_CIRCLE} width={24} height={24} color="black" /></XButton>
        <SBCBox sx={{ alignItems: 'flex-start' }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h1">임시저장</Typography>
            <Typography variant="subtitle1" color="#666666">ㆍ임시저장한 공지 게시글을 선택하면 해당 내용을 불러옵니다.</Typography>
            <Typography variant="subtitle1" color="#666666">ㆍ담당자명은 "최종으로 임시저장한 담당자명"이 표기됩니다.</Typography>
            <Typography variant="subtitle1" color="#666666">ㆍ게시물 등록시 작성자는 "최종 작성한 담당자명"이 표기됩니다.</Typography>
            <Typography variant="subtitle1" color="#666666">ㆍ저장개수 50개를 초과하면 오래된 순으로 삭제됩니다.</Typography>
            <Typography variant="subtitle1" color="#666666">ㆍ저장기간 : 30일</Typography>
          </Box>
          <Typography variant="h1" color="#808080">총{tempList.length}개(최대 50개)</Typography>
        </SBCBox>
        <hr style={{ margin: 0, marginTop: '20px' }} />
        <Box height='400px' overflow='auto'>
          {tempList.map((item, idx) => (
            <SBCBox key={`temp_${idx}`} p="20px" sx={{ borderBottom: '1px solid #E0E0E0', cursor: 'pointer' }}>
              <Box sx={{ textAlign: 'left' }} onClick={() => {
                navigate(ROUTES.PARTNERS_NOTICE + `/new`, {
                  state: {
                    noticeId: item.noticeId
                  }
                })
              }}>
                <Typography variant="h3" fontSize='21px'>{item.title}</Typography>
                <DCBox>
                  <Typography variant="subtitle1" fontSize='17px' lineHeight='140%' color="#363636" mr="8px">{detailTimeFormat(item.updtDt)}</Typography>
                  <Typography variant="h2" fontSize='12px' color="#A0AEC0">{item.artistName} | {item.updtId}</Typography>
                </DCBox>
              </Box>
              <Box onClick={() => { setDeleteModal(item) }}>
                <img src={SVG_IMAGES.TRASH_CAN} alt="휴지통" style={{ width: '54px', height: '54px' }} />
              </Box>
            </SBCBox>
          ))}
        </Box>
      </Box>
    </FullSizeFixedBox>
  </>
}

const XButton = styled(CCBOX)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: '1',
  cursor: 'pointer',
})