export const DOCS_CODE = {
  SIGNUP_PERSONAL: "003",
  AUTH_PERSONAL: "004",
  PAYMENT_PERSONAL: "005",
  PAYMENT_ORDER: "006",
};
export const DOCUMENT_CODE = {
  TERMS_OF_SERVICE: "001",
  PRIVACY_POLICY: "002",
  PARTNER_CENTER_TERMS_OF_SERVICE: "007",
  PARTNER_CENTER_PRIVACY_POLICY: "008",
};
