import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow, Typography, styled } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  DCBox,
  DarkContainedButton,
  DetailButton,
  InfoComp,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  MainButton,
  ModalContentTypo,
  MyTable,
  PageTitle,
  SBCBox,
  StretchBox,
  THS,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';
import { logoutWithOutNavigate } from '../../Utils/utils';
import _, { debounce } from 'lodash';
import {
  api__deleteAgency,
  api__getAgencyArtistList,
  api__getAgencyData,
  api__getAgencyUserList,
  api__putAgencyData,
} from '../../services/AgencieServices';
import { Icon } from '@iconify/react';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { AgencyArtistType, AgencyDataType, AgencyMananerType } from '../../Utils/types';
import { useNavigate, useParams } from 'react-router';

export default function PartnersAccountDetail() {
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const [agencyData, setAgencyData] = useState<AgencyDataType | null>(null);
  const [agencyUserList, setAgencyUserList] = useState<AgencyMananerType[]>([]);

  const [artistList, setArtistList] = useState<AgencyArtistType[]>([]);
  const [artistModal, setArtistModal] = useState<AgencyArtistType[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState<AgencyDataType | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const getMyInfo = debounce(async () => {
    try {
      const result = await api__getAgencyData(agencyId!);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setAgencyData(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);
  const getUserList = debounce(async () => {
    api__getAgencyUserList(agencyId!)
      .then(res => {
        if (!res) return;
        const { code, msg } = res.data;
        if (code.includes('T')) {
          logoutWithOutNavigate(code);
          return;
        }
        if (code !== 'S200') throw new Error(msg);
        setAgencyUserList(res.data.data);
      })
      .catch(console.error);
  }, 300);

  const updateFn = () => {
    api__putAgencyData(agencyId!, {
      agencyName: agencyData!.agencyName,
      artistIdList: agencyData!.artistList.map(item => item.artistId),
    })
      .then(res => {
        if (!res) return;
        const { code, msg } = res.data;
        if (code.includes('T')) {
          logoutWithOutNavigate(code);
          return;
        }
        if (code !== 'S200') throw new Error(msg);
        setEditMode(false);
        setSaving(null);
        window.location.reload();
      })
      .catch(console.error);
  };
  const deleteFn = debounce(() => {
    api__deleteAgency(agencyId!)
      .then(res => {
        if (!res) return;
        const { code, msg } = res.data;
        if (code.includes('T')) {
          logoutWithOutNavigate(code);
          return;
        }
        if (code !== 'S200') throw new Error(msg);
        window.history.back();
      })
      .catch(console.error);
  }, 300);

  useEffect(() => {
    getMyInfo();
    getUserList();
    // eslint-disable-next-line
  }, []);
  const getArtistList = debounce(() => {
    api__getAgencyArtistList(agencyId)
      .then(res => {
        if (!res) return;
        const { code, msg, data } = res.data;
        if (code.includes('T')) {
          logoutWithOutNavigate(code);
          return;
        }
        if (code !== 'S200') throw new Error(msg);
        setArtistList(data);
      })
      .catch(console.error);
  }, 300);
  useEffect(() => {
    if (editMode && artistList.length === 0) {
      getArtistList();
    }
    // eslint-disable-next-line
  }, [editMode, artistList]);
  if (agencyData === null) return <MainLayout title="소속사(관리자) 파트너센터 / 소속사 상세" children="" />;
  return (
    <>
      {deleteModal && (
        <ModalMsgBox
          title="해당 소속사를 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(false);
            if (!bool) {
              deleteFn();
            }
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>소속사 삭제시</ModalContentTypo>
          <ModalContentTypo>ㆍ공지 게시물은 모두 그대로 유지</ModalContentTypo>
          <ModalContentTypo>ㆍ해당 소속사의 모든 마스터, 매니저 계정 삭제</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="소속사(관리자) 파트너센터 / 소속사 상세">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>소속사 상세</PageTitle>
            </DCBox>
            <DCBox>
              <DarkContainedButton
                onClick={() => {
                  setDeleteModal(true);
                }}
                sx={{ padding: '8px 16px', marginRight: '8px' }}
              >
                삭제
              </DarkContainedButton>
              {editMode && (
                <DarkContainedButton
                  sx={{ padding: '8px 16px', marginRight: '8px' }}
                  onClick={() => {
                    setEditMode(false);
                    setAgencyData(_.cloneDeep(saving));
                    setTimeout(() => {
                      setSaving(null);
                    }, 500);
                  }}
                >
                  수정 취소
                </DarkContainedButton>
              )}
              <MainButton
                onClick={() => {
                  if (editMode) {
                    if (agencyData.agencyName.trim() === '') {
                      window.alert('소속사명을 입력해주세요.');
                      return;
                    }
                    if (agencyData.artistList.length === 0) {
                      window.alert('관리 아티스트를 선택해주세요.');
                      return;
                    }
                    updateFn();
                  } else {
                    setEditMode(true);
                    setSaving(_.cloneDeep(agencyData));
                  }
                }}
              >
                {editMode ? '완료' : '수정'}
              </MainButton>
            </DCBox>
          </SBCBox>
          <Box mb="82px">
            {editMode ? (
              <InfoTxtComp
                title="소속사명"
                value={agencyData?.agencyName}
                onChange={e => {
                  setAgencyData(prev => {
                    if (prev === null) return prev;
                    return { ...prev, agencyName: e.target.value };
                  });
                }}
              />
            ) : (
              <InfoComp title="소속사명" value={agencyData.agencyName} />
            )}
            <StretchBox>
              <InfoTitleBox>관리 아티스트</InfoTitleBox>
              <InfoTextBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ThisSelectBox
                  style={{ width: '200px' }}
                  onClick={() => {
                    if (editMode) {
                      setArtistModal(_.cloneDeep(artistList));
                    }
                  }}
                >
                  <SBCBox>
                    <Typography variant="body2" color={'#222'}>
                      아티스트 선택
                    </Typography>
                    <Icon icon={ICONIFY_ICONS.ARROW_DOWN} width={18} height={18} />
                  </SBCBox>
                </ThisSelectBox>
                <hr style={{ width: '100%' }} />
                <Box>
                  {agencyData.artistList.map((item, idx) => (
                    <Box key={`artist_${idx}`}>ㆍ{item.nameKo}</Box>
                  ))}
                </Box>
              </InfoTextBox>
            </StretchBox>
          </Box>
          <Box>
            <SBCBox mb="18px">
              <PageTitle>마스터/매니저 계정</PageTitle>
              <MainButton
                onClick={() => {
                  if (agencyData.artistList.length === 0) {
                    window.alert('관리 아티스트를 등록 후 계정 생성이 가능합니다.');
                    return;
                  }
                  navigate(window.location.pathname + '/new', { state: { agencyName: agencyData.agencyName } });
                }}
              >
                계정 생성
              </MainButton>
            </SBCBox>
            <MyTable>
              <THS
                ths={[
                  { name: '소속사명', width: 120 },
                  { name: '계정 이메일 ID', width: 200 },
                  { name: '담당자명', width: 120 },
                  { name: '계정 유형', width: 100 },
                  { name: '관리 아티스트', width: 200 },
                  { name: '관리', width: 80 },
                ]}
              />
              <TableBody>
                {agencyUserList.map((item, uidx) => (
                  <CenterRow key={`agency-user-${uidx}`}>
                    <TableCell>{item.agencyName}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      {item.name}({item.position})
                    </TableCell>
                    <TableCell>{item.accountType}</TableCell>
                    <TableCell>
                      {(item.managedArtists || '-').split('\n').map((a, i) => (
                        <div key={`${uidx}_a_${i}`}>{a}</div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <DetailButton
                        onClick={() => {
                          navigate(window.location.pathname + '/detail/' + item.uid);
                        }}
                      >
                        상세
                      </DetailButton>
                    </TableCell>
                  </CenterRow>
                ))}
              </TableBody>
            </MyTable>
          </Box>
        </Box>
      </MainLayout>
      {artistModal.length !== 0 && (
        <ModalMsgBox
          title="아티스트 선택(복수선택 가능)"
          setModal={bool => {
            const applyArr = _.cloneDeep(artistModal);
            if (!bool) {
              setArtistList(applyArr);
              setAgencyData((prev: any) => {
                if (prev === null) return prev;
                return { ...prev, artistList: applyArr.filter(item => item.checkYn === 'Y') };
              });
              setTimeout(() => {
                setArtistModal([]);
              }, 500);
            } else setArtistModal([]);
          }}
          extraBtn={'저장'}
        >
          <ArtistListBox className="reset-scroll">
            {artistModal.map((item, idx) => (
              <label key={`artist_modal_${idx}`}>
                <input
                  type="checkbox"
                  checked={item.checkYn === 'Y'}
                  onChange={() => {
                    const temp = [...artistModal];
                    temp[idx].checkYn = temp[idx].checkYn === 'Y' ? 'N' : 'Y';
                    setArtistModal(temp);
                  }}
                />
                {item.nameKo}
              </label>
            ))}
          </ArtistListBox>
        </ModalMsgBox>
      )}
    </>
  );
}

const ThisSelectBox = styled(Box)({
  padding: '7px 16px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  cursor: 'pointer',
  position: 'relative',
});
const ArtistListBox = styled(Box)({
  padding: '16px',
  overflow: 'auto',
  height: '300px',
  '& label': {
    marginBottom: '12px',
    display: 'block',
  },
  '& input': {
    marginRight: '8px',
  },
});
const CenterRow = styled(TableRow)({
  '& td': {
    textAlign: 'center',
  },
});
