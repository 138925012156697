import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import {
  ContainedLinkBtn,
  DeleteButton,
  DetailButton,
  ModalContentTypo,
  MyTable,
  PageTitle,
  SBCBox,
  THS,
} from '../../Components/common/CommonElement';
import LimitBox from '../../Components/common/LimitBox';
import Pages from '../../Components/common/Pages';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';

import { TermsInfo, TermsListInfo } from '../../Utils/types';
import { delData, detailTimeFormat, logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { api__delTerms, api__getTerms } from '../../services/TermsService';
import { debounce } from 'lodash';

export default function PartnersTerms() {
  const navigate = useNavigate();
  const { page, limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<TermsListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<TermsInfo | null>(null);

  const getMyList = debounce(async () => {
    try {
      const result = await api__getTerms(Number(page) || 1, Number(limit) || 10, 'PARTNER_CENTER_TERMS_OF_SERVICE');
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setDataListInfo(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);

  const delMyData = async (seq: number) => {
    delData(seq, api__delTerms, getMyList);
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit]);
  return (
    <>
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(deleteModal.seq);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ버전 : V {deleteModal.version}</ModalContentTypo>
        </ModalMsgBox>
      )}

      <MainLayout title="소속사(관리자) 파트너센터 / 서비스 이용약관">
        <Box p="30px">
          <SBCBox mb="12px">
            <PageTitle>[파트너센터]서비스 이용약관</PageTitle>
            <ContainedLinkBtn
              style={{ padding: '16px 24px', fontSize: '16px' }}
              href={ROUTES.PARTNERS_TERMS + '/new'}
              target="_self"
              rel="noopener noreferrer"
            >
              약관 생성
            </ContainedLinkBtn>
          </SBCBox>
          <LimitBox limit={limit || '10'} page={page || '1'} route={ROUTES.PARTNERS_TERMS} />
          <Box mt="18px">
            <MyTable>
              <THS
                ths={[
                  { name: '#', width: 40 },
                  { name: '버전', width: 100 },
                  { name: '등록 일시', width: 200 },
                  { name: '관리', width: 200 },
                ]}
              />
              <TableBody>
                {dataListInfo &&
                  dataListInfo.data.map((item, uidx) => (
                    <CenterRow key={`admin-${uidx}`}>
                      <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                      <TableCell>V {item.version} </TableCell>
                      <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
                      <TableCell>
                        <DetailButton
                          onClick={() => {
                            navigate(ROUTES.PARTNERS_TERMS + '/' + item.seq);
                          }}
                        >
                          상세
                        </DetailButton>
                        <DeleteButton
                          onClick={() => {
                            setDeleteModal(item);
                          }}
                        >
                          삭제
                        </DeleteButton>
                      </TableCell>
                    </CenterRow>
                  ))}
              </TableBody>
            </MyTable>
            {dataListInfo && (
              <Pages
                pageInfo={dataListInfo}
                getList={p => {
                  navigate(ROUTES.PARTNERS_TERMS + `/${String(p)}/${limit}`);
                }}
              />
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}

const CenterRow = styled(TableRow)({
  '& td': {
    textAlign: 'center',
  },
});
