import { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { DeleteButton, FlexBox, ImageBox } from './CommonElement';
import { ReactSVG } from 'react-svg';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { api__fileUpload } from '../../services/CommonService';
import { logoutWithOutNavigate } from '../../Utils/utils';
import { handleError } from '../../Utils/utils';

type IProps = {
  title: string;
  width: number;
  height: number;
  imageUrl: string;
  size: string;
  onChange: Function;
  folderName: string;
  disabled?: boolean | undefined;
};

export default function ImageUploader({
  title,
  width,
  height,
  imageUrl,
  size,
  onChange,
  disabled,
  folderName,
}: IProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (disabled) return;
    fileInputRef.current?.click();
  };

  const handleFileChange = async event => {
    const formData = new FormData();
    const selectedFile = event.target.files[0];
    if (selectedFile.size >= 10000000) {
      window.alert('파일의 크기가 너무 큽니다.\n용량 제한 : 10MB');
      return;
    }
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(selectedFile.name)) {
      // 유효한 확장자가 아닌 경우 처리할 내용
      window.alert('이미지 파일만 등록이 가능합니다.\n허용 확장자 : [jpg, jpeg, png]');
      return;
    }

    formData.append('file', selectedFile);
    formData.append('folder', folderName);
    try {
      const result = await api__fileUpload(formData);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const { data } = result.data;
      for (let [key, value] of Object.entries(data)) {
        if (value === null) data[key] = '';
      }
      onChange(data);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <Box>
      <Typography variant="h3" fontSize={'18px'} mb="6px">
        {title}
      </Typography>
      <FlexBox>
        <Box width={`${width}px`} height={`${height}px`} margin="auto">
          <ImageBox>
            {imageUrl !== '' ? (
              <img src={imageUrl} alt={title} />
            ) : (
              <FlexBox
                width={`${width}px`}
                height={`${height}px`}
                justifyContent={'center'}
                alignItems={'center'}
                bgcolor={'#dedede'}
              >
                <ReactSVG src={SVG_IMAGES.MIDDLE_EMPTY_PROFILE} />
              </FlexBox>
            )}
          </ImageBox>
        </Box>
        <Box ml="6px">
          <DeleteButton sx={{ mb: '11px' }} onClick={handleButtonClick}>
            이미지 업로드
          </DeleteButton>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <Typography variant="body2">ㆍ지원하는 파일형식 : jpg, png</Typography>
          <Typography variant="body2">ㆍ권장 규격 : {size}(px)</Typography>
        </Box>
      </FlexBox>
    </Box>
  );
}
