import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import dayjs from 'dayjs';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';
import CalendarPicker from '../../Components/common/CalendarPicker';
import {
  BackBtn,
  DCBox,
  DeleteButton,
  DetailButton,
  FlexBox,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  ModalContentTypo,
  PageTitle,
  SBCBox,
  StretchBox
} from '../../Components/common/CommonElement';
import ImageUploader from '../../Components/common/ImageUploader';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import MainLayout from '../../layout/MainLayout';
import { api__delGoodNews, api__getGoodNewsInfo, api__updateGoodNewsInfo } from '../../services/HomePostService';
import { GoodNewsDetailInfo } from '../../Utils/types';
import { delData, getInfo, handleError } from '../../Utils/utils';
import {checkBookingStatus} from '../../Utils/utils';

export default function GoodNewsDetail() {
  const [detailInfo, setDetailInfo] = useState<GoodNewsDetailInfo>({
    bookedDt: '',
    bookedYn: 'N',
    content: '',
    linkName: '',
    linkUrl: '',
    openYn: 'Y',
    rgstDt: '',
    rgstId: '',
    seq: 0,
    thumbImgUrl: '',
    title: '',
    updtDt: '',
    updtId: '',
    youtubeUrl: '',
    temporaryYn: 'Y',
  });

  const [editMode, setEditMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [noticeModal, setNoticeModal] = useState<string | null>(null);
  const [isPicked, setIsPicked] = useState(false);
  const [bookedTime, setBookedTime] = useState<Date | null>(null)
  const [isBooked, setIsBooked] = useState(false);
  const [isPublished, setIsPublished] = useState(false); // 게시글 공개 여부 상태
  // 수정모드에 들어갈 때의 bookedDt 값
  const [editBookedDt, setEditBookedDt] = useState<string | null>(null);

  const { goodNewsSeq } = useParams();

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'goodnews');
    };
  }

  const getMyInfo = () => {
    getInfo(api__getGoodNewsInfo, goodNewsSeq, setDetailInfo);
  };

  const changeEditState = (value: string, target: string) => {
    if (!isEdit) setIsEdit(true);
    setDetailInfo({ ...detailInfo, [target]: value });
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);

// 예약 시간이 현재보다 이전이고, 예약 날짜가 다르면 발행된 상태
  useEffect(() => {
    if (detailInfo.rgstId !== '') {      
       const { isBooked, bookedTime, isPublished } = checkBookingStatus(
          detailInfo.bookedYn ?? 'N',
          detailInfo.bookedDt ?? null,
          detailInfo.openYn ?? 'N'
        );
      // 임시 저장 여부에 따라 수정 모드 활성화
      if (detailInfo.temporaryYn === 'Y') setEditMode(true);
      // 예약 여부 및 상태 업데이트
      setIsPicked(isBooked);
      setIsBooked(isBooked);
      // 예약된 시간이 있으면 bookedTime에 저장
      setBookedTime(bookedTime);
      // 게시 상태 업데이트
      setIsPublished(isPublished);
    }
    // eslint-disable-next-line
  }, [detailInfo]);
  

  const editActivate = () => {
    if (editMode) { // 수정 적용
      const bookedDate = bookedTime ? new Date(bookedTime).getTime() : null;
      const editedBookedDate = editBookedDt ? new Date(editBookedDt).getTime() : null;
  
      // 예약 시간이 현재보다 이전이고, 예약 날짜가 다르면 발행된 상태
      const isAlreadyPublished = 
      bookedDate && 
      editedBookedDate && 
      bookedDate !== editedBookedDate && 
      editedBookedDate <= Date.now();

      // 발행되었다면 alert 띄운 뒤 예약 공개 시간을 기존 값으로 초기화한 후 '게시됨'으로 변경하기
      if (isAlreadyPublished) {
        setIsPublished(true);
        alert('이미 게시되어 예약 공개 시간을 수정할 수 없습니다.');
        setBookedTime(editBookedDt ? new Date(editBookedDt) : null);
      } else {
        setNoticeModal('edit');
      }

    } else { // 수정 모드 진입
      setEditMode(true);
      // bookedDt 값 수정모드 진입 시의 값으로 고정
      const clonedBookedDt = JSON.parse(JSON.stringify(detailInfo.bookedDt));
      setEditBookedDt(clonedBookedDt || null);
    }
  };
  
  // 생성 버튼 클릭 시 굿뉴스 생성 함수
  const createPost = async (temporaryYn: string) => {
    try {
      const { content, linkUrl, thumbImgUrl, title } = detailInfo;
      if (temporaryYn === 'N') {
        if (content.trim() === '') throw new Error('goodnews_create_content');
        if (title.trim() === '') throw new Error('goodnews_create_title');
        if (thumbImgUrl === '') throw new Error('upload_thumbImg');
      }   
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;
      const result = await api__updateGoodNewsInfo(Number(goodNewsSeq), {
        content,
        linkUrl,
        thumbImgUrl,
        title,
        temporaryYn,
        openYn: temporaryYn === 'Y' ? 'N' : 'Y',
        bookedDt,
      });

      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      if (temporaryYn === 'Y') {
        window.alert('임시저장이 완료되었습니다!');
      } else {
        window.alert('생성이 완료되었습니다!');
      }
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  const delMyData = async (seq: number) => {
    delData(seq, api__delGoodNews, () => {
      window.alert('삭제 되었습니다.');
      window.history.back();
    });
  };

  const updatePost = async () => {
    try {
      const { content, thumbImgUrl, title } = detailInfo;
      if (content.trim() === '') throw new Error('goodnews_create_content');
      if (title.trim() === '') throw new Error('goodnews_create_title');
      if (thumbImgUrl === '') throw new Error('upload_thumbImg');
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;      
      // API로 보낼 데이터를 명시적으로 정의
      const updateData = {
        content,
        thumbImgUrl,
        title,
        bookedDt,
        temporaryYn: detailInfo.temporaryYn,
        openYn: detailInfo.openYn,
        linkUrl: detailInfo.linkUrl,
        youtubeUrl: detailInfo.youtubeUrl
      };
      const result = await api__updateGoodNewsInfo(Number(goodNewsSeq), updateData);
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('수정이 완료되었습니다.');
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  return (
    <>
      {noticeModal === 'delete' && (
        <ModalMsgBox
          title={'게시물을 삭제하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) {
              delMyData(Number(goodNewsSeq));
            }
          }}
          extraBtn={'삭제'}
        ></ModalMsgBox>
      )}
      {noticeModal === 'create' && (
        <ModalMsgBox
          title={'게시물을 생성하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) createPost('N');
          }}
          extraBtn={'생성'}
        >
          {isPicked && !bookedTime ? (
            <>
              ㆍ예약공개 일시를 입력해주세요.
            </>
          ) : (
            <>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
          )}
        </ModalMsgBox>
      )}
      {noticeModal === 'temp' && (
        <ModalMsgBox
          title={'게시물을 임시저장 하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) createPost('Y');
          }}
          extraBtn={'임시저장'}
        >
          <ModalContentTypo>
            ㆍ임시저장된 게시물은 앱에 등록되지 않으며, 언제든 수정하여 앱에 등록 가능해요
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      {noticeModal === 'edit' && (
        <ModalMsgBox
          title={'수정사항을 적용하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) updatePost();
          }}
          extraBtn={(isPicked && !bookedTime) ? undefined : '적용'}
        >
          {(isPicked && !bookedTime) ? (
            <>
              ㆍ예약공개 일시를 입력하셔야 등록 가능합니다.
            </>
          ) : (
            <>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
          )}
        </ModalMsgBox>
      )}
      <MainLayout title="홈게시물 관리 / 굿뉴스">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="29px">
            <DCBox>
              <BackBtn />
              <PageTitle>굿뉴스 상세</PageTitle>
            </DCBox>
            <FlexBox>
              <DeleteButton
                onClick={() => {
                  setNoticeModal('delete');
                }}
                style={{ marginRight: '20px' }}
              >
                삭제
              </DeleteButton>
              {detailInfo.temporaryYn === 'Y' ? (
                <>
                  <DeleteButton
                    onClick={() => {
                      setNoticeModal('temp');
                    }}
                    style={{ marginRight: '20px' }}
                  >
                    임시저장
                  </DeleteButton>
                  <DetailButton
                    onClick={() => {
                      setNoticeModal('create');
                    }}
                  >
                    생성
                  </DetailButton>
                </>
              ) : (
                <DetailButton onClick={editActivate}>{editMode ? '수정 적용' : '수정'}</DetailButton>
              )}
            </FlexBox>
          </SBCBox>
          {detailInfo.rgstId !== '' && (
            <>
              <Box mb="12px">
                <InfoTxtComp
                  title="제목"
                  value={detailInfo.title}
                  onChange={e => {
                    changeEditState(e.target.value, 'title');
                  }}
                  readonly={!editMode}
                />
                <InfoTxtComp
                  title="링크버튼 URL"
                  value={detailInfo.linkUrl || ''}
                  onChange={e => {
                    changeEditState(e.target.value, 'linkUrl');
                  }}
                  readonly={!editMode}
                  isUrl
                />
                <InfoTxtComp
                  title="유튜브 URL"
                  value={detailInfo.youtubeUrl || ''}
                  onChange={e => {
                    changeEditState(e.target.value, 'youtubeUrl');
                  }}
                  readonly={!editMode}
                  isUrl
                />
                <StretchBox>
                  <InfoTitleBox>썸네일 이미지</InfoTitleBox>
                  <InfoImageBox>
                    <Box mr="90px">
                      <ImageUploader
                        title=""
                        width={332}
                        height={106}
                        imageUrl={detailInfo.thumbImgUrl || ''}
                        size="1014x705"
                        onChange={(url: string) => {
                          changeEditState(url, 'thumbImgUrl');
                        }}
                        disabled={!editMode}
                        folderName="goodNews"
                      />
                    </Box>
                  </InfoImageBox>
                </StretchBox>
                <StretchBox>
                  <InfoTitleBox>예약 공개</InfoTitleBox>
                  <InfoTextBox>
                    <CalendarPicker
                      isPicked={isPicked}
                      setIsPicked={setIsPicked}
                      bookedTime={bookedTime}
                      setBookedTime={setBookedTime}
                      editMode={editMode}
                      isBooked={isBooked}
                      isPublished={isPublished}
                    />
                  </InfoTextBox>
                </StretchBox>
              </Box>
              <Box width={445} margin={'0 auto'}>
                <CKEditor
                  editor={ClassicEditor}
                  data={detailInfo.content}
                  config={{ extraPlugins: [uploadPlugin] }}
                  onChange={(event, editor) => {
                    changeEditState(editor.getData(), 'content');
                  }}
                  disabled={!editMode}
                />
              </Box>
            </>
          )}
        </Box>
      </MainLayout>
    </>
  );
}
