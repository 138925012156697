import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { getAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 홈 게시물 관리 - 굿뉴스 리스트 불러오기
export const api__getGoodNewsList = async (page: string, limit: string) => {
  try {
    const url = `${rootUrl}/api/good-news?page=${page}&limit=${limit}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getUserList error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿뉴스 상세보기
export const api__getGoodNewsInfo = async (goodNewsSeq: number) => {
  try {
    const url = `${rootUrl}/api/good-news/${goodNewsSeq}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGoodNewsInfo error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿뉴스 생성
export const api__createGoodNewsInfo = async (params: {
  content: string;
  linkUrl: string | null;
  thumbImgUrl: string | null;
  title: string;
  temporaryYn: string;
  youtubeUrl: string | null;
  bookedDt: string | null;
}) => {
  try {
    return await axios.post(`${rootUrl}/api/good-news`, params, makeHeader());
  } catch (error) {
    console.log("createGoodNewsInfo error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿뉴스 업데이트
export const api__updateGoodNewsInfo = async (goodNewsSeq: number, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/good-news/${goodNewsSeq}`, params, makeHeader());
  } catch (error) {
    console.log("api__updateGoodNewsInfo error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿뉴스 삭제
export const api__delGoodNews = async (goodNewsSeq: number) => {
  try {
    return await axios.delete(`${rootUrl}/api/good-news/${goodNewsSeq}`, makeHeader());
  } catch (error) {
    console.log("delGoodNews error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 리스트 불러오기
export const api__getGoodDuckMagazineList = async (page: string, limit: string) => {
  try {
    const url = `${rootUrl}/api/magazines?page=${page}&limit=${limit}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGoodDuckMagazineList error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 생성
export const api__createGoodduckMagazine = async (params: {
  artistId: string | null;
  title: string;
  subTitle: string;
  content: string;
  thumbImgUrl: string;
  mainImgUrl: string;
  youtubeUrl: string | null;
  linkUrl: string | null;
  temporaryYn: string;
  moreMagazineIdList: string[];
  bookedDt?: string | null;
}) => {
  try {
    return await axios.post(`${rootUrl}/api/magazines`, params, makeHeader());
  } catch (error) {
    console.log("createGoodNewsInfo error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 상세보기
export const api__getGoodduckMagazineInfo = async (magazineId: string) => {
  try {
    const url = `${rootUrl}/api/magazines/${magazineId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGoodduckMagazineInfo error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 업데이트
export const api__updateGoodduckMagazine = async (magazineId: string, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/magazines/${magazineId}`, params, makeHeader());
  } catch (error) {
    console.log("api__updateGoodduckMagazine error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 삭제하기
export const api__delGoodduckMagazine = async (magazineId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/magazines/${magazineId}`, makeHeader());
  } catch (error) {
    console.log("delGoodduckMagazine error ::: " + error);
    return null;
  }
};

// 홈 게시물 관리 - 굿덕매거진 리스트 불러오기
export const api__getMagazinesTargetList = async (addQuery: string) => {
  try {
    const url = `${rootUrl}/api/magazines/more-magazine/target/list${addQuery}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("api__getMagazinesTargetList error ::: " + error);
    return null;
  }
};
