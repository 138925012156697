import { Box, Typography, styled } from "@mui/material";
import { CCBOX, CDBox, FullSizeFixedBox, SBCBox } from "../../Components/common/CommonElement";
import { Icon } from "@iconify/react";
import { ICONIFY_ICONS } from "../../Constant/IMAGES";
import ReactPlayer from "react-player";

export default function NoticePreview({ title, content, setPreviewOn, imgList, youtubeUrl, linkUrl }) {
  return <FullSizeFixedBox>
    <Box bgcolor='#D9D9D9'>
      <Box p="27px 38px" position='relative' bgcolor='white'>
        <Typography variant='h1' style={{ textAlign: 'left' }}>공지사항 미리보기</Typography>
        <XButton onClick={() => { setPreviewOn(false) }}><Icon icon={ICONIFY_ICONS.DELETE_CIRCLE} width={24} height={24} color="black" /></XButton>
      </Box>
      <Box p="100px 55px" overflow='auto' height='80vh'>
        <ContentBox width={375} bgcolor='white' pb="125px">
          <SBCBox p="53px 16px 16px">
            <Icon icon={ICONIFY_ICONS.ARROW_LEFT} width={24} height={24} color="black" />
            <Typography variant="subtitle2">공지</Typography>
            <Box width={24} height={24} />
          </SBCBox>
          <ResetHr />
          <Box px="16px">
            <Typography variant="h3" style={{ fontWeight: 600, padding: '11px 0 17px' }}>{title || "공지제목"}</Typography>
            <Typography variant="h6" style={{ fontWeight: 400 }}>작성자</Typography>
            <Typography variant="h6" style={{ color: 'rgba(0,0,0,0.38)', marginBottom: '4px', fontSize: '12px', lineHeight: '150%' }}>작성날짜</Typography>
          </Box>
          <ResetHr />
          <div dangerouslySetInnerHTML={{ __html: (content || '내용').replaceAll("\n", '<br/>') }} style={{
            padding: '25px 17px 40px',
            fontFamily: 'Pretendard',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '160%',
            color: 'rgba(0,0,0,0.87)',
            textAlign: 'left'
          }} />
          {imgList.map((item, idx) => (
            <img src={item} alt={`image_preview_${idx}`} key={`image_preview_${idx}`} style={{ width: '100%', marginBottom: '20px' }} />
          ))}
          {youtubeUrl && (
            <ReactPlayer
              url={youtubeUrl}
              width={375}
              height={375 * 9 / 16}
              controls
              style={{ marginBottom: '15px' }}
            />
          )}
          {linkUrl && (
            <CDBox width={252} mx="auto" py="12px" border="2px solid #47BB81" borderRadius='100px' sx={{ cursor: 'pointer' }} onClick={() => {
              window.open(linkUrl, '_blank', 'noopener noreferrer')
            }}>
              <Typography variant="h6" color="#47BB81" lineHeight="150%" marginRight='20px'>링크로 이동</Typography>
              <Icon icon={ICONIFY_ICONS.LINK} width={24} height={24} color="#47BB81" />
            </CDBox>
          )}
        </ContentBox>
      </Box>
    </Box>
  </FullSizeFixedBox>
}

const XButton = styled(CCBOX)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: '1',
  cursor: 'pointer',
})
const ResetHr = styled('hr')({
  borderColor: '#00000033',
  margin: '0'
})
const ContentBox = styled(Box)({
  '& h3': {
    textAlign: 'left'
  },
  '& h6': {
    textAlign: 'left'
  },
  '& span': {
    textAlign: 'left',
  },
})