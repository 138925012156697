import { useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CenterBox, DeleteButton, DragBox, FlexBox, UploadImageBox } from './CommonElement';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import { COLORS } from '../../Constant/COLORS';
import { logoutWithOutNavigate, uploadS3 } from '../../Utils/utils';
import { handleError } from '../../Utils/utils';

type IProps = {
  setImgUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setImgModal: React.Dispatch<React.SetStateAction<string>>;
  getMyInfo: Function;
  api_Fn: Function;
  no_api?: boolean | undefined;
  folderName?: 'calendar' | 'mail-box' | 'notice';
};

export default function ImageUploadModal({
  setImgUploadModal,
  setImgModal,
  getMyInfo,
  api_Fn,
  no_api,
  folderName = 'calendar',
}: IProps) {
  const processing = useRef(false);
  const [dragOn, setDragOn] = useState(false);
  const [newImgs, setNewImgs] = useState<any[]>([]);
  const [newFiles, setNewFiles] = useState<any[]>([]);

  const imageChangeHandler = files => {
    if (!files.length) return;
    const extCheck = ['png', 'jpg', 'jpeg', 'JPG'];
    try {
      const imgList: any[] = [];
      const fileList: any[] = [];
      for (let file of files) {
        if (!extCheck.includes(file.name.split('.').pop())) throw new Error('only_img');
        if (file.size >= 10000000) throw new Error('too_mush_size');
        fileList.push(file);
        const reader = new FileReader();
        reader.onload = () => {
          imgList.push(reader.result);
          if (imgList.length === files.length) {
            setNewImgs(newImgs.concat(imgList));
            setNewFiles(newFiles.concat(fileList));
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  // 드래그, 드롭에 따른 이벤트
  const dropDragEnter = e => {
    e.stopPropagation();
    e.preventDefault();
    setDragOn(true);
  };
  const dropDragLeave = e => {
    e.stopPropagation();
    e.preventDefault();
    setDragOn(false);
  };
  const dragDrop = e => {
    e.preventDefault();
    setDragOn(true);
    if (e.type === 'drop') {
      setDragOn(false);
      imageChangeHandler(e.dataTransfer.files);
    }
  };

  const removeImageOnList = (idx: number) => {
    setNewImgs(prev => {
      const clone = [...prev];
      clone.splice(idx, 1);
      return clone;
    });
  };

  const uploadImages = async folderName => {
    if (processing.current) {
      window.alert('업로드 중입니다. 잠시 기다려 주십시오.');
      return;
    }
    if (newImgs.length === 0) return;
    processing.current = true;
    try {
      const datas = await Promise.all(newFiles.map(item => uploadS3(item, folderName)));
      const result = await api_Fn(datas);
      if (no_api) {
        setImgUploadModal(false);
        setNewImgs([]);
        setNewFiles([]);
      }
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      getMyInfo();
      setImgUploadModal(false);
      setNewImgs([]);
      setNewFiles([]);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      processing.current = false;
    }
  };

  return (
    <CenterBox position="fixed" left="0" top="0" zIndex={50}>
      <Box p="30px" width={960} height={450} bgcolor={'white'} boxShadow="0 4px 8px rgba(0,0,0,0.5)">
        <DragBox
          onDragEnter={dropDragEnter}
          onDragLeave={dropDragLeave}
          onDrop={dragDrop}
          onDragOver={dragDrop}
          className={dragOn ? 'drag-on' : ''}
        >
          {newImgs.length === 0 ? (
            <label
              style={{
                cursor: 'pointer',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <input
                type="file"
                onChange={e => {
                  imageChangeHandler(e.target.files);
                }}
                accept=".png, .jpg, .jpeg"
                multiple
                style={{ display: 'none' }}
              />
              <FaArrowAltCircleUp size={30} />
              <Typography variant="body2" mt="12px">
                이미지를 끌어다 놓거나 클릭하여 파일을 선택하세요.
              </Typography>
              <Typography variant="body2">ㆍ복수 이미지 업로드가능</Typography>
              <Typography variant="body2">ㆍ지원하는 파일형식 : jpg, png</Typography>
              <Typography variant="body2">ㆍ권장 규격 : 260x260(px)</Typography>
            </label>
          ) : (
            newImgs.map((item, idx) => (
              <UploadImageBox
                key={`image_preview_${idx}`}
                item={item}
                idx={idx}
                setImgModal={setImgModal}
                removeImage={() => {
                  removeImageOnList(idx);
                }}
              />
            ))
          )}
        </DragBox>
        <FlexBox justifyContent={'flex-end'}>
          <Button
            variant="contained"
            sx={{ color: 'white', backgroundColor: COLORS.BUTTON_BLACK }}
            onClick={() => {
              setImgUploadModal(false);
              setNewImgs([]);
              setNewFiles([]);
            }}
          >
            취소
          </Button>
          <DeleteButton
            sx={{ ml: '20px' }}
            onClick={() => {
              uploadImages(folderName);
            }}
          >
            업로드
          </DeleteButton>
        </FlexBox>
      </Box>
    </CenterBox>
  );
}
