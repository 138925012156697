import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import dayjs from 'dayjs';
import { ReactSVG } from 'react-svg';
import ArtistSelectBox from '../../Components/common/ArtistSelectBox';
import getBookedTimeDisplay from '../../Components/common/BookedTimeDisplay';
import CalendarPicker from '../../Components/common/CalendarPicker';
import {
  BackBtn,
  CheckLabelStyle,
  DCBox,
  DeleteButton,
  DetailButton,
  FlexBox,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  InfoTxtComp,
  ModalContentTypo,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import ImageUploader from '../../Components/common/ImageUploader';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import MoreMagazineModal from '../../Components/goodduck-magazine/MoreMagazineModal';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import MainLayout from '../../layout/MainLayout';
import {
  api__delGoodduckMagazine,
  api__getGoodduckMagazineInfo,
  api__updateGoodduckMagazine,
} from '../../services/HomePostService';
import { ArtistId, GoodduckMagazineDetailInfo, magazineSelectInfo } from '../../Utils/types';
import { delData, getArtistList, getInfo, handleError } from '../../Utils/utils';
import {checkBookingStatus} from '../../Utils/utils';

export default function GoodduckMagazineDetail() {
  const [detailInfo, setDetailInfo] = useState<GoodduckMagazineDetailInfo>({
    content: '',
    linkName: '',
    linkUrl: '',
    openYn: 'Y',
    rgstDt: '',
    rgstId: '',
    thumbImgUrl: '',
    moreMagazineList: [],
    title: '',
    updtDt: '',
    updtId: '',
    youtubeUrl: '',
    artistId: '',
    artistNameKo: '',
    magazineId: '',
    subTitle: '',
    mainImgUrl: '',
    temporaryYn: 'Y',
    bookedYn: 'N',
    bookedDt: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [noticeModal, setNoticeModal] = useState<string | null>(null);

  const { magazineId } = useParams();

  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const [magModal, setMagModal] = useState(false);

  const [isPicked, setIsPicked] = useState(false);
  const [bookedTime, setBookedTime] = useState<Date | null>(null)
  const [isBooked, setIsBooked] = useState(false);
  const [isPublished, setIsPublished] = useState(false); // 게시글 공개 여부 상태
  // 수정모드에 들어갈 때의 bookedDt 값
  const [editBookedDt, setEditBookedDt] = useState<string | null>(null);

  const changeSelect = (aVal: string, nameVal: string) => {
    if (!isEdit) setIsEdit(true);
    setDetailInfo({ ...detailInfo, artistId: aVal, artistNameKo: nameVal });
    
    setIsDropped(false);
  };
  const getMyInfo = async () => {
    getInfo(api__getGoodduckMagazineInfo, magazineId, setDetailInfo);
  };
  const changeEditState = (value: string, target: string) => {
    if (!isEdit) setIsEdit(true);
    setDetailInfo(prev => {
      const clone = { ...prev };
      clone[target] = value;
      return clone;
    });
  };
  
  useEffect(() => {
    getMyInfo();
    getArtistList(setArtistList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detailInfo.rgstId !== '') {
      // 임시 저장 여부에 따라 수정 모드 활성화
      if (detailInfo.temporaryYn === 'Y') setEditMode(true);
    }
    const { isBooked, bookedTime, isPublished } = checkBookingStatus(
      detailInfo.bookedYn ?? 'N',
      detailInfo.bookedDt ?? null,
      detailInfo.openYn ?? 'N'
    );
    // 예약 여부 및 상태 업데이트
    setIsBooked(isBooked);
    // 예약된 시간이 있으면 bookedTime에 저장
    setBookedTime(bookedTime);
    // 게시물 발행 여부 업데이트
    setIsPublished(isPublished);
    // eslint-disable-next-line
  }, [detailInfo.rgstId]);
  

  const createPost = async (temporaryYn: string) => {
    try {
      const { content, linkUrl, thumbImgUrl, title, artistId, subTitle, mainImgUrl, moreMagazineList, youtubeUrl } =
        detailInfo;
      if (temporaryYn === 'N') {
        if (content.trim() === '') throw new Error('fill_content');
        if (title.trim() === '') throw new Error('fill_title');
        if (subTitle === null || subTitle.trim() === '') throw new Error('fill_subTitle');
        if (thumbImgUrl === '') throw new Error('upload_thumbImg');
        if (mainImgUrl === '') throw new Error('upload_mainImg');
      }
      const newArr = moreMagazineList.filter(item => item.flag !== 'in');
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;
      const result = await api__updateGoodduckMagazine(magazineId || '', {
        artistId: artistId === '' ? null : artistId,
        youtubeUrl: youtubeUrl === '' ? null : youtubeUrl,
        linkUrl: linkUrl === '' ? null : linkUrl,
        content,
        mainImgUrl,
        moreMagazineList: newArr,
        subTitle,
        thumbImgUrl,
        title,
        temporaryYn,
        openYn: temporaryYn === 'Y' ? 'N' : 'Y',
        bookedDt
      });
      
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      if (temporaryYn === 'Y') {
        window.alert('임시저장이 완료되었습니다!');
      } else {
        window.alert('생성이 완료되었습니다!');
      }
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    }
  };
  const changeMoreList = (item: magazineSelectInfo) => {
    if (!editMode) return;
    if (!isEdit) setIsEdit(true);
    setDetailInfo(prev => {
      const clone = { ...prev };
      const { moreMagazineList } = clone;

      const sIdx = moreMagazineList.findIndex(mag => mag.magazineId === item.magazineId);
      if (sIdx === -1) {
        if (moreMagazineList.filter(mag => mag.flag !== 'delete').length === 5) {
          // 이미 5개가 선택된 경우에는 alert창 이우 return;
          window.alert('5개까지 선택이 가능합니다.');
          return prev;
        } else {
          // 새롭게 추가되는 경우에는 flag를 new로 하여 push
          moreMagazineList.push({ ...item, flag: 'new' });
          return clone;
        }
      } else {
        switch (moreMagazineList[sIdx].flag) {
          case 'delete':
            if (moreMagazineList.length === 5) {
              window.alert('5개까지 선택이 가능합니다.');
            } else {
              moreMagazineList[sIdx].flag = 'in';
            }
            break;
          case 'in':
            moreMagazineList[sIdx].flag = 'delete';
            break;
          case 'new':
            moreMagazineList.splice(sIdx, 1);
            break;
          default:
            break;
        }
        return clone;
      }
    });
  };

  const editActivate = () => {
    if (editMode) { // 수정 적용
      const bookedDate = bookedTime ? new Date(bookedTime).getTime() : null;
      const editedBookedDate = editBookedDt ? new Date(editBookedDt).getTime() : null;
  
      // 예약 시간이 현재보다 이전이고, 예약 날짜가 다르면 발행된 상태
      const isAlreadyPublished = 
      bookedDate && 
      editedBookedDate && 
      bookedDate !== editedBookedDate && 
      editedBookedDate <= Date.now();

      // 발행되었다면 alert 띄운 뒤 예약 공개 시간을 기존 값으로 초기화한 후 '게시됨'으로 변경하기
    if(isAlreadyPublished) {
      setIsPublished(true);
      alert('이미 게시되어 예약 공개 시간을 수정할 수 없습니다.')
      setBookedTime(editBookedDt ? new Date(editBookedDt) : null);
    } else {
      setNoticeModal('edit');
    }
      
    } else { // 수정 모드 진입
      setEditMode(true);
      // bookedDt 값 수정모드 진입 시의 값으로 고정
      const clonedBookedDt = JSON.parse(JSON.stringify(detailInfo.bookedDt));
      setEditBookedDt(clonedBookedDt || null);
    }
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'magazine');
    };
  }
  useEffect(() => {
    if (detailInfo.rgstId !== '') {
      setDetailInfo(prev => {
        const clone = { ...prev };
        for (let i of clone.moreMagazineList) {
          i.flag = 'in';
        }
        return clone;
      });
    }
    // eslint-disable-next-line
  }, [detailInfo.rgstId]);
  const updatePost = async () => {
    try {
      const {
        content,
        linkUrl,
        thumbImgUrl,
        title,
        subTitle,
        mainImgUrl,
        moreMagazineList,
        openYn,
        temporaryYn,
        youtubeUrl,
        artistId,
      } = detailInfo;
      if (content.trim() === '') throw new Error('fill_content');
      if (title.trim() === '') throw new Error('fill_title');
      if (subTitle === null || subTitle.trim() === '') throw new Error('fill_subTitle');
      if (thumbImgUrl === '') throw new Error('upload_thumbImg');
      if (mainImgUrl === '') throw new Error('upload_mainImg');
      
      const bookedDt = bookedTime ? dayjs(bookedTime).format('YYYY-MM-DD HH:mm:ss') : null;
      const filteredMoreMagazineList = moreMagazineList.filter(item => item.flag !== 'in');
      // API로 보낼 데이터를 명시적으로 정의
      const updateData = {
        artistId: artistId === '' ? null : artistId,
        youtubeUrl: youtubeUrl === '' ? null : youtubeUrl,
        linkUrl: linkUrl === '' ? null : linkUrl,
        content,
        mainImgUrl,
        moreMagazineList: filteredMoreMagazineList,
        openYn,
        subTitle,
        temporaryYn,
        thumbImgUrl,
        title,
        bookedDt,
    };
    // API 호출
    const result = await api__updateGoodduckMagazine(magazineId || '', updateData);
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('수정이 완료되었습니다.');
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    }
  };
  const delMyData = async (id: string) => {
    delData(id, api__delGoodduckMagazine, () => {
      window.alert('삭제 되었습니다.');
      window.history.back();
    });
  };

  return (
    <>
      {magModal && (
        <MoreMagazineModal
          setMagModal={setMagModal}
          changeMoreList={changeMoreList}
          selectedMagazineList={detailInfo.moreMagazineList}
        />
      )}
      {noticeModal === 'delete' && (
        <ModalMsgBox
          title={'게시물을 삭제하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) {
              delMyData(magazineId || '');
            }
          }}
          extraBtn={'삭제'}
        ></ModalMsgBox>
      )}
      {noticeModal === 'create' && (
        <ModalMsgBox
          title={'게시물을 생성하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) createPost('N');
          }}
          extraBtn={'생성'}
        >          
        {isPicked && !bookedTime ? (
          <>
            ㆍ예약공개 일시를 입력해주세요.
          </>
        ) : (
          <>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
        )}</ModalMsgBox>
      )}
      {noticeModal === 'temp' && (
        <ModalMsgBox
          title={'게시물을 임시저장 하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) createPost('Y');
          }}
          extraBtn={'임시저장'}
        >
          <ModalContentTypo>
            ㆍ임시저장된 게시물은 앱에 등록되지 않으며, 언제든 수정하여 앱에 등록 가능해요
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      {noticeModal === 'edit' && (
        <ModalMsgBox
          title={'수정사항을 적용하시겠어요?'}
          setModal={bool => {
            setNoticeModal(null);
            if (!bool) updatePost();
          }}
          extraBtn={(isPicked && !bookedTime) ? undefined : '적용'}
        >
          {(isPicked && !bookedTime) ? (
            <>
              ㆍ예약공개 일시를 입력하셔야 등록 가능합니다.
            </>
          ) : (
            <>ㆍ예약공개 : {getBookedTimeDisplay(bookedTime)}</>
          )}
        </ModalMsgBox>
      )}
      <MainLayout title="홈게시물 관리 / 굿덕 매거진">
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="29px">
            <DCBox>
              <BackBtn />
              <PageTitle>굿덕 매거진 상세</PageTitle>
            </DCBox>
            <FlexBox>
              <DeleteButton
                onClick={() => {
                  setNoticeModal('delete');
                }}
                style={{ marginRight: '20px' }}
              >
                삭제
              </DeleteButton>
              {detailInfo.temporaryYn === 'Y' ? (
                <>
                  <DeleteButton
                    onClick={() => {
                      setNoticeModal('temp');
                    }}
                    style={{ marginRight: '20px' }}
                  >
                    임시저장
                  </DeleteButton>
                  <DetailButton
                    onClick={() => {
                      setNoticeModal('create');
                    }}
                  >
                    생성
                  </DetailButton>
                </>
              ) : (
                <DetailButton onClick={editActivate}>{editMode ? '수정 적용' : '수정'}</DetailButton>
              )}
            </FlexBox>
          </SBCBox>
          {detailInfo.rgstId !== '' && (
            <Box mb="24px">
              <InfoTxtComp
                title="제목"
                value={detailInfo.title}
                onChange={e => {
                  changeEditState(e.target.value, 'title');
                }}
                readonly={!editMode}
              />
              <InfoTxtComp
                title="부제목"
                value={detailInfo.subTitle || ''}
                onChange={e => {
                  changeEditState(e.target.value, 'subTitle');
                }}
                readonly={!editMode}
              />
              <InfoTxtComp
                title="링크버튼 URL"
                value={detailInfo.linkUrl || ''}
                onChange={e => {
                  changeEditState(e.target.value, 'linkUrl');
                }}
                readonly={!editMode}
                isUrl
              />
              <InfoTxtComp
                title="유튜브 URL"
                value={detailInfo.youtubeUrl || ''}
                onChange={e => {
                  changeEditState(e.target.value, 'youtubeUrl');
                }}
                readonly={!editMode}
                isUrl
              />
              <StretchBox>
                <InfoTitleBox>이미지</InfoTitleBox>
                <InfoImageBox>
                  <Box mr="90px">
                    <ImageUploader
                      title="썸네일 이미지(외부)"
                      width={133}
                      height={93}
                      imageUrl={detailInfo.thumbImgUrl || ''}
                      size="1014x705"
                      onChange={(url: any) => {
                        changeEditState(url, 'thumbImgUrl');
                      }}
                      disabled={!editMode}
                      folderName="magazine"
                    />
                  </Box>
                  <Box>
                    <ImageUploader
                      title="메인 이미지(내부)"
                      width={93}
                      height={93}
                      imageUrl={detailInfo.mainImgUrl || ''}
                      size="750x750"
                      onChange={(url: any) => {
                        changeEditState(url, 'mainImgUrl');
                      }}
                      disabled={!editMode}
                      folderName="magazine"
                    />
                  </Box>
                </InfoImageBox>
              </StretchBox>
              <StretchBox>
                <InfoTitleBox>아티스트 정보란 추가</InfoTitleBox>
                <InfoTextBox>
                  <ArtistSelectBox
                    aName={detailInfo.artistNameKo}
                    artistId={detailInfo.artistId}
                    artistList={artistList}
                    changeSelect={changeSelect}
                    isDropped={isDropped}
                    setIsDropped={editMode ? setIsDropped : () => {}}
                    notAll
                    isMagazine
                  />
                  <Typography variant="body2" ml="5px">
                    ㆍ1팀 선택 가능
                  </Typography>
                </InfoTextBox>
              </StretchBox>
              <StretchBox>
                <InfoTitleBox>굿덕 매거진 더보기 선택</InfoTitleBox>
                <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start' }}>
                  <DCBox>
                    <DetailButton
                      onClick={() => {
                        if (editMode) setMagModal(true);
                      }}
                    >
                      굿덕 매거진 더보기 선택
                    </DetailButton>
                    <Typography variant="body1">ㆍ5개까지 선택 가능</Typography>
                  </DCBox>
                  <Box>
                    {detailInfo.moreMagazineList.map((item, idx) => {
                      const isIncluded = !!(item.flag === 'in' || item.flag === 'new');
                      if (isIncluded)
                        return (
                          <CheckLabelStyle key={`seletedMagazine_${idx}`} sx={{ marginY: '5px' }}>
                            <input
                              type="checkbox"
                              checked={isIncluded}
                              onChange={() => {
                                changeMoreList(item);
                              }}
                            />
                            <div className={isIncluded ? 'custom active' : 'custom'}>
                              <ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
                            </div>
                            <Typography ml="8px" variant="h5">
                              {item.title}
                            </Typography>
                          </CheckLabelStyle>
                        );
                      else return <Box key={`selectedMagazine_${idx}`} />;
                    })}
                  </Box>
                </InfoTextBox>
              </StretchBox>
              <StretchBox>
                  <InfoTitleBox>예약 공개</InfoTitleBox>
                  <InfoTextBox>
                    <CalendarPicker
                      isPicked={isPicked}
                      setIsPicked={setIsPicked}
                      bookedTime={bookedTime}
                      setBookedTime={setBookedTime}
                      editMode={editMode}
                      isBooked={isBooked}
                      isPublished={isPublished}
                    />
                  </InfoTextBox>
                </StretchBox>
            </Box>
          )}
          {detailInfo.rgstId !== '' && (
            <Box width={445} margin={'0 auto'}>
              <CKEditor
                editor={ClassicEditor}
                data={detailInfo.content}
                config={{ extraPlugins: [uploadPlugin] }}
                onChange={(event, editor) => {
                  changeEditState(editor.getData(), 'content');
                }}
                disabled={!editMode}
              />
            </Box>
          )}
        </Box>
      </MainLayout>
    </>
  );
}
