import axios from "axios";
import { getCookie } from "../Utils/cookies";

import { logoutWithOutNavigate, makeHeader } from "../Utils/utils";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;
export const ongoingRequests = {};
export const isOngoingRequest = (url) => {
  if (ongoingRequests[url]) {
    console.log("Request already in progress for this URL.");
    return false;
  }
  return true;
};
export const getAxiosProcess = async (url) => {
  if (!isOngoingRequest(url)) return null;
  ongoingRequests[url] = true;
  const response = await axios.get(url, makeHeader());
  delete ongoingRequests[url];
  return response.data;
};
export const postAxiosProcess = async (url, params) => {
  if (!isOngoingRequest(url)) return null;
  ongoingRequests[url] = true;
  const response = await axios.post(url, params, makeHeader());
  delete ongoingRequests[url];
  return response.data;
};
export const putAxiosProcess = async (url, params) => {
  if (!isOngoingRequest(url)) return null;
  ongoingRequests[url] = true;
  const response = await axios.put(url, params, makeHeader());
  delete ongoingRequests[url];
  return response.data;
};
export const deleteAxiosProcess = async (url) => {
  if (!isOngoingRequest(url)) return null;
  ongoingRequests[url] = true;
  const response = await axios.delete(url, makeHeader());
  delete ongoingRequests[url];
  return response.data;
};
export const defaultHeader = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
};
export const api__getAllArtistList = async () => {
  try {
    const url = `${rootUrl}/api/artists/id`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getAllArtistList error ::: " + error);
    return null;
  }
};

export const api__fileUpload = async (formData: FormData) => {
  try {
    return await axios.post(`${rootUrl}/api/files/upload`, formData, {
      headers: {
        Authorization: getCookie("authCookie").token,
        "Content-Type": `multipart/form-data`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  } catch (error) {
    console.log("fileUpload error ::: " + error);
    return null;
  }
};

export const api__getCodes = async (grpCode: string) => {
  try {
    return await axios.get(`${rootUrl}/api/code?grpCode=${grpCode}`, makeHeader());
  } catch (error) {
    console.log("getCodes error ::: " + error);
    return null;
  }
};

export const codeHandler = (res: any) => {
  try {
    if (res === null) return false;
    if (res.code === "S200") return true;
    if (res.code.includes("T")) {
      logoutWithOutNavigate(res.code);
    } else {
    }
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};
