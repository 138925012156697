import { Box, Button, Link, Table, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material";
import { COLORS } from "../../Constant/COLORS";
import { images } from "../../Utils/types";
import { Icon } from "@iconify/react";
import { ICONIFY_ICONS } from "../../Constant/IMAGES";

// =======================================================================================
// BOX_ELEMENT
// =======================================================================================
export const CenterBox = styled(Box)({
	width: '100%',
	minWidth: '100vw',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
})

export const FlexBox = styled(Box)({
	display: 'flex'
})

export const FEBox = styled(FlexBox)({
	justifyContent: 'flex-end'
})

export const FSCBox = styled(FlexBox)({
	justifyContent: 'flex-start',
	alignItems: 'center'
})

export const SBCBox = styled(FlexBox)({
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%'
})

export const StretchBox = styled(FlexBox)({
	alignItems: 'stretch',
	"&.here": {
		border: '3px solid #3298cb'
	}
})
export const DCBox = styled(FlexBox)({
	alignItems: 'center',
})

export const CDBox = styled(FlexBox)({
	justifyContent: 'center'
})
export const CCBOX = styled(CDBox)({
	alignItems: 'center'
})

export const CommonBorderBox = styled(Box)({
	border: '1px solid #A4A4A4',
	fontSize: '16px',
	fontFamily: 'Pretendard',
	display: 'flex',
	alignItems: 'center',
	'&.right': {
		fontWeight: 400,
		justifyContent: 'flex-start',
		padding: '15px 23px',
		width: 'calc(100% - 200px)'
	},
	'&.text': {
		justifyContent: 'space-between',
		padding: '10px',
		width: 'calc(100% - 200px)'
	},
})
export const InfoTitleBox = styled(CommonBorderBox)({
	fontWeight: 700,
	backgroundColor: COLORS.SECOND,
	color: 'black',
	padding: '15px 0px',
	justifyContent: 'center',
	width: '200px',
	position: 'relative',
})

export const InfoTextBox = styled(CommonBorderBox)({
	fontWeight: 400,
	padding: '8px 72px 8px 12px',
	justifyContent: 'flex-start',
	width: 'calc(100% - 200px)',
	"&.url": {
		textDecoration: "underline"
	}
})
export const InfoImageBox = styled(CommonBorderBox)({
	fontWeight: 400,
	padding: '12px 8px',
	justifyContent: 'flex-start',
	width: 'calc(100% - 200px)'
})
export const ImageBox = styled(Box)({
	width: '100%',
	height: '100%',
	position: 'relative',
	margin: '0 auto',
	'img': {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		width: 'auto',
		height: 'auto',
		maxWidth: '100%',
		maxHeight: '100%',
		margin: 'auto'
	}
})

export const DayPickerBox = styled(Box)({
	position: 'absolute',
	left: '400px',
	top: '-200px',
	zIndex: 10,
	backgroundColor: 'white',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
	borderRadius: '12px',

	'.my-selected:not([disabled])': {
		color: 'white',
		backgroundColor: COLORS.MAIN,
		borderRadius: '6px'
	},
	'.my-selected:hover:not([disabled])': {
		color: 'white',
		backgroundColor: COLORS.MAIN,
		borderRadius: '6px'
	},
	'.my-today': {
		color: '#2196F3',
		position: 'relative'
	},
	".my-today:before": {
		position: 'absolute',
		left: '16px',
		bottom: '0px',
		width: '4px',
		height: '4px',
		borderRadius: '50%',
		backgroundColor: '#2196F3',
		display: 'block',
		textAlign: 'center',
		content: '""',
	}
})

export const ImageModalBox = styled(Box)({
	width: '720px',
	height: '640px',
	overflow: 'hidden',
	backgroundColor: 'white',
	borderRadius: '12px',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)'
})

export const ArtistMemberBox = styled(Box)({
	width: 'calc(100% - 24px)',
	margin: '0 12px',
	marginBottom: '12px',
	padding: '20px 35px 24px 30px',
	border: '1px solid #969696',
	borderRadius: '19px',
	position: 'relative'
})
export const InactiveBox = styled(Box)({
	width: '100%',
	height: '100%',
	position: 'absolute',
	borderRadius: '19px',
	top: 0,
	left: 0,
	zIndex: 1,
	backgroundColor: 'rgba(0,0,0,0.4)',
})

export const DragBox = styled(FlexBox)({
	width: '100%',
	height: '340px',
	marginBottom: '20px',
	backgroundColor: '#DEDEDE',
	padding: "50px",
	paddingBottom: "20px",
	flexWrap: 'wrap',
	overflow: 'auto',
	boxSizing: 'border-box',
	"&.drag-on": {
		border: '2px solid #3298cb'
	}
})

export const ImageSizeBox = styled(Box)({
	border: '1px solid #b3b3b3',
	minWidth: 120,
	width: '120px',
	height: '120px',
	position: 'relative',
	cursor: 'pointer'
})

export const DisableBox = styled(DCBox)({
	"&.disabled": {
		">input": {
			backgroundColor: COLORS.BORDER_GRAY,
			opacity: 0.5
		},
		">button": {
			opacity: 0.5
		}
	}
})

export const DetailLayoutBox = styled(Box)({
	padding: '38px 34px',
	paddingBottom: '120px',
	height: 'calc(100vh - 240px)',
	overflow: 'auto'
})

// =======================================================================================
// BOX_ELEMENT_END
// =======================================================================================

export const ResetInput = styled('input')({
	border: 'none',
	outline: 'none',
	backgroundColor: 'white',
})

export const TimeInput = styled(ResetInput)({
	width: '40px',
	padding: 0,
	textAlign: 'center',
	fontFamily: 'Pretendard',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	borderRadius: '6px',
	border: `1px solid ${COLORS.BORDER_GRAY}`,
	"&::placeholder": {
		fontFamily: 'Pretendard',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
		color: COLORS.BORDER_GRAY,
	}
})

export const FButton = styled(Button)({
	boxSizing: 'border-box',
	"&:focus": {
		outline: '2px solid black'
	}
})

export const TextInput = styled('input')({
	backgroundColor: 'transparent',
	border: 'none',
	outline: 'none'
})

export const OptionButton = styled(Button)({
	width: '100%',
	backgroundColor: 'transparent',
	justifyContent: 'flex-start',
	fontFamily: 'Pretendard',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '24px',
	fontSize: '16px',
	color: '#232325',
	'&:hover': {
		backgroundColor: '#eeeeee'
	},
	'&.selected': {
		fontWeight: 'bold'
	}
})

export const SelectBox = styled('select')({
	padding: '6px',
	fontSize: '16px',
	borderRadius: '8px',
	borderColor: '#ccc'
})

export const SelectStyles = {
	menu: (provided) => ({
		...provided,
		maxHeight: '200px',
		overflowY: 'auto',

	})
}

export const SearchInput = styled('input')({
	width: '332px',
	borderRadius: '8px 0 0 8px',
	outline: 'none',
	border: '1px solid #DEE2E6',
	fontWeight: 400,
	fontFamily: 'Pretendard',
	padding: '9px 16px',
})

export const SearchButton = styled(Button)({
	minWidth: '60px',
	backgroundColor: COLORS.MAIN,
	color: 'white',
	borderRadius: '0 8px 8px 0',
	"&:hover": {
		backgroundColor: COLORS.MAIN,
		opacity: 0.8
	}
})

export const DeleteButton = styled(Button)({
	boxSizing: 'border-box',
	fontFamily: 'Pretendard',
	fontWeight: 600,
	fontSize: '14px',
	padding: '4px 8px',
	backgroundColor: 'white',
	minWidth: '50px',
	color: COLORS.MAIN,
	border: `1px solid ${COLORS.MAIN}`,
})

export const AMPMButton = styled(Button)({
	minWidth: '40px',
	color: '#222',
	border: '1px solid #222',
	padding: '2px 0',
	"&:hover": {
		backgroundColor: 'transparent'
	},
	"&.selected": {
		color: 'white',
		backgroundColor: COLORS.MAIN,
		borderColor: COLORS.MAIN
	},
	"&.selected:hover": {
		color: 'white',
		backgroundColor: COLORS.MAIN
	}
})

export const MyTable = styled(Table)({
	backgroundColor: '#fff',
	'td': {
		textAlign: 'center'
	},
	'th': {
		paddingY: '15px',
		fontFamily: 'Pretendard',
		color: '#152536',
		fontWeight: 800,
		borderTop: '1px solid #DEE2E6',
		borderBottom: '2px solid #DEE2E6',
		backgroundColor: 'white',
		textAlign: 'center'
	}
})

export const THS = ({ ths }: { ths: any[]; }) => (
	<TableHead>
		<TableRow>
			{ths.map((item, idx) => (
				<TableCell key={`th-${idx}`} sx={{ minWidth: `${item.width}px`, }}>
					{item.name}
				</TableCell>
			))}
		</TableRow>
	</TableHead >
)

export const TextInputInBBox = styled(ResetInput)({
	width: '100%',
	border: '1px solid #DEE2E6',
	borderRadius: '6px',
	fontWeight: 400,
	padding: '9px 16px',
	fontSize: '16px',
	"&.disabled": {
		backgroundColor: '#f3f3f3',
		cursor: 'not-allowed'
	},

})

type ModalBtnProps = {
	onClick: () => void;
	onKeyDown: () => void;
	forwardRef: any;
	extra?: boolean;
}

export const ModalBtn = ({ onClick, onKeyDown, forwardRef, extra }: ModalBtnProps) => (
	<Button style={{ backgroundColor: COLORS.BUTTON_BLACK }} onClick={onClick} onKeyDown={onKeyDown} ref={forwardRef}>
		<Typography variant="body1" fontWeight={600} color='white'>
			{extra ? "취소" : "확인"}
		</Typography>
	</Button>
)

export const ResetButton = styled(FButton)({
	backgroundColor: 'transparent',
	color: '#222',
	fontFamily: 'Pretendard',
	"&:hover": {
		backgroundColor: 'transparent',
		opacity: 0.8
	}
})

export const MainColorButton = styled(FButton)({
	backgroundColor: COLORS.MAIN,
	color: 'white',
	fontFamily: 'Pretendard',
	"&:hover": {
		backgroundColor: COLORS.MAIN,
		opacity: 0.6
	}
})

export const DarkContainedButton = styled(FButton)({
	backgroundColor: COLORS.BUTTON_BLACK,
	color: 'white',
	fontFamily: 'Pretendard',
	"&:hover": {
		backgroundColor: COLORS.BUTTON_BLACK,
		opacity: 0.8
	},
	padding: '4px 8px',
	fontSize: '14px',
	minWidth: '50px',
})

export const MainButton = styled(MainColorButton)({
	fontWeight: 600,
	fontSize: '16px',
	padding: '8px 24px',
	"&.inactive": {
		padding: '4px',
		gap: '8px',
		background: COLORS.BUTTON_BLACK,
		fontSize: '14px'
	},
	"&.active": {
		padding: '4px',
		gap: '8px',
		background: COLORS.MAIN,
		fontSize: '14px'
	}
})

export const SecondaryButton = styled(FButton)({
	fontWeight: 600,
	fontSize: '16px',
	color: COLORS.MAIN,
	backgroundColor: COLORS.SECOND,
	fontFamily: 'Pretendard',
	"&:hover": {
		backgroundColor: COLORS.SECOND,
		opacity: 0.6
	}
})

export const DetailButton = styled(MainColorButton)({
	border: `1px solid ${COLORS.MAIN}`,
	padding: '4px 8px',
	fontSize: '14px',
	minWidth: '50px',
	marginRight: '9px'
})
export const ImageDeleteBtn = styled(Button)({
	position: 'absolute',
	right: '2px',
	top: '2px',
	minWidth: '5px',
	width: '5px',
	height: '12px',
	fontSize: '10px',
	padding: '0 5px',
	borderRadius: '10px',
	zIndex: 10,
	border: '1px solid black',
	color: 'black'
})

type BackProps = {
	back?: number | undefined
}

export const BackBtn = ({ back }: BackProps) => (
	<DeleteButton style={{ marginRight: '12px', border: 'none' }} onClick={() => {
		window.history.go(back || -1)
	}}>
		<Icon icon={ICONIFY_ICONS.ARROW_LEFT} width={36} height={36} />
	</DeleteButton>
)


export const HeaderLink = styled(Link)({
	color: COLORS.BUTTON_BLACK,
	fontFamily: 'Pretendard',
	fontWeight: 400,
	fontSize: '14px',
	textDecoration: 'none',
	marginLeft: '45px',
	"&.active": {
		color: COLORS.MAIN,
		fontWeight: 'bold',
	},
})

export const PageTitle = styled(Typography)({
	fontWeight: 700,
	fontSize: '27px',
	lineHeight: '16px',
	color: COLORS.MAIN,
	fontStyle: 'normal',
	fontFamily: 'Pretendard'
})

export const ModalContentTypo = styled(Typography)({
	fontFamily: "Pretendard",
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '26px',
	color: COLORS.BUTTON_BLACK
})

export const SimepleTextArea = styled('textarea')({
	background: 'white',
	border: '1px solid #E0E2E7',
	borderRadius: '8px',
	padding: '20px 16px',
	width: '100%',
	fontFamily: 'Prentendard',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '24px',
})

export const ReadOnlyTextArea = styled('textarea')({
	width: '100%',
	fontFamily: 'Prentendard',
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '24px',
	border: 'none',
	overflow: 'auto',
	resize: 'none'
})

export const InfoComp = ({ title, value }: { title: string; value: string|null; }) => (
	<StretchBox>
		<InfoTitleBox >{title}</InfoTitleBox>
		<InfoTextBox>{value}</InfoTextBox>
	</StretchBox>
)


export const InfoTextComp = ({ title }) => (
	<StretchBox>
		<InfoTitleBox>{title}</InfoTitleBox>
		<InfoTextBox>
			<TextInputInBBox />
		</InfoTextBox>
	</StretchBox>
)

export const InfoContextComp = ({ title, value }) => {
	const regex = new RegExp("\\n", 'g');
	const matches = value.match(regex);
	const h = matches ? matches.length : 0;

	return <StretchBox>
		<InfoTitleBox>{title}</InfoTitleBox>
		<InfoTextBox>
			<ReadOnlyTextArea defaultValue={value} readOnly
				style={{ minHeight: `${50 + h * 25}px` }}
			/>
		</InfoTextBox>
	</StretchBox>
}

type InfoTxtCompProps = {
	title: string;
	value: string | undefined;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	required?: boolean;
	readonly?: boolean | undefined;
	isUrl?: boolean | undefined;
	maxLength?: number | undefined;
}

export const InfoTxtComp = ({ title, value, onChange, required, readonly, isUrl, maxLength }: InfoTxtCompProps) => (
	<StretchBox>
		<InfoTitleBox className={`${required ? "must" : ""}`}>{title}</InfoTitleBox>
		<InfoTextBox className={isUrl ? "url" : ""}>
			<TextInputInBBox value={value} onChange={onChange} readOnly={readonly} maxLength={maxLength || 1000} />
		</InfoTextBox>
	</StretchBox>
)

export const ResetLink = styled(Link)({
	textDecoration: 'none',
	fontFamily: "Pretendard",
	fontStyle: "normal",
	fontSize: '14px',
	fontWeight: 600,
	lineHeight: '16px',
	borderRadius: '6px',
	padding: '8px 12px',
})


export const OutLinedLinkBtn = styled(ResetLink)({
	border: '1px solid #6610F2',
	color: COLORS.MAIN,
})

export const ContainedLinkBtn = styled(ResetLink)({
	backgroundColor: COLORS.MAIN,
	color: 'white',
})

export const InfoURLComp = ({ title, url, readOnly }: { title: string, url: string, readOnly?: boolean | undefined }) => (
	<StretchBox>
		<InfoTitleBox>{title}</InfoTitleBox>
		<InfoTextBox style={{ justifyContent: 'space-between', paddingRight: '12px' }}>
			<TextInputInBBox style={{
				width: '80%',
				textDecoration: 'underline',
				color: '#ADB5BD'
			}} defaultValue={url} readOnly={readOnly} />
			<OutLinedLinkBtn href={url} target="_new" rel="noopener noreferrer">이동</OutLinedLinkBtn>
		</InfoTextBox>
	</StretchBox>
)
export const InfoImageComp = ({
	title, imageList, setImgModal
}: {
	title: string, imageList: images[], setImgModal: React.Dispatch<React.SetStateAction<string>>
}
) => (
	<StretchBox>
		<InfoTitleBox>{title}</InfoTitleBox>
		<InfoTextBox style={{ paddingRight: '12px', overflow: 'auto', whiteSpace: 'nowrap' }}>
			{imageList.map((item, idx) => (
				<Box
					key={`detail_img_${idx}`}
					minWidth={120}
					width={'120px'}
					height={'120px'}
					mr="12px"
					style={{ cursor: 'pointer' }}
					onClick={() => { setImgModal(item.imgUrl) }}
				>
					<ImageBox>
						<img src={item.imgUrl} alt={`image_${item.seq}`} />
					</ImageBox>
				</Box>
			))}
		</InfoTextBox>
	</StretchBox>
)

export const UploadImageBox = ({ item, idx, setImgModal, removeImage }) => (
	<Box
		border={'1px solid #b3b3b3'}
		minWidth={120}
		width={'120px'}
		height={'120px'}
		ml={idx % 5 === 0 ? "0" : "45px"}
		mb="45px"
		style={{ cursor: 'pointer' }}
		onClick={() => { setImgModal(item) }}
		position={'relative'}
	>
		<Button sx={{ position: 'absolute', right: '2px', top: '2px', minWidth: '5px', width: '5px', height: '12px', padding: '0 5px', borderRadius: '10px', zIndex: 10, border: '1px solid black', fontSize: '10px', color: 'black' }} onClick={(e) => { e.stopPropagation(); removeImage() }}>
			X
		</Button>
		<ImageBox>
			<img src={item} alt={`image_preview_${idx}`} />
		</ImageBox>
	</Box>
)

export const ReportBtnBoxes = ({ reportInfo, detailInfo, setDeleteModal, commentReportHandler }) => {
	return (
		<FEBox>
			{reportInfo !== undefined && detailInfo.openYn === "Y" && <SecondaryButton
				onClick={() => { if (reportInfo.reportResult !== "처리완료_유지") commentReportHandler("keep"); }}
				style={{ padding: '4px 12px', marginRight: '16px', opacity: reportInfo.reportResult === "처리완료_유지" ? 0.6 : 1 }}
			>
				{reportInfo.reportResult === "처리완료_유지" ? "유지된 게시물" : "유지"}
			</SecondaryButton>}
			{detailInfo.openYn === "Y" ? <MainButton onClick={() => { setDeleteModal(detailInfo) }}>삭제</MainButton> :
				<MainButton onClick={() => { }} sx={{ opacity: 0.6 }}>
					삭제된 게시물
				</MainButton>}
		</FEBox>
	)
}


const RadioLabelStyle = styled('label')({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	">input": {
		display: 'none'
	},
	">.custom": {
		width: '16px',
		height: '16px',
		borderRadius: '50%',
		border: '1px solid #ABB5BE',
		marginRight: '10px',
		position: 'relative',
	},
	">.custom:before": {
		content: `""`,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		backgroundColor: '#fff',
		opacity: 0,
		transition: 'opacity 0.3s ease',
	},
	">.active": {
		backgroundColor: COLORS.MAIN,
	},
	">.active:before": {
		opacity: 1
	}
})

export const LabelRadio = ({ name, checkedCondition, onChange, value, desc }) => {
	return (
		<RadioLabelStyle>
			<input type="radio" value={value} name={name} checked={checkedCondition} onChange={onChange} />
			<div className={checkedCondition ? "custom active" : "custom"} />
			<Typography variant="body1">{desc}</Typography>
		</RadioLabelStyle>
	)
}

export const CheckLabelStyle = styled('label')({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	">input": {
		display: 'none'
	},
	">.custom": {
		width: '16px',
		height: '16px',
		borderRadius: '4px',
		border: '1px solid #ABB5BE',
		marginRight: '10px',
		position: 'relative',
	},
	">.active": {
		backgroundColor: COLORS.MAIN,
	},
	">.active:before": {
		opacity: 1
	},
})


export const textCutStyle = {
	width: '500px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	justifyContent: 'flex-start'
}
export const FullSizeFixedBox = styled(Box)({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	zIndex: 1000,
	backgroundColor: 'rgba(0,0,0,0.5)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'white',
	textAlign: 'center',
	fontSize: '64px',
	fontWeight: 'bold'
})
export const MainBox = styled(Box)({
	padding: '38px 34px',
	paddingBottom: '120px'
})
export const BottomRightBox = styled(FEBox)({
	marginTop: '112px'
})

export const DetailHeader = ({ title }) => (
	<DCBox mb={'29px'}>
		<BackBtn />
		<PageTitle>{title}</PageTitle>
	</DCBox>
)