import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { CreateArtistBasicInfo, CreateMusicPlatformInfo, CreateYoutubeInfo } from "../Utils/types";
import { getAxiosProcess, postAxiosProcess, putAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 유저관리 - 유저 리스트 불러오기
export const api__getUserList = async (page: number, limit: number, keyword: string | undefined, queryKey: string | undefined) => {
  try {
    const url = `${rootUrl}/api/users/v2?page=${page}&limit=${limit}${keyword ? `&keyword=${keyword}` : ""}${queryKey ? `&queryKey=${queryKey}` : ""}`;
    return await getAxiosProcess(url);
  } catch (error) {
    console.log("getUserList error ::: " + error);
    return null;
  }
};
export const api__downExcel = async () => {
  try {
    const url = `${rootUrl}/api/users/excel/direct-send`;
    return await getAxiosProcess(url);
  } catch (error) {
    console.log("downExcel error ::: " + error);
    return null;
  }
};

// 유저관리 - 유저 상세 불러오기
export const api__getUserDetail = async (uid: string) => {
  try {
    const url = `${rootUrl}/api/users/${uid}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("api__getUserDetail error ::: " + error);
    return null;
  }
};

// 유저관리 - 유저 닉네임 변경하기
export const api__putNickname = async (uid: string, nickName: string, profileId: string) => {
  try {
    const url = `${rootUrl}/api/users/nick-name`;
    return putAxiosProcess(url, { uid, nickName, profileId });
  } catch (error) {
    console.log("api__putNickname error ::: " + error);
    return null;
  }
};

export const api__putExpiredDate = async (uid: string, endDt: string, membershipId: string) => {
  try {
    const url = `${rootUrl}/api/users/${uid}/membership/expiration`;
    return putAxiosProcess(url, { endDt, membershipId });
  } catch (error) {
    console.log("api__putExpiredDate error ::: " + error);
    return null;
  }
};

// 유저관리 - 유저 삭제하기
export const api__delUser = async (uid: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/users/${uid}`, makeHeader());
  } catch (error) {
    console.log("api__deleteUser error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 리스트 불러오기
export const api__getArtistList = async (page: string, limit: string, searchParams?: string) => {
  try {
    const url = `${rootUrl}/api/artists?page=${page}&limit=${limit}${searchParams ? `&${searchParams}` : ""}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getArtistList error ::: " + error);
    return null;
  }
};
export const api__getArtistListV2 = async (page: string, limit: string, searchParams?: string, keyword?: string) => {
  try {
    const url = `${rootUrl}/api/artists/v2?page=${page}&limit=${limit}${searchParams ? `&${searchParams}` : ""}${keyword ? `&keyword=${keyword}` : ""}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getArtistList error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 상세 정보 불러오기
export const api__getArtistInfo = async (artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getArtistInfo error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 생성
export const api__createArtist = async (params: {
  artistBasicInfo: CreateArtistBasicInfo;
  // artistMemberAccountList: CreateArtistMemberAccountInfo[];
  // artistManagerAccount: CreateArtistManagerAccountInfo | null;
  musicPlatformList: CreateMusicPlatformInfo[];
  youtubeList: CreateYoutubeInfo[];
}) => {
  try {
    return await axios.post(`${rootUrl}/api/artists`, params, makeHeader());
  } catch (error) {
    console.log("api__createArtist error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 생성
export const api__updateArtist = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}`, params, makeHeader());
  } catch (error) {
    console.log("api__updateArtist error ::: " + error);
    return null;
  }
};

export const api__getMemberData = async (uid: string) => {
  try {
    return await axios.get(`${rootUrl}/api/artists/account/detail`, {
      ...makeHeader(),
      params: { uid },
    });
  } catch (error) {
    console.log("api__getMemberData error ::: " + error);
    return null;
  }
};
export const api__putMemberData = async (params: any) => {
  try {
    const url = `${rootUrl}/api/artists/account`;
    return await putAxiosProcess(url, params);
  } catch (error) {
    console.log("api_putMemberData error ::: " + error);
    return null;
  }
};
export const api__deleteMemberData = async (uid: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/artists/account`, {
      ...makeHeader(),
      params: { uid },
    });
  } catch (error) {
    console.log("api__deleteMemberData error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 활성화 업데이트
export const api__updateArtistActivation = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/activation`, params, makeHeader());
  } catch (error) {
    console.log("api__updateArtistActivation error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 기본 정보 업데이트
export const api__updateArtistBasicInfo = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/basic-info`, params, makeHeader());
  } catch (error) {
    console.log("api__updateArtistBasicInfo error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 멤버 정보 가져오기
export const api__getArtistMembersInfo = async (artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/member-account/list`;
    return await getAxiosProcess(url);
  } catch (error) {
    console.log("api__getArtistMembersInfo error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 계정 생성
export const api__postArtistMemberInfo = async (params: any, artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/member-account`;
    return await postAxiosProcess(url, params);
  } catch (error) {
    console.log("api__postArtistMemberInfo error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 멤버 정보 업데이트
export const api__updateArtistMembersInfo = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/member-account`, params, makeHeader());
  } catch (error) {
    console.log("api__updateArtistMembersInfo error ::: " + error);
    return null;
  }
};
export const api__getManagerInfo = async (artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/manager-account/list`;
    return await getAxiosProcess(url);
  } catch (error: unknown) {
    console.log("api__getManagerInfo error ::: " + error);
    return null;
  }
};
// 유저관리 - 매니저 계정 생성
export const api__postManagerInfo = async (params: any, artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/manager-account`;
    return await postAxiosProcess(url, params);
  } catch (error) {
    console.log("api__postManagerInfo error ::: " + error);
    return null;
  }
};

// 유저관리 - 매니저 정보 업데이트
export const api__updateArtistManagerInfo = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/manager-account`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__updateArtistManagerInfo error ::: " + error);
    return null;
  }
};
// 유저관리 - 뮤직플랫폼 정보 업데이트
export const api__updateMusicPlatformInfo = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/music-platform`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__updateMusicPlatformInfo error ::: " + error);
    return null;
  }
};

// 유저관리 - 유튜브 정보 업데이트
export const api__updateYotubueInfo = async (params: any, artistId: string) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/youtube`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__updateYotubueInfo error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 멤버 이메일 중복체크
export const api__dupleMemberEmail = async (email: string) => {
  try {
    return await axios.get(`${rootUrl}/api/artists/email/duplicate?email=${email}`, makeHeader());
  } catch (error: unknown) {
    console.log("api__dupleMemberEmail error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 멤버 닉네임 중복체크
export const api__dupleMemberName = async (artistId: string, nickName: string) => {
  try {
    return await axios.get(`${rootUrl}/api/artists/nickname/duplicate?artistId=${artistId}&nickName=${nickName}`, makeHeader());
  } catch (error: unknown) {
    console.log("api__dupleMemberName error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 게시물 통계
export const api__getPostStatic = async (artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/post-static`;
    return await getAxiosProcess(url);
  } catch (error: unknown) {
    console.log("api__getPostStatic error ::: " + error);
    return null;
  }
};
// 유저관리 - 아티스트 멤버십 히스토리 조회
export const api__getMembershipHistory = async (artistId: string) => {
  try {
    const url = `${rootUrl}/api/artists/${artistId}/membership/history`;
    return await getAxiosProcess(url);
  } catch (error: unknown) {
    console.log("api__getMembershipHistory error ::: " + error);
    return null;
  }
};

export const api__postMembership = async (artistId: string, params: any) => {
  try {
    return await axios.post(`${rootUrl}/api/artists/${artistId}/membership`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__postMembership error ::: " + error);
    return null;
  }
};

// 유저관리 - 아티스트 멤버십 히스토리 조회
export const api__getMembershipDetail = async (artistId: string, productId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/artists/${artistId}/membership/detail`, {
      params: { productId },
      ...makeHeader(),
    });
  } catch (error: unknown) {
    console.log("api__getMembershipDetail error ::: " + error);
    return null;
  }
};

export const api__putMembershipInfos = async (artistId: string, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/membership`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__putMembershipInfos error ::: " + error);
    return null;
  }
};

export const api__putMembershipSaleStatus = async (artistId: string, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/artists/${artistId}/membership/sale-status`, params, makeHeader());
  } catch (error: unknown) {
    console.log("api__postMembershipSaleStatus error ::: " + error);
    return null;
  }
};

export const api__activeMember = async (artistId: string, params: any) => {
  const url = `${rootUrl}/api/artists/${artistId}/account/activation`;
  return await putAxiosProcess(url, params);
};

export const api__deactivateMembership = async (artistId: string) => {
  const url = `${rootUrl}/api/artists/${artistId}/membership/deactivate`;
  return await putAxiosProcess(url, {});
};

export const api_giveMembership = async (artistId: string, profileId: string, uid: string) => {
  const url = `${rootUrl}/api/users/membership?artistId=${artistId}&profileId=${profileId}&uid=${uid}`;
  return await postAxiosProcess(url, {});
};
export const api_cancelMembership = async (membershipId: string, uid: string) => {
  const url = `${rootUrl}/api/users/cancel/membership?membershipId=${membershipId}&uid=${uid}`;
  return await postAxiosProcess(url, {});
};
