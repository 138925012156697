// 예약 시간을 판단하는 함수(예약 일시가 없거나 현재 시각보다 이전이면 '즉시'로 표시)
const getBookedTimeDisplay = (bookedTime: Date | null) => {
    if (!bookedTime || bookedTime < new Date()) {
      return '즉시';
    }
    return bookedTime.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

export default getBookedTimeDisplay;