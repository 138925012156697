import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { getAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 배지 관리 - 배지 리스트 불러오기
export const api__getBadgeList = async () => {
  try {
    return await axios.get(`${rootUrl}/api/badges`, makeHeader());
  } catch (error) {
    console.log("getBadgeList error ::: " + error);
    return null;
  }
};

// 배지 관리 - 배지 스태틱 불러오기
export const api__getBadgeStatic = async () => {
  try {
    return await axios.get(`${rootUrl}/api/badges/static`, makeHeader());
  } catch (error) {
    console.log("getBadgeStatic error ::: " + error);
    return null;
  }
};

// 배지 관리 - 배지 상세 불러오기
export const api__getBadgeDetail = async (badgeId: string) => {
  try {
    const url = `${rootUrl}/api/badges/${badgeId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getBadgeDetail error ::: " + error);
    return null;
  }
};

// 배지 관리 - 배지 상세 불러오기
export const api__putBadgeDetail = async (badgeId: string, imgUrl: string, name: string) => {
  try {
    return await axios.put(`${rootUrl}/api/badges/${badgeId}`, { imgUrl, name }, makeHeader());
  } catch (error) {
    console.log("putBadgeDetail error ::: " + error);
    return null;
  }
};
