import { useEffect, useState } from 'react';
import { ArtistId, GoodduckMoreMagazineListInfo, MoreMagazineInfo } from '../../Utils/types';
import SimpleModal from '../common/SimpleModal';
import { CheckLabelStyle, DCBox, DetailButton, ImageDeleteBtn, ImageModalBox, MyTable, PageTitle, SelectBox, THS } from '../common/CommonElement';
import Pages from '../common/Pages';
import { api__getMagazinesTargetList } from '../../services/HomePostService';
import { addCommas, getArtistList } from '../../Utils/utils';
import { ERRORS } from '../../Constant/ERRORS';
import CommonSearch from '../common/CommonSearch';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { ReactSVG } from 'react-svg';
import ArtistSelectBox from '../common/ArtistSelectBox';
import { SVG_IMAGES } from '../../Constant/IMAGES';
import { codeHandler } from '../../services/CommonService';
import { debounce } from 'lodash';

type IProps = {
	setMagModal: React.Dispatch<React.SetStateAction<boolean>>;
	changeMoreList: Function;
	selectedMagazineList: MoreMagazineInfo[];
}

export default function MoreMagazineModal({ setMagModal, changeMoreList, selectedMagazineList }: IProps) {
	const [dataListInfo, setDataListInfo] = useState<GoodduckMoreMagazineListInfo | null>(null);
	const [changeInfo, setChangeInfo] = useState({
		page: 1,
		queryKey: '',
		keyword: ''
	});

	const [qk, setQk] = useState('ARTIST_ID');
	const [kw, setKw] = useState('');

	const [aName, setAName] = useState("아티스트 선택");
	const [isDropped, setIsDropped] = useState(false);
	const [artistList, setArtistList] = useState<ArtistId[]>([]);
	const [artistId, setArtistId] = useState("");

	const getMyData = debounce(() => {
		const { page, queryKey, keyword } = changeInfo;
		let addQuery = `?page=${page}`;
		if (queryKey !== "") addQuery += `&queryKey=${queryKey}`;
		if (keyword !== "") addQuery += `&keyword=${keyword}`;
		api__getMagazinesTargetList(addQuery).then((res: any) => {
			if (codeHandler(res)) {
				setDataListInfo(res.data);
			} else {
				throw new Error(res.msg);
			}
		}).catch((error) => {
			window.alert(ERRORS[error?.message] || error?.message || "오류가 발생하였습니다.\n다시 시도하여주세요.");
		})
	}, 300)

	useEffect(() => {
		getArtistList(setArtistList);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (qk !== "ARTIST_ID") setArtistId("");
	}, [qk])

	useEffect(() => {
		getMyData();
		// eslint-disable-next-line
	}, [changeInfo])

	const applySearch = () => {
		setChangeInfo({ page: 1, queryKey: qk, keyword: kw });
	}

	const changeSelect = (aVal: string, nameVal: string) => {
		setChangeInfo(prev => {
			const clone = { ...prev };
			const checkAll = aVal === "" || aVal === "ALL";
			clone.queryKey = checkAll ? "" : "ARTIST_ID";
			clone.keyword = checkAll ? "" : aVal;
			return clone;
		})
		setAName(nameVal);
		setArtistId(aVal);
		setIsDropped(false);
	}

	return (
		<SimpleModal>
			<ImageModalBox position='relative' p='34px' style={{ overflow: 'unset', width: '960px', minHeight: '720px', height: 'auto' }}>
				<ImageDeleteBtn
					style={{
						right: '12px',
						top: '12px',
						minWidth: '20px',
						width: '20px',
						height: '20px',
						borderRadius: '50%',
						backgroundColor: '#ff5656',
						border: 'none'
					}}
					onClick={(e) => {
						e.stopPropagation();
						setMagModal(false);
					}}>
				</ImageDeleteBtn>
				<PageTitle>매거진 개수 : {addCommas(dataListInfo?.totalCount || 0)}개</PageTitle>
				<DCBox m="24px 0">
					<SelectBox value={qk} onChange={(e) => { setQk(e.target.value) }}>
						<option value="ARTIST_ID">아티스트</option>
						<option value="TITLE">제목</option>
					</SelectBox>
					<Box mr='12px' />
					{qk === "ARTIST_ID" ? (
						<ArtistSelectBox
							aName={aName} artistId={artistId} isDropped={isDropped} artistList={artistList}
							changeSelect={changeSelect} setIsDropped={setIsDropped}
						/>
					) :
						<CommonSearch applySearch={applySearch} setText={setKw} text={kw} />
					}
					<DetailButton sx={{ ml: '12px' }} onClick={() => { setChangeInfo({ page: 1, queryKey: '', keyword: '' }) }}>초기화</DetailButton>
				</DCBox>
				<Box mt="18px">
					<MyTable>
						<THS
							ths={[
								{ name: "#", width: 40 },
								{ name: "아티스트", width: 120 },
								{ name: "매거진 제목", width: 240 },
								{ name: "체크", width: 80 },
							]}
						/>
						<TableBody>
							{dataListInfo?.data.map((item, midx) => {
								const isIncluded = !!(selectedMagazineList.find((mag) => (mag.magazineId === item.magazineId) && mag.flag !== "delete"))
								return <TableRow key={`moreMagazine-${midx}`}>
									<TableCell sx={{ textAlign: 'center' }}>{(changeInfo.page - 1) * 10 + midx + 1}</TableCell>
									<TableCell>{item.artistNameKo}</TableCell>
									<TableCell>{item.title}</TableCell>
									<TableCell>
										<CheckLabelStyle sx={{ display: 'flex', justifyContent: 'center' }}>
											<input type="checkbox" checked={isIncluded} onChange={() => { changeMoreList(item) }} />
											<div className={isIncluded ? "custom active" : "custom"} style={{ margin: 0 }}>
												<ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
											</div>
										</CheckLabelStyle>
									</TableCell>
								</TableRow>
							})}
						</TableBody>
					</MyTable>
					{dataListInfo && <Pages pageInfo={dataListInfo} getList={(p) => { setChangeInfo({ ...changeInfo, page: p }) }} />}
				</Box>
			</ImageModalBox>
		</SimpleModal>
	)
}