import { ArtistId } from "../../Utils/types";
import { OptionButton } from "./CommonElement"
import MySelectBox from "./MySelectBox"

type IProps = {
	aName: string;
	artistId: string;
	isDropped: boolean;
	setIsDropped: React.Dispatch<React.SetStateAction<boolean>>;
	changeSelect: Function;
	artistList: ArtistId[];
	notAll?: boolean | undefined;
	isMagazine?: boolean | undefined;
}

export default function ArtistSelectBox({ aName, artistId, isDropped, artistList, changeSelect, setIsDropped, notAll, isMagazine }: IProps) {
	return (
		<MySelectBox value={aName} width={332} isDefault={artistId === ""} isDropped={isDropped} setIsDropped={setIsDropped}>
			{!notAll && <OptionButton
				className={artistId === "ALL" ? "selected" : ""}
				onClick={() => { changeSelect("ALL", "전체") }}
			>전체</OptionButton>}
			{artistList.map((item, idx) => {
				if (isMagazine && item.artistUseYn === "N") return <></>;
				return (
					<OptionButton
						key={`al_${idx}`}
						onClick={() => { changeSelect(item.artistId, item.artistNameKo) }}
						className={artistId === item.artistId ? "selected" : ""}
					>{item.artistNameKo}{item.artistUseYn === "Y" ? "" : "(비활성화)"}
					</OptionButton>
				)
			})}
		</MySelectBox>
	)
}