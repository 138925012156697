import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import ModalMsgBox from '../common/ModalMsgBox';
import LimitBox from '../common/LimitBox';
import { DeleteButton, MainButton, ModalContentTypo, MyTable, PageTitle, SBCBox, THS } from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';

import { AdminAccountInfo, AdminAccountListInfo } from '../../Utils/types';
import { api__delAccount, api__getAccountList } from '../../services/AdminMemberService';
import { ERRORS } from '../../Constant/ERRORS';
import { addCommas, getListPL } from '../../Utils/utils';
import { codeHandler } from '../../services/CommonService';

type IProps = {
	setAddMember: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MemberList({ setAddMember }: IProps) {
	const navigate = useNavigate();
	const { page, limit } = useParams();
	const [dataListInfo, setDataListInfo] = useState<AdminAccountListInfo | null>(null);

	const [accountModal, setAccountModal] = useState<AdminAccountInfo | null>(null);

	const getMyList = () => {
		getListPL(api__getAccountList, setDataListInfo, page || "1", limit || "10");
	}

	const delAccount = (id: string) => {
		api__delAccount(id).then((res: any) => {
			if (codeHandler(res)) {
				getMyList();
			} else {
				throw new Error(res.msg);
			}
		}).catch((error) => {
			window.alert(ERRORS[error?.message] || error?.message || "오류가 발생하였습니다.\n다시 시도하여주세요.");
		});
	}

	useEffect(() => {
		getMyList();
		// eslint-disable-next-line
	}, [page, limit]);

	return (
		<>
			{accountModal && (
				<ModalMsgBox title="멤버 계정을 삭제하시겠어요?" setModal={(bool) => {
					setAccountModal(null);
					if (!bool) delAccount(accountModal.adminId);
				}} extraBtn={'삭제'}>
					<ModalContentTypo>
						ㆍID : {accountModal.adminId}
					</ModalContentTypo>
					<ModalContentTypo>
						ㆍ멤버명 : {accountModal.adminName}
					</ModalContentTypo>
				</ModalMsgBox>
			)}
			<SBCBox mb="29px">
				<PageTitle>멤버 관리 / {addCommas(dataListInfo?.totalCount || 0)}개</PageTitle>
				<MainButton onClick={() => { setAddMember(true) }}>멤버 추가</MainButton>
			</SBCBox>
			<LimitBox limit={limit || "10"} page={page || "1"} route={'/master'} />
			<Box mt="18px">
				<MyTable>
					<THS
						ths={[
							{ name: "#", width: 40 },
							{ name: "닉네임", width: 120 },
							{ name: "아이디", width: 120 },
							{ name: "이메일", width: 200 },
							{ name: "인증", width: 60 },
							{ name: "최종 접속 일시", width: 200 },
							{ name: "관리", width: 100 },
						]}
					/>
					<TableBody>
						{dataListInfo && dataListInfo.data.map((item, uidx) => (
							<TableRow key={`admin-${uidx}`}>
								<TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
								<TableCell>{item.adminName} </TableCell>
								<TableCell>{item.adminId}</TableCell>
								<TableCell>{item.adminEmail}</TableCell>
								<TableCell>{item.allowYn}</TableCell>
								<TableCell>{item.lastLoginTime}</TableCell>
								<TableCell>
									<DeleteButton onClick={() => { setAccountModal(item) }}>삭제</DeleteButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</MyTable>
				{dataListInfo && <Pages pageInfo={dataListInfo} getList={(p) => { navigate(`/master/${String(p)}/${limit}`) }} />}
			</Box>
		</>
	)
}