import { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router";
import { Box } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { api__delTipOffSchedule, api__getTipOffScheduleDetail } from '../../services/CalendarService'; 

import { delData, getInfo, updateReportStatus } from '../../Utils/utils';
import { BackBtn, DCBox, InfoComp, InfoTextBox, InfoTitleBox, PageTitle, ReportBtnBoxes, SBCBox, SimepleTextArea, StretchBox } from '../../Components/common/CommonElement';
import { ReportType, TipOffDetailInfo } from '../../Utils/types';
import moment from 'moment';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import DetailArtistName from '../../Components/common/DetailArtistName';


export default function TipOffScheduleDetail() {
	const { state } = useLocation();
	const [detailInfo, setDetailInfo] = useState<TipOffDetailInfo>({
		artistNameKo: "",
		checkYn: "N",
		content: "",
		rgstDt: "",
		rgstYn: "N",
		scheduleDt: "",
		scheduleTime: "",
		tipOffScheduleId: "",
		title: "",
		uid: "",
		uploaderNickname: "",
		artistUseYn: "Y",
		openYn: "Y"
	});
	const [reportInfo, setReportInfo] = useState<ReportType | undefined>(state?.reportInfo);

	const { tipOffScheduleId } = useParams();
	const [deleteModal, setDeleteModal] = useState<TipOffDetailInfo | null>(null);

	const getMyInfo = () => {
		getInfo(api__getTipOffScheduleDetail, tipOffScheduleId, setDetailInfo);
	}

	const delMyData = (id: string) => {
		delData(id, api__delTipOffSchedule, getMyInfo);
	}

	const checkDelete = (result: string | null, openYn: string) => {
		if (result !== "처리완료_삭제" && openYn === "N") {
			updateReportStatus({ result: "처리완료_삭제", seq: reportInfo?.seq }, () => { });
		}
	}
	useEffect(() => {
		if (reportInfo !== undefined && detailInfo.tipOffScheduleId !== "") {
			checkDelete(reportInfo.reportResult, detailInfo.openYn);
		}
		// eslint-disable-next-line
	}, [reportInfo, detailInfo.tipOffScheduleId])

	useEffect(() => {
		getMyInfo();
		// eslint-disable-next-line
	}, [tipOffScheduleId]);

	const commentReportHandler = async (type: string) => {
		if (reportInfo === undefined) return;
		const { seq } = reportInfo;
		if (type === "keep") {
			updateReportStatus({ result: "처리완료_유지", seq }, () => { });
		} else {
			delMyData(detailInfo.tipOffScheduleId);
			updateReportStatus({ result: "처리완료_삭제", seq }, () => { });
		}
		setReportInfo(prev => {
			if (prev === undefined) return prev;
			const clone = { ...prev };
			clone.reportResult = type === "keep" ? "처리완료_유지" : "처리완료_삭제";
			return clone;
		})
	}
	return (
		<>
			{deleteModal && (
				<ModalMsgBox title="게시물을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(null);
					if (!bool) commentReportHandler("delete");
				}} extraBtn={'삭제'}>
				</ModalMsgBox>
			)}
			<MainLayout title="캘린더 관리 / 일정 게시물">
				<Box p="38px 34px">
					<SBCBox mb="29px">
						<DCBox>
							<BackBtn />
							<PageTitle>일정 제보 상세</PageTitle>
						</DCBox>
					</SBCBox>
					<Box mb="40px">
						<DetailArtistName artistNameKo={detailInfo.artistNameKo} artistUseYn={detailInfo.artistUseYn} />
						<InfoComp title='유저 닉네임' value={detailInfo.uid === "" ? "someone" : detailInfo.uploaderNickname} />
						<InfoComp title='제목' value={detailInfo.title} />
						<InfoComp title='일정'
							value={moment(`${detailInfo.scheduleDt} ${detailInfo.scheduleTime}`, "YYYYMMDDHHmmss").format("YYYY. MM. DD A HH:mm")}
						/>
						<InfoComp title='제보일시' value={moment(detailInfo.rgstDt).format('YYYY. MM. DD A hh:mm')} />
						<InfoComp title='아티스트 확인 여부' value={detailInfo.checkYn} />
						<InfoComp title='일정 업로드 여부' value={detailInfo.rgstYn} />
						<StretchBox>
							<InfoTitleBox>내용</InfoTitleBox>
							<InfoTextBox>
								<SimepleTextArea sx={{ minHeight: '316px' }} readOnly value={detailInfo.content} />
							</InfoTextBox>
						</StretchBox>
					</Box>
					<ReportBtnBoxes
						reportInfo={reportInfo}
						detailInfo={detailInfo}
						setDeleteModal={setDeleteModal}
						commentReportHandler={commentReportHandler}
					/>
				</Box>
			</MainLayout>
		</>
	);
}
