import { Typography } from "@mui/material"
import { CheckLabelStyle, DCBox, InfoTextBox, InfoTitleBox, StretchBox, TextInputInBBox } from "../common/CommonElement"
import { ReactSVG } from "react-svg"
import { SVG_IMAGES } from "../../Constant/IMAGES"
import { useContext } from "react"
import { ArtistDetailContext } from "../../pages/manage/ArtistDetail"

export default function ArtistDetailPlatformInfo() {
  const { mpEdit, setMpEdit, platformInfo, setPlatformInfo, editMode } = useContext(ArtistDetailContext);
  // 뮤직 플랫폼 함수
  const mpIo = (bool: boolean, idx: number) => {
    if (!editMode) return;
    if (!mpEdit) setMpEdit(true);
    setPlatformInfo(prev => {
      const clone = [...prev];
      clone[idx].checked = bool ? "Y" : "N";
      return clone;
    });
  }
  const changeMusicPlatformInfo = (value: string | boolean, column: string, idx: number) => {
    if (!editMode) return;
    if (!mpEdit) setMpEdit(true);
    setPlatformInfo(prev => {
      const clone = [...prev];
      clone[idx][column] = value;
      return clone;
    });
  }

  return (
    <StretchBox>
      <InfoTitleBox>음원 사이트 URL</InfoTitleBox>
      <InfoTextBox flexDirection={'column'} style={{ alignItems: 'flex-start' }}>
        {platformInfo.map((item, idx) => (
          <DCBox width='100%' mb="8px" key={`mp_${idx}`}>
            <CheckLabelStyle>
              <input type="checkbox" checked={item.checked === "Y" ? true : false}
                onChange={(e) => {
                  if (!editMode) return;
                  mpIo(e.target.checked, idx)
                }} />
              <div className={item.checked === "Y" ? "custom active" : "custom"}>
                <ReactSVG src={SVG_IMAGES.CHECK} style={{ transform: 'translate(1px, -3px)' }} />
              </div>
              <Typography ml="8px" width='120px' variant="h5">{item.siteName}</Typography>
            </CheckLabelStyle>
            <TextInputInBBox value={item.siteUrl || ""} readOnly={item.checked === "N"} className={item.checked === "N" ? "disabled" : ""}
              onChange={(e) => { changeMusicPlatformInfo(e.target.value, "siteUrl", idx) }}
            />
          </DCBox>
        ))}
      </InfoTextBox>
    </StretchBox>
  )
}