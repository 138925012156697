import { useContext, useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow, styled } from "@mui/material"
import { ImageBox, MainButton, MyTable, PageTitle, SBCBox, THS } from "../common/CommonElement"
import { ArtistMemberAccountInfo } from "../../Utils/types"
import { api__getArtistMembersInfo, api__getManagerInfo } from '../../services/ManageUserService';
import { debounce } from 'lodash';
import { ArtistDetailContext } from '../../pages/manage/ArtistDetail';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../Utils/routes';
import { codeHandler } from '../../services/CommonService';

export default function ArtistDetailAccountsInfo() {
  const navigate = useNavigate();
  const { artistId } = useContext(ArtistDetailContext);
  const [membersList, setMembersList] = useState<ArtistMemberAccountInfo[]>([]);
  const [managerList, setManagerList] = useState<ArtistMemberAccountInfo[]>([]);

  const getData = debounce(() => {
    api__getArtistMembersInfo(artistId || '').then((res) => {
      if (codeHandler(res)) {
        setMembersList(res.data);
      }
    }).catch(console.error);
    api__getManagerInfo(artistId || '').then((res) => {
      if (codeHandler(res)) {
        setManagerList(res.data);
      }
    }).catch(console.error);
  }, 300);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <Box className="accounts">
      <SBCBox mb="29px">
        <PageTitle>아티스트 계정</PageTitle>
        <MainButton onClick={() => {
          navigate(`${ROUTES.SIGNUPARTIST}/${artistId}/member-new`, {
            state: {
              isManager: false
            }
          })
        }}>계정 생성</MainButton>
      </SBCBox>
      <Box mt="18px">
        <MyTable>
          <THS
            ths={[
              { name: "계정 이미지", width: 80 },
              { name: "이메일", width: 200 },
              { name: "계정 닉네임", width: 120 },
              { name: "활동명(본명)", width: 120 },
              { name: "관리", width: 100 },
            ]}
          />
          <TableBody>
            {membersList.map((item, uidx) => (
              <ThisRow key={`artist-${uidx}`} className={item.useYn === 'Y' ? '' : 'inactive'} >
                <TableCell>
                  <Box width={80} height={80} mx="auto">
                    <ImageBox>
                      <img src={item.profileImgUrl} alt="profile" />
                    </ImageBox>
                  </Box>
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.nickName}</TableCell>
                <TableCell>{item.fullName}</TableCell>
                <TableCell>
                  <MainButton sx={{ padding: '4px 8px' }} onClick={() => {
                    navigate(`${ROUTES.SIGNUPARTIST}/${artistId}/detail/${item.uid}`, {
                      state: {
                        isManager: false
                      }
                    })
                  }}>관리</MainButton>
                </TableCell>
              </ThisRow>
            ))}
          </TableBody>
        </MyTable>
      </Box>
      <SBCBox mb="29px" mt="38px">
        <PageTitle>관리자 계정</PageTitle>
        <MainButton onClick={() => {
          navigate(`${ROUTES.SIGNUPARTIST}/${artistId}/member-new`, {
            state: {
              isManager: true
            }
          })
        }}>계정 생성</MainButton>
      </SBCBox>
      <Box mt="18px">
        <MyTable>
          <THS
            ths={[
              { name: "계정 이미지", width: 80 },
              { name: "이메일", width: 200 },
              { name: "계정 닉네임", width: 120 },
              { name: "관리자명", width: 120 },
              { name: "관리", width: 100 },
            ]}
          />
          <TableBody>
            {managerList.map((item, uidx) => (
              <ThisRow key={`artist-${uidx}`} className={item.useYn === 'Y' ? '' : 'inactive'} >
                <TableCell>
                  <Box width={80} height={80} mx="auto">
                    <ImageBox>
                      <img src={item.profileImgUrl} alt="profile" />
                    </ImageBox>
                  </Box>
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.nickName}</TableCell>
                <TableCell>{item.fullName}</TableCell>
                <TableCell>
                  <MainButton sx={{ padding: '4px 8px' }} onClick={() => {
                    navigate(`${ROUTES.SIGNUPARTIST}/${artistId}/detail/${item.uid}`, {
                      state: {
                        isManager: true
                      }
                    })
                  }}>관리</MainButton>
                </TableCell>
              </ThisRow>
            ))}
          </TableBody>
        </MyTable>
      </Box>
    </Box>
  )
}

const ThisRow = styled(TableRow)({
  '& td': {
    textAlign: 'center'
  },
  '&.inactive': {
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
})