import { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import MainLayout from '../../layout/MainLayout';
import { BackBtn, DCBox, FlexBox, InfoComp, MainButton, PageTitle } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';

import { api__getLatestVersion, api__postTerms } from '../../services/TermsService';
import { logoutWithOutNavigate } from '../../Utils/utils';

export default function PersonalTermsCreate() {
  const [content, setContent] = useState<string>('');
  const [version, setVersion] = useState('');

  const [createModal, setCreateModal] = useState(false);

  const isGetInfoProgress = useRef(false);

  const getMyInfo = async () => {
    if (isGetInfoProgress.current) return;
    isGetInfoProgress.current = true;
    try {
      const result = await api__getLatestVersion('PRIVACY_POLICY');
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const trans = Number(result.data.data);
      setVersion(String((trans + 0.1).toFixed(1)));
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetInfoProgress.current = false;
    }
  };

  const postServiceTerms = async () => {
    try {
      const result = await api__postTerms(content, 'PRIVACY_POLICY', version);
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.history.back();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {createModal && (
        <ModalMsgBox
          title="게시물을 생성하시겠어요?"
          setModal={bool => {
            setCreateModal(false);
            if (!bool) {
              postServiceTerms();
            }
          }}
          extraBtn={'생성'}
        ></ModalMsgBox>
      )}

      <MainLayout title="약관 및 추천URL 관리 / 개인정보처리방침">
        <Box p="38px 34px" pb="120px">
          <DCBox mb="34px">
            <BackBtn />
            <PageTitle>개인정보처리방침 생성</PageTitle>
          </DCBox>
          <Box mb="82px">
            <InfoComp title="버전" value={'V ' + version} />
          </Box>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              setContent(editor.getData());
            }}
          />
          <FlexBox justifyContent={'flex-end'} mt="18px">
            <MainButton
              onClick={() => {
                if (content.trim() === '') {
                  window.alert('콘텐츠 내용을 채워주세요.');
                  return;
                }
                setCreateModal(true);
              }}
              sx={{ padding: '4px 12px' }}
            >
              생성
            </MainButton>
          </FlexBox>
        </Box>
      </MainLayout>
    </>
  );
}
