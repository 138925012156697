import { useEffect, useRef, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';

import MainLayout from '../../layout/MainLayout';
import {
  DCBox,
  DeleteButton,
  DetailButton,
  ModalContentTypo,
  MyTable,
  OutLinedLinkBtn,
  PageTitle,
  SBCBox,
  SelectBox,
  THS,
} from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';
import LimitBox from '../../Components/common/LimitBox';
import ArtistSelectBox from '../../Components/common/ArtistSelectBox';
import CommonSearch from '../../Components/common/CommonSearch';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { handleError } from '../../Utils/utils';

import { api__delUser, api__downExcel, api__getUserList } from '../../services/ManageUserService';
import { ArtistId, UserInfo, UserListInfo } from '../../Utils/types';
import { addCommas, delData, getArtistList, logoutWithOutNavigate } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { debounce } from 'lodash';

export default function ManageUser() {
  const navigate = useNavigate();
  const { page, limit, keyword, queryKey } = useParams();
  const [dataListInfo, setDataListInfo] = useState<UserListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<UserInfo | null>(null);
  const [qk, setQk] = useState(queryKey || 'EMAIL');
  const [kw, setKw] = useState(keyword || '');

  const [aName, setAName] = useState('아티스트 선택');
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);
  const [artistId, setArtistId] = useState('');
  const preventRef = useRef(false);

  const getMyList = debounce(async () => {
    try {
      const result = await api__getUserList(Number(page) || 1, Number(limit) || 10, keyword, queryKey);
      if (result === null) return;

      const { code, msg, data } = result;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setDataListInfo(data);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit, keyword, queryKey]);

  useEffect(() => {
    getArtistList(setArtistList);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (artistList.length !== 0 && queryKey === 'ARTIST_ID' && keyword) {
      setAName(artistList.find(item => item.artistId === keyword)?.artistNameKo || '아티스트 선택');
      setArtistId(keyword);
    }
    // eslint-disable-next-line
  }, [artistList]);
  const execlDown = debounce(() => {
    if (preventRef.current) return;
    preventRef.current = true;
    api__downExcel()
      .then(res => {
        if (res === null) return;
        if (res.code === 'S200') {
          const a = document.createElement('a');
          a.href = res.data;
          a.click();
        }
      })
      .catch(console.error)
      .finally(() => {
        preventRef.current = false;
      });
  }, 300);

  const changeSelect = (aVal: string, nameVal: string) => {
    navigate(ROUTES.SIGNUPUSERS + `/1/${limit}${aVal !== '' && aVal !== 'ALL' ? `/ARTIST_ID/${aVal}` : ''}`);
    setAName(nameVal);
    setArtistId(aVal);
    setIsDropped(false);
  };
  const applySearch = () => {
    navigate(ROUTES.SIGNUPUSERS + `/1/${limit}${kw !== '' ? `/${qk}/${kw}` : ''}`);
  };
  const delMyData = async (id: string) => {
    delData(id, api__delUser, getMyList);
  };
  useEffect(() => {
    if (qk !== 'ARTIST_ID') setArtistId('');
  }, [qk]);

  return (
    <>
      {deleteModal && (
        <ModalMsgBox
          title="계정을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(deleteModal.uid);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ이메일 ID : {deleteModal.email}</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="유저관리 / 가입유저">
        <Box p="30px">
          <SBCBox m="20px 0 40px 10px">
            <PageTitle>
              가입유저 : {addCommas(dataListInfo?.totalCount || 0)}명 / 멀티프로필 :{' '}
              {addCommas(dataListInfo?.additionalData.totalProfileCnt || 0)}개
            </PageTitle>
            <LimitBox
              limit={limit || '10'}
              page={page || '1'}
              route={ROUTES.SIGNUPUSERS}
              subUrl={queryKey !== undefined && keyword !== undefined ? `/${queryKey}/${keyword}` : ''}
            />
          </SBCBox>
          <DCBox>
            <SelectBox
              value={qk}
              onChange={e => {
                setQk(e.target.value);
              }}
            >
              <option value="EMAIL">이메일</option>
              <option value="NICKNAME">닉네임</option>
              <option value="ARTIST_ID">아티스트</option>
            </SelectBox>
            <Box mr="20px" />
            {qk === 'ARTIST_ID' ? (
              <ArtistSelectBox
                aName={aName}
                artistId={artistId}
                isDropped={isDropped}
                artistList={artistList}
                changeSelect={changeSelect}
                setIsDropped={setIsDropped}
              />
            ) : (
              <>
                <CommonSearch applySearch={applySearch} setText={setKw} text={kw} />
                <DetailButton
                  sx={{ ml: '12px' }}
                  onClick={() => {
                    navigate(ROUTES.SIGNUPUSERS + `/1/${limit}`);
                  }}
                >
                  초기화
                </DetailButton>
              </>
            )}
            <OutLinedLinkBtn sx={{ ml: 'auto', fontWeight: 700, cursor: 'pointer' }} onClick={execlDown}>
              엑셀 다운로드(전체유저 이메일)
            </OutLinedLinkBtn>
          </DCBox>
          <Box mt="18px">
            <MyTable>
              <THS
                ths={[
                  { name: '#', width: 40 },
                  { name: '이메일ID', width: 240 },
                  { name: '회원가입일', width: 100 },
                  { name: '입덕아티스트', width: 100 },
                  { name: '닉네임', width: 120 },
                  { name: '입덕일자', width: 120 },
                  { name: '입덕한 아티스트 수', width: 80 },
                  { name: '멤버십 가입 여부', width: 60 },
                  { name: '관리', width: 80 },
                ]}
              />
              <TableBody>
                {dataListInfo?.data.map((item, uidx) => (
                  <TableRow key={`user-${uidx}`}>
                    <TableCell sx={{ textAlign: 'center' }}>{(Number(page) - 1) * Number(limit) + uidx + 1}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item.userRgstDt
                        .split('\n')
                        .slice(0, 3)
                        .map((c, cIdx) => (
                          <Box key={`userRgstDt_${uidx}_${cIdx}`}>{moment(c).format('YY. MM. DD')}</Box>
                        ))}{' '}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item.artistNameKo === null
                        ? '-'
                        : item.artistNameKo
                            .split('\n')
                            .slice(0, 3)
                            .map((c, cIdx) => <Box key={`artistNameKo_${uidx}_${cIdx}`}>{c}</Box>)}{' '}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item.userNickname === null
                        ? '-'
                        : item.userNickname
                            .split('\n')
                            .slice(0, 3)
                            .map((c, cIdx) => <Box key={`userNickname_${uidx}_${cIdx}`}>{c}</Box>)}{' '}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item.duckRgstDt === null
                        ? '-'
                        : item.duckRgstDt
                            .split('\n')
                            .slice(0, 3)
                            .map((c, cIdx) => (
                              <Box key={`duckRgstDt_${uidx}_${cIdx}`}>{moment(c).format('YY. MM. DD')}</Box>
                            ))}{' '}
                    </TableCell>
                    <TableCell>{item.duckCnt}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {item.isMembership === null
                        ? '-'
                        : item.isMembership
                            .split('\n')
                            .slice(0, 3)
                            .map((c, cIdx) => <Box key={`isMembership_${uidx}_${cIdx}`}>{c}</Box>)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <DetailButton
                        onClick={() => {
                          navigate(ROUTES.SIGNUPUSERS + '/' + item.uid);
                        }}
                      >
                        상세
                      </DetailButton>
                      <DeleteButton
                        onClick={() => {
                          setDeleteModal(item);
                        }}
                      >
                        삭제
                      </DeleteButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MyTable>
            {dataListInfo && (
              <Pages
                pageInfo={dataListInfo}
                getList={p => {
                  navigate(
                    ROUTES.SIGNUPUSERS +
                      `/${String(p)}/${limit}${keyword !== undefined && queryKey !== undefined ? `/${queryKey}/${keyword}` : ''}`,
                  );
                }}
              />
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
