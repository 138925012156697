import { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { BackBtn, DCBox, DarkContainedButton, DeleteButton, InfoComp, InfoTextBox, InfoTitleBox, InfoTxtComp, MainButton, ModalContentTypo, PageTitle, SBCBox, StretchBox, TextInputInBBox } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { logoutWithOutNavigate, validateEmail } from '../../Utils/utils';
import _, { debounce } from 'lodash';
import { api__deleteAgencyUserData, api__emailDupleCheck, api__getAgencyUserData, api__getArtistListInAgency, api__putAgencyUserData } from '../../services/AgencieServices';
import { Icon } from '@iconify/react';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { AgencyArtistType, AgencyUserDetailType } from '../../Utils/types';
import { useParams } from 'react-router';


export default function PartnersAccountMemberDetail() {
	const { agencyId, uid } = useParams();
	const [userData, setUserData] = useState<AgencyUserDetailType | null>(null);
	const [saving, setSaving] = useState<AgencyUserDetailType | null>(null);
	const [checkEmail, setCheckEmail] = useState(true);

	const [artistList, setArtistList] = useState<AgencyArtistType[]>([]);
	const [artistModal, setArtistModal] = useState<AgencyArtistType[]>([]);
	const [editMode, setEditMode] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);

	const getArtistList = debounce(() => {
		api__getArtistListInAgency(agencyId!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			const hasArtist = userData?.artistList.map((item) => item.artistId) || [];
			setArtistList(res.data.data.map((item) => ({ ...item, checkYn: hasArtist.includes(item.artistId) ? 'Y' : 'N' })));
		}).catch(console.error);
	}, 300);

	const getUserData = debounce(() => {
		api__getAgencyUserData(agencyId!, uid!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setUserData(res.data.data);
		}).catch(console.error);
	}, 300)

	useEffect(() => {
		getUserData();
		// eslint-disable-next-line
	}, []);

	const dupleEmailCheck = () => {
		if (!validateEmail(userData?.email || '')) {
			window.alert("이메일 형식을 확인해주세요.");
			return;
		}
		api__emailDupleCheck(userData?.email || '').then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			if (res.data.data.isDuplicated) {
				window.alert("중복된 이메일입니다.");
			} else {
				window.alert("사용이 가능한 이메일입니다.");
				setCheckEmail(true);
			}
		}).catch(console.error);
	}
	const deleteFn = debounce(() => {
		api__deleteAgencyUserData(agencyId!, uid!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.history.back();
		}).catch(console.error);
	}, 300)
	const updateFn = debounce(() => {
		api__putAgencyUserData(agencyId!, uid!, {
			email: userData?.email,
			name: userData?.name,
			position: userData?.position,
			artistIdList: userData?.artistList.map((item) => item.artistId) || []
		}).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setEditMode(false);
			setSaving(null);
			getArtistList();
		}).catch(console.error);
	}, 300)

	useEffect(() => {
		if (editMode && artistList.length === 0) {
			getArtistList();
		}
		// eslint-disable-next-line
	}, [editMode, artistList]);
	if (userData === null) return <MainLayout title="소속사(관리자) 파트너센터 / 계정 상세" children="" />
	return (
		<>
			{deleteModal && (
				<ModalMsgBox title="계정을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(false);
					if (!bool) { deleteFn() };
				}} extraBtn={'삭제'}>
					<ModalContentTypo>
						ㆍ해당 계정이 게시한 게시물은 삭제되지 않아요.
					</ModalContentTypo>
				</ModalMsgBox>
			)}
			<MainLayout title="소속사(관리자) 파트너센터 / 계정 발급">
				<Box p="38px 34px" pb="120px">
					<SBCBox mb="34px">
						<DCBox>
							<BackBtn />
							<PageTitle>계정 상세</PageTitle>
						</DCBox>
						<DCBox>
							<DarkContainedButton onClick={() => { setDeleteModal(true) }} sx={{ padding: '8px 16px', marginRight: '8px' }}>삭제</DarkContainedButton>
							{editMode && <DarkContainedButton sx={{ padding: '8px 16px', marginRight: '8px' }} onClick={() => {
								setEditMode(false);
								setUserData(_.cloneDeep(saving));
								setTimeout(() => {
									setSaving(null);
								}, 500)
							}}>수정 취소</DarkContainedButton>}
							<MainButton onClick={() => {
								if (editMode) {
									if (!checkEmail) {
										window.alert("이메일 중복체크를 해주세요.");
										return;
									}
									if (userData.name.trim() === '') {
										window.alert("담당자명을 입력해주세요.");
										return;
									}
									if (userData.artistList.length === 0) {
										window.alert("관리 아티스트를 선택해주세요.");
										return;
									}
									updateFn();
								} else {
									setEditMode(true);
									setSaving(_.cloneDeep(userData));
								}
							}}
							>{editMode ? '완료' : '수정'}</MainButton>
						</DCBox>
					</SBCBox>
					<Box mb="32px">
						<InfoComp title='소속사명' value={userData.agencyName} />
						<StretchBox>
							<InfoTitleBox>계정 이메일 ID</InfoTitleBox>
							<InfoTextBox sx={{ pr: "12px" }}>
								{editMode ? <TextInputInBBox style={{ width: '400px' }} value={userData.email}
									onChange={(e) => {
										setUserData((prev: any) => {
											return { ...prev, email: e.target.value }
										}); setCheckEmail(false)
									}} /> :
									userData.email}
								{editMode && (
									<DeleteButton
										style={{ marginLeft: 'auto' }}
										onClick={() => { if (!checkEmail) { dupleEmailCheck() } }}>
										{checkEmail ? "체크완료" : "중복체크"}
									</DeleteButton>
								)}
							</InfoTextBox>
						</StretchBox>
						{editMode ? (
							<InfoTxtComp title='담당자명' value={userData.name} onChange={(e) => {
								setUserData((prev: any) => {
									return { ...prev, name: e.target.value }
								})
							}} />
						) : <InfoComp title='담당자명' value={userData.name} />}
						{editMode ? (
							<InfoTxtComp title='직책' value={userData.position} onChange={(e) => {
								setUserData((prev: any) => {
									return { ...prev, position: e.target.value }
								})
							}} />
						) : <InfoComp title='직책' value={userData.position} />}
						<InfoComp title='계정 유형' value={userData.accountType} />
						<StretchBox>
							<InfoTitleBox>관리 아티스트</InfoTitleBox>
							<InfoTextBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
								<ThisSelectBox style={{ width: '200px' }} onClick={() => {
									if (editMode) {
										setArtistModal(_.cloneDeep(artistList))
									}
								}}>
									<SBCBox>
										<Typography variant="body2" color={"#222"}>아티스트 선택</Typography>
										<Icon icon={ICONIFY_ICONS.ARROW_DOWN} width={18} height={18} />
									</SBCBox>
								</ThisSelectBox>
								<hr style={{ width: '100%' }} />
								<Box>
									{userData.artistList.map((item, idx) => (<Box key={`artist_${idx}`}>ㆍ{item.nameKo}</Box>))}
								</Box>
							</InfoTextBox>
						</StretchBox>
					</Box>
				</Box>
			</MainLayout>
			{artistModal.length !== 0 && (
				<ModalMsgBox title="아티스트 선택(복수선택 가능)" setModal={(bool) => {
					const applyArr = _.cloneDeep(artistModal);
					if (!bool) {
						setArtistList(applyArr);
						setUserData((prev: any) => {
							if (prev === null) return prev;
							return { ...prev, artistList: applyArr.filter((item) => item.checkYn === 'Y') }
						})
						setTimeout(() => {
							setArtistModal([]);
						}, 500)
					}
					else setArtistModal([]);
				}} extraBtn={'저장'}>
					<ArtistListBox className="reset-scroll">
						{artistModal.map((item, idx) => (
							<label key={`artist_modal_${idx}`}>
								<input type='checkbox' checked={item.checkYn === 'Y'} onChange={() => {
									const temp = [...artistModal];
									temp[idx].checkYn = temp[idx].checkYn === 'Y' ? 'N' : 'Y';
									setArtistModal(temp);
								}} />
								{item.nameKo}
							</label>
						))}
					</ArtistListBox>
				</ModalMsgBox >
			)
			}
		</>
	);
}

const ThisSelectBox = styled(Box)({
	padding: '7px 16px',
	borderRadius: '8px',
	border: '1px solid #ccc',
	cursor: 'pointer',
	position: 'relative'
})
const ArtistListBox = styled(Box)({
	padding: '16px',
	overflow: 'auto',
	height: '300px',
	'& label': {
		marginBottom: '12px',
		display: 'block'
	},
	'& input': {
		marginRight: '8px'
	},

})