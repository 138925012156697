import { useEffect, useRef } from 'react';
import ModalBox from './ModalBox';
import { Box } from '@mui/material';
import { DCBox, MainButton, ModalBtn } from './CommonElement';
import { COLORS } from '../../Constant/COLORS';

type IProps = {
	setModal: React.Dispatch<React.SetStateAction<boolean>>;
	title?: string;
	children?: React.ReactNode;
	extraBtn?: string | null;
}

export default function ModalMsgBox({ setModal, title, children, extraBtn }: IProps) {
	const keyRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (keyRef.current) {
			keyRef.current.focus();
		}
	}, [])

	return (
		<ModalBox title={title}>
			<Box borderBottom={`1px solid ${COLORS.BORDER_GRAY}`} p="16px">
				{children}
			</Box>
			<DCBox p="16px" justifyContent={'flex-end'}>
				{extraBtn && <ModalBtn onClick={() => { setModal(true) }} onKeyDown={() => { }} forwardRef={null} extra={true} />}
				{extraBtn ? <MainButton onClick={() => { setModal(false) }} sx={{ p: '4px 12px', marginLeft: '8px' }}>
					{extraBtn}
				</MainButton>
					:
					<ModalBtn onClick={() => { setModal(false) }} onKeyDown={() => { }} forwardRef={keyRef} />
				}
			</DCBox>
		</ModalBox>
	)
}