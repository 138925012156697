import axios from 'axios';
import { makeHeader } from '../Utils/utils';

const rootUrl = process.env.REACT_APP_ENV === 'LOCAL' ? '' : process.env.REACT_APP_API_URL;

export const api__getAgencies = async () => {
  try {
    return await axios.get(`${rootUrl}/api/agencies`, makeHeader());
  } catch (error: unknown) {
    console.log('getAgencies error ::: ' + error);
    return null;
  }
};

export const api__getAgencyArtistList = async (agencyId = '') => {
  try {
    const config = agencyId ? { params: { agencyId }, ...makeHeader() } : makeHeader();
    return await axios.get(`${rootUrl}/api/agencies/artists/available`, config);
  } catch (error: unknown) {
    console.log('getAgencyArtistList error ::: ' + error);
    return null;
  }
};

export const api__postAgency = async (params: { agencyName: string; artistIdList: string[] }) => {
  try {
    return await axios.post(`${rootUrl}/api/agencies`, params, makeHeader());
  } catch (error: unknown) {
    console.log('postAgencies error ::: ' + error);
    return null;
  }
};
export const api__putAgencyData = async (agencyId: string, params: { agencyName: string; artistIdList: string[] }) => {
  try {
    return await axios.put(`${rootUrl}/api/agencies/${agencyId}`, params, makeHeader());
  } catch (error: unknown) {
    console.log('putAgencyData error ::: ' + error);
    return null;
  }
};
export const api__getAgencyData = async (agencyId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/${agencyId}`, makeHeader());
  } catch (error: unknown) {
    console.log('getAgencyData error ::: ' + error);
    return null;
  }
};
export const api__getAgencyUserList = async (agencyId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/${agencyId}/users`, makeHeader());
  } catch (error: unknown) {
    console.log('getAgencyUserList error ::: ' + error);
    return null;
  }
};
export const api__getAgencyManagerType = async (agencyId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/${agencyId}/account/type`, makeHeader());
  } catch (error: unknown) {
    console.log('getAgencyManagerType error ::: ' + error);
    return null;
  }
};
export const api__emailDupleCheck = async (email: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/users/email/duplicate`, {
      ...makeHeader(),
      params: { email },
    });
  } catch (error: unknown) {
    console.log('emailDupleCheck error ::: ' + error);
    return null;
  }
};
export const api__getArtistListInAgency = async (agencyId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/${agencyId}/artists`, makeHeader());
  } catch (error: unknown) {
    console.log('getArtistListInAgency error ::: ' + error);
    return null;
  }
};
export const api__postAgencyManager = async (
  agencyId: string,
  params: { email: string; name: string; position: string; accountTypeCode: string; artistIdList: string[] },
) => {
  try {
    return await axios.post(`${rootUrl}/api/agencies/${agencyId}/users`, params, makeHeader());
  } catch (error: unknown) {
    console.log('postAgencyManager error ::: ' + error);
    return null;
  }
};
export const api__deleteAgency = async (agencyId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/agencies/${agencyId}`, makeHeader());
  } catch (error: unknown) {
    console.log('deleteAgency error ::: ' + error);
    return null;
  }
};
export const api__getAgencyUserData = async (agencyId: string, uid: string) => {
  try {
    return await axios.get(`${rootUrl}/api/agencies/${agencyId}/users/${uid}`, makeHeader());
  } catch (error: unknown) {
    console.log('getAgencyUserData error ::: ' + error);
    return null;
  }
};
export const api__deleteAgencyUserData = async (agencyId: string, uid: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/agencies/${agencyId}/users/${uid}`, makeHeader());
  } catch (error: unknown) {
    console.log('deleteAgencyUserData error ::: ' + error);
    return null;
  }
};
export const api__putAgencyUserData = async (agencyId: string, uid: string, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/agencies/${agencyId}/users/${uid}`, params, makeHeader());
  } catch (error: unknown) {
    console.log('putAgencyUserData error ::: ' + error);
    return null;
  }
};
export const api__getNotices = async (artistId: string, limit: number, page: number) => {
  try {
    return await axios.get(`${rootUrl}/api/notices`, {
      ...makeHeader(),
      params: { limit, page, artistId },
    });
  } catch (error: unknown) {
    console.log('getNotices error ::: ' + error);
    return null;
  }
};
export const api__getNoticeArtistList = async () => {
  try {
    return await axios.get(`${rootUrl}/api/notices/artists/available`, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('getNoticeArtistList error ::: ' + error);
    return null;
  }
};
export const api__postNotices = async (params: any) => {
  try {
    return await axios.post(`${rootUrl}/api/notices`, params, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('postNotices error ::: ' + error);
    return null;
  }
};
export const api__getNoticeTemps = async () => {
  try {
    return await axios.get(`${rootUrl}/api/notices/temporary`, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('getNoticeTemps error ::: ' + error);
    return null;
  }
};
export const api__getNoticeData = async (noticeId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/notices/${noticeId}`, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('getNoticeData error ::: ' + error);
    return null;
  }
};
export const api__deleteNoticeData = async (noticeId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/notices/${noticeId}`, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('deleteNoticeData error ::: ' + error);
    return null;
  }
};
export const api__putNoticeData = async (noticeId: string, params: any) => {
  try {
    return await axios.put(`${rootUrl}/api/notices/${noticeId}`, params, { ...makeHeader() });
  } catch (error: unknown) {
    console.log('putNoticeData error ::: ' + error);
    return null;
  }
};
