export const ERRORS = {
  pick_artist: "아티스트를 선택해주세요.",
  fill_title: "제목을 입력해주세요.",
  pick_tag: "분류를 선택해주세요.",
  pick_date: "날짜를 선택해주세요.",
  fill_content: "내용을 입력주세요.",
  fill_subTitle: "부제목을 입력주세요.",
  upload_thumbImg: "썸네일 이미지를 업로드해주세요.",
  upload_mainImg: "메인 이미지를 업로드해주세요.",
  pick_period: "기간을 선택해주세요.",

  login_id: "아이디를 입력해주세요.",
  login_password: "비밀번호를 입력해주세요.",

  change_cur_password: "비밀번호를 입력해주세요.",
  change_new_password: "새 비밀번호를 입력해주세요.",
  change_check_password: "확인 비밀번호를 입력해주세요.",
  change_different: "새 비밀번호가 서로 다릅니다.",
  change_short: "8자리 이상으로 입력해주세요.",
  change_invalid: "비밀번호 조건을 확인하여주세요.",
  change_same: "비밀번호가 동일합니다.",

  create_artist_name_ko: "아티스트명(한글)을 채워주세요.",
  create_artist_name_en: "아티스트명(영문)을 채워주세요.",
  create_artist_genre: "분야 설명을 채워주세요.",
  create_artist_about: "한 줄 소개를 채워주세요.",
  create_artist_main_img: "메인 이미지를 등록해주세요.",
  create_artist_profile_img: "랜덤 아티스트 추천 이미지를 등록해주세요.",

  goodnews_create_content: "내용을 입력해주세요",
  goodnews_create_title: "제목을 입력해주세요",

  membership_fill_name: "멤버십명을 채워주세요.",
  membership_upload_badge: "배지 이미지를 업로드해주세요.",
  membership_benefits: "멤버십 혜택을 입력해주세요.",
  membership_detail: "멤버십 상품상세를 입력해주세요.",
  membership_amount: "유효하지 않은 가격입니다.",
};
