import { ROUTES } from "../Utils/routes";
import GoodNews from "../pages/good-news";
import GoodNewsCreate from "../pages/good-news/GoodNewsCreate";
import GoodNewsDetail from "../pages/good-news/GoodNewsDetail";
import GoodDuckMagazine from "../pages/goodduck-magazine";
import GoodduckMagazineCreate from "../pages/goodduck-magazine/GoodduckMagazineCreate";
import GoodduckMagazineDetail from "../pages/goodduck-magazine/GoodduckMagazineDetail";

export const homeContentsMenuList = [
  { path: ROUTES.GOODNEWS_MAIN + "/:page/:limit", component: GoodNews },
  { path: ROUTES.GOODNEWS_MAIN + "/:goodNewsSeq", component: GoodNewsDetail },
  { path: ROUTES.GOODNEWS_MAIN + "/new", component: GoodNewsCreate },

  { path: ROUTES.GOODDUCKMAGAZINE_MAIN + "/:page/:limit", component: GoodDuckMagazine },
  { path: ROUTES.GOODDUCKMAGAZINE_MAIN + "/:magazineId", component: GoodduckMagazineDetail },
  { path: ROUTES.GOODDUCKMAGAZINE_MAIN + "/new", component: GoodduckMagazineCreate },
]