import { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { BackBtn, DCBox, DeleteButton, FEBox, InfoComp, InfoTextBox, InfoTitleBox, InfoTxtComp, MainButton, ModalContentTypo, PageTitle, SBCBox, StretchBox, TextInputInBBox } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { logoutWithOutNavigate, validateEmail } from '../../Utils/utils';
import _, { debounce } from 'lodash';
import { api__emailDupleCheck, api__getAgencyManagerType, api__getArtistListInAgency, api__postAgencyManager } from '../../services/AgencieServices';
import { Icon } from '@iconify/react';
import { ICONIFY_ICONS } from '../../Constant/IMAGES';
import { AgencyArtistType, AgencyManagerAccountType } from '../../Utils/types';
import { useLocation, useParams } from 'react-router';


export default function PartnersAccountMemberNew() {
	const { agencyId } = useParams();
	const location = useLocation();
	const [email, setEmail] = useState('');
	const [checkEmail, setCheckEmail] = useState(false);
	const [name, setName] = useState('');
	const [position, setPosition] = useState('');
	const [accType, setAccType] = useState<AgencyManagerAccountType[]>([]);
	const [accChecked, setAccChecked] = useState('001');

	const [artistList, setArtistList] = useState<AgencyArtistType[]>([]);
	const [artistModal, setArtistModal] = useState<AgencyArtistType[]>([]);

	const [createModal, setCreateModal] = useState(false);

	const getArtistList = debounce(() => {
		api__getArtistListInAgency(agencyId!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setArtistList(res.data.data.map((item) => ({ ...item, checkYn: 'Y' })));
		}).catch(console.error);
	}, 300);

	//소속사에 입력 가능한 계정 유형을 가져오는 API
	const getAccType = debounce(() => {
		api__getAgencyManagerType(agencyId!).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			setAccType(res.data.data);
			setAccChecked(res.data.data[0].code);
		}).catch(console.error);
	}, 300)

	const createFn = async () => {
		api__postAgencyManager(agencyId!, {
			email, name, position,
			accountTypeCode: accChecked,
			artistIdList: artistList.filter(item => item.checkYn === 'Y').map(item => item.artistId)
		}).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			window.alert("계정이 생성되었습니다.");
			window.history.back();
		}).catch(console.error);
	}

	useEffect(() => {
		getArtistList();
		getAccType();
		// eslint-disable-next-line
	}, []);

	const dupleEmailCheck = () => {
		if (!validateEmail(email)) {
			window.alert("이메일 형식을 확인해주세요.");
			return;
		}
		api__emailDupleCheck(email).then((res) => {
			if (!res) return;
			const { code, msg } = res.data;
			if (code.includes("T")) {
				logoutWithOutNavigate(code);
				return;
			}
			if (code !== "S200") throw new Error(msg);
			if (res.data.data.isDuplicated) {
				window.alert("중복된 이메일입니다.");
			} else {
				window.alert("사용이 가능한 이메일입니다.");
				setCheckEmail(true);
			}
		}).catch(console.error);
	}
	if (accType.length === 0) return <MainLayout title="소속사(관리자) 파트너센터 / 계정 발급" children="" />
	return (
		<>
			{createModal && (
				<ModalMsgBox title="소속사를 추가하시겠어요?" setModal={(bool) => {
					setCreateModal(false);
					if (!bool) { createFn() };
				}} extraBtn={'생성'}>
					<ModalContentTypo>
						ㆍ소속사명 : {location.state.agencyName}
					</ModalContentTypo>
					<ModalContentTypo>
						ㆍ계정 이메일 ID : {email}
					</ModalContentTypo>
					<ModalContentTypo>
						ㆍ담당자명 : {name}
					</ModalContentTypo>
					<ModalContentTypo>
						ㆍ계정 유형 : {accType.find(item => item.code === accChecked)?.codeName || "매니저"}
					</ModalContentTypo>
					<ModalContentTypo>
						ㆍ관리 아티스트
					</ModalContentTypo>
					{artistList.map((al, idx) => (
						al.checkYn === 'Y' && <ModalContentTypo ml="12px" key={`artist_${idx}`}>ㆍ{al.nameKo}</ModalContentTypo>
					))}
				</ModalMsgBox>
			)}
			<MainLayout title="소속사(관리자) 파트너센터 / 계정 발급">
				<Box p="38px 34px" pb="120px">
					<SBCBox mb="34px">
						<DCBox>
							<BackBtn />
							<PageTitle>계정 발급(최초 발급 임시 비밀번호 : goodduck123!)</PageTitle>
						</DCBox>
						<MainButton onClick={() => {
							if (!checkEmail) {
								window.alert("이메일 중복체크를 해주세요.");
								return;
							}
							if (name.trim() === "") {
								window.alert("담당자명을 입력해주세요.");
								return;
							}
							if (artistList.filter(item => item.checkYn === 'Y').length === 0) {
								window.alert("관리 아티스트를 선택해주세요.");
								return;
							}
							if (accChecked === '001' && artistList.find(item => item.checkYn !== 'Y')) {
								window.alert("마스터 계정은 모든 아티스트를 선택해야합니다..");
								return;
							}
							setCreateModal(true)
						}}
						>계정 발급</MainButton>
					</SBCBox>
					<Box mb="32px">
						<InfoComp title='소속사명' value={location.state.agencyName} />
						<StretchBox>
							<InfoTitleBox>계정 이메일 ID</InfoTitleBox>
							<InfoTextBox sx={{ pr: "12px" }}>
								<TextInputInBBox style={{ width: '400px' }} value={email}
									onChange={(e) => { setEmail(e.target.value); setCheckEmail(false) }} />
								<DeleteButton
									style={{ marginLeft: 'auto' }}
									onClick={() => { if (!checkEmail) { dupleEmailCheck() } }}>
									{checkEmail ? "체크완료" : "중복체크"}
								</DeleteButton>
							</InfoTextBox>
						</StretchBox>
						<InfoTxtComp title='담당자명' value={name} onChange={(e) => { setName(e.target.value) }} />
						<InfoTxtComp title='직책' value={position} onChange={(e) => { setPosition(e.target.value) }} />
						<StretchBox>
							<InfoTitleBox>계정 유형</InfoTitleBox>
							<InfoTextBox>
								{accType.length === 1 ? "매니저" : accType.map((acc, idx) => <label>
									<input key={`account_type_${idx}`} type="radio" name="accType" value={acc.code} checked={accChecked === acc.code} onChange={(e) => { setAccChecked(e.target.value) }} style={{ marginRight: '4px' }} />
									{acc.codeName}
								</label>)}
							</InfoTextBox>
						</StretchBox>
						<StretchBox>
							<InfoTitleBox>관리 아티스트</InfoTitleBox>
							<InfoTextBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
								<ThisSelectBox style={{ width: '200px' }} onClick={() => { setArtistModal(_.cloneDeep(artistList)) }}>
									<SBCBox>
										<Typography variant="body2" color={"#222"}>아티스트 선택</Typography>
										<Icon icon={ICONIFY_ICONS.ARROW_DOWN} width={18} height={18} />
									</SBCBox>
								</ThisSelectBox>
								<hr style={{ width: '100%' }} />
								<Box>
									{artistList.map((item, idx) => (item.checkYn === 'Y' && <Box key={`artist_${idx}`}>ㆍ{item.nameKo}</Box>))}
								</Box>
							</InfoTextBox>
						</StretchBox>
					</Box>
					<FEBox sx={{ flexDirection: 'column', alignItems: 'flex-end' }}>
						<Typography variant='h6'>*마스터 계정은 소속사당 1개만 생성 가능합니다.</Typography>
						<Typography variant='h6'>*마스터 계정은 소속사의 모든 아티스트를 관리할 권한이 자동으로 부여됩니다.</Typography>
					</FEBox>
				</Box>
			</MainLayout>
			{artistModal.length !== 0 && (
				<ModalMsgBox title="아티스트 선택(복수선택 가능)" setModal={(bool) => {
					if (!bool) {
						setArtistList(_.cloneDeep(artistModal));
						setArtistModal([]);
					}
					else setArtistModal([]);
				}} extraBtn={'저장'}>
					<ArtistListBox className="reset-scroll">
						{artistModal.map((item, idx) => (
							<label key={`artist_modal_${idx}`}>
								<input type='checkbox' checked={item.checkYn === 'Y'} onChange={() => {
									const temp = [...artistModal];
									temp[idx].checkYn = temp[idx].checkYn === 'Y' ? 'N' : 'Y';
									setArtistModal(temp);
								}} />
								{item.nameKo}
							</label>
						))}
					</ArtistListBox>
				</ModalMsgBox >
			)
			}
		</>
	);
}

const ThisSelectBox = styled(Box)({
	padding: '7px 16px',
	borderRadius: '8px',
	border: '1px solid #ccc',
	cursor: 'pointer',
	position: 'relative'
})
const ArtistListBox = styled(Box)({
	padding: '16px',
	overflow: 'auto',
	height: '300px',
	'& label': {
		marginBottom: '12px',
		display: 'block'
	},
	'& input': {
		marginRight: '8px'
	},

})