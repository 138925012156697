import { api__dupleMemberEmail, api__dupleMemberName } from '../services/ManageUserService';
import { ArtistBasicInfo, ArtistMemberAccountInfo, MembershipType } from './types';
import { addCommas, addCommasFixed, detailTimeFormat, logoutWithOutNavigate, validateEmail } from './utils';
import { handleError } from './utils';

export const validBasicInfo = (basicInfo: ArtistBasicInfo) => {
  const { about, artistMainImgUrl, artistNameEn, artistNameKo, artistRecommendProfileImgUrl, genre } = basicInfo;
  if (artistNameKo.trim() === '') return 'create_artist_name_ko';
  if (artistNameEn.trim() === '') return 'create_artist_name_en';
  if (genre.trim() === '') return 'create_artist_genre';
  if (about.trim() === '') return 'create_artist_about';
  if (artistMainImgUrl === '') return 'create_artist_main_img';
  if (artistRecommendProfileImgUrl === '') return 'create_artist_profile_img';
  return null;
};
export const validMemberInfo = (memberInfo: ArtistMemberAccountInfo, idx: number) => {
  const { email, fullName, nickName, profileImgUrl, emailCheck, nameCheck } = memberInfo;
  const now = `${idx + 1}번째 멤버의`;
  if (profileImgUrl === '') return `${now} 이미지를 등록해주세요.`;
  if (email.trim() === '') return `${now} 이메일을 채워주세요.`;
  if (!validateEmail(email)) return `${now} 이메일 형식이 올바르지 않습니다.`;
  if (nickName.trim() === '') return `${now} 닉네임을 채워주세요.`;
  if (!emailCheck) return `${now} 이메일 중복확인을 해주세요.`;
  if (fullName !== null && fullName.trim() === '') return `${now} 활동명을 채워주세요.`;
  if (!nameCheck) return `${now} 닉네임 중복확인을 해주세요.`;
  return null;
};

export const refineMembershipHistory = (datas: MembershipType[]) => ({
  totalCash: addCommas(
    datas.reduce((acc, cur) => {
      acc += cur.amount * cur.userCnt;
      return acc;
    }, 0),
  ),
  MHistory: datas.map(item => {
    const { name, period, saleStartDt, saleEndDt, status, userCnt, userKrwCnt, userUsdCnt, amount, amountd, membershipSaleYn, productId } = item;
    return {
      sellStatus: status === '002' ? '판매만료' : membershipSaleYn === 'Y' ? '판매중' : '판매중지',
      name,
      productId,
      period: `${Number(period) / 30}개월(${period}일)`,
      salePeriod: `${detailTimeFormat(saleStartDt)} - ${status === '002' ? detailTimeFormat(saleEndDt) : '현재'}`,
      userCnt: addCommas(userCnt),
      userKrwCnt: addCommas(userKrwCnt),
      userUsdCnt: addCommas(userUsdCnt),
      amount: addCommas(amount),
      totalAmount: addCommas(amount * userCnt),
      amountd: addCommasFixed(amountd / 100),
      totalUSDAmount: addCommasFixed(amountd * userUsdCnt / 100)
    };
  }),
});

export const newArtistMember = (setMembersInfo: any) => {
  setMembersInfo(prev => {
    const clone = [...prev];
    clone.push({
      uid: null,
      email: '',
      fullName: '',
      nickName: '',
      profileImgUrl: '',
      profileId: null,
      emailCheck: false,
      nameCheck: false,
      useYn: 'Y',
    });
    return clone;
  });
};
export const newManager = (setManagerInfo: any) => {
  setManagerInfo({
    email: '',
    nickName: '',
    profileImgUrl: '',
    profileId: null,
    uid: null,
    useYn: 'Y',
  });
};
export const changeMemberInfo = (setMembersInfo: any, idx: number, column: string, value: string | boolean) => {
  setMembersInfo(prev => {
    const clone = [...prev];
    if (clone[idx].flag === 'default') clone[idx].flag = 'origin';
    clone[idx][column] = value;
    if (column === 'nickName') clone[idx].nameCheck = false;
    else if (column === 'email') clone[idx].emailCheck = false;
    return clone;
  });
};
export const changeManagerState = (setManagerInfo: any, column: string, value: string) => {
  setManagerInfo(prev => {
    if (prev !== null) {
      const clone = { ...prev };
      clone[column] = value;
      if (clone.flag === 'default') clone.flag = 'origin';
      return clone;
    } else return prev;
  });
};
export const checkingEmailDuple = (email: string, memberStateChange: any, idx: number) => {
  api__dupleMemberEmail(email)
    .then(result => {
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      if (result.data.data.isDuplicated) {
        window.alert(`중복된 이메일입니다.\nemail : ${email}`);
      } else {
        window.alert(`사용이 가능합니다.\nemail : ${email}`);
        memberStateChange(true, 'emailCheck', idx);
      }
    })
    .catch((error: unknown) => {
      handleError(error);
    });
};
export const checkingNickNameDuple = (nickname: string, memberStateChange: any, idx: number, artistId: string) => {
  api__dupleMemberName(artistId, nickname)
    .then(result => {
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      if (result.data.data.isDuplicated) {
        window.alert(`중복된 닉네임입니다.\n닉네임 : ${nickname}`);
      } else {
        window.alert(`사용이 가능합니다.\n닉네임 : ${nickname}`);
        memberStateChange(true, 'nameCheck', idx);
      }
    })
    .catch((error: unknown) => {
      handleError(error);
    });
};
