import { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import { api__delTipOffSchedule, api__getTipOffScheduleList } from '../../services/CalendarService';
import { TipOffInfo, TipOffListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { COLORS } from '../../Constant/COLORS';
import CommonTable from '../../Components/CommonTable';


export default function TipOffSchedule() {
  const navigate = useNavigate();
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<TipOffListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<TipOffInfo | null>(null);
  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="tipOffScheduleId"
      deleteDesc="title"

      mainTitle='캘린더 관리 / 일정 제보'
      pageTitle={`일정 제보 / ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.TIPOFFSCHEDULE_MAIN}

      getApiFn={api__getTipOffScheduleList}
      delApiFn={api__delTipOffSchedule}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 120 },
        { name: "계정 닉네임", width: 100 },
        { name: "일정", width: 120 },
        { name: "제목", width: 150 },
        { name: "제보 일시", width: 120 },
        { name: "아티스트 확인 여부", width: 50 },
        { name: "일정 업로드 여부", width: 50 },
        { name: "삭제 여부", width: 60 },
        { name: "관리", width: 100 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.uid === null ? "someone" : item.uploaderNickname}</TableCell>
            <TableCell>{detailTimeFormat(`${item.scheduleDt} ${item.scheduleTime}`)}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
            <TableCell>{item.checkYn}</TableCell>
            <TableCell>{item.rgstYn}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.TIPOFFSCHEDULE_MAIN + "/" + item.tipOffScheduleId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}