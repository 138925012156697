import { useEffect, useState, useRef } from 'react';
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import {
  DCBox,
  DeleteButton,
  ModalContentTypo,
  MyTable,
  OptionButton,
  PageTitle,
  SBCBox,
  THS,
} from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';

import { api__delReply, api__getReplyList } from '../../services/ReplyService';
import { ArtistId, ReplyInfo, ReplyListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat, getArtistList, logoutWithOutNavigate } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import ArtistSelectBox from '../../Components/common/ArtistSelectBox';
import MySelectBox from '../../Components/common/MySelectBox';
import { handleError } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import LimitBox from '../../Components/common/LimitBox';
import { COLORS } from '../../Constant/COLORS';

const contentList = {
  MAGAZINE: '굿덕 매거진',
  ARTIST_PROFILE: '아티스트 프로필',
  ARTIST_FEED: '아티스트 피드',
  MAIL_BOX: '굿덕 사서함',
  GOOD_TALK: '굿톡 다시듣기',
  GALLERY: '갤러리',
  FAN_ONLY: '팬끼리',
  INFO_ROOM: '자유게시판',
  CALENDAR: '캘린더',
};

export default function Reply() {
  const navigate = useNavigate();
  const { page, limit, contentsType, artistId } = useParams();
  const [dataListInfo, setDataListInfo] = useState<ReplyListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<ReplyInfo | null>(null);

  const isGetListProgress = useRef(false);

  const [aName, setAName] = useState('아티스트 선택');
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const [tName, setTName] = useState(contentList[contentsType || 'MAGAZINE']);
  const [typeIsDropped, setTypeIsDropped] = useState(false);

  const getMyList = async () => {
    if (isGetListProgress.current) return;
    isGetListProgress.current = true;
    try {
      const result = await api__getReplyList(page || '1', limit || '10', contentsType || 'MAGAZINE', artistId || '');
      if (!result) return;

      const { code, msg } = result.data;
      if (code.includes('T')) {
        window.alert('토큰이 만료되었습니다.');
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      setDataListInfo(result.data.data);
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetListProgress.current = false;
    }
  };

  const delMyData = async (id: string) => {
    try {
      const result = await api__delReply(contentsType || 'MAGAZINE', id);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      getMyList();
    } catch (error: unknown) {
      handleError(error);
    }
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit, contentsType, artistId]);

  useEffect(() => {
    getArtistList(setArtistList);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (artistList.length !== 0 && artistId) {
      setAName(artistList.find(item => item.artistId === artistId)?.artistNameKo || '아티스트 선택');
    }
    // eslint-disable-next-line
  }, [artistList]);

  useEffect(() => {
    if (artistList.length !== 0) {
      setAName(artistList.find(item => item.artistId === artistId)?.artistNameKo || '아티스트 선택');
    }
    // eslint-disable-next-line
  }, [artistList]);

  const changeSelect = (aVal: string, nameVal: string) => {
    navigate(ROUTES.REPLY_MAIN + `/1/${limit}/${contentsType}${aVal !== '' && aVal !== 'ALL' ? `/${aVal}` : ''}`);
    setAName(nameVal);
    setIsDropped(false);
  };

  const changeContentSelect = (cVal: string, nameVal: string) => {
    navigate(ROUTES.REPLY_MAIN + `/1/${limit}/${cVal}${artistId ? `/${artistId}` : ''}`);
    setTName(nameVal);
    setTypeIsDropped(false);
  };

  return (
    <>
      {deleteModal && (
        <ModalMsgBox
          title="댓글을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) delMyData(deleteModal.reviewId);
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ내용 : {deleteModal.content}</ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title="댓글 관리 / 댓글 리스트">
        <Box p="34px">
          <SBCBox mb="12px" style={{ alignItems: 'flex-start' }}>
            <Box>
              <PageTitle mb="14px">
                댓글 / 위치 : {tName} / 개수 : {addCommas(dataListInfo?.totalCount || 0)}개
              </PageTitle>
              <Typography variant="body2">ㆍ반드시 "댓글 위치"를 선택해야 조회되어요.</Typography>
            </Box>
            <DCBox>
              <MySelectBox
                value={tName}
                width={332}
                isDefault={false}
                isDropped={typeIsDropped}
                setIsDropped={setTypeIsDropped}
              >
                {Object.entries(contentList).map(([contentType, contentName], idx) => (
                  <OptionButton
                    key={`reple_select_${idx}`}
                    className={contentsType === contentType ? 'selected' : ''}
                    onClick={() => {
                      changeContentSelect(contentType, contentName);
                    }}
                  >
                    {contentName}
                  </OptionButton>
                ))}
              </MySelectBox>
              <Box mr={'40px'} />
              <ArtistSelectBox
                aName={aName}
                artistId={artistId || ''}
                isDropped={isDropped}
                artistList={artistList}
                changeSelect={changeSelect}
                setIsDropped={setIsDropped}
              />
            </DCBox>
          </SBCBox>
          <LimitBox
            limit={limit || '10'}
            page={page || '1'}
            route={ROUTES.REPLY_MAIN}
            subUrl={`/${contentsType}${artistId ? `/${artistId}` : ''}`}
          />
          <Box mt="18px">
            <MyTable>
              <THS
                ths={[
                  { name: '#', width: 40 },
                  { name: '아티스트명', width: 150 },
                  { name: '계정 닉네임', width: 150 },
                  { name: '내용', width: 200 },
                  { name: '업로드 일시', width: 120 },
                  { name: '삭제여부', width: 80 },
                  { name: '관리', width: 120 },
                ]}
              />
              <TableBody>
                {dataListInfo &&
                  dataListInfo.data.map((item, uidx) => (
                    <TableRow key={`user-${uidx}`}>
                      <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                      <TableCell sx={{ color: item.artistUseYn === 'N' ? COLORS.SIDEBAR_GRAY : '#222' }}>
                        {item.artistNameKo}
                        {item.artistUseYn === 'N' ? '(비)' : ''}
                      </TableCell>
                      <TableCell>{item.uid === null ? 'someone' : item.uploaderNickname}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          sx={{ wordWrap: 'break-word', width: `${((window.innerWidth - 300) / 100) * 23}px` }}
                        >
                          {item.content}
                        </Typography>
                      </TableCell>
                      <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
                      <TableCell>{item.openYn === 'Y' ? 'N' : 'Y'}</TableCell>
                      <TableCell>
                        {item.openYn === 'Y' && (
                          <DeleteButton
                            onClick={() => {
                              setDeleteModal(item);
                            }}
                          >
                            삭제
                          </DeleteButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </MyTable>
            {dataListInfo && (
              <Pages
                pageInfo={dataListInfo}
                getList={p => {
                  navigate(
                    ROUTES.REPLY_MAIN + `/${String(p)}/${limit}/${contentsType}${artistId ? `/${artistId}` : ''}`,
                  );
                }}
              />
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}
