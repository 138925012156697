import { CenterBox } from "./CommonElement";

type IProps = {
	children: React.ReactNode;
}

export default function SimpleModal({ children }: IProps) {
	return (
		<CenterBox position='fixed' left='0' top='0' zIndex={100}>
			{children}
		</CenterBox>
	)
}