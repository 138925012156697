import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { handleError } from '../../Utils/utils';

import { api__emailVerify } from '../../services/AuthService';

export default function EmailVerify() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const isProgress = useRef(false);
  useEffect(() => {
    const c = search.replace('?code=', '');
    const verifying = async () => {
      if (isProgress.current) return;
      isProgress.current = true;
      try {
        const result = await api__emailVerify(c);
        if (!result) return;
        const { code, msg } = result.data;
        if (code !== 'S200') throw new Error(msg);
        window.alert('이메일 인증이 완료되었습니다!');
      } catch (error: unknown) {
        handleError(error);
      } finally {
        isProgress.current = true;
        navigate('/auth');
      }
    };
    verifying();
    // eslint-disable-next-line
  }, []);
  return <div />;
}
