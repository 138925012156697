import { useContext, useState } from 'react';
import { Box } from "@mui/material";
import { BackBtn, DCBox, FlexBox, MainButton, ModalContentTypo, PageTitle, SBCBox } from "../common/CommonElement";
import { COLORS } from "../../Constant/COLORS";
import ModalMsgBox from '../common/ModalMsgBox';
import { ArtistDetailContext } from '../../pages/manage/ArtistDetail';

type IProps = {
  updateActivation: Function;
  editActivate: () => void;
}

export default function ArtistDetailHeader({ editActivate, updateActivation }: IProps) {
  const { editMode, basicInfo } = useContext(ArtistDetailContext);
  const [activeModal, setActiveModal] = useState('');

  return <>
    {activeModal !== '' && (
      <ModalMsgBox
        title={`해당 아티스트를 ${activeModal === 'active' ? '' : '비'}활성화 하시겠어요?`}
        setModal={(bool) => {
          setActiveModal('');
          if (!bool) {
            updateActivation(activeModal === 'active' ? "Y" : "N");
          }
        }} extraBtn={activeModal === 'active' ? '활성화' : '비활성화'}>
        {basicInfo?.artistUseYn === 'Y' && <ModalContentTypo>
          ㆍ아티스트 비활성화를 하는 경우 현재 아티스트에 입덕 되어 있는 팬들은 모두 입덕 취소처리됩니다.
        </ModalContentTypo>}
      </ModalMsgBox>
    )}
    <SBCBox p="38px 34px 12px 34px">
      <Box>
        <DCBox mb="29px">
          <BackBtn />
          <PageTitle>아티스트 상세 관리(상태: {basicInfo?.artistUseYn === "Y" ? "활성화" : "비활성화"})</PageTitle>
        </DCBox>
      </Box>
      <FlexBox>
        <MainButton sx={{ mr: '16px' }} style={basicInfo?.artistUseYn === "Y" ? { backgroundColor: COLORS.BORDER_GRAY, color: '#222' } : {}} onClick={() => { setActiveModal(basicInfo?.artistUseYn === "Y" ? "inactive" : "active") }}>
          {basicInfo?.artistUseYn === "Y" ? "아티스트 비활성화" : "아티스트 활성화"}
        </MainButton>
        <MainButton onClick={editActivate}>{editMode ? "수정 적용" : "수정"}</MainButton>
      </FlexBox>
    </SBCBox>
  </>
}