import { ROUTES } from "../Utils/routes";
import ArtistDetail from "../pages/manage/ArtistDetail";
import ArtistMemberDetail from "../pages/manage/ArtistMemberDetail";
import ArtistMemberNew from "../pages/manage/ArtistMemberNew";
import ArtistMembershipManage from "../pages/manage/ArtistMembershipManage";
import ArtistMembershipNew from "../pages/manage/ArtistMembershipNew";
import ArtistNew from "../pages/manage/ArtistNew";
import ManageArtist from "../pages/manage/ManageArtist";
import ManageUser from "../pages/manage/ManageUser";
import UserDetail from "../pages/manage/UserDetail";

export const userManageMenuList = [
  { path: ROUTES.SIGNUPUSERS + "/:page/:limit/:queryKey?/:keyword?", component: ManageUser },
  { path: ROUTES.SIGNUPUSERS + "/:uid", component: UserDetail },

  { path: ROUTES.SIGNUPARTIST + "/:page/:limit/:keyword?", component: ManageArtist },
  { path: ROUTES.SIGNUPARTIST + "/new", component: ArtistNew },
  { path: ROUTES.SIGNUPARTIST + "/:artistId", component: ArtistDetail },
  { path: ROUTES.SIGNUPARTIST + "/:artistId/member-new", component: ArtistMemberNew },
  { path: ROUTES.SIGNUPARTIST + "/:artistId/detail/:uid", component: ArtistMemberDetail },
  { path: ROUTES.SIGNUPARTIST + "/membership-new/:artistId", component: ArtistMembershipNew },
  { path: ROUTES.SIGNUPARTIST + "/membership/:artistId/:productId", component: ArtistMembershipManage },
]