import { Box, Checkbox, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";

interface CalendarPickerProps {
  isBooked: boolean; // 예약된 시간이 있는지 여부
  editMode?: boolean;
  isPicked: boolean; // 체크박스 체크 여부
  setIsPicked: (value: boolean) => void;
  bookedTime: Date | null;
  setBookedTime: (value: Date | null) => void;
  isPublished?: boolean; // 게시글이 발행되었는지 여부
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({ isBooked, editMode, isPicked, setIsPicked, bookedTime, setBookedTime, isPublished }) => {

  // 체크박스 변경 핸들러
  // editMode가 활성화될 때 isBooked로 isPicked를 초기화
  useEffect(() => {
    if (editMode) {
      setIsPicked(isBooked); // editMode가 활성화되면 isBooked 값을 isPicked로 설정
    }
  }, [editMode, isBooked]);

  const handlePicked = (event) => {
    setIsPicked(event.target.checked); // 체크박스 변경 시 isPicked 업데이트
    if (!event.target.checked) {
      setBookedTime(null); // 체크 해제 시 선택된 시간 초기화
    }
  };

  // 날짜 변경 핸들러
  const handleBookedTime = (newValue: Dayjs | null) => {
    if (newValue) {
      setBookedTime(newValue.toDate()); // Dayjs 객체를 Date로 변환하여 저장
    }
  };
  
  return (
      <>
        {isPublished ? <Box>게시됨</Box>
        :
        <>
        <Checkbox
          color='info'
          size='medium'
          checked={editMode ? isPicked : isBooked}
          onChange={handlePicked}
          sx={{my:1}}
          disabled={!editMode}
        />
        {(isPicked || isBooked) && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label="예약 공개 시간을 선택해 주세요"
                value={bookedTime ? dayjs(bookedTime) : null}
                onChange={handleBookedTime}
                minutesStep={1}
                minDateTime={dayjs()} // 현재 시각 이전의 시간 선택 방지
                format="YYYY.MM.DD A hh:mm"
                disabled={!editMode}
                closeOnSelect={false}
                slots={{ textField: TextField }}  // TextField를 전달하는 방식으로 수정
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      shrink: undefined,  // 기본 shrink 동작을 따름 (포커스 시 라벨 작아짐)
                      sx: {
                        maxWidth: '100%',
                        // 포커스 시나 shrink 상태일 때 스타일 적용
                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(14px, -9px) scale(0.75)',
                        },
                        transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',  // 부드러운 변환 효과
                        whiteSpace: 'nowrap',
                        overflow: 'visible',
                      },
                    },
                  },
                }}
            />
            </LocalizationProvider>
        )}
        </>}
      </>
  );
};

export default CalendarPicker;
