import { useEffect, useState, useRef } from 'react';
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import MainLayout from '../../layout/MainLayout';
import {
  DetailButton,
  FEBox,
  MainButton,
  ModalBtn,
  ModalContentTypo,
  MyTable,
  PageTitle,
  SecondaryButton,
  THS,
} from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';

import { BoardReportListInfo, ReportPopupInfo, ReportType } from '../../Utils/types';
import { addCommas, getListPL, logoutWithOutNavigate, updateReportStatus } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { getCodes } from '../../Utils/utils';
import { detailTimeFormat } from '../../Utils/utils';
import { useNavigate, useParams } from 'react-router';
import { api__delCheerUp, api__getCheerUp } from '../../services/ArtistPageService';
import { api__getReportPostList } from '../../services/RerportService';
import { ROUTES } from '../../Utils/routes';
import ModalBox from '../../Components/common/ModalBox';
import { COLORS } from '../../Constant/COLORS';

import { handleError } from '../../Utils/utils';

export default function BoardReport() {
  const navigate = useNavigate();
  const { page, limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<BoardReportListInfo | null>(null);
  const [groupType, setGroupType] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState<ReportPopupInfo | null>(null);
  const [detailModal, setDetailModal] = useState<ReportPopupInfo | null>(null);

  const isGetListProgress = useRef(false);
  const codeProcess = useRef(false);

  const getMyList = () => {
    getListPL(api__getReportPostList, setDataListInfo, page || '1', limit || '10');
  };

  const getMyCodes = () => {
    getCodes(codeProcess, 'REPORT_TYPE', setGroupType);
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    getMyCodes();
  }, []);

  const cheerPopup = async (item: ReportType) => {
    if (isGetListProgress.current) return;
    isGetListProgress.current = true;
    try {
      const result = await api__getCheerUp(item.contentsId);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      const { artistNameKo, artistUseYn, content, openYn } = result.data.data;
      if (item.reportResult !== '처리완료_삭제' && openYn === 'N') {
        updateReportStatus({ result: '처리완료_삭제', seq: item.seq }, getMyList);
      }
      setDetailModal({ ...item, artistNameKo, artistUseYn, content, openYn });
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isGetListProgress.current = false;
    }
  };

  const clickDetail = (item: ReportType) => {
    switch (item.type) {
      case '005': {
        // 갤러리
        navigate(ROUTES.GALLERY_MAIN + '/' + item.contentsId, {
          state: {
            report: true,
            reportInfo: item,
          },
        });
        break;
      }
      case '007': {
        // 응원하기(팝업)
        cheerPopup(item);
        break;
      }
      case '008': {
        // 팬끼리
        navigate(ROUTES.FANONLY_MAIN + '/' + item.contentsId, {
          state: {
            report: true,
            reportInfo: item,
          },
        });
        break;
      }
      case '010': {
        // 자유게시판(기존 정보방)
        navigate(ROUTES.INFOROOM_MAIN + '/' + item.contentsId, {
          state: {
            report: true,
            reportInfo: item,
          },
        });
        break;
      }
      case '014': {
        // 제보하기
        navigate(ROUTES.TIPOFFSCHEDULE_MAIN + '/' + item.contentsId, {
          state: {
            report: true,
            reportInfo: item,
          },
        });
        break;
      }
      case '015': {
        // 피드상세
        navigate(ROUTES.ARTISTFEED_MAIN + '/' + item.contentsId, {
          state: {
            report: true,
            reportInfo: item,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  const delCheerData = async (item: ReportType) => {
    try {
      const result = await api__delCheerUp(item.contentsId);
      if (!result) return;
      const { code, msg } = result.data;
      if (code.includes('T')) {
        logoutWithOutNavigate(code);
        return;
      }
      if (code !== 'S200') throw new Error(msg);
      window.alert('삭제되었습니다.');
    } catch (error: unknown) {
      handleError(error);
    }
  }

  const commentReportHandler = async (type: string, reportInfo: ReportPopupInfo) => {
    const { seq } = reportInfo;
    if (type === 'keep') {
      updateReportStatus({ result: '처리완료_유지', seq }, getMyList);
    } else {
      delCheerData(reportInfo);
      updateReportStatus({ result: '처리완료_삭제', seq }, getMyList);
    }
    setDetailModal(null);
  };

  return (
    <>
      {detailModal && (
        <ModalBox title="응원글 상세">
          <Box borderBottom={`1px solid ${COLORS.BORDER_GRAY}`} p="16px">
            <ModalContentTypo>
              <Typography variant="body1">
                ㆍ응원글이 위치한 아티스트명 : {detailModal.artistNameKo}
                {detailModal.artistUseYn === 'N' ? '(비)' : ''}
              </Typography>
              <Typography variant="body1">ㆍ신고유형 : {detailModal.reportContent}</Typography>
              <Typography variant="body1">
                ㆍ신고한 계정 닉네임 : {detailModal.uid === null ? 'someone' : detailModal.reportNickname}
              </Typography>
              <Typography variant="body1">ㆍ신고 일시 : {detailTimeFormat(detailModal.rgstDt)}</Typography>
              <Typography variant="body1">ㆍ응원글 내용</Typography>
              <Typography variant="body1" pl="14px">
                {detailModal.content}
              </Typography>
            </ModalContentTypo>
          </Box>
          <FEBox p="16px">
            <ModalBtn
              onClick={() => {
                setDetailModal(null);
              }}
              onKeyDown={() => { }}
              forwardRef={null}
              extra={true}
            />
            {detailModal.openYn === 'Y' ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    if (detailModal.reportResult !== '처리완료_유지') commentReportHandler('keep', detailModal);
                  }}
                  style={{
                    padding: '4px 12px',
                    marginLeft: '8px',
                    opacity: detailModal.reportResult === '처리완료_유지' ? 0.6 : 1,
                  }}
                >
                  {detailModal.reportResult === '처리완료_유지' ? '유지된 게시물' : '유지'}
                </SecondaryButton>
                <MainButton
                  onClick={() => {
                    setDeleteModal(detailModal);
                    setDetailModal(null);
                  }}
                  sx={{ p: '4px 12px', marginLeft: '8px' }}
                >
                  삭제
                </MainButton>
              </>
            ) : (
              <MainButton onClick={() => { }} sx={{ p: '4px 12px', marginLeft: '8px', opacity: 0.6 }}>
                삭제된 게시물
              </MainButton>
            )}
          </FEBox>
        </ModalBox>
      )}
      {deleteModal && (
        <ModalMsgBox
          title="게시물을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal(null);
            if (!bool) commentReportHandler('delete', deleteModal);
          }}
          extraBtn={'삭제'}
        ></ModalMsgBox>
      )}
      <MainLayout title="신고내역 관리 / 게시물 리스트">
        <Box p="34px">
          <PageTitle mb="12px">신고 내역 : 게시물 / {addCommas(dataListInfo?.totalCount || 0)}개</PageTitle>
          <Box mt="24px">
            <MyTable>
              <THS
                ths={[
                  { name: '#', width: 40 },
                  { name: '게시물 종류', width: 100 },
                  { name: '신고 유형', width: 120 },
                  { name: '신고한 계정 닉네임', width: 100 },
                  { name: '신고 일시', width: 120 },
                  { name: '관리', width: 80 },
                  { name: '처리여부', width: 60 },
                ]}
              />
              <TableBody>
                {dataListInfo &&
                  dataListInfo.data.map((item, uidx) => (
                    <TableRow key={`user-${uidx}`}>
                      <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                      <TableCell>{groupType[item.type]}</TableCell>
                      <TableCell>{item.reportContent}</TableCell>
                      <TableCell>{item.uid === null ? 'someone' : item.reportNickname}</TableCell>
                      <TableCell>{detailTimeFormat(item.rgstDt)}</TableCell>
                      <TableCell>
                        <DetailButton
                          style={{ marginRight: 0 }}
                          onClick={() => {
                            clickDetail(item);
                          }}
                        >
                          상세
                        </DetailButton>
                      </TableCell>
                      <TableCell>{item.reportResult || '미처리'}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </MyTable>
            {dataListInfo && (
              <Pages
                pageInfo={dataListInfo}
                getList={p => {
                  navigate(ROUTES.BOARD_REPORT_MAIN + `/${String(p)}/${limit}`);
                }}
              />
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
};