import { useEffect, useRef, useState } from 'react';
import { Button, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router';
import { ReactSVG } from 'react-svg';
import { MdAccountCircle } from 'react-icons/md';
import { FaLock } from 'react-icons/fa';

import { CenterBox, DCBox, ModalContentTypo, TextInput } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { COLORS } from '../../Constant/COLORS';
import { SVG_IMAGES } from '../../Constant/IMAGES';

import { enterFn, toHash } from '../../Utils/utils';
import { api__login } from '../../services/AuthService';
import { setCookie } from '../../Utils/cookies';

import { handleError } from '../../Utils/utils';

const ThisInput = styled(TextInput)({
  fontSize: '18px',
  borderRadius: '6px',
  width: '240px',
  padding: '9px 16px',
  backgroundColor: 'white',
  marginLeft: '13px',
});

export default function Login() {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [verifyModal, setVerifyModal] = useState(false);

  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const isProgress = useRef(false);

  const completeFn = async () => {
    // 이미 진행중일 경우 반환
    if (isProgress.current) return;
    isProgress.current = true;

    try {
      if (id.trim() === '') throw new Error('login_id');
      if (pw.trim() === '') throw new Error('login_password');
      const result = await api__login(id, toHash(pw));
      if (!result) return;
      const { code, msg } = result.data;
      switch (code) {
        case 'S200': {
          setCookie('authCookie', result.data.data);
          navigate('/');
          break;
        }
        case 'L001': {
          setErrorMsg('ㆍ비밀번호가 일치하지 않아요.');
          return;
        }
        case 'L002': {
          setErrorMsg('ㆍ존재하지 않는 ID에요.');
          return;
        }
        case 'L003': {
          setVerifyModal(true);
          break;
        }
        default:
          throw new Error(msg);
      }
    } catch (error: unknown) {
      handleError(error);
    } finally {
      isProgress.current = false;
    }
  };

  // 페이지 진입시 아이디쪽으로 커서
  useEffect(() => {
    idRef.current?.focus();
  }, []);

  return (
    <>
      {verifyModal && (
        <ModalMsgBox title="인증되지 않은 계정입니다." setModal={setVerifyModal}>
          <ModalContentTypo>ㆍ이메일 인증을 완료해주세요.</ModalContentTypo>
          <ModalContentTypo>ㆍ받은 편지함에 인증 이메일이 없다면, 스팸 메일함을 확인해주세요.</ModalContentTypo>
        </ModalMsgBox>
      )}
      <CenterBox>
        <DCBox flexDirection="column" width="362px" height="297px" bgcolor="#D9D9D9" borderRadius="6px" p="25px 40px">
          <DCBox mb="37px">
            <ReactSVG src={SVG_IMAGES.GOODDUCK_HEAD} style={{ transform: 'translateY(2px)' }} />
            <Typography variant="h4" ml="8px" fontWeight={800}>
              Goodduck Admin
            </Typography>
          </DCBox>
          <DCBox mb="16px">
            <MdAccountCircle size={24} />
            <ThisInput
              value={id}
              onChange={e => {
                setId(e.target.value);
                setErrorMsg('');
              }}
              type="text"
              placeholder="ID"
              ref={idRef}
              onKeyDown={e => {
                enterFn(e, completeFn);
              }}
            />
          </DCBox>
          <DCBox mb="17px">
            <FaLock size={22} />
            <ThisInput
              value={pw}
              onChange={e => {
                setPw(e.target.value);
                setErrorMsg('');
              }}
              type="password"
              placeholder="Password"
              ref={pwRef}
              onKeyDown={e => {
                enterFn(e, completeFn);
              }}
            />
          </DCBox>
          <Typography height={'18px'} width={'80%'} variant="h5" fontSize={'16px'} color="error">
            {errorMsg}
          </Typography>
          <DCBox mt="17px">
            <Button
              sx={{
                width: '135px',
                bgcolor: COLORS.BLACK,
                fontWeight: 600,
                marginRight: '13px',
                color: 'white',
                backgroundColor: COLORS.MAIN,
              }}
              onClick={completeFn}
            >
              로그인
            </Button>
            <Button
              sx={{
                width: '135px',
                bgcolor: COLORS.BLACK,
                fontWeight: 600,
                color: '#222',
                backgroundColor: COLORS.SECOND,
              }}
              onClick={() => {
                navigate('/auth/reset-password');
              }}
            >
              비밀번호 찾기
            </Button>
          </DCBox>
        </DCBox>
      </CenterBox>
    </>
  );
}
