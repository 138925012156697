export const SVG_IMAGES = {
  EMPTY_PROFILE: `/img/artist/empty_profile.svg`,
  MIDDLE_EMPTY_PROFILE: `/img/artist/middle_empty_profile.svg`,
  GOODDUCK_HEAD: `/img/auth/goodduck-head.svg`,
  HAMBURGER: `/img/home/list_icon.svg`,
  BIG_HAMBURGER: `/img/hamburger_icon.svg`,
  QUESTION_CIRCLE: `/img/question_circle_icon.svg`,
  DATE_PICKER: `/img/date_picker_icon.svg`,
  UPLOAD_IMAGE: `/img/upload_image_icon.svg`,
  CHECK: "/img/check_icon.svg",
  FIXED: "/img/fixed-icon.png",
  CHECK_EMPTY: "/img/check-empty.png",
  CHECK_FULL: "/img/check-full.png",
  TRASH_CAN: "/img/trash-can.png",
};

export const ICONIFY_ICONS = {
  CANCEL: "iconoir:cancel",
  DELETE_CIRCLE: "iconoir:delete-circle",
  EYE_EMPTY: "iconoir:eye-empty",
  EYE_OFF: "iconoir:eye-off",
  ARROW_LEFT: "octicon:arrow-left-24",
  ARROW_RIGHT: "octicon:arrow-right-24",
  ARROW_UP: "octicon:chevron-up-24",
  ARROW_DOWN: "octicon:chevron-down-24",
  NAV_ARROW_RIGHT: "iconoir:nav-arrow-right",
  CHAT_BUBBLE_EMPTY: "iconoir:chat-bubble-empty",
  CHAT_BUBBLE: "iconoir:chat-bubble",
  BELL_LIGHT: "ph:bell-light",
  BOOKMARK_FILL: "octicon:bookmark-fill-24",
  BOOKMARK_EMPTY: "octicon:bookmark-24",
  LINK: "iconoir:link",
  EDIT_PENCIL: "iconoir:edit-pencil",
};
